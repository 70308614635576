export var HeaderActionTypes;
(function (HeaderActionTypes) {
    HeaderActionTypes["GET_AB_FEATURE_REQUEST"] = "GET_AB_FEATURE_REQUEST";
    HeaderActionTypes["GET_AB_FEATURE_SUCCESS"] = "GET_AB_FEATURE_SUCCESS";
    HeaderActionTypes["GET_AB_FEATURE_FAILURE"] = "GET_AB_FEATURE_FAILURE";
    HeaderActionTypes["GET_USER_OWN_PREFERENCES_REQUEST"] = "GET_USER_OWN_PREFERENCES_REQUEST";
    HeaderActionTypes["GET_USER_OWN_PREFERENCES_SUCCESS"] = "GET_USER_OWN_PREFERENCES_SUCCESS";
    HeaderActionTypes["GET_USER_OWN_PREFERENCES_FAILURE"] = "GET_USER_OWN_PREFERENCES_FAILURE";
    HeaderActionTypes["GET_USER_DETAILS"] = "GET_USER_DETAILS";
    HeaderActionTypes["GET_USER_DETAILS_SUCCESS"] = "GET_USER_DETAILS_SUCCESS";
    HeaderActionTypes["GET_USER_DETAILS_FAILURE"] = "GET_USER_DETAILS_FAILURE";
    HeaderActionTypes["GET_COMPANY_INFO_REQUEST"] = "GET_COMPANY_INFO_REQUEST";
    HeaderActionTypes["GET_COMPANY_INFO_SUCCESS"] = "GET_COMPANY_INFO_SUCCESS";
    HeaderActionTypes["SET_SOLARGRAF_ACCESS"] = "SET_SOLARGRAF_ACCESS";
})(HeaderActionTypes || (HeaderActionTypes = {}));
