const accountInfo = {
  accountInfo: {
    account_information: 'Informacje o moim koncie',
    services: 'Moje usługi',
    data_privacy: 'Moje dane i prywatność',
    benefits: 'Korzyści',
    company: 'Profil firmy',
    branches: 'Oddziały',
    users: 'Użytkownicy',
    area_coverage: 'Obszary usług',
    lead_program: 'Preferencje dotyczące potencjalnych klientów',
    ein_program: 'Sieć potencjalnych klientów i instalatorów Enphase',
    ac_module_admin: 'Zarządzanie modułem AC',
    training_dashboard: 'Szkolenia',
    enphase_store: 'Sklep Enphase',
    product_warranty_extension: 'Przedłużenie gwarancji na produkt',
    lpp: 'Program ochrony pracy',
    permit_assistance: 'Pomoc w uzyskaniu zezwolenia',
    incentive_programs: 'Programy motywacyjne',
    ein_merchandise: 'Towary EIN',
    design_tool: 'Narzędzie ofertowe i do projektowania',
    design_review_service: 'Usługa recenzowania projektu',
    bom: 'Kalkulator BOM',
    financing: 'Finansowanie',
    training: 'Szkolenia',
    collaterals: 'Zabezpieczenia',
    my_leads: 'Moi potencjalni klienci',
    lead_details: 'Szczegóły zapytania ofertowego',
    api_access: 'Dostęp do interfejsu API',
    third_party: 'Raportowanie podmiotów trzecich',
    profiles: 'Profile sieci',
    createBranch: 'Dodaj oddział',
    branch_profile: 'Profil oddziału',
    new: 'Nowe',
    applications: 'Zastosowania',
    createUser: 'Dodaj nowego użytkownika',
    user_profile: 'Profil użytkownika',
    platform_discounts: 'Zniżki na platformę',
    account_details: 'Szczegóły konta',
    preferences: 'Preferencje',
    change_password: 'Zmień hasło',
    account: 'Konto',
    dashboard: 'Pulpit nawigacyjny',
    systems: 'Systemy',
    admin: 'Administrator',
    einDashboard: 'Pulpit EIN',
    einAdmin: 'Administracja EIN',
    edit: 'Edytuj',
    gotoSolargraf: 'Przejdź do Solargraf',
    createSolargrafAccountMessage:
      'Nie mogliśmy znaleźć aktywnego konta na Solargraf z adresem %{email}. Utwórz konto lub wykup subskrypcję.',
    createSolargrafAccountNote:
      'Uwaga! Jeśli masz już konto Solargraf, zmień identyfikator e-mail Solargraf na e-mail Enlighten',
    verificationText: 'Weryfikacja',
    solargrafOtpMessage:
      'Wysłaliśmy 6-cyfrowy kod weryfikacyjny na Twój adres e-mail. Wprowadź poniższy kod, aby połączyć Enlighten Manager z Solargraf',
    enterOtpText: 'Wpisz hasło jednorazowe',
    didntReceivedOtpMessage: 'Nie otrzymano hasła jednorazowego?',
    resendOtp: 'Wyślij hasło jednorazowe ponownie',
    verificationSuccess: 'Zweryfikowano pomyślnie!',
    verificationFailure: 'Nieprawidłowe informacje',
    otpExpired: 'Hasło jednorazowe wygasło. Wyślij prośbę o nowe hasło jednorazowe',
    otpSentSuccessfully: 'Pomyślnie wysłano hasło jednorazowe',
    otpSentFailed: 'Nie udało się wysłać hasła jednorazowego, spróbuj ponownie później',
    linkSolargrafTitle: 'Połącz swoje konto Solargraf',
    getVerificationCodeText: 'Uzyskaj kod weryfikacyjny',
    linkSolargrafMessageOne:
      'Aby skorzystać z tej opcji, Twoje konto Enlighten musi zostać połączone z kontem Solargraf.',
    linkSolargrafMessageTwo: 'Aby zakończyć ten etap, zostanie wykonana jednorazowa weryfikacja Twojego adresu e-mail.'
  }
};

export default accountInfo;
