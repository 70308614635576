const header = {
	header: {
		logOut: 'Uitloggen',
		enphaseStore: 'Enphase Store',
		enphaseSupport: 'Enphase Support',
		comingSoon: 'Komt binnenkort',
		new: 'Nieuw',
		dashboard: 'Dashboard',
		systems: 'Systemen',
		account: 'Account',
		services: 'Diensten',
		dataPrivacy: 'Gegevens & Privacy',
		support: 'Ondersteuning',
		admin: 'Admin',
		adminNew: 'Admin(Nieuw)'
	}
};

export default header;
