import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
var LightTooltip = withStyles(function (theme) { return ({
    tooltip: {
        backgroundColor: 'white',
        color: '#111111',
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: theme.spacing(1),
        minWidth: theme.spacing(15),
        maxWidth: 350,
        pointerEvents: 'auto',
        fontWeight: 'normal',
    },
    arrow: {
        color: 'white',
        fontSize: 14,
    },
}); })(Tooltip);
export default LightTooltip;
