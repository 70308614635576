import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) { return ({
    buttonStyle: {
        fontSize: theme.spacing(1.7),
        borderRadius: theme.spacing(0.5),
        height: theme.spacing(4.75),
        padding: theme.spacing(0, 3),
        border: '1px solid',
        borderColor: theme.tertiary.buttonColor,
        backgroundColor: theme.tertiary.buttonColor,
        color: theme.tertiary.main,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: '#d15d13',
        },
        minWidth: 'auto',
    },
    disableStyle: {
        opacity: 0.5,
    },
    adminActionButtons: {
        width: '100%',
        fontSize: theme.spacing(1.5),
        overflow: 'auto',
        textOverflow: 'ellipsis',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    smaller: {
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(0, 2),
        height: theme.spacing(4),
    },
}); });
/**
 * MUI Button with custom contained styling
 * @param props {@link Props | Props interface}
 * @returns a MUI button with contained styles
 */
export var ContainedButton = function (props) {
    var classes = useStyles();
    var text = props.text, _a = props.size, size = _a === void 0 ? 'small' : _a, handleClick = props.handleClick, _b = props.disabled, disabled = _b === void 0 ? false : _b, adminActionButtons = props.adminActionButtons, smaller = props.smaller;
    var classNames = clsx(classes.buttonStyle, disabled && classes.disableStyle, adminActionButtons && classes.adminActionButtons, smaller && classes.smaller);
    return (_jsx(Button, { onClick: function (e) { return handleClick(e); }, size: size, className: classNames, disabled: disabled, disableRipple: true, children: text }));
};
export default ContainedButton;
