const header = {
	header: {
		logOut: 'Log out',
		enphaseStore: 'Enphase Store',
		enphaseSupport: 'Enphase Support',
		comingSoon: 'Coming Soon',
		new: 'New',
		dashboard: 'Dashboard',
		systems: 'Systems',
		account: 'Account',
		services: 'Services',
		dataPrivacy: 'Data & Privacy',
		support: 'Support',
		admin: 'Admin',
		adminNew: 'Admin(New)'
	}
};

export default header;
