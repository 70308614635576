var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Dialog, DialogContent, DialogTitle, Box, TextField } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { clone, isEmpty } from 'lodash';
import { getS3Url, putToS3 } from '../../../containers/Installer/action';
import { connect } from 'react-redux';
import { ACCEPTABLE_DOC_FORMAT, MAX_FILE_UPLOAD_SIZE } from '../../../utils/constant';
import ContainedButton from '../../ContainedButton';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return ({
        header: {
            color: theme.palette.primary.dark,
            fontSize: theme.spacing(3.25),
            fontWeight: 'bold',
        },
        smallHeader: {
            color: theme.palette.primary.dark,
            fontSize: theme.spacing(2.5),
            fontWeight: 'bold',
        },
        close: {
            position: 'absolute',
            right: theme.spacing(1.25),
            top: theme.spacing(1.25),
            opacity: 0.23,
            cursor: 'pointer',
        },
        content: {
            border: '1px solid #7070704D',
            margin: theme.spacing(0, 2.5, 2.5, 2.5),
            padding: theme.spacing(2),
        },
        dropDown: (_a = {
                width: theme.spacing(37.5)
            },
            _a[theme.breakpoints.down('xs')] = {
                width: '100%',
                marginBottom: theme.spacing(1),
            },
            _a),
        dragArea: (_b = {
                margin: theme.spacing(0, 0, 2.5, 0),
                backgroundColor: '#B9B9B91A',
                width: theme.spacing(65.375),
                height: theme.spacing(44.875),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textAlign: 'center'
            },
            _b[theme.breakpoints.down('xs')] = {
                width: 'auto',
                height: 'auto',
            },
            _b),
        dragArea2: {
            height: theme.spacing(31),
        },
        input: {
            visibility: 'hidden',
            height: 0,
        },
        err: {
            color: '#FF0000',
        },
        acceptButton: {
            color: theme.tertiary.main,
            textTransform: 'none',
        },
        italic: {
            fontStyle: 'italic',
        },
        imgContainer: {
            border: '1px solid #70707040',
            height: theme.spacing(10),
            width: theme.spacing(15),
            display: 'flex',
            position: 'relative',
        },
        previewImage: {
            margin: 'auto',
            display: 'block',
            maxWidth: theme.spacing(15),
            maxHeight: theme.spacing(10),
        },
        labelColor: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontWeight: 400,
        },
        imageTypeWrapper: (_c = {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline'
            },
            _c[theme.breakpoints.down('xs')] = {
                display: 'block',
            },
            _c),
        imageTypeOptions: (_d = {},
            _d[theme.breakpoints.down('xs')] = {
                fontSize: 14,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            _d),
        selectedFilesWrapper: {
            minWidth: theme.spacing(16),
            maxHeight: theme.spacing(20),
            overflow: 'auto',
            textAlign: 'left',
        },
        selectedFileWrapper: {
            maxWidth: theme.spacing(40),
            maxheight: theme.spacing(15),
        },
        commentText: {
            fontSize: theme.spacing(1.75),
            marginBottom: theme.spacing(1.5),
        },
        commentField: {
            marginBottom: theme.spacing(1.5),
        },
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
        },
        footerText: {
            fontStyle: 'italic',
            padding: theme.spacing(0, 1),
            opacity: 0.8,
        },
        secondaryHeaderText: {
            fontSize: 14,
            opacity: 0.8,
        },
    });
});
var selectedFiles = [];
var FileUploadDialog = function (props) {
    var open = props.open, docType = props.docType, handleUploadDesignDoc = props.handleUploadDesignDoc, handleRequestResubmission = props.handleRequestResubmission, handleRequestTier2Approval = props.handleRequestTier2Approval, onPopupClose = props.onPopupClose, isUploadDesignDoc = props.isUploadDesignDoc, isResubmissionRequest = props.isResubmissionRequest, requestTier2 = props.requestTier2, drsId = props.drsId;
    var fileRef = React.createRef();
    var _a = useState([]), fileNames = _a[0], setFileNames = _a[1];
    var _b = useState(), errMsg = _b[0], setErrMsg = _b[1];
    var _c = useState(false), filesSelected = _c[0], setFilesSelected = _c[1];
    var _d = useState(''), comment = _d[0], setComment = _d[1];
    var _e = useState(false), uploadInProgress = _e[0], setUploadInProgress = _e[1];
    var _f = useState(''), resubmissionError = _f[0], setResubmissionError = _f[1];
    var buttonText = '';
    if (isUploadDesignDoc) {
        buttonText = I18n.t('buttons.sendInstaller');
    }
    else if (isResubmissionRequest) {
        buttonText = I18n.t('buttons.requestInformation');
    }
    else if (requestTier2) {
        buttonText = I18n.t('buttons.requestTier2');
    }
    var close = function () {
        selectedFiles = [];
        setErrMsg('');
        setResubmissionError('');
        setFileNames([]);
        onPopupClose();
        setFilesSelected(false);
    };
    var handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
    };
    var handleDrop = function (e) {
        var _a;
        e.preventDefault();
        e.stopPropagation();
        if (((_a = e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) && e.dataTransfer.files.length > 0) {
            setFilesSelected(true);
            var files = e.dataTransfer.files;
            selectedFiles = selectedFiles.concat(Array.from(files));
            var selectedFileNames = Array.from(files).map(function (file) { return file.name; });
            setFileNames(__spreadArray(__spreadArray([], fileNames, true), selectedFileNames, true));
        }
    };
    var fileAttachments = isEmpty(props.fileArray) ? [] : clone(props.fileArray);
    var allSelectedFiles = [];
    var callS3AndUpload = function (sFiles) {
        if (sFiles.length === 0) {
            var len = selectedFiles === null || selectedFiles === void 0 ? void 0 : selectedFiles.length;
            // for splicing the loader element
            fileAttachments.splice(fileAttachments.length - len - 1, 1);
            if (isUploadDesignDoc) {
                handleUploadDesignDoc(allSelectedFiles, comment);
            }
            if (isResubmissionRequest) {
                handleRequestResubmission(allSelectedFiles, comment);
            }
            if (requestTier2) {
                handleRequestTier2Approval(allSelectedFiles, comment);
            }
            return;
        }
        var sFile = sFiles[0];
        var index = !isEmpty(fileAttachments) ? fileAttachments === null || fileAttachments === void 0 ? void 0 : fileAttachments.length : 0;
        var array = sFile.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g);
        var date = Date.now();
        var extension = !isEmpty(array) && array ? array[0] : '';
        var name = '';
        if (isUploadDesignDoc) {
            name = "".concat(drsId, "_Design_Docs_").concat(moment().format('DDMMYYYY-hhmmss')).concat(extension);
        }
        else {
            name = "".concat(drsId, "_").concat(docType, "_").concat(date).concat(extension);
        }
        props.getS3Url({
            fileName: name,
            methodType: 'PUT',
            successCb: function (preSignedS3Url) {
                props.putToS3({
                    fileObj: sFile,
                    preSignedS3Url: preSignedS3Url,
                    fileName: name,
                    successCbS3: function () {
                        fileAttachments.splice(index, 1);
                        fileAttachments.push({
                            file_name: name,
                            original_file_name: sFile.name,
                            file_size: sFile.size / 1024 / 1024,
                            loading: false,
                            tag: docType,
                        });
                        allSelectedFiles.push({
                            file_name: name,
                            original_file_name: sFile.name,
                            file_size: sFile.size / 1024 / 1024,
                            loading: false,
                            tag: docType,
                        });
                        callS3AndUpload(sFiles.slice(1, sFiles.length));
                    },
                    failureCbS3: function () {
                        fileAttachments.splice(index, 1);
                    },
                });
            },
        });
    };
    var fireUpload = function (files) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (files) {
                fileAttachments.push({ loading: true, original_file_name: '', tag: docType });
                callS3AndUpload(Array.from(files));
            }
            else {
                if (isUploadDesignDoc) {
                    handleUploadDesignDoc([], comment);
                }
                if (isResubmissionRequest) {
                    handleRequestResubmission([], comment);
                }
                if (requestTier2) {
                    handleRequestTier2Approval([], comment);
                }
            }
            return [2 /*return*/];
        });
    }); };
    var classes = useStyles();
    var selectFiles = function (event) {
        if (event.target.files) {
            setFilesSelected(true);
            var files = event.target.files;
            selectedFiles = selectedFiles.concat(Array.from(files));
            var selectedFileNames = Array.from(files).map(function (file) { return file.name; });
            setFileNames(__spreadArray(__spreadArray([], fileNames, true), selectedFileNames, true));
        }
    };
    var addFile = function () {
        if (!selectedFiles && isEmpty(comment)) {
            setErrMsg(I18n.t('fileUpload.fileValidation'));
            return;
        }
        if (isResubmissionRequest && isEmpty(comment)) {
            setResubmissionError(I18n.t('fileUpload.validationReasonResubmission'));
            return;
        }
        if (selectedFiles) {
            Array.from(selectedFiles).forEach(function (file) {
                if (file.size / 1024 / 1024 > Number(MAX_FILE_UPLOAD_SIZE)) {
                    setErrMsg(I18n.t('fileUpload.fileMaxSizeLabel', { fileSize: MAX_FILE_UPLOAD_SIZE }));
                    return;
                }
            });
        }
        setUploadInProgress(true);
        fireUpload(selectedFiles);
    };
    var inputProps = { accept: ACCEPTABLE_DOC_FORMAT };
    var handleClose = function () {
        props.onPopupClose();
    };
    var handleCommentsChange = function (e) {
        if (isResubmissionRequest && !isEmpty(e.target.value)) {
            setResubmissionError('');
        }
        setComment(e.target.value);
    };
    return (_jsxs(Dialog, { onClose: handleClose, open: open, disableBackdropClick: true, children: [_jsxs(DialogTitle, { children: [_jsxs(Typography, { className: classes.header, children: [props.dialogHeader || I18n.t('fileUpload.uploadFiles'), _jsx(CloseIcon, { onClick: close, className: classes.close, fontSize: "large" })] }), _jsx(Typography, { className: classes.secondaryHeaderText, children: props.secondaryHeaderText })] }), _jsxs(DialogContent, { className: classes.content, children: [_jsxs("div", { className: clsx(classes.dragArea), onDrop: handleDrop, onDragEnter: handleDrag, onDragLeave: handleDrag, onDragOver: handleDrag, children: [_jsx(Typography, { gutterBottom: true, children: I18n.t('fileUpload.dropFile') }), _jsx(Typography, { gutterBottom: true, children: "Or" }), _jsx(ContainedButton, { text: I18n.t('fileUpload.selectFiles'), handleClick: function () { var _a; return (_a = fileRef.current) === null || _a === void 0 ? void 0 : _a.click(); } }), _jsx("input", __assign({ type: "file", onChange: selectFiles, ref: fileRef, className: classes.input }, inputProps, { multiple: true })), _jsx("br", {}), _jsx("div", { className: classes.selectedFilesWrapper, children: fileNames &&
                                    Array.from(fileNames).map(function (fileName, i) {
                                        return (_jsx("div", { className: classes.selectedFileWrapper, children: _jsx(Typography, { color: "textSecondary", variant: "caption", gutterBottom: true, children: fileName }) }, "file-".concat(docType, "-").concat(i)));
                                    }) }), _jsx("br", {}), !selectedFiles && errMsg && (_jsx(Typography, { className: classes.err, gutterBottom: true, children: errMsg })), uploadInProgress && _jsx(CircularProgress, { size: 20 }), _jsxs(Typography, { color: "textSecondary", variant: "caption", className: classes.italic, children: [I18n.t('fileUploadDialog.maxFileSize'), " :", MAX_FILE_UPLOAD_SIZE, " MB"] })] }), _jsxs(Box, { children: [_jsx(Typography, { className: classes.commentText, children: isResubmissionRequest ? I18n.t('fileUpload.reasonResubmission') : I18n.t('fileUpload.shareComments') }), _jsx(TextField, { value: comment, onChange: function (e) { return handleCommentsChange(e); }, multiline: true, rows: 4, rowsMax: 16, fullWidth: true, variant: "outlined", className: classes.commentField }), isResubmissionRequest && resubmissionError && (_jsx(Typography, { className: classes.err, gutterBottom: true, children: resubmissionError }))] }), _jsxs(Box, { className: classes.displayFlex, children: [_jsx(ContainedButton, { text: buttonText, handleClick: addFile, disabled: uploadInProgress ||
                                    (isUploadDesignDoc || requestTier2 ? !filesSelected : !filesSelected && isEmpty(comment)) }), _jsx(Typography, { className: classes.footerText, children: props.footerText })] })] })] }));
};
var mapDispatchToProps = function (dispatch) { return ({
    getS3Url: function (payload) { return dispatch(getS3Url(payload)); },
    putToS3: function (payload) { return dispatch(putToS3(payload)); },
}); };
export default connect(null, mapDispatchToProps)(FileUploadDialog);
