import { InstallerActionTypes } from './constants';
export function createApplication(payload) {
    return { type: InstallerActionTypes.CREATE_APPLICATION_REQUEST, payload: payload };
}
export function getApplication(drsId, successCbk) {
    return { type: InstallerActionTypes.DRS_GET_APPLICATION_REQUEST, drsId: drsId, successCbk: successCbk };
}
export function setSiteId(siteId) {
    return { type: InstallerActionTypes.SET_SITE_ID, siteId: siteId };
}
export function setDrsId(drsId) {
    return { type: InstallerActionTypes.SET_DRS_ID, drsId: drsId };
}
export function getUserSelectionData() {
    return { type: InstallerActionTypes.GET_USER_SELECTION_DATA_REQUEST };
}
export function getS3Url(payload) {
    return { type: InstallerActionTypes.GET_S3_URL_REQUEST, payload: payload };
}
export function getS3DownloadUrl(payload) {
    return { type: InstallerActionTypes.GET_S3_DOWNLOAD_URL_REQUEST, payload: payload };
}
export function putToS3(payload) {
    return { type: InstallerActionTypes.PUT_TO_S3_REQUEST, payload: payload };
}
export function getCartUrl(drsId, successCbk, failureCbk) {
    return { type: InstallerActionTypes.GET_CART_URL_REQUEST, drsId: drsId, successCbk: successCbk, failureCbk: failureCbk };
}
export function setPaymentStatusFromStore(status) {
    return { type: InstallerActionTypes.SET_PAYMENT_STATUS_FROM_STORE, status: status };
}
export function postNpsFeedback(payload) {
    return { type: InstallerActionTypes.POST_NPS_FEEDBACK_REQUEST, payload: payload };
}
export function resubmissionReceived(payload) {
    return { type: InstallerActionTypes.RESUBMISSION_RECEIVED_REQUEST, payload: payload };
}
export function getNpsFeedback(drsId) {
    return { type: InstallerActionTypes.GET_NPS_FEEDBACK_REQUEST, drsId: drsId };
}
export function downloadLoadCalculationFile(countryCode) {
    return { type: InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_REQUEST, countryCode: countryCode };
}
export function savePaymentDetails(payload) {
    return { type: InstallerActionTypes.SAVE_PAYMENT_DETAILS_REQUEST, payload: payload };
}
