const common = {
  error: {
    unauthorized: 'Nieautoryzowany dostęp',
    required: 'Wymagane'
  },
  common: {
    mandatory: 'Pola obowiązkowe'
  },
  utils: {
    dashboard: 'Pulpit nawigacyjny',
    systems: 'Systemy',
    account: 'Konto',
    services: 'Usługi',
    dataPrivacy: 'Dane i prywatność',
    support: 'Wsparcie',
    admin: 'Administrator',
    defaultMessage: 'Aby pobrać pliki, wyłącz blokowanie wyskakujących okienek w przeglądarce',
    signInMessage: 'Musisz się zalogować lub zarejestrować, aby kontynuować',
    commonApiMessage: 'Nie udało się przetworzyć prośby',
    adminNew: 'Administrator (nowy)'
  },
  message: {
    yelpWaitMessage: 'Poczekaj na aktualizację Yelp',
    googleWaitMessage: 'Poczekaj na aktualizację identyfikatora miejsca Google',
    successMessageUpload: 'Pomyślnie przesłano plik.',
    failureMessageUpload: 'Wystąpił problem, spróbuj ponownie',
    serviceAreasCreateSuccess: 'Pomyślnie utworzono obszar usług',
    serviceAreasUpdateSuccess: 'Pomyślnie zaktualizowano obszar usług',
    serviceAreasDeleteSuccess: 'Pomyślnie usunięto obszar usług',
    hardware_product: 'Produkty sprzętowe',
    rowsFailed: 'Błąd wierszy: %{failedCount}/%{totalCount}',
    accessRequestSuccess: 'Pomyślnie przesłano prośbę o przyznanie dostępu'
  },
  buttons: {
    cancel: 'Anuluj',
    edit: 'Edytuj',
    update: 'AKTUALIZUJ',
    updateAccount: 'Zaktualizuj konto',
    submit: 'Prześlij',
    submitUpperCase: 'Prześlij',
    back: 'Wstecz',
    save: 'Zapisz',
    show: 'Pokaż',
    add: 'Dodaj',
    downloadSampleFile: 'Pobierz przykładowy plik',
    proceed: 'Kontynuuj',
    close: 'Zamknij'
  }
};

export default common;
