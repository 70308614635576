const designReviewService = {
  All: 'Allemaal',
  Document: 'documenteren',
  Image: 'Afbeelding',
  File: 'Bestand',
  drsDashboard: {
    pageHeader: 'Aanvragen voor ontwerp beoordelingen',
    createNewTicket: 'Een nieuwe aanvraag aanmaken',
    filter: 'Filter',
    all: 'Allemaal',
    tier1: 'Niveau 1',
    tier2: 'Niveau 2',
    country: 'Land',
    us: 'VS',
    australia: 'Australië',
    hideChart: 'Grafiek verbergen',
    currentlyAssigned: 'Momenteel toegewezen',
    overAllStatus: 'Algemene aanvraagstatus',
    totalNoOf: 'Total number of ',
    applications: 'Applications',
    allApplication: 'Alle aanvragen',
    note: 'Opmerking: De onderstaande tabel is gefilterd met commerciële ontwerpbeoordelingsaanvragen. Alstublieft',
    clickHere: 'om alle aanvragen te bekijken',
    viewAllApplication: 'um alle Anträge anzuzeigen',
    projectType: 'RESIDENTIEEL',
    service: 'DRS',
    assignedTo: 'Toewijzen aan',
    assign: 'Toewijzen',
    germany: 'Duitsland',
    belgium: 'België',
    austria: 'Oostenrijk',
    netherlands: 'Nederland',
    france: 'France',
    switzerland: 'Switzerland',
  },
  applicationStatus: {
    pageHeader: 'Ontwerp beoordeling',
    applicationSubmitted: 'Antrag eingereicht für den Enphase Speicher Zertifizierungskurs',
    resubmittedRequest: 'Opnieuw indienen van gevraagde informatie',
    shareInformation: 'Wij zullen de verstrekte informatie delen met het Enphase team om deze verder te bekijken.',
    sendEnPhaseTeam: 'Versturen naar Enphase Team',
  },
  designReviewService: {
    requestedId: 'Verzoek-ID',
    enphaseStorageCourse: 'Deel van Enphase Storage Certification cursus',
  },
  projectDetails: {
    communicateEnphase: 'Communiceer met Enphase',
    headerText: 'Aanvraag indienen',
    applicationSubmitted: 'Aanvraag ingediend',
    paymentFailed: 'Betaling mislukt',
    applicationSuccess: 'Gefeliciteerd! Uw aanvraag is ingediend.',
    applicationFailure: 'Oeps! We konden uw transactie niet voltooien.',
    paymentStatusSuccess: 'Ons team neemt binnen 48 uur contact met u op voor uw Ontwerpbeoordelingssaanvraag.',
    paymentStatusFailure: 'Probeer het opnieuw of neem contact op met',
    documents: 'Documenten',
    siteMap: 'Sitemap',
    singleLineDiagram: 'Eenlijnschema (ELS)',
    msp: 'Hoofverdeelbord (ALSB)',
    additionalDoc: 'Aanvullende documenten',
    loadCalculation: 'Belastingberekening',
    backupLoadDetails: 'Back-up Laadgegevens',
    batterySizingTool: 'Tool voor batterijmeting',
    siteSurvey: 'Systeem gegevens',
    additionalDetails: 'Overige Opmerkingen',
    photoUpload: 'Foto uploaden (Survey-foto’s indien beschikbaar)',
    notes: 'Opmerkingen',
    batterySizingDetails: 'Gegevens over de afmetingen van de batterij',
    siteSurveyDetails: 'Systeemgegevens Details',
  },
  requestDetails: {
    siteMapPhotosUrl: 'Sitemap',
    sldDocumentsUrl: 'Enkelvoudig lijndiagram (SLD)',
    installationPhotosUrl: `Installatiefoto's`,
    loadCalculationDocumentsUrl: 'Belastingberekeningsdocumenten',
    mspDocumentsUrl: 'Afbeelding van het hoofdbedieningspaneel',
    otherDocumentsUrl: 'Documenten',
    surveyPhotosUrl: `Foto's van het onderzoek`,
    heading: 'Ontwerpbeoordelingaanvraagdetails',
    requestId: 'VERZOEK-ID',
    installerName: 'NAAM INSTALLATEUR',
    systemId: 'SYSTEEM-ID',
    systemName: 'SYSTEEM / PROJECTNAAM',
    customerName: 'NAAM KLANT',
    zipState: 'POSTCODE / STAAT',
    status: 'STATUS',
    type: 'TYPE',
    assignedTo: 'TOEGEWEZEN AAN',
    createdOn: 'AANGEMAAKT OP',
    updatedOn: 'BIJGEWERKT OP',
    documents: 'Documenten',
    downloadAll: 'Download alles',
    links: 'Links',
    installer: 'INSTALLATEUR',
    admin: 'ADMIN',
    today: 'Vandaag',
    pastDays: 'Afgelopen 7 dagen',
    monthToData: 'Maand tot heden',
    yearToDate: 'Jaar tot heden',
    specificDate: 'Specifieke datum',
    allDatesBefore: 'Alle datums voor',
    allDatesAfter: 'Alle datums na',
    dateRange: 'Datumbereik',
    clearDate: 'Datums wissen',
    utilityBill: 'Elektriciteitsfactuur',
    equipmentElevation: 'Hoogte van de apparatuur',
    planSet: 'Plannenreeks',
  },
  chats: {
    fileUploadIssue: 'We hebben wat problemen met het uploaden van bestanden, probeer het later nog eens.',
    others: 'Anderen',
    downloading: 'wordt gedownload',
    downloaded: 'gedownload',
    fromEnphase: 'Van Enphase',
    fromInstaller: 'Van Installateur',
    fromUnknown: 'Van Onbekend',
    notifyViaEmail:
      'U kunt dit venster sluiten na het verzenden van uw bericht. Wij laten u per e-mail weten wanneer er een antwoord op uw vraag beschikbaar is.',
    query:
      'Dit gedeelte kan worden gebruikt om vragen te stellen over uw ontwerpbeoordelingsaanvraag & uw gesprekken bij te houden.',
    typeMessage: 'Type bericht',
    communicateWithEnphase: 'Communiceer met Enphase',
    communicateWithInstaller: 'Communiceren met Installateur',
    submitted: 'ingediend',
    maxFileSizeError1: 'De maximale bestandsgrootte moet ',
    maxFileSizeError2: ' Mb zijn',
  },

  approvalDialog: {
    documentApprove: 'Ich genehmige das/die folgende(n) Designprüfungsdokument(e)',
    specificComments: 'Als u specifieke opmerkingen wilt maken, kunt u die hieronder kwijt',
    approveSend: 'Genehmigen und an den Installateur senden',
  },

  fileUpload: {
    fileValidation: 'fileValidation	Selecteer een bestand of voer opmerkingen in om verder te gaan...',
    reasonResubmission: 'Reden voor herindiening',
    validationReasonResubmission: 'Vul de reden voor herindiening in',
    fileMaxSize: `Bestandsgrootte is groter dan %{fileSize} MB...`,
    fileMaxSizeLabel: 'Bestandsgrootte is groter dan',
    shareComments: 'Als u specifieke opmerkingen wilt maken, kunt u die hieronder kwijt',
    uploadFiles: 'Bestanden uploaden',
    selectFiles: 'Bestanden selecteren',

    dropFile: 'Plaats hier bestanden om te uploaden',
    or: 'of',
  },
  infoDialog: {
    notes: 'Opmerkingen',
  },
  adminAction: {
    designDocUpload: 'Ontwerpdocument geüpload',
    tier2ApprovalComment: 'Niveau 2 Goedkeuring Opmerkingen',
    resubmissionRequested: 'Herindiening gevraagd',
    uploadSendInstaller: 'Uploaden en verzenden naar Installateur',
    finalDrsUpload:
      'Upload het/de definitieve Design Review document(en) om te delen met de Installateur. Als u geüploade documenten van Niveau 1 en hetzelfde met Installateur wilt goedkeuren, ga dan terug naar het vorige scherm en klik op de knop "Document van Niveau 1 goedkeuren"',
    drsShareInstaller: 'Upload het/de definitieve Design Review document(en) om te delen met de installateur.',
    requestToTier2: 'Verzoek om goedkeuring van niveau 2',
    tier2Approval:
      'Upload het/de document(en) van de ontwerpbeoordeling om te delen met het Niveau 2-team voor goedkeuring.',
    tier1DocUpload: 'Goedkeuring van het door niveau 1 geüploade document',
    approveTier1Doc: 'Goedkeuring van Niveau 1-document',
    modifyUploadDrs:
      'Wij zullen het/de document(en) na goedkeuring met de Installateurs delen. Als u een nieuw ontwerpbeoordelingsdocument wilt wijzigen en uploaden, ga dan terug naar het vorige scherm en klik op "Uploaden en verzenden naar installateur".',
    shareInformation: 'Deel de informatie die u nodig hebt van de Installateur',
  },
  applicationDetails: {
    applicantDetails: 'Details aanvrager',
    applicationDetails: 'Details aanvraag',
    viewApplication: 'Aanvraag bekijken',
  },
  applicationView: {
    systemProjectName: 'Naam systeem/project',
    systemId: 'Systeem-ID',
    type: 'Type',
    solarSize: 'Zonne-energie grootte (KWac)',
    batteryType: 'Batterij type',
    batteryQuantity: 'Hoeveelheid batterijen',
    storageSize: 'Opslaggrootte (kWh)',
    customerName: 'Naam van de klant',
    zip: 'Postcode',

    State: 'Staat',

    address: 'Adresse',
    installerName: 'Naam installateur',
    installerCompanyName: 'Installateur Bedrijfsnaam',
    installerContactNumber: 'Installateur Contactnummer',
    installerEmailAddress: 'Installateur e-mailadres',
    notes: 'Opmerkingen',
    receivedOn: 'Ontvangen op',
    uploadedOn: 'Geüpload op',
    noDoc: 'Geen documenten beschikbaar',
    resubmissionReceived: 'Herindiening ontvangen',
  },
  statusDetails: {
    applicationStatus: 'Aanvraagstatus',
    updateStatus: 'Status bijwerken',
    downloadfailed: 'Download mislukt',
    download: 'Downloaden',
    dateOfComplete: 'Datum van voltooiing',
    update: 'Bijwerken',
  },
  datePicker: {
    datePickerInline: 'Datumkiezer inline',
    changeDate: 'Datum wijzigen',
  },
  installerAction: {
    downloadReviewDoc: 'Herzieningsdocument downloaden',
    shareYourFeedback: 'Uw feedback delen',
    resubmitDoc: 'Opnieuw indienen van gevraagde informatie',
  },
  status: {
    updatedOn: 'Bijgewerkt op',
    downloadReviewDoc: 'Herzieningsdocument downloaden',
    totalAmount: 'Totaal te betalen bedrag',
    exclusiveTaxes: 'exclusief alle belastingen',
    enphaseService1: 'Ik heb de',
    enphaseService2: 'van Enphase Design Review Services gelezen en ga hiermee akkoord',
    redirectNotification: 'U wordt doorgestuurd naar de betaalpagina om de transactie te voltooien.',
    noChargeNotification:
      'Er worden u geen kosten in rekening gebracht voor het indienen van deze ontwerpbeoordelingsaanvraag',
    partOfCourse: ' als onderdeel van uw certificeringscursus.',
  },
  docUpload: {
    addFile: 'Bestand toevoegen',
    add: 'Toevoegen',
    addImage: 'Voeg afbeelding toe',
    addDocument: 'Document toevoegen',
    downloadTemplate: 'Template downloaden',
    shareDocLink: 'Documentlink delen',
    linkFromGoogleDrive: 'Link van GoogleDrive etc',
    giveLink: 'Geef ons een link van GoogleDrive, Dropbox etc',
  },
  buttons: {
    sendInstaller: 'Naar installateur sturen',
    requestInformation: 'Meer informatie aanvragen',
    requestTier2: 'Verzoek om goedkeuring van niveau 2',
    proceedButtonYes: 'Ja',
    proceedButtonText: 'Doorgaan',
    closeButtonText: 'Nee',
    footerSubmitButton: 'Indienen',
    back: 'Terug',
    confirm: 'Bevestigen',
    footerCloseButton: 'Sluiten',
    update: 'Bijwerken',
    cancel: 'Annuleren',
    edit: 'Edit',
    proceed: 'Doorgaan',
    view: 'Bekijken',
    ok: 'Ok',
    owner: 'Eigenaar',
    saveClose: 'Bewaren en afsluiten',
  },

  constants: {
    fieldRequired: 'Dit veld is verplicht',
    documentFieldRequired: 'Upload een document of voer een link in',
    linkError: 'De verstrekte link is niet geldig',
    applicationIncomplete: 'Aanvraag onvolledig',
    newApplicationReceived: 'Nieuwe aanvraag ontvangen',
    resubmissionRequested: 'Herindiening gevraagd',
    newApplicationAssigned: 'Nieuwe aanvraag toegewezen',
    newApplicationSubmitted: 'Nieuwe aanvraag ingediend',
    applicationUnderReview: 'Aanvraag in behandeling',
    resubmissionReceived: 'Herindiening ontvangen',
    State: 'Staat',

    resubmissionSubmitted: 'Herindiening ingediend',
    applicationReviewCompleted: 'Beoordeling aanvraag voltooid',
    requestForTier2Approval: 'Niveau 2 goedkeuring aangevraagd',
    tier2ApplicationAssigned: 'Niveau 2 aanvraag toegewezen',
    tier2ApprovalUnderProgress: 'Niveau 2 goedkeuring in behandeling',
    tier2ResubmissionRequested: 'Niveau 2 opnieuw ingediend',
    tier2ResubmissionReceived: 'Niveau 2 herindiening ontvangen',
    tier2ApprovalCompleted: 'Niveau 2 goedkeuring voltooid',
    defaultMessage: 'Schakel de pop-up blocker in uw browser uit om bestanden te downloaden.',
    rowsPerPage: 'rijen per pagina:',
    of: 'van',
  },
  drsListingTable: {
    noRequestAvailable: 'Geen verzoeken beschikbaar',
    notAssigned: 'Niet toegewezen',
    application: 'aanvraag',
    messages: 'Berichten',
    message: 'Berichten',
    all: 'Allemaal',
  },
  fileUploadDialog: {
    documentUpload: 'Document Upload Checklist',

    fileTypeValidation: 'Selecteer bestandstype om door te gaan...',
    fileSizeValidation: `Bestandsgrootte is groter dan %{fileSize} MB...`,
    maxFileSize: 'Maximale bestandsgrootte',
    addSpecificComment: 'Als u specifieke opmerkingen wilt maken, kunt u die hieronder kwijt',
    poor: 'Matig',
    great: 'Goed',

    feedback: 'Feedback',

    recommendSurvey:
      'Op een schaal van 0 tot 10, hoe tevreden bent u met deze website waarop u de ontwerpbeoordeling heeft ingediend?',
  },
  prefillingDialog: {
    beforeSubmit:
      'Sommige velden in deze toepassing zijn vooraf ingevuld aan de hand van gegevens die in Enlighten bestaan. Bekijk de details zorgvuldig voordat u ze indient.',
  },
  basicDetails: {
    projectDetails: 'Projectdetails',
    residential: `Residentieel = contract met huiseigenaren van eengezinswoningen. 
 Commercieel = contract met meergezins/commerciële eigenaars.`,
    customerName: 'Naam van de klant',
    zip: 'Postcode',

    owner: 'Eigenaar',
  },
  microInvererDetails: {
    microInverterType: 'Micro-omvormer type',
    pageHeader: 'Micro-omvormer details',
  },
  solarDetails: {
    pageHeader: 'Zonne-energie details',
    solarSystemSize: 'Zonne-energie Systeemgrootte (kWac)',
    storageSize: 'Opslaggrootte (kWh)',
    kWh: 'kWh',
    batteryTypeNote: 'Een IQ Battery 10T komt overeen met drie keer de IQ Battery 3T',
  },
  notification: {
    noMoreNotification: 'Geen meldingen meer weergeven',
    noNotification: 'Geen meldingen beschikbaar',
    serviceNotification: 'Dienstmeldingen',
    viewAll: 'Alles bekijken',
  },
  leadManagement: {
    selectSystemID: 'Selecteer de systeem-ID uit de onderstaande lijst als de systeemactivering al is gestart',
    systemNameValidation: 'Vul de naam van het systeem in',
    createSystem: 'Een nieuw systeem creëren',
    systemName: 'Naam van het systeem',
    type: 'Type',
    siteInformation: 'Site Informatie',
    Residential: 'Residentieel',
    Commercial: 'Commercieel',
    other: 'Sonstiges',
    thirdParty: 'In eigendom van derden ("ex": PPA of Geleased)',
    collapse: 'Inklappen',
    expand: 'Uitklappen',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    email: 'E-Mail',
    phone: 'Telefon',
    invalidEmail: 'Ongeldige e-mail',
    invalidPhoneNumber: 'Ongeldig telefoonnummer',
    invalidState: 'Ongeldig Staat/provincie',
    required: 'Verplicht',
    enlightenVersion: 'Enlighten Versie',
    ownerReceive: 'De eigenaar zal <b>MyEnlighten</b> ontvangen voor het monitoren van de systeemprestaties.',
    location: 'Locatie',
    streetAddress: 'Adres',
    zip: 'Postcode',
    residential: 'Residentieel',
    commercial: 'Commercieel',
    zipCode: 'Postcode',
    country: 'Land',
    state: 'Staat/provincie',
    city: 'Stad',
    noOfInverter: 'Aantal micro-omvormers',
    customerName: 'Naam van de klant',
    enphaseGuideLine:
      'Enphase biedt richtlijnen en hulpmiddelen om installateurs te helpen bij het ontwerpen van een systeem binnen de juiste parameters.. De installateur en de gekwalificeerde elektricien zijn verantwoordelijk voor het definitieve ontwerp en de uitvoering.',
    confirm: 'Ik bevestig het volgende',
    authorized:
      'Ik ben gemachtigd om mij voor Enlighten te registreren namens de systeemeigenaar/gastheer, die mij toestemming heeft gegeven om deze informatie namens hem/haar bij Enphase in te dienen.',
    readUnderstand: `Ik heb de Enphase's`,
    privacyPolicy: 'Privacybeleid',
    and: 'en',
    terms: 'Gebruiksvoorwaarden',
    termsAndCondition: 'Algemene Voorwaarden',
    policy: 'het "Beleid” geleen en begrepen.',
    acknowledge: 'De systeemeigenaar/gastheer heeft bevestigd dat hij het beleid heeft gelezen en begrepen.',
    informedSystemOwner:
      'Ik heb de systeemeigenaar/gastheer geïnformeerd dat mijn bedrijf de hoeveelheden en tijdstippen van zijn energieverbruik kan controleren en dat hij deze toestemming te allen tijde kan intrekken.',
  },
  serviceDialog: {
    quoteSetUp: 'Offerte Setup',
    systemConfiguration: 'Systeemconfiguratie',
    bomPricing: 'BOM & Prijzen',
    projectDesign:
      'Dit project wordt momenteel ontworpen door Sofdesk. Het is nu niet toegankelijk, maar zal voor u beschikbaar zijn zodra het klaar is.',
    redirect: 'U wordt doorgestuurd naar uw dashboard op de Solargraf Design and Proposal Tool.',
    wishToProceed: 'Wilt u doorgaan?',

    designProposalTool: 'Ontwerp- en offertetool',
    measurementInProgress: 'Metingen in uitvoering',
    permitAssistanceStatus: 'Status van de vergunningsaanvragen',
  },

  breadCrumb: {
    dashboard: 'Dashboard',
    SGIPApplication: 'SGIP Applicaties',
  },
};

export default designReviewService;
