var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fork } from 'redux-saga/effects';
import { getMessageListFlow, watchLastMessageFlow, updateChatStatusFlow } from './containers/Chats/saga';
import { assignReviewerFlow, getApplicationsFlow, getApplicationStatsFlow, getReviewerListFlow, getSiteSearchResultsFlow, canApplyDRSForCertificationCourseFlow, getAppliedSitesFlow, isDrsEligibleFlow, } from './containers/DRSListing/saga';
import { createDrsApplicationFlow, getDrsApplicationFlow, getUserSelectionDataFlow, getFileLinkFlow, watchLastPutS3Flow, getCartUrlFlow, getFileDownloadFlow, postNpsFeedbackFlow, resubmissionReceivedFlow, getNpsFeedbacksFlow, downloadLoadCalculationFileFlow, savePaymentDetailsFlow, } from './containers/Installer/saga';
import { requestTier2Flow, updateAdminDocsFlow, updateStatusFlow, downloadAllFlow, setStatusToUnderReviewFlow, } from './containers/Admin/saga';
import { getAccountDataFlow, getSiteDetailsFlow } from './containers/Layouts/saga';
import { getABFeatureList, getUserOwnPreferencesFlow, getUserDetails, companyInfoFlow } from './containers/Header/saga';
import { getApplicationNotificationsFlow, readApplicationNotificationFlow, markNotificationSeenFlow, deleteApplicationNotificationFlow, getSolargrafSession, verifySolargrafSession, verifySolargrafEmail, requestSolargrafAccess, grantSolargrafAccess, getApplicationBySiteFlow, getProjectDetails, } from './components/Temp/containers/Layouts/saga';
import { createSystem } from './components/Temp/containers/Systems/saga';
import { linkSiteWithLeadFlow, getSolargrafFinancials, getSiteSearchLeadFlow, searchForSiteIdInLeadsFlow, getSystemDetails, getLeadDetailsFlow, getLeadFromJourneyId, } from './components/Temp/containers/LeadManagement/saga';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: 
            // layout sagas
            return [4 /*yield*/, fork(getAccountDataFlow)];
            case 1:
                // layout sagas
                _a.sent();
                // for left panel starts
                return [4 /*yield*/, fork(getSiteDetailsFlow)];
            case 2:
                // for left panel starts
                _a.sent();
                // for left panel ends
                // header saga
                return [4 /*yield*/, fork(getABFeatureList)];
            case 3:
                // for left panel ends
                // header saga
                _a.sent();
                return [4 /*yield*/, fork(getUserOwnPreferencesFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, fork(getUserDetails)];
            case 5:
                _a.sent();
                return [4 /*yield*/, fork(companyInfoFlow)];
            case 6:
                _a.sent();
                // installer sagas
                return [4 /*yield*/, fork(createDrsApplicationFlow)];
            case 7:
                // installer sagas
                _a.sent();
                return [4 /*yield*/, fork(getDrsApplicationFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, fork(getSiteSearchResultsFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, fork(getApplicationsFlow)];
            case 10:
                _a.sent();
                return [4 /*yield*/, fork(getUserSelectionDataFlow)];
            case 11:
                _a.sent();
                return [4 /*yield*/, fork(getFileLinkFlow)];
            case 12:
                _a.sent();
                return [4 /*yield*/, fork(watchLastPutS3Flow)];
            case 13:
                _a.sent();
                return [4 /*yield*/, fork(getCartUrlFlow)];
            case 14:
                _a.sent();
                return [4 /*yield*/, fork(postNpsFeedbackFlow)];
            case 15:
                _a.sent();
                return [4 /*yield*/, fork(resubmissionReceivedFlow)];
            case 16:
                _a.sent();
                return [4 /*yield*/, fork(getNpsFeedbacksFlow)];
            case 17:
                _a.sent();
                return [4 /*yield*/, fork(downloadLoadCalculationFileFlow)];
            case 18:
                _a.sent();
                return [4 /*yield*/, fork(savePaymentDetailsFlow)];
            case 19:
                _a.sent();
                // chat sagas
                return [4 /*yield*/, fork(getMessageListFlow)];
            case 20:
                // chat sagas
                _a.sent();
                return [4 /*yield*/, fork(watchLastMessageFlow)];
            case 21:
                _a.sent();
                return [4 /*yield*/, fork(getFileDownloadFlow)];
            case 22:
                _a.sent();
                return [4 /*yield*/, fork(updateChatStatusFlow)];
            case 23:
                _a.sent();
                // listing sagas
                return [4 /*yield*/, fork(assignReviewerFlow)];
            case 24:
                // listing sagas
                _a.sent();
                return [4 /*yield*/, fork(getReviewerListFlow)];
            case 25:
                _a.sent();
                return [4 /*yield*/, fork(getApplicationStatsFlow)];
            case 26:
                _a.sent();
                return [4 /*yield*/, fork(canApplyDRSForCertificationCourseFlow)];
            case 27:
                _a.sent();
                return [4 /*yield*/, fork(getAppliedSitesFlow)];
            case 28:
                _a.sent();
                return [4 /*yield*/, fork(isDrsEligibleFlow)];
            case 29:
                _a.sent();
                // admin sagas
                return [4 /*yield*/, fork(updateAdminDocsFlow)];
            case 30:
                // admin sagas
                _a.sent();
                return [4 /*yield*/, fork(updateStatusFlow)];
            case 31:
                _a.sent();
                return [4 /*yield*/, fork(requestTier2Flow)];
            case 32:
                _a.sent();
                return [4 /*yield*/, fork(downloadAllFlow)];
            case 33:
                _a.sent();
                return [4 /*yield*/, fork(setStatusToUnderReviewFlow)];
            case 34:
                _a.sent();
                // copied layouts saga
                return [4 /*yield*/, fork(getApplicationNotificationsFlow)];
            case 35:
                // copied layouts saga
                _a.sent();
                return [4 /*yield*/, fork(readApplicationNotificationFlow)];
            case 36:
                _a.sent();
                return [4 /*yield*/, fork(markNotificationSeenFlow)];
            case 37:
                _a.sent();
                return [4 /*yield*/, fork(deleteApplicationNotificationFlow)];
            case 38:
                _a.sent();
                return [4 /*yield*/, fork(getSolargrafSession)];
            case 39:
                _a.sent();
                return [4 /*yield*/, fork(verifySolargrafSession)];
            case 40:
                _a.sent();
                return [4 /*yield*/, fork(verifySolargrafEmail)];
            case 41:
                _a.sent();
                return [4 /*yield*/, fork(requestSolargrafAccess)];
            case 42:
                _a.sent();
                return [4 /*yield*/, fork(grantSolargrafAccess)];
            case 43:
                _a.sent();
                return [4 /*yield*/, fork(getApplicationBySiteFlow)];
            case 44:
                _a.sent();
                return [4 /*yield*/, fork(getProjectDetails)];
            case 45:
                _a.sent();
                // system sagas
                return [4 /*yield*/, fork(createSystem)];
            case 46:
                // system sagas
                _a.sent();
                // lead sagas
                return [4 /*yield*/, fork(linkSiteWithLeadFlow)];
            case 47:
                // lead sagas
                _a.sent();
                return [4 /*yield*/, fork(getSolargrafFinancials)];
            case 48:
                _a.sent();
                return [4 /*yield*/, fork(getSiteSearchLeadFlow)];
            case 49:
                _a.sent();
                return [4 /*yield*/, fork(searchForSiteIdInLeadsFlow)];
            case 50:
                _a.sent();
                return [4 /*yield*/, fork(getSystemDetails)];
            case 51:
                _a.sent();
                return [4 /*yield*/, fork(getLeadDetailsFlow)];
            case 52:
                _a.sent();
                return [4 /*yield*/, fork(getLeadFromJourneyId)];
            case 53:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
