export var ListingTableActions;
(function (ListingTableActions) {
    ListingTableActions["ASSIGN_REVIEWER_REQUEST"] = "ASSIGN_REVIEWER_REQUEST";
    ListingTableActions["ASSIGN_REVIEWER_SUCCESS"] = "ASSIGN_REVIEWER_SUCCESS";
    ListingTableActions["ASSIGN_REVIEWER_FAILURE"] = "ASSIGN_REVIEWER_FAILURE";
    ListingTableActions["GET_APPLICATIONS_REQUEST"] = "GET_APPLICATIONS_REQUEST";
    ListingTableActions["GET_APPLICATIONS_SUCCESS"] = "GET_APPLICATIONS_SUCCESS";
    ListingTableActions["GET_APPLICATIONS_FAILURE"] = "GET_APPLICATIONS_FAILURE";
    ListingTableActions["SITE_SEARCH_REQUEST"] = "SITE_SEARCH_REQUEST";
    ListingTableActions["SITE_SEARCH_FAILURE"] = "SITE_SEARCH_FAILURE";
    ListingTableActions["SITE_SEARCH_SUCCESS"] = "SITE_SEARCH_SUCCESS";
    ListingTableActions["GET_REVIEWER_LIST_REQUEST"] = "GET_REVIEWER_LIST_REQUEST";
    ListingTableActions["GET_REVIEWER_LIST_SUCCESS"] = "GET_REVIEWER_LIST_SUCCESS";
    ListingTableActions["GET_REVIEWER_LIST_FAILURE"] = "GET_REVIEWER_LIST_FAILURE";
    ListingTableActions["GET_APPLICATIONS_STATS_REQUEST"] = "GET_APPLICATIONS_STATS_REQUEST";
    ListingTableActions["GET_APPLICATIONS_STATS_SUCCESS"] = "GET_APPLICATIONS_STATS_SUCCESS";
    ListingTableActions["GET_APPLICATIONS_STATS_FAILURE"] = "GET_APPLICATIONS_STATS_FAILURE";
    ListingTableActions["CAN_APPLY_DRS_FOR_CC_REQUEST"] = "CAN_APPLY_DRS_FOR_CC_REQUEST";
    ListingTableActions["CAN_APPLY_DRS_FOR_CC_SUCCESS"] = "CAN_APPLY_DRS_FOR_CC_SUCCESS";
    ListingTableActions["CAN_APPLY_DRS_FOR_CC_FAILURE"] = "CAN_APPLY_DRS_FOR_CC_FAILURE";
    ListingTableActions["GET_APPLIED_SITES_REQUEST"] = "GET_APPLIED_SITES_REQUEST";
    ListingTableActions["GET_APPLIED_SITES_SUCCESS"] = "GET_APPLIED_SITES_SUCCESS";
    ListingTableActions["GET_APPLIED_SITES_FAILURE"] = "GET_APPLIED_SITES_FAILURE";
    ListingTableActions["GET_DRS_ELIGIBLE_REQUEST"] = "GET_DRS_ELIGIBLE_REQUEST";
    ListingTableActions["GET_DRS_ELIGIBLE_SUCCESS"] = "GET_DRS_ELIGIBLE_SUCCESS";
    ListingTableActions["GET_DRS_ELIGIBLE_FAILURE"] = "GET_DRS_ELIGIBLE_FAILURE";
})(ListingTableActions || (ListingTableActions = {}));
