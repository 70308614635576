import getConfig from '../config/env';
var Settings = /** @class */ (function () {
    function Settings(config) {
        this.config = config;
    }
    Settings.prototype.getConfig = function () {
        return this.config;
    };
    Settings.prototype.getApiUrl = function (action) {
        return "".concat(this.config.api, "/").concat(action);
    };
    Settings.prototype.enlightenUrl = function (url) {
        return "".concat(this.config.enlightenUrl, "/").concat(url);
    };
    Settings.prototype.digiCoreUrl = function (url) {
        return "".concat(this.config.digitalCoreUrl, "/api/v1/").concat(url);
    };
    Settings.prototype.getDrsUrl = function (url) {
        return "".concat(this.config.drsUrl, "/api/v1/").concat(url);
    };
    Settings.prototype.getPermittingUrl = function (url) {
        return "".concat(this.config.permittingUrl, "/api/v1/permit/").concat(url);
    };
    Settings.prototype.einCoreUrl = function (url) {
        return "".concat(this.config.ein_core_url, "/api/v1/").concat(url);
    };
    Settings.prototype.enlightenMenuUrl = function (menu) {
        return "".concat(this.config.enlightenUrl, "/").concat(menu);
    };
    Settings.prototype.bomMenuUrl = function (menu) {
        return "".concat(this.config.enlightenUrl).concat(menu);
    };
    Settings.prototype.solargraf = function (url) {
        return "".concat(this.config.solargraf_url, "/").concat(url);
    };
    Settings.prototype.solargrafApiUrl = function (url) {
        return "".concat(this.config.solargraf_api_url, "/").concat(url);
    };
    Settings.prototype.drsUiUrl = function (url) {
        return "".concat(this.config.drs_ui_url, "/").concat(url);
    };
    Settings.prototype.enlightenOrigin = function () {
        return this.config.enlightenUrl;
    };
    Settings.prototype.getTokenCookieName = function () {
        return "".concat(this.config.enlighten_token);
    };
    return Settings;
}());
export default new Settings(getConfig(process.env.NODE_ENV));
