export var InstallerActionTypes;
(function (InstallerActionTypes) {
    InstallerActionTypes["CREATE_APPLICATION_REQUEST"] = "CREATE_APPLICATION_REQUEST";
    InstallerActionTypes["CREATE_APPLICATION_SUCCESS"] = "CREATE_APPLICATION_SUCCESS";
    InstallerActionTypes["CREATE_APPLICATION_FAILURE"] = "CREATE_APPLICATION_FAILURE";
    InstallerActionTypes["DRS_GET_APPLICATION_REQUEST"] = "DRS_GET_APPLICATION_REQUEST";
    InstallerActionTypes["DRS_GET_APPLICATION_SUCCESS"] = "DRS_GET_APPLICATION_SUCCESS";
    InstallerActionTypes["DRS_GET_APPLICATION_FAILURE"] = "DRS_GET_APPLICATION_FAILURE";
    InstallerActionTypes["SET_SITE_ID"] = "SET_SITE_ID";
    InstallerActionTypes["SET_DRS_ID"] = "SET_DRS_ID";
    InstallerActionTypes["GET_USER_SELECTION_DATA_REQUEST"] = "GET_USER_SELECTION_DATA_REQUEST";
    InstallerActionTypes["GET_USER_SELECTION_DATA_SUCCESS"] = "GET_USER_SELECTION_DATA_SUCCESS";
    InstallerActionTypes["GET_USER_SELECTION_DATA_FAILURE"] = "GET_USER_SELECTION_DATA_FAILURE";
    InstallerActionTypes["GET_S3_URL_REQUEST"] = "GET_S3_URL_REQUEST";
    InstallerActionTypes["GET_S3_URL_SUCCESS"] = "GET_S3_URL_SUCCESS";
    InstallerActionTypes["GET_S3_URL_FAILURE"] = "GET_S3_URL_FAILURE";
    InstallerActionTypes["PUT_TO_S3_REQUEST"] = "PUT_TO_S3_REQUEST";
    InstallerActionTypes["PUT_TO_S3_SUCCESS"] = "PUT_TO_S3_SUCCESS";
    InstallerActionTypes["PUT_TO_S3_FAILURE"] = "PUT_TO_S3_FAILURE";
    InstallerActionTypes["GET_CART_URL_REQUEST"] = "GET_CART_URL_REQUEST";
    InstallerActionTypes["GET_CART_URL_SUCCESS"] = "GET_CART_URL_SUCCESS";
    InstallerActionTypes["GET_CART_URL_FAILURE"] = "GET_CART_URL_FAILURE";
    InstallerActionTypes["SET_PAYMENT_STATUS_FROM_STORE"] = "SET_PAYMENT_STATUS_FROM_STORE";
    InstallerActionTypes["GET_S3_DOWNLOAD_URL_REQUEST"] = "GET_S3_DOWNLOAD_URL_REQUEST";
    InstallerActionTypes["GET_S3_DOWNLOAD_URL_SUCCESS"] = "GET_S3_DOWNLOAD_URL_SUCCESS";
    InstallerActionTypes["GET_S3_DOWNLOAD_URL_FAILURE"] = "GET_S3_DOWNLOAD_URL_FAILURE";
    InstallerActionTypes["POST_NPS_FEEDBACK_REQUEST"] = "POST_NPS_FEEDBACK_REQUEST";
    InstallerActionTypes["POST_NPS_FEEDBACK_SUCCESS"] = "POST_NPS_FEEDBACK_SUCCESS";
    InstallerActionTypes["POST_NPS_FEEDBACK_FAILURE"] = "POST_NPS_FEEDBACK_FAILURE";
    InstallerActionTypes["RESUBMISSION_RECEIVED_REQUEST"] = "RESUBMISSION_RECEIVED_REQUEST";
    InstallerActionTypes["RESUBMISSION_RECEIVED_SUCCESS"] = "RESUBMISSION_RECEIVED_SUCCESS";
    InstallerActionTypes["RESUBMISSION_RECEIVED_FAILURE"] = "RESUBMISSION_RECEIVED_FAILURE";
    InstallerActionTypes["GET_NPS_FEEDBACK_REQUEST"] = "GET_NPS_FEEDBACK_REQUEST";
    InstallerActionTypes["GET_NPS_FEEDBACK_SUCCESS"] = "GET_NPS_FEEDBACK_SUCCESS";
    InstallerActionTypes["GET_NPS_FEEDBACK_FAILURE"] = "GET_NPS_FEEDBACK_FAILURE";
    InstallerActionTypes["DOWNLOAD_LOAD_CALCULATION_REQUEST"] = "DOWNLOAD_LOAD_CALCULATION_REQUEST";
    InstallerActionTypes["DOWNLOAD_LOAD_CALCULATION_SUCCESS"] = "DOWNLOAD_LOAD_CALCULATION_SUCCESS";
    InstallerActionTypes["DOWNLOAD_LOAD_CALCULATION_FAILURE"] = "DOWNLOAD_LOAD_CALCULATION_FAILURE";
    InstallerActionTypes["SAVE_PAYMENT_DETAILS_REQUEST"] = "SAVE_PAYMENT_DETAILS_REQUEST";
    InstallerActionTypes["SAVE_PAYMENT_DETAILS_SUCCESS"] = "SAVE_PAYMENT_DETAILS_SUCCESS";
    InstallerActionTypes["SAVE_PAYMENT_DETAILS_FAILURE"] = "SAVE_PAYMENT_DETAILS_FAILURE";
    InstallerActionTypes["IS_URL_VALID_REQUEST"] = "IS_URL_VALID_REQUEST";
    InstallerActionTypes["IS_URL_VALID_SUCCESS"] = "IS_URL_VALID_SUCCESS";
    InstallerActionTypes["IS_URL_VALID_FAILURE"] = "IS_URL_VALID_FAILURE";
})(InstallerActionTypes || (InstallerActionTypes = {}));
export var FIELD_REQUIRED = 'constants.fieldRequired';
export var DOCUMENT_FIELD_REQUIRED = 'constants.documentFieldRequired';
export var LINK_ERROR = 'constants.linkError';
export var ALLOWED_DRS_COUNTRIES = ['AU', 'DE', 'AT', 'BE', 'NL', 'FR', 'CH'];
export var SUPPORTED_LOCALES = ['nl', 'de', 'fr', 'en', 'en-AU'];
