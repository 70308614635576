import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stepper, Step, StepLabel, StepContent, Paper, Link, } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import OutlinedButton from '../../../OutlinedButton';
import { CircularProgress } from '@material-ui/core';
import { APPLICATION_STATES } from '../../../../utils/constant';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    panelHead: {
        background: theme.tertiary.panelHead,
        color: theme.tertiary.mainText,
        minHeight: '40px !important',
        height: '40px',
        cursor: 'unset !important',
    },
    heading: {
        fontWeight: 'bold',
        flexBasis: '90%',
        flexShrink: 0,
    },
    panelWrapper: {
        maxHeight: '516px',
        minHeight: '516px',
        overflow: 'auto',
    },
    columnFlex: {
        flexDirection: 'column',
    },
    stepperWrapper: {
        width: '100%',
        padding: '10px',
    },
    statusLabel: {
        fontWeight: 'bold',
        fontSize: '13px',
    },
    linkStyle: {
        fontSize: '12px',
        textTransform: 'none',
        color: '#3685D1',
    },
    updatedOnStyle: {
        fontSize: '12px',
    },
    updatedOnColor: {
        color: 'green',
    },
    infoText: {
        fontSize: '10px',
    },
    drsFiles: {
        padding: theme.spacing(2),
    },
    link: {
        color: '#3B86FF',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    loader: {
        position: 'relative',
        top: theme.spacing(0.5),
        left: theme.spacing(1),
    },
}); });
var StatusDetails = function (props) {
    var classes = useStyles();
    var statusDetails = get(props, 'application.statuses', []);
    statusDetails = statusDetails.filter(function (s) { return s.state_id !== APPLICATION_STATES.APPLICATION_INCOMPLETE.id; });
    var currentState = get(props.application, 'current_status.state_id', 0);
    var upComingStates = [];
    if (currentState === APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id) {
        upComingStates = [APPLICATION_STATES.APPLICATION_UNDER_REVIEW, APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
    }
    else if ([APPLICATION_STATES.RESUBMISSION_REQUESTED.id, APPLICATION_STATES.TIER2_RESUBMISSION_REQUESTED.id].includes(currentState)) {
        upComingStates = [APPLICATION_STATES.RESUBMISSION_RECEIVED, APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
    }
    else if ([
        APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id,
        APPLICATION_STATES.RESUBMISSION_RECEIVED.id,
        APPLICATION_STATES.REQUEST_FOR_TIER2_APPROVAL.id,
        APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS.id,
        APPLICATION_STATES.TIER2_RESUBMISSION_RECEIVED.id,
    ].includes(currentState)) {
        upComingStates = [APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
    }
    var statusesId = get(props.application, 'statuses', []).map(function (s) { return s.state_id; });
    var enableDownloadDrs = props.handleNpsDialogOpen &&
        (statusesId.includes(APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id) ||
            statusesId.includes(APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id));
    var getStatusName = function (status) {
        var statusName = '';
        if (status === null || status.state_id === APPLICATION_STATES.APPLICATION_INCOMPLETE.id) {
            statusName = APPLICATION_STATES.APPLICATION_INCOMPLETE.name;
        }
        else {
            if (status.state_id === APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id) {
                statusName = APPLICATION_STATES.NEW_APPLICATION_RECEIVED.nameForInstaller;
            }
            else if (status.state_id === APPLICATION_STATES.RESUBMISSION_RECEIVED.id) {
                statusName = APPLICATION_STATES.RESUBMISSION_RECEIVED.nameForInstaller;
            }
            else {
                Object.keys(APPLICATION_STATES).forEach(function (key) {
                    if (status.state_id === APPLICATION_STATES[key].id) {
                        statusName = APPLICATION_STATES[key].name;
                    }
                });
            }
        }
        return I18n.t(statusName);
    };
    var getFeedback = function () {
        if (props.handleNpsDialogOpen) {
            props.handleNpsDialogOpen();
        }
    };
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Accordion, { expanded: true, children: [_jsx(AccordionSummary, { className: classes.panelHead, children: _jsx(Typography, { className: classes.heading, children: I18n.t('statusDetails.applicationStatus') }) }), _jsxs(AccordionDetails, { className: classes.columnFlex, children: [_jsx("div", { className: classes.panelWrapper, children: _jsxs(Stepper, { activeStep: statusDetails.length, orientation: "vertical", className: classes.stepperWrapper, children: [statusDetails.map(function (status, i) { return (_jsxs(Step, { expanded: true, children: [_jsx(StepLabel, { children: _jsx(Typography, { className: classes.statusLabel, children: getStatusName(status) }) }), _jsx(StepContent, { children: status.last_updated_date !== '' && (_jsxs(Typography, { className: classes.updatedOnStyle, children: [_jsx("span", { className: classes.updatedOnColor, children: I18n.t('status.updatedOn') + ' : ' }), moment(status.last_updated_date).format('Do MMMM YYYY')] })) })] }, i)); }), upComingStates.map(function (status, i) { return (_jsx(Step, { expanded: true, children: _jsx(StepLabel, { children: _jsx(Typography, { className: classes.statusLabel, children: status.nameForInstaller ? I18n.t(status.nameForInstaller) : I18n.t(status.name) }) }) }, i)); })] }) }), enableDownloadDrs && (_jsxs(Paper, { elevation: 2, className: classes.drsFiles, children: [_jsxs(Link, { onClick: props.handleDownload, className: classes.link, children: [I18n.t('installerAction.downloadReviewDoc'), props.downloadInProgress && _jsx(CircularProgress, { className: classes.loader, size: 20 })] }), !props.isFeedbackReceived && (_jsx(OutlinedButton, { text: I18n.t('installerAction.shareYourFeedback'), handleClick: getFeedback, smaller: true }))] }))] })] }) }) }) }));
};
export default StatusDetails;
