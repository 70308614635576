import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Typography, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ContainedButton from '../../ContainedButton';
import { CUSTOMER_SUPPORT_LINK } from '../../../utils/constant';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        dialogHeader: {
            color: theme.palette.primary.dark,
            fontSize: theme.spacing(3.5),
            fontWeight: 'bold',
        },
        paper: {
            paddingRight: 0,
        },
        close: {
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(1.5),
            opacity: 0.23,
        },
        content: {
            padding: theme.spacing(0, 3),
        },
        requestId: {
            marginTop: theme.spacing(1.5),
            fontWeight: 'bold',
        },
        link: {
            color: '#3B86FF !important',
            cursor: 'pointer',
        },
        acceptButton: {
            backgroundColor: theme.tertiary.buttonColor,
            color: theme.tertiary.main,
            borderRadius: theme.spacing(0.5),
        },
        rejectButton: {
            color: theme.tertiary.gray,
        },
        buttonWrapper: {
            justifyContent: 'flex-start',
            padding: theme.spacing(3),
        },
        successText: {
            fontWeight: 'bold',
            color: theme.tertiary.green,
        },
        failureText: {
            fontWeight: 'bold',
            color: theme.tertiary.red,
        },
        autoCompleteText: (_a = {},
            _a[theme.breakpoints.down('sm')] = {
                '& .MuiInputLabel-formControl': {
                    fontSize: 14,
                },
            },
            _a),
    });
});
var PaymentStatusDialog = function (props) {
    var classes = useStyles();
    var openDialog = props.openDialog, handleClose = props.handleClose, handleProceed = props.handleProceed, headerText = props.headerText, closeButtonText = props.closeButtonText, proceedButtonText = props.proceedButtonText, paymentStatus = props.paymentStatus, statusMessage = props.statusMessage, infoText = props.infoText, requestId = props.requestId;
    var handleContinue = function () {
        if (handleProceed) {
            handleProceed();
        }
    };
    return (_jsxs(Dialog, { onClose: handleClose, open: openDialog, classes: { paper: classes.paper }, maxWidth: "md", children: [_jsx(DialogTitle, { children: _jsxs(Typography, { className: classes.dialogHeader, children: [headerText, _jsx(IconButton, { onClick: handleClose, className: classes.close, children: _jsx(CloseIcon, {}) })] }) }), _jsxs(DialogContent, { className: classes.content, children: [_jsx(Typography, { gutterBottom: true, className: clsx(paymentStatus === 'success' && classes.successText, paymentStatus === 'failure' && classes.failureText), children: statusMessage }), _jsxs(Typography, { children: [infoText, ' ', paymentStatus === 'failure' && (_jsx(Link, { className: classes.link, href: CUSTOMER_SUPPORT_LINK, target: "__blank", children: "Enphase Customer Support." })), ' '] }), requestId && (_jsxs(Typography, { className: classes.requestId, children: [I18n.t('designReviewService.requestedId'), ": ", requestId] }))] }), _jsxs(DialogActions, { className: classes.buttonWrapper, children: [proceedButtonText && _jsx(ContainedButton, { text: proceedButtonText, handleClick: handleContinue }), closeButtonText && (_jsx(Button, { onClick: handleClose, className: classes.rejectButton, children: closeButtonText }))] })] }));
};
export default PaymentStatusDialog;
