import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ContainedButton from '../ContainedButton';
import I18n from '../../config/locales';
var useStyles = makeStyles(function () { return ({
    dateRangeWrapper: {
        boxShadow: 'none',
        padding: '0px !important',
    },
}); });
var DatePicker = function (props) {
    var classes = useStyles();
    var open = props.open, handleClose = props.handleClose, handleSave = props.handleSave;
    var _a = useState(new Date()), selectedDate = _a[0], setSelectedDate = _a[1];
    var handleDateSave = function () {
        handleSave(selectedDate);
    };
    return (_jsxs(Dialog, { open: open, onClose: handleClose, children: [_jsx(DialogContent, { className: classes.dateRangeWrapper, children: _jsx(MuiPickersUtilsProvider, { utils: DateFnsUtils, children: _jsx(KeyboardDatePicker, { disableToolbar: true, variant: "static", format: "MM/dd/yyyy", margin: "normal", id: "date-picker-inline", label: I18n.t('datePicker.datePickerInline'), value: selectedDate, onChange: function (date) { return setSelectedDate(date); }, KeyboardButtonProps: {
                            'aria-label': 'change date',
                        } }) }) }), _jsx(DialogActions, { children: _jsx(ContainedButton, { handleClick: handleDateSave, text: I18n.t('buttons.ok') }) })] }));
};
export default DatePicker;
