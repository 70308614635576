import React from 'react';

function Link() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(52 -52.028)">
        <rect style={{ fill: 'none' }} width="16" height="16" transform="translate(-52 52.028)" />
        <path
          style={{ fill: '#4a4a4a' }}
          d="M-31.359,58.2a3.077,3.077,0,0,0-4.152-.685.51.51,0,0,0-.061.78l0,0a.512.512,0,0,0,.646.059,2.053,2.053,0,0,1,2.846.6,2.086,2.086,0,0,1-.314,2.6l-3.994,3.994a2.086,2.086,0,0,1-2.6.315,2.052,2.052,0,0,1-.336-3.17l.362-.362a.512.512,0,0,0,0-.724h0a.511.511,0,0,0-.724,0l-.263.263a3.176,3.176,0,0,0-.011,4.53,3.035,3.035,0,0,0,2.073.813,3.087,3.087,0,0,0,2.184-.9l4-4A3.146,3.146,0,0,0-31.359,58.2Z"
          transform="translate(-5.426 -2.44)"
        />
        <path
          style={{ fill: '#4a4a4a' }}
          d="M-49.6,68.26a2.089,2.089,0,0,1,.315-2.605l3.97-3.969a2.156,2.156,0,0,1,2.878-.138,2.019,2.019,0,0,1,.675,1.628,2.22,2.22,0,0,1-.68,1.435l-.634.634a.512.512,0,0,0,0,.724h0a.512.512,0,0,0,.724,0l.63-.629a3.161,3.161,0,0,0,.146-4.385,3.06,3.06,0,0,0-2.484-.946,3.245,3.245,0,0,0-2.005.977l-3.888,3.887a3.176,3.176,0,0,0-.006,4.534,3.032,3.032,0,0,0,2.069.811,3.12,3.12,0,0,0,1.729-.522.508.508,0,0,0,.059-.777l0,0a.512.512,0,0,0-.646-.059A2.055,2.055,0,0,1-49.6,68.26Z"
          transform="translate(-0.535 -3.907)"
        />
      </g>
    </svg>
  );
}

export default Link;
