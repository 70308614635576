var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return ({
    mainPaper: {
        padding: theme.spacing(0.75),
        marginTop: theme.spacing(2),
    },
    paperHeader: {
        background: '#f1f1f1',
        color: '#EA6318',
        padding: theme.spacing(1),
    },
    boxShadow: {
        boxShadow: '0px 0px 8px #00000029',
    },
    boldFont: {
        fontWeight: 'bold',
    },
}); });
var PanelTemplate = function (props) {
    var children = props.children, header = props.header, _a = props.boxShadow, boxShadow = _a === void 0 ? false : _a, _b = props.bold, bold = _b === void 0 ? false : _b, _c = props.className, className = _c === void 0 ? {} : _c, _d = props.headerClassName, headerClassName = _d === void 0 ? {} : _d;
    var additionalProps = boxShadow ? {} : { variant: 'outlined' };
    var classes = useStyles();
    var classNames = clsx(classes.mainPaper, className, boxShadow && classes.boxShadow);
    return (_jsxs(Paper, __assign({ square: true, className: classNames }, additionalProps, { children: [header && (_jsx(Typography, { component: "div", role: "tabpanel", className: clsx(classes.paperHeader, headerClassName, bold && classes.boldFont), children: header })), children] })));
};
export default PanelTemplate;
