const header = {
	header: {
		logOut: 'Cerrar sesión',
		enphaseStore: 'Tienda de Enphase',
		enphaseSupport: 'Soporte técnico de Enphase ',
		comingSoon: 'Próximamente',
		new: 'Nuevo',
		dashboard: 'Panel',
		systems: 'Sistemas',
		account: 'Cuenta',
		services: 'Servicios',
		dataPrivacy: 'Datos y privacidad',
		support: 'Soporte técnico',
		admin: 'Admin',
		adminNew: 'Admin (nuevo)'
	}
};

export default header;
