var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { InstallerActionTypes } from './constants';
var initialState = {
    drsId: '', // will be either new or DRS-${number}
    siteId: null,
    message: '',
    messageType: '',
    currentlySending: false,
    application: {
        site_id: null,
        project_details: {
            system_name: '',
            customer_name: '',
            address1: '',
            address2: '',
            state: '',
            city: '',
            country: '',
            zipcode: '',
            type: '',
            solar_system_size: '',
            battery_type: '',
            battery_quantity: 0,
            micro_inverter_types: [],
            notes: '',
        },
        project_documents: {
            site_map_photos: [],
            sld_documents: [],
            msp_documents: [],
            installation_photos: [],
            other_documents: [],
            load_calculation_documents: [],
            survey_photos: [],
            site_map_photos_url: '',
            sld_documents_url: '',
            msp_documents_url: '',
            other_documents_url: '',
            load_calculation_documents_url: '',
            survey_photos_url: '',
            resubmitted_documents: [],
            chat_documents: [],
        },
    },
    userSelectionData: {
        systemTypes: [],
        microInverterTypes: [],
        batteryTypes: [],
    },
    cartUrl: '',
    paymentStatus: '',
    downloadingDoc: false,
    feedbacks: [],
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types , @typescript-eslint/no-explicit-any
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case InstallerActionTypes.CREATE_APPLICATION_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.CREATE_APPLICATION_SUCCESS:
            return __assign(__assign({}, state), { message: action.message, messageType: 'success', currentlySending: false });
        case InstallerActionTypes.CREATE_APPLICATION_FAILURE:
            return __assign(__assign({}, state), { message: action.message, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.DRS_GET_APPLICATION_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.DRS_GET_APPLICATION_SUCCESS:
            return __assign(__assign({}, state), { application: action.application, message: action.message, messageType: 'success', currentlySending: false });
        case InstallerActionTypes.DRS_GET_APPLICATION_FAILURE:
            return __assign(__assign({}, state), { message: action.message, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.GET_S3_URL_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.GET_S3_URL_SUCCESS:
            return __assign(__assign({}, state), { currentlySending: false });
        case InstallerActionTypes.GET_S3_URL_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.GET_S3_DOWNLOAD_URL_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.GET_S3_DOWNLOAD_URL_SUCCESS:
            return __assign(__assign({}, state), { currentlySending: false });
        case InstallerActionTypes.GET_S3_DOWNLOAD_URL_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.SET_SITE_ID:
            return __assign(__assign({}, state), { siteId: action.siteId });
        case InstallerActionTypes.SET_DRS_ID:
            return __assign(__assign({}, state), { drsId: action.drsId, currentlySending: false });
        case InstallerActionTypes.PUT_TO_S3_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.PUT_TO_S3_SUCCESS:
            return __assign(__assign({}, state), { currentlySending: false });
        case InstallerActionTypes.PUT_TO_S3_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.GET_USER_SELECTION_DATA_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.GET_USER_SELECTION_DATA_SUCCESS:
            return __assign(__assign({}, state), { userSelectionData: action.userSelectionData, currentlySending: false });
        case InstallerActionTypes.GET_USER_SELECTION_DATA_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case InstallerActionTypes.GET_CART_URL_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.GET_CART_URL_SUCCESS:
            return __assign(__assign({}, state), { cartUrl: action.cartUrl, currentlySending: false });
        case InstallerActionTypes.GET_CART_URL_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false, paymentStatus: action.status });
        case InstallerActionTypes.SET_PAYMENT_STATUS_FROM_STORE:
            return __assign(__assign({}, state), { paymentStatus: action.status });
        case InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_REQUEST:
            return __assign(__assign({}, state), { downloadingDoc: true });
        case InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_SUCCESS:
            return __assign(__assign({}, state), { downloadingDoc: false });
        case InstallerActionTypes.DOWNLOAD_LOAD_CALCULATION_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', downloadingDoc: false });
        case InstallerActionTypes.GET_NPS_FEEDBACK_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case InstallerActionTypes.GET_NPS_FEEDBACK_SUCCESS:
            return __assign(__assign({}, state), { currentlySending: false, feedbacks: action.feedbacks });
        case InstallerActionTypes.GET_NPS_FEEDBACK_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        default:
            return state;
    }
};
export default reducer;
