var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
export default function createMyTheme(options) {
    return responsiveFontSizes(createMuiTheme(__assign({ typography: {
            fontFamily: 'Roboto',
        }, palette: {
            primary: {
                main: '#F37320',
                dark: '#335977',
            },
            secondary: __assign({ main: '#01B4DE' }, blue),
        }, overrides: {
            MuiButton: {
                root: {
                    '&:disabled': {
                        color: '#FFFFFF',
                    },
                },
            },
            MuiStepIcon: {
                text: {
                    fontWeight: 'bold',
                    fill: '#FFFFFF',
                },
                root: {
                    '&$active': {
                        color: '',
                    },
                },
            },
        } }, options)));
}
// for adding any new color here go to modules/themeModules add the required color there in interface
export var theme = createMyTheme({
    tertiary: {
        main: '#FFFFFF',
        light: '#FAFAFA',
        dark: '#7E7E7E',
        background: '#F6F7FB',
        lighter: grey[100],
        yellow: '#FFE785',
        gray: '#919191',
        buttonColor: '#F37321',
        mainText: '#545456',
        panelHead: '#f1f1f1',
        green: '#6E9D03DE',
        red: '#E43E3D',
        white: '#FFFFFF',
        primaryMain: '#F3731F',
        link: '#2679f6',
        lightOrange: '#FFECE0',
        darkBlue: '#306FB2',
    },
});
