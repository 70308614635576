import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, DialogActions, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';
import settings from '../../../../../utils/settings';
import I18n from '../../../../../config/locales';

const RedirectionConfirmPopup = (props) => {
  const classes = useStyles();
  const { handleClose = () => {}, sgToken, token } = props;

  const navigateToSolargraf = () => {
    window.location = settings.solargraf(`enlm/login?solargrafToken=${sgToken}&token=${token}`);
    handleClose();
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="link-solargraf-dialog-title"
      aria-describedby="link-solargraf-description"
      maxWidth="sm"
      classes={{ paper: classes.dialogContainer }}
      fullWidth
    >
      <DialogTitle disableTypography id="link-solargraf-dialog-title" className={classes.dialogTitle}>
        <Typography variant="h6">{I18n.t('serviceDialog.designProposalTool')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="link-solargraf-description" className={classes.dialogContent}>
        <Typography>{I18n.t('serviceDialog.redirect')}</Typography>
        <Typography className={classes.dialogParaText}>{I18n.t('serviceDialog.wishToProceed')}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleClose} className={classes.cancelButton} variant="outlined">
          {I18n.t('buttons.cancel')}
        </Button>
        <Button onClick={navigateToSolargraf} color="primary" variant="contained" className={classes.buttonText}>
          {I18n.t('buttons.proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectionConfirmPopup;
