/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import I18n from '../../../../config/locales';
import {
  Typography,
  Box,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  Divider,
  FormControlLabel,
  Radio,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormHelperText,
  Link,
} from '@material-ui/core';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getCountryBasedLink } from '../../utils/helper';
import { Label } from '../../components/common/Label';
import { createSystem } from '../../containers/Systems/actions';

const styles = (theme) => ({
  inputField: {
    width: '80%',
    minWidth: theme.spacing(10),
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginTop: theme.spacing(2),
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiRadio-root': {
      paddingLeft: 0,
    },
  },
  radioInputField: {
    width: 'auto',
    marginTop: 0,
    padding: theme.spacing(0, 2.5),
  },
  heading: {
    fontWeight: 'bold',
    color: '#F37320',
    padding: theme.spacing(1, 2.5),
  },
  headerWrapper: {
    background: theme.palette.secondary.light,
    display: 'flex',
  },
  expandButton: {
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    textTransform: 'none',
    borderColor: '#F3731F',
    margin: theme.spacing('auto', 0),
  },
  subtitle: {
    fontWeight: 'bold',
  },
  divider: {
    margin: theme.spacing(0, 2.5),
  },
  warningAlert: {
    margin: theme.spacing(2.5),
    padding: theme.spacing(1.5, 2.5),
    background: '#FFFAE2',
    color: '#707070',
  },
  paperContent: {
    padding: theme.spacing(2.5, 0),
    border: '1px solid #7070704D',
    borderRadius: theme.spacing(0.5),
  },
  footerSection: {
    '& *': {
      fontSize: 12,
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(2.5),
      marginTop: theme.spacing(0.75),
    },
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'flex-start',
    },
  },
  outerPadding: {
    padding: theme.spacing(2.5),
  },
  checkbox: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    padding: 0,
  },
  checkboxLabel: {
    fontSize: 14,
    paddingLeft: theme.spacing(1),
    lineHeight: 1,
  },
  checkboxWrapper: {
    marginTop: theme.spacing(2),
    flexWrap: 'nowrap',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  gridContainer: {
    marginTop: theme.spacing(0),
  },
  footerContainer: {
    margin: theme.spacing(1.5, 0),
  },
  enlightenVersionlabel: {
    color: '#707070',
    fontSize: 14,
    marginTop: theme.spacing(1),
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  enlightenVersionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandedRoot: {
    width: '100%',
    padding: theme.spacing(2.5),
    display: 'grid',
    gridRowGap: theme.spacing(2.5),
    gridColumnGap: theme.spacing(2.5),
    gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
    },
  },
  contHeader: {
    fontWeight: 'bold',
    fontSize: 15,
  },
  contData: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.dataBlack,
    paddingTop: 2,
  },
  termsConditionWrapper: {
    margin: theme.spacing(0, 2.5),
  },
  link: {
    padding: theme.spacing(0, 0.5),
  },
  systemDetailsWrapper: {
    paddingTop: 0,
  },
});

class AddSystemSection extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('street1'), {});
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect = () => {
    let addressObject = this.autocomplete.getPlace();
    let addresses = addressObject.address_components;
    const streetNumber = addresses.find(
      (address) => address.types.includes('street_number') || address.types.includes('premise')
    );
    const locality = addresses.find(
      (address) => address.types.includes('locality') || address.types.includes('political')
    );
    const route = addresses.find(
      (address) => address.types.includes('route') || address.types.includes('sublocality_level_1')
    );
    const addressLocality = locality ? locality.long_name : '';
    const address1 = streetNumber && route ? [streetNumber.long_name, route.long_name].join(', ') : '';
    const city = addresses.find((address) => address.types.includes('locality'));
    const state = addresses.find((address) => address.types.includes('administrative_area_level_1'));
    const zip = addresses.find((address) => address.types.includes('postal_code'));
    const country = addresses.find((address) => address.types.includes('country'));

    const newAddress = { ...this.props.address };
    newAddress.street1 = address1 || addressLocality;
    newAddress.street2 = address1 || addressLocality;
    newAddress.city = city && (city.long_name || city.short_name);
    newAddress.state = state && (state.short_name || state.long_name);
    newAddress.postal_code = (zip && (zip.short_name || zip.long_name)) || '';
    newAddress.country = country && (country.short_name || country.long_name);
    this.props.setAddress(newAddress);
  };

  preventDefault = (event, key) => {
    if (event.which !== 8 && event.which !== 0 && (event.which < 48 || event.which > 57)) {
      event.preventDefault();
    }
  };

  render() {
    const {
      classes,
      countryList = [],
      setAddressInfo,
      address,
      owner,
      system,
      setSystemInfo,
      setOwnerInfo,
      isNewSystem,
      setIsNewSystem,
      errors,
      isConfirmChecked,
      handleConfirmCheckedChange,
      isExpanded,
      toggleExpansion,
      isInValidEmail,
      isInValidPhone,
    } = this.props;
    const { street1, street2, city, state, country, postal_code } = address;
    const { first_name, last_name, email, phone } = owner;
    const { system_name, is_lease, source, expected_pcu_count } = system;

    return (
      <Box className={classes.root}>
        <Box className={classes.paperContent}>
          <FormControlLabel
            className={clsx(classes.radioInputField)}
            value={isNewSystem === true}
            control={
              <Radio
                checked={isNewSystem === true}
                color="primary"
                size="medium"
                classes={{ padding: 0 }}
                onChange={() => setIsNewSystem(true)}
              />
            }
            label={<Typography>{I18n.t('leadManagement.createSystem')}</Typography>}
          />
          {isNewSystem && (
            <Fragment>
              <Grid className={clsx(classes.outerPadding, classes.systemDetailsWrapper)}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item lg={4} md={4} xs={12} sm={6}>
                    <TextField
                      id="sysytemName"
                      className={classes.inputField}
                      label={<Label text={I18n.t('leadManagement.systemName')} isRequired />}
                      value={system_name}
                      onChange={(e) => setSystemInfo(e, 'system_name')}
                      error={get(errors, 'system_name')}
                      helperText={get(errors, 'system_name') && 'Required'}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12} sm={6}>
                    <FormControl id="systemType" className={classes.inputField} error={get(errors, 'source')}>
                      <InputLabel id="systemTypeLabel">
                        <Label text={I18n.t('leadManagement.type')} isRequired />
                      </InputLabel>
                      <Select
                        id="country-select"
                        labelId="country-select-label"
                        IconComponent={(props) => <ExpandMoreIcon className="MuiSelect-icon" />}
                        value={source}
                        onChange={(e) => setSystemInfo(e, 'source')}
                        defaultValue={1}
                      >
                        {[
                          { type: I18n.t('leadManagement.residential'), value: 1 },
                          { type: I18n.t('leadManagement.commercial'), value: 2 },
                          { type: I18n.t('leadManagement.other'), value: 3 },
                        ].map((item) => {
                          return (
                            <MenuItem key={item.value} value={item.value}>
                              {item.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {get(errors, 'source') && <FormHelperText>{get(errors, 'source') && 'Required'}</FormHelperText>}
                    </FormControl>
                  </Grid>
                  {/* <Grid item lg={4} md={4} xs={12} sm={6}>
                    <TextField
                      className={classes.inputField}
                      label={<Label text={'Installer reference'} />}
                      value={reference}
                      onChange={e => setSystemInfo(e, 'reference', false)}
                      error={get(errors, 'reference')}
                      helperText={get(errors, 'reference') && 'Required'}
                      InputProps={{ inputProps: { maxLength: 32 } }}
                    />
                  </Grid> */}
                </Grid>

                <Grid container alignItems="center" className={classes.checkboxWrapper}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={is_lease}
                    onChange={(e) => setSystemInfo(e, 'is_lease', false, true)}
                    size="small"
                    color="primary"
                    disabled
                  />
                  <Typography className={clsx(classes.checkboxLabel, classes.disabled)} component="span">
                    {I18n.t('leadManagement.thirdParty')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={classes.headerWrapper}>
                <Typography className={classes.heading}> {I18n.t('leadManagement.siteInformation')}</Typography>
                <Button className={classes.expandButton} variant="outlined" onClick={toggleExpansion}>
                  {isExpanded ? I18n.t('leadManagement.collapse') : I18n.t('leadManagement.expand')}
                </Button>
              </Grid>

              {isExpanded ? (
                <Grid className={classes.mainGrid}>
                  <Grid className={classes.outerPadding}>
                    <Typography className={classes.subtitle}>{I18n.t('basicDetails.owner')}</Typography>
                    <Grid container spacing={2} className={classes.gridContainer}>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          id="firstName"
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.firstName')} isRequired />}
                          value={first_name}
                          onChange={(e) => setOwnerInfo(e, 'first_name')}
                          error={get(errors, 'first_name')}
                          helperText={get(errors, 'first_name') && 'Required'}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          id="lastName"
                          className={classes.inputField}
                          label={<Label text={'Last Name'} isRequired />}
                          value={last_name}
                          onChange={(e) => setOwnerInfo(e, 'last_name')}
                          error={get(errors, 'last_name')}
                          helperText={get(errors, 'last_name') && 'Required'}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          id="email"
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.email')} isRequired />}
                          value={email}
                          onChange={(e) => setOwnerInfo(e, 'email')}
                          error={get(errors, 'email')}
                          helperText={
                            get(errors, 'email')
                              ? isInValidEmail
                                ? I18n.t('leadManagement.invalidEmail')
                                : I18n.t('leadManagement.required')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.phone')} isRequired />}
                          value={phone}
                          onChange={(e) => setOwnerInfo(e, 'phone')}
                          error={get(errors, 'phone')}
                          helperText={
                            get(errors, 'phone')
                              ? isInValidPhone
                                ? I18n.t('leadManagement.invalidPhoneNumber')
                                : I18n.t('leadManagement.required')
                              : ''
                          }
                          onKeyPress={(e) => this.preventDefault(e)}
                          InputProps={{ inputProps: { maxLength: 10 } }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6} className={classes.enlightenVersionContainer}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.enlightenVersion')} />}
                          value={'MyEnlighten'}
                          disabled
                        />
                        <label className={classes.enlightenVersionlabel}>{I18n.t('leadManagement.ownerReceive')}</label>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid className={classes.outerPadding}>
                    <Typography className={classes.subtitle}>{I18n.t('leadManagement.location')}</Typography>
                    <Grid container spacing={2} className={classes.gridContainer}>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          id="street1"
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.streetAddress')} isRequired />}
                          value={street1}
                          onChange={(e) => setAddressInfo(e, 'street1')}
                          error={get(errors, 'street1')}
                          helperText={get(errors, 'street1') && I18n.t('leadManagement.required')}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.streetAddress') + '2'} />}
                          value={street2}
                          onChange={(e) => setAddressInfo(e, 'street2', false)}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.zipCode')} isRequired />}
                          value={postal_code}
                          onChange={(e) => setAddressInfo(e, 'postal_code')}
                          error={get(errors, 'postal_code')}
                          helperText={get(errors, 'postal_code') && 'Required'}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <FormControl id="country" className={classes.inputField} error={''}>
                          <InputLabel id="countryLabel">
                            <Label text={I18n.t('leadManagement.country')} isRequired />
                          </InputLabel>
                          <Select
                            id="country-select"
                            labelId="country-select-label"
                            IconComponent={(props) => <ExpandMoreIcon className="MuiSelect-icon" />}
                            value={country}
                            onChange={(e) => setAddressInfo(e, 'country')}
                            defaultValue={country}
                          >
                            {countryList.map((country) => {
                              const isDisabled = country[2] && country[2].disabled ? true : false;
                              return (
                                <MenuItem disabled={isDisabled} key={country[1]} value={country[1]}>
                                  {country[0]}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.state')} isRequired />}
                          value={state}
                          onChange={(e) => setAddressInfo(e, 'state')}
                          error={get(errors, 'state')}
                          helperText={get(errors, 'state') && 'Required'}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.city')} isRequired />}
                          value={city}
                          onChange={(e) => setAddressInfo(e, 'city')}
                          error={get(errors, 'city')}
                          helperText={get(errors, 'city') && 'Required'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid className={classes.outerPadding}>
                    <Typography className={classes.subtitle} gutterBottom>
                      Device Details
                    </Typography>
                    <Grid container className={classes.gridContainer}>
                      <Grid item lg={4} md={4} xs={12} sm={6}>
                        <TextField
                          className={classes.inputField}
                          label={<Label text={I18n.t('leadManagement.noOfInverter')} isRequired />}
                          value={expected_pcu_count}
                          onChange={(e) => setSystemInfo(e, 'expected_pcu_count')}
                          error={get(errors, 'expected_pcu_count')}
                          helperText={get(errors, 'expected_pcu_count') && 'Required'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="row" className={classes.expandedRoot}>
                  <Box>
                    <Typography className={classes.contHeader}>{I18n.t('leadManagement.customerName')}</Typography>
                    <Typography className={classes.contData}>{`${first_name} ${last_name}`}</Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.contHeader}>{I18n.t('applicationView.address')}</Typography>
                    <Typography className={classes.contData}>{Object.values(address).join(', ')}</Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.contHeader}>{I18n.t('leadManagement.zip')}</Typography>
                    <Typography className={classes.contData}>{postal_code}</Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.contHeader}>{I18n.t('leadManagement.noOfInverter')}</Typography>
                    <Typography className={classes.contData}>{expected_pcu_count}</Typography>
                  </Box>
                </Grid>
              )}
              <Divider className={classes.divider} />

              <Alert icon={false} severity="info" className={classes.warningAlert}>
                {I18n.t('leadManagement.enphaseGuideLine')}
              </Alert>
              <Grid className={classes.termsConditionWrapper}>
                <Grid container alignItems="center">
                  <Checkbox
                    className={classes.checkbox}
                    checked={isConfirmChecked}
                    onChange={handleConfirmCheckedChange}
                    size="small"
                    color="primary"
                  />
                  <Typography className={classes.checkboxLabel} component="span">
                    {I18n.t('leadManagement.confirm')}
                  </Typography>
                </Grid>
                <Grid container alignItems="center" className={classes.footerContainer}>
                  <List component="ul" disablePadding className={classes.footerSection}>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText disableTypography>{I18n.t('leadManagement.authorized')}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        {I18n.t('leadManagement.readUnderstand')}
                        <Link className={classes.link} href={getCountryBasedLink('privacy')} target="_blank">
                          {I18n.t('leadManagement.privacyPolicy')}
                        </Link>
                        {I18n.t('leadManagement.and')}
                        <Link className={classes.link} href={getCountryBasedLink('terms')} target="_blank">
                          {I18n.t('leadManagement.terms')}
                        </Link>
                        ({I18n.t('leadManagement.policy')}).
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>{I18n.t('leadManagement.acknowledge')}</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText>{I18n.t('leadManagement.informedSystemOwner')}</ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </Box>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  countryList: state.appReducer.masterData.country_list,
});

const mapDispatchToProps = (dispatch) => ({
  createSystem: (payload) => dispatch(createSystem(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddSystemSection));
