const footer = {
  footer: {
    imageTitle: 'Odwiedź witrynę internetową Enphase Energy',
    privacy: 'Prywatność',
    terms: 'Warunki',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Wszelkie prawa zastrzeżone.',
    language: 'Język'
  }
};

export default footer;
