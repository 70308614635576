const designReviewService = {
  All: 'All',
  drsDashboard: {
    pageHeader: 'Design Review Service Requests',
    createNewTicket: 'Create a New Request',
    filter: 'Filter',
    all: 'All',
    tier1: 'Tier 1',
    tier2: 'Tier 2',
    country: 'Country',
    us: 'US',
    australia: 'Australia',
    hideChart: 'Hide Chart',
    currentlyAssigned: 'Currently Assigned',
    overAllStatus: 'Overall Application Status',
    totalNoOf: 'Total number of ',
    applications: 'Applications',
    allApplication: 'All Applications',
    note: 'Note: The below table is filtered with commercial design review applications. Please',
    clickHere: 'click here',
    viewAllApplication: 'to view all applications',
    projectType: 'RESIDENTIAL',
    service: 'DRS',
    assignedTo: 'Assign To',
    assign: 'Assign',
  },
  applicationStatus: {
    pageHeader: 'Design Review Service',
    applicationSubmitted: 'Application submitted for Enphase Storage Certification course',
    resubmittedRequest: 'Resubmit Requested Information',
    shareInformation: 'We will the share the provided information with Enphase team to review it further.',
    sendEnPhaseTeam: 'Send to Enphase Team',
  },
  designReviewService: {
    requestedId: 'Request ID',
    enphaseStorageCourse: 'Part of Enphase Storage Certification course',
  },
  projectDetails: {
    communicateEnphase: 'Communicate with Enphase',
    headerText: 'Submit application',
    applicationSubmitted: 'Application Submitted',
    paymentFailed: 'Payment Failed',
    applicationSuccess: 'Congrats! Your application has been submitted.',
    applicationFailure: 'Oops! We were not able to complete your transaction.',
    paymentStatusSuccess: 'Our team will reach out to you for your Design Review application within 48 hours.',
    paymentStatusFailure: 'Please try again or contact',
    documents: 'Documents',
    siteMap: 'Site Map',
    singleLineDiagram: 'Single Line Diagram (SLD)',
    msp: 'Main Service Panel (MSP)',
    additionalDoc: 'Additional Documents',
    loadCalculation: 'Load Calculation',
    additionalDetails: 'Additional Details',
    photoUpload: 'Photo Upload (Survey Photos if available)',
    notes: 'Notes',
    backupLoadDetails: 'Backup Load Details',
    batterySizingDetails: 'Battery Sizing Details',
    siteSurveyDetails: 'Site Survey Details',
  },
  requestDetails: {
    siteMapPhotosUrl: 'Site Map',
    sldDocumentsUrl: 'Single Line Diagram (SLD)',
    installationPhotosUrl: 'Installation Photos',
    loadCalculationDocumentsUrl: 'Load Calculation Documents',
    mspDocumentsUrl: 'Image of Main Service Panel',
    otherDocumentsUrl: 'Documents',
    surveyPhotosUrl: 'Survey Photos',
    heading: 'Design Review Application Details',
    requestId: 'REQUEST ID',
    installerName: 'INSTALLER NAME',
    systemId: 'SYSTEM ID',
    systemName: 'SYSTEM / PROJECT NAME',
    customerName: 'CUSTOMER NAME',
    zipState: 'ZIP / STATE',
    status: 'STATUS',
    type: 'TYPE',
    assignedTo: 'ASSIGNED TO',
    createdOn: 'CREATED ON',
    updatedOn: 'UPDATED ON',
    documents: 'Documents',
    downloadAll: 'Download All',
    links: 'Links',
    installer: 'INSTALLER',
    admin: 'ADMIN',
    today: 'Today',
    pastDays: 'Past 7 Days',
    monthToData: 'Month to Date',
    yearToDate: 'Year to Date',
    specificDate: 'Specific Date',
    allDatesBefore: 'All Dates Before',
    allDatesAfter: 'All Dates After',
    dateRange: 'Date Range',
    clearDate: 'Clear Dates',
    utilityBill: 'Utility Bill',
    equipmentElevation: 'Equipment Elevation',
    planSet: 'Plan Set',
  },
  chats: {
    fileUploadIssue: 'We are facing some issues with file upload, please try later.',
    others: 'Others',
    downloading: 'downloading',
    downloaded: 'downloaded',
    fromEnphase: 'From Enphase',
    fromInstaller: 'From Installer',
    fromUnknown: 'From Unknown',
    notifyViaEmail:
      'You can close this window after sending your message. We will notify you via email when there is a response available to your query.',
    query:
      'This section can be used to query regarding your design review application & keep track of your conversations.',
    typeMessage: 'Type message',
    communicateWithEnphase: 'Communicate with Enphase',
    communicateWithInstaller: 'Communicate with Installer',
    submitted: 'submitted',
  },
  approvalDialog: {
    documentApprove: 'I approve the following design review document(s)',
    specificComments: ' If you would like to share specific comments please enter them below',
    approveSend: 'Approve and Send to Installer',
  },
  fileUpload: {
    fileValidation: 'Please select file or enter comments to continue...',
    reasonResubmission: 'Reason for Resubmission',
    validationReasonResubmission: 'Please enter the reason for resubmission',
    fileMaxSize: `File size is greater that %{fileSize} MB...`,
    shareComments: 'If you would like to share specific comments please enter them below',
    uploadFiles: 'Upload Files',
    selectFiles: 'Select Files',
  },
  infoDialog: {
    notes: 'Notes',
  },
  adminAction: {
    designDocUpload: 'Design Doc Uploaded',
    tier2ApprovalComment: 'Tier 2 Approval Comments',
    resubmissionRequested: 'Resubmission Requested',
    uploadSendInstaller: 'Upload and Send to Installer',
    finalDrsUpload:
      'Please upload final Design Review document(s) to share with installer. If you would like to approve Tier 1 uploaded documents and the same with Installer, then please go back to previous screen and click on “Approve Tier1 Document“ button.',
    drsShareInstaller: 'Please upload final Design Review document(s) to share with installer',
    requestToTier2: 'Request for Tier 2 Approval',
    tier2Approval: 'Please upload the Design Review document(s) to share with Tier 2 team for approval',
    tier1DocUpload: 'Approve document uploaded by Tier 1',
    approveTier1Doc: 'Approve Tier1 Document',
    modifyUploadDrs:
      'We will share the document(s) with installers on approval. If you would like to modify and upload a new design review document, then please go back to previous screen and click on “Upload and Send to Installer"',
    shareInformation: 'Please share the information you need from installer',
  },
  applicationDetails: {
    applicantDetails: 'Applicant Details',
    applicationDetails: 'Application Details',
    viewApplication: 'View Application',
  },
  applicationView: {
    systemProjectName: 'System/Project Name',
    systemId: 'System ID',
    type: 'Type',
    solarSize: 'Solar Size (KW)',
    batteryType: 'Battery Type',
    batteryQuantity: 'Battery Quantity',
    customerName: 'Customer Name',
    zip: 'Zip',
    State: 'State',
    address: 'Address',
    installerName: 'Installer Name',
    installerCompanyName: 'Installer Company Name',
    installerContactNumber: 'Installer Contact Number',
    installerEmailAddress: 'Installer Email Address',
    notes: 'Notes',
    receivedOn: 'Received on',
    uploadedOn: 'Uploaded on',
    noDoc: 'No Documents Available',
    resubmissionReceived: 'Resubmission Received',
  },
  statusDetails: {
    applicationStatus: 'Application Status',
    updateStatus: 'Update Status',
    downloadfailed: 'Download Failed',
    download: 'Download',
    dateOfComplete: 'Date of Completion',
    update: 'Update',
  },
  datePicker: {
    datePickerInline: 'Date picker inline',
    changeDate: 'change date',
  },
  installerAction: {
    downloadReviewDoc: 'Download Review Document',
    shareYourFeedback: 'Share your feedback',
    resubmitDoc: 'Resubmit Requested Information',
  },
  status: {
    updatedOn: 'Updated On',
    downloadReviewDoc: 'Download Review Document',
    totalAmount: 'Total Amount to Pay',
    exclusiveTaxes: 'exclusive of all taxes',
    enphaseService1: 'I have read and agreed to the Enphase Design Review Services',
    enphaseService2: '',
    redirectNotification: 'You will be redirected to payment checkout page to complete the transaction',
    noChargeNotification: 'You will not be charged for submitting this design review application',
    partOfCourse: ' as part of your certification course.',
  },
  docUpload: {
    addFile: 'Add File',
    add: 'Add',
    downloadTemplate: 'Download template',
    shareDocLink: 'Share document link',
    linkFromGoogleDrive: 'Link from GoogleDrive etc',
    giveLink: 'Give us a link from GoogleDrive, Dropbox etc',
  },
  buttons: {
    sendInstaller: 'Send to Installer',
    requestInformation: 'Request More Information',
    requestTier2: 'Request for Tier 2 Approval',
    proceedButtonYes: 'Yes',
    proceedButtonText: 'Continue',
    closeButtonText: 'No',
    footerSubmitButton: 'Submit',
    back: 'Back',
    confirm: 'Confirm',
    footerCloseButton: 'Close',
    update: 'Update',
    cancel: 'Cancel',
    edit: 'Edit',
    proceed: 'Proceed',
    view: 'View',
    ok: 'Ok',
  },
  constants: {
    fieldRequired: 'This field is required',
    documentFieldRequired: 'Please upload a document or enter a link',
    linkError: 'The link provided is not valid',
    applicationIncomplete: 'Application Incomplete',
    newApplicationReceived: 'New Application Received',
    newApplicationAssigned: 'New Application Assigned',
    newApplicationSubmitted: 'New Application Submitted',
    applicationUnderReview: 'Application Under Review',
    resubmissionRequested: 'Resubmission Requested',
    resubmissionReceived: 'Resubmission Received',
    resubmissionSubmitted: 'Resubmission Submitted',
    applicationReviewCompleted: 'Application Review Completed',
    requestForTier2Approval: 'Tier 2 Approval Requested',
    tier2ApplicationAssigned: 'Tier 2 Application Assigned',
    tier2ApprovalUnderProgress: 'Tier 2 Approval Under Progress',
    tier2ResubmissionRequested: 'Tier 2 Resubmission Requested',
    tier2ResubmissionReceived: 'Tier 2 Resubmission Received',
    tier2ApprovalCompleted: 'Tier 2 Approval Completed',
    defaultMessage: 'To download files, please disable popup blocker in your browser',
  },
  drsListingTable: {
    noRequestAvailable: 'No Requests Available',
    notAssigned: 'Not Assigned',
    application: 'application',
    messages: 'Messages',
    message: 'Message',
    all: 'All',
  },
  fileUploadDialog: {
    documentUpload: 'Document Upload Checklist',
    fileTypeValidation: 'Please select file type to continue...',
    fileSizeValidation: `File size is greater that %{fileSize} MB...`,
    maxFileSize: 'Maximum file size',
    addSpecificComment: 'If you would like to share specific comments please enter them below',
    poor: 'Poor',
    great: 'Great',
    feedback: 'Feedback',
    recommendSurvey:
      'On a scale of 0 to 10, how likely are you to recommend our Design Review Service to a friend or colleague?',
  },
  prefillingDialog: {
    beforeSubmit:
      'Some of the fields in this application have been pre-filled using data exists in Enlighten. Please review the details carefully before submitting.',
  },
  basicDetails: {
    projectDetails: 'Project Details',
    residential: `Residential = contract with homeowners of single family home. \n 
    Commercial = contract with multi-family / commercial property owners.`,
    customerName: 'Customer Name',
    zip: 'Zip',
    owner: 'Owner',
  },
  microInvererDetails: {
    microInverterType: 'MicroInverter Type',
    pageHeader: 'MicroInverter Details',
  },
  solarDetails: {
    pageHeader: 'Solar Details',
    solarSystemSize: 'Solar System Size (kWac)',
    batteryType: 'Battery Type',
    batteryTypeNote: 'One IQ Battery 10T equals three times the IQ Battery 3T',
    batteryQuantity: 'Battery Quantity',
    kWh: 'kWh',
  },
  notification: {
    noMoreNotification: 'No more notifications to display',
    noNotification: 'No notifications available',
    serviceNotification: 'Service Notifications',
    viewAll: 'View All',
  },
  leadManagement: {
    selectSystemID: 'Select the system ID from the below list if system activation has already been started',
    systemNameValidation: 'Please enter the system name',
    createSystem: 'Create a new system',
    systemName: 'System Name',
    type: 'Type',
    siteInformation: 'Site Information',
    residential: 'Residential',
    commercial: 'Commercial',
    other: 'Other',
    thirdParty: 'Third Party Owned (ex: PPA or Leased)',
    collapse: 'Collapse',
    expand: 'Expand',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phone: 'Phone',
    invalidEmail: 'Invalid Email',
    invalidPhoneNumber: 'Invalid Phone Number',
    required: 'Required',
    enlightenVersion: 'Enlighten Version',
    ownerReceive: 'Owner will receive <b>MyEnlighten</b> for system performance monitoring.',
    location: 'Location',
    streetAddress: 'Street Address',
    zip: 'Zip Code',
    zipCode: 'Zip/Postal Code',
    country: 'Country',
    state: 'State/Province',
    city: 'City',
    noOfInverter: 'No. Of Microinverters',
    customerName: 'Customer Name',
    enphaseGuideLine:
      'Enphase provides guidance and resources to assist installers in designing a system within appropriate parameters. The installer and qualified electrician are responsible for final design and implementation.',
    confirm: 'I confirm the following',
    authorized:
      'I am authorized to register for Enlighten on behalf of the system owner/host, who has given me permission to submit this information to Enphase on their behalf.',
    readUnderstand: 'I have read and understand Enphase’s',
    privacyPolicy: ' Privacy Policy',
    and: 'and',
    terms: 'Terms of Service',
    policy: 'the “Policies”',
    acknowledge: 'The system owner/host has acknowledged that they have read and understand the Policies.',
    informedSystemOwner:
      'I have informed the system owner/host that my company will be able to monitor their energy consumption amounts and times of usage and that they may revoke this permission at any time',
  },
  serviceDialog: {
    quoteSetUp: 'Quote Setup',
    systemConfiguration: 'System Configuration',
    bomPricing: 'BOM & Pricing',
    projectDesign:
      'This project is currently being designed by Sofdesk. It is inaccessible now but will be available to you as soon as we have it ready.',
    redirect: 'You will be re-directed to your dashboard on the Solargraf Design and Proposal Tool.',
    wishToProceed: 'Do you wish to proceed?',
    designProposalTool: 'Design and Proposal Tool',
    measurementInProgress: 'Measurements In Progress',
    permitAssistanceStatus: 'PERMIT ASSISTANCE STATUS',
  },
  breadCrumb: {
    dashboard: 'Dashboard',
    SGIPApplication: 'SGIP Applications',
  },
};

export default designReviewService;
