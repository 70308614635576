var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ContainedButton from '../../ContainedButton';
import { cloneDeep, get } from 'lodash';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d;
    return ({
        dialogHeader: {
            color: theme.palette.primary.dark,
            fontSize: theme.spacing(3.5),
            fontWeight: 'bold',
        },
        paper: (_a = {
                paddingRight: 0,
                width: theme.spacing(70)
            },
            _a[theme.breakpoints.down('xs')] = {
                margin: theme.spacing(1),
            },
            _a),
        close: {
            position: 'absolute',
            right: theme.spacing(1.5),
            top: theme.spacing(1.5),
            opacity: 0.23,
        },
        content: {
            padding: theme.spacing(0, 3),
        },
        rejectButton: {
            color: theme.tertiary.gray,
        },
        buttonWrapper: {
            justifyContent: 'flex-start',
            padding: theme.spacing(3),
        },
        displayFlex: {
            display: 'flex',
            marginBottom: theme.spacing(2),
        },
        displayButtonFlex: {
            display: 'flex',
            flexDirection: 'column',
        },
        ratingButton: (_b = {
                border: '1px solid #233643 ',
                borderRadius: 0,
                height: theme.spacing(4.25),
                width: theme.spacing(4.25),
                marginRight: theme.spacing(1.5),
                outlineWidth: 0,
                backgroundColor: 'white',
                '&:hover': {
                    cursor: 'pointer',
                }
            },
            _b[theme.breakpoints.down('sm')] = {
                height: theme.spacing(3.25),
                width: theme.spacing(3.25),
                marginRight: theme.spacing(1.5),
            },
            _b[theme.breakpoints.down('xs')] = {
                height: theme.spacing(2.9),
                width: theme.spacing(2.9),
                marginRight: theme.spacing(1),
            },
            _b),
        backgroundRed: {
            backgroundColor: '#E43E3D',
            borderColor: '#E43E3D !important',
        },
        backgroundOrange: {
            backgroundColor: '#EF874C',
            borderColor: '#EF874C !important',
        },
        backgroundYellow: {
            backgroundColor: '#F8C43C',
            borderColor: '#F8C43C !important',
        },
        backgroundGreen: {
            backgroundColor: '#5BAF2A',
            borderColor: '#5BAF2A !important',
        },
        borderRed: {
            border: '1px solid #E43E3D',
        },
        borderOrange: {
            border: '1px solid #EF874C',
        },
        borderYellow: {
            border: '1px solid #F8C43C',
        },
        borderGreen: {
            border: '1px solid #5BAF2A',
        },
        ratingText: {
            paddingLeft: theme.spacing(0.5),
            fontSize: theme.spacing(1.5),
        },
        poorText: (_c = {
                paddingLeft: theme.spacing(0.5),
                color: '#EF212C',
                fontSize: theme.spacing(1.5)
            },
            _c[theme.breakpoints.down('sm')] = {
                fontSize: theme.spacing(1.2),
            },
            _c[theme.breakpoints.down('xs')] = {
                paddingLeft: 0,
            },
            _c),
        greatText: (_d = {
                paddingLeft: theme.spacing(0.5),
                color: '#3C9C46',
                fontSize: theme.spacing(1.5)
            },
            _d[theme.breakpoints.down('sm')] = {
                fontSize: theme.spacing(1.2),
            },
            _d[theme.breakpoints.down('xs')] = {
                paddingLeft: 0,
            },
            _d),
    });
});
var NPSFeedback = function (props) {
    var classes = useStyles();
    var openDialog = props.openDialog, handleClose = props.handleClose, handleProceed = props.handleProceed;
    var _a = useState([
        {
            colourClass: classes.backgroundRed,
            borderClass: classes.borderRed,
            text: 'fileUploadDialog.poor',
            className: '',
        },
        {
            colourClass: classes.backgroundRed,
            borderClass: classes.borderRed,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundRed,
            borderClass: classes.borderRed,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundOrange,
            borderClass: classes.borderOrange,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundOrange,
            borderClass: classes.borderOrange,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundOrange,
            borderClass: classes.borderOrange,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundOrange,
            borderClass: classes.borderOrange,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundYellow,
            borderClass: classes.borderYellow,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundYellow,
            borderClass: classes.borderYellow,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundGreen,
            borderClass: classes.borderGreen,
            text: '',
            className: '',
        },
        {
            colourClass: classes.backgroundGreen,
            borderClass: classes.borderGreen,
            text: 'fileUploadDialog.great',
            className: '',
        },
    ]), ratingObj = _a[0], setRatingObj = _a[1];
    var _b = useState(false), clicked = _b[0], setClicked = _b[1];
    var _c = useState(''), feedback = _c[0], setFeedback = _c[1];
    var _d = useState(-1), rating = _d[0], setRating = _d[1];
    var handleMouseHover = function (index) {
        if (!clicked) {
            var tempRatingObject = cloneDeep(ratingObj);
            tempRatingObject[index].className = ratingObj[index].colourClass;
            setRatingObj(__spreadArray([], tempRatingObject, true));
        }
    };
    var handleMouseLeave = function (index) {
        if (!clicked) {
            var tempRatingObject = cloneDeep(ratingObj);
            tempRatingObject[index].className = '';
            setRatingObj(__spreadArray([], tempRatingObject, true));
        }
    };
    var feedbackChange = function (e) {
        setFeedback(e.target.value);
    };
    var changeButtonColours = function (ratingValue) {
        var tempRatingObject = cloneDeep(ratingObj);
        for (var ind in ratingObj) {
            tempRatingObject[ind].className = '';
        }
        for (var i = 0; i <= ratingValue; i++) {
            tempRatingObject[i].className = ratingObj[ratingValue].colourClass;
        }
        setRatingObj(__spreadArray([], tempRatingObject, true));
    };
    var buttonClick = function (e, ind) {
        setClicked(true);
        setRating(ind);
        changeButtonColours(ind);
    };
    var handleFeedbackSubmit = function () {
        props.postNpsFeedback({
            body: {
                feedback_mode: get(props.application, 'current_status.state_id'),
                rating: rating,
                comment: feedback,
            },
            drsId: props.drsId,
            successCbk: function () {
                if (handleProceed) {
                    handleProceed();
                }
            },
        });
    };
    return (_jsxs(Dialog, { onClose: handleClose, open: openDialog, classes: { paper: classes.paper }, maxWidth: "md", children: [_jsx(DialogTitle, { children: _jsxs(Typography, { className: classes.dialogHeader, children: [I18n.t('fileUploadDialog.feedback'), _jsx(IconButton, { onClick: handleClose, className: classes.close, children: _jsx(CloseIcon, {}) })] }) }), _jsxs(DialogContent, { className: classes.content, children: [_jsx(Typography, { children: I18n.t('fileUploadDialog.recommendSurvey') }), _jsx("div", { className: classes.displayFlex, children: ratingObj.map(function (ele, idx) {
                            return (_jsxs("div", { className: classes.displayButtonFlex, children: [_jsx("button", { id: "button".concat(idx), onMouseOver: function () { return handleMouseHover(idx); }, onMouseLeave: function () { return handleMouseLeave(idx); }, className: clsx(classes.ratingButton, ele.borderClass, ele.className), value: idx, onClick: function (e) { return buttonClick(e, idx); }, children: idx }), _jsx(Typography, { className: clsx(classes.ratingText, ele.text === 'Poor' ? classes.poorText : classes.greatText), children: ele.text !== '' ? I18n.t(ele.text) : '' })] }, idx));
                        }) }), _jsx(Typography, { children: I18n.t('approvalDialog.specificComments') }), _jsx(TextField, { value: feedback, onChange: function (e) { return feedbackChange(e); }, multiline: true, rows: 4, rowsMax: 16, fullWidth: true, variant: "outlined" })] }), _jsxs(DialogActions, { className: classes.buttonWrapper, children: [_jsx(ContainedButton, { disabled: rating === -1, text: I18n.t('buttons.footerSubmitButton'), handleClick: handleFeedbackSubmit }), _jsx(Button, { onClick: handleClose, className: classes.rejectButton, children: I18n.t('buttons.cancel') })] })] }));
};
export default NPSFeedback;
