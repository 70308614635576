import {
  GET_LRE_PLAN_REQUEST,
  GET_SITE_LIST_REQUEST,
  SET_PURCHASE,
  SET_PURCHASED_LIST,
  GET_LRE_ITEMS,
  UPDATE_LRE_ITEMS,
  ADD_ITEMS_TO_CART,
  GET_SELECTED_ITEMS,
  SET_TRANSACTION_STATUS,
  GET_PLAN_BY_SITES,
  CREATE_SYSTEM_REQUEST,
  GET_AGREEMENT_REQUEST,
} from './constants';

export function getLREPlans(payload) {
  return { type: GET_LRE_PLAN_REQUEST, payload };
}
export function getSiteLists(payload) {
  return { type: GET_SITE_LIST_REQUEST, payload };
}

export function setPurchase(payload) {
  return { type: SET_PURCHASE, payload };
}

export function setPurchasedList(payload) {
  return { type: SET_PURCHASED_LIST, payload };
}

export function getLREItems(payload) {
  return { type: GET_LRE_ITEMS, payload };
}

export function updateLREItems(payload) {
  return { type: UPDATE_LRE_ITEMS, payload };
}

export function addItemsToCart(payload) {
  return { type: ADD_ITEMS_TO_CART, payload };
}

export function getSelectedItems(payload) {
  return { type: GET_SELECTED_ITEMS, payload };
}

export function setTransactionStatus(payload) {
  return { type: SET_TRANSACTION_STATUS, payload };
}

export function getPlansBySiteId(payload) {
  return { type: GET_PLAN_BY_SITES, payload };
}

// needed by addSystemSection.js, addSystemDialog.js
export function createSystem(payload) {
  return { type: CREATE_SYSTEM_REQUEST, payload };
}

export function getAgreementFile(payload) {
  return { type: GET_AGREEMENT_REQUEST, payload };
}
