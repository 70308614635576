import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Stepper, Step, StepLabel, StepContent, Button, } from '@material-ui/core';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { APPLICATION_STATES } from '../../../../utils/constant';
import { CircularProgress } from '@material-ui/core';
import I18n from '../../../../config/locales';
moment.locale('en');
var useStyles = makeStyles(function (theme) { return ({
    panelHead: {
        background: theme.tertiary.panelHead,
        color: theme.tertiary.mainText,
        minHeight: '40px !important',
        height: '40px',
        cursor: 'unset !important',
    },
    heading: {
        fontWeight: 'bold',
        flexBasis: '90%',
        flexShrink: 0,
    },
    panelWrapper: {
        maxHeight: '516px',
        minHeight: '516px',
        overflow: 'auto',
    },
    columnFlex: {
        flexDirection: 'column',
    },
    stepperWrapper: {
        width: '100%',
        padding: '10px',
        '& .MuiStepConnector-vertical': {
            height: theme.spacing(3.5),
            padding: 0,
        },
    },
    statusLabel: {
        fontWeight: 'bold',
        fontSize: '13px',
    },
    linkStyle: {
        fontSize: '12px',
        textTransform: 'none',
        color: '#3685D1',
    },
    updatedOnStyle: {
        fontSize: '12px',
    },
    updatedDate: {
        color: theme.tertiary.dark,
    },
    updatedOnColor: {
        color: 'green',
    },
    infoText: {
        fontSize: '10px',
    },
    assignedUser: {
        fontSize: theme.spacing(1.5),
        color: theme.tertiary.dark,
    },
    reviewerName: {
        color: theme.tertiary.link,
    },
    updateStatus: {
        color: theme.tertiary.link,
        textTransform: 'none',
        margin: theme.spacing(1, 0, 0, 0),
        padding: 0,
        fontSize: 13,
    },
    loader: {
        color: theme.tertiary.link,
        marginLeft: theme.spacing(1),
    },
}); });
var StatusDetails = function (props) {
    var classes = useStyles();
    var statusDetails = get(props, 'application.statuses', []);
    statusDetails = statusDetails.filter(function (s) { return s.state_id !== APPLICATION_STATES.APPLICATION_INCOMPLETE.id; });
    var currentState = get(props.application, 'current_status.state_id', 0);
    var upComingStates = [];
    switch (currentState) {
        case APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id:
            upComingStates = [
                APPLICATION_STATES.NEW_APPLICATION_ASSIGNED,
                APPLICATION_STATES.APPLICATION_UNDER_REVIEW,
                APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED,
            ];
            break;
        case APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id:
            upComingStates = [APPLICATION_STATES.APPLICATION_UNDER_REVIEW, APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
            break;
        case APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id:
        case APPLICATION_STATES.RESUBMISSION_RECEIVED.id:
            upComingStates = [APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
            break;
        case APPLICATION_STATES.RESUBMISSION_REQUESTED.id:
            upComingStates = [APPLICATION_STATES.RESUBMISSION_RECEIVED, APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED];
            break;
        case APPLICATION_STATES.REQUEST_FOR_TIER2_APPROVAL.id:
            upComingStates = [
                APPLICATION_STATES.TIER2_APPLICATION_ASSIGNED,
                APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS,
                APPLICATION_STATES.TIER2_APPROVAL_COMPLETED,
            ];
            break;
        case APPLICATION_STATES.TIER2_APPLICATION_ASSIGNED.id:
            upComingStates = [APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS, APPLICATION_STATES.TIER2_APPROVAL_COMPLETED];
            break;
        case APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS.id:
        case APPLICATION_STATES.TIER2_RESUBMISSION_RECEIVED.id:
            upComingStates = [APPLICATION_STATES.TIER2_APPROVAL_COMPLETED];
            break;
        case APPLICATION_STATES.TIER2_RESUBMISSION_REQUESTED.id:
            upComingStates = [APPLICATION_STATES.RESUBMISSION_RECEIVED, APPLICATION_STATES.TIER2_APPROVAL_COMPLETED];
            break;
        default:
            break;
    }
    var _a = useState(), initialReviewer = _a[0], setInitialReviewer = _a[1];
    var showAssignedUser = false;
    var lastAssignedUserName = '';
    var reAssigned = false;
    useEffect(function () {
        if (isEmpty(initialReviewer) && !isEmpty(get(props.application, 'reviewers', []))) {
            setInitialReviewer(get(props.application, 'reviewers', [])[0]);
        }
    }, [props.application]);
    var isReassigned = function (reviewer) {
        var currentReviewerName = get(reviewer, 'user_info.name');
        if (currentReviewerName !== (initialReviewer === null || initialReviewer === void 0 ? void 0 : initialReviewer.user_info.name)) {
            reAssigned = true;
        }
        return reAssigned ? 'Re assigned' : 'Assigned';
    };
    var showUpdateStatusButton = function (status) {
        var allowedStatuses = [
            APPLICATION_STATES.NEW_APPLICATION_ASSIGNED.id,
            APPLICATION_STATES.RESUBMISSION_REQUESTED.id,
            APPLICATION_STATES.TIER2_APPLICATION_ASSIGNED.id,
            APPLICATION_STATES.TIER2_RESUBMISSION_REQUESTED.id,
        ];
        var allowedTo = props.userEmail === get(props.application, 'current_reviewer.user_info.email') || props.isServiceAdmin;
        return (allowedTo &&
            allowedStatuses.includes(status.state_id) &&
            status.state_id === currentState &&
            status.last_updated_date === get(props.application, 'current_status.last_updated_date', ''));
    };
    var getStatusName = function (status) {
        if (status.state_id === APPLICATION_STATES.REQUEST_FOR_TIER2_APPROVAL.id) {
            return I18n.t(APPLICATION_STATES.REQUEST_FOR_TIER2_APPROVAL.name);
        }
        return status.state_name;
    };
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Accordion, { expanded: true, children: [_jsx(AccordionSummary, { className: classes.panelHead, children: _jsx(Typography, { className: classes.heading, children: I18n.t('statusDetails.applicationStatus') }) }), _jsx(AccordionDetails, { className: classes.columnFlex, children: _jsx("div", { className: classes.panelWrapper, children: _jsxs(Stepper, { activeStep: statusDetails.length, orientation: "vertical", className: classes.stepperWrapper, children: [statusDetails.map(function (status, i) {
                                            return (_jsxs(Step, { expanded: true, children: [_jsx(StepLabel, { children: _jsx(Typography, { className: classes.statusLabel, children: getStatusName(status) }) }), _jsxs(StepContent, { children: [status.last_updated_date !== '' && (_jsxs(Typography, { className: classes.updatedOnStyle, children: [_jsx("span", { className: classes.updatedOnColor, children: I18n.t('status.updatedOn') + ' : ' }), _jsx("span", { className: classes.updatedDate, children: moment(status.last_updated_date).format('Do MMMM YYYY') })] })), get(status, 'reviewers', []).map(function (reviewer, j) {
                                                                showAssignedUser = false;
                                                                if (!isEmpty(reviewer)) {
                                                                    showAssignedUser = get(reviewer, 'user_info.name', '') !== lastAssignedUserName;
                                                                    lastAssignedUserName = get(reviewer, 'user_info.name', '');
                                                                }
                                                                if (!showAssignedUser)
                                                                    return _jsx(Fragment, {});
                                                                return (_jsxs(Typography, { component: "li", className: classes.assignedUser, children: [isReassigned(reviewer), " to", ' ', _jsx("span", { className: classes.reviewerName, children: get(reviewer, 'user_info.name', '') }), ' ', moment(get(reviewer, 'assigned_date', '')).format('Do MMMM YYYY, h:mm:ss a')] }, j));
                                                            }), showUpdateStatusButton(status) && (_jsxs(Button, { onClick: props.updateStatus, className: classes.updateStatus, children: [I18n.t('statusDetails.updateStatus'), ' ', props.updatingStatus && _jsx(CircularProgress, { className: classes.loader, size: 12 })] }))] })] }, i));
                                        }), upComingStates.map(function (status, i) { return (_jsx(Step, { expanded: true, children: _jsx(StepLabel, { children: _jsx(Typography, { className: classes.statusLabel, children: I18n.t(status.name) }) }) }, i)); })] }) }) })] }) }) }) }));
};
export default StatusDetails;
