var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call } from 'redux-saga/effects';
import { get, post, putMethod } from '../../utils/api';
import { get as getLodash } from 'lodash';
import settings from '../../utils/settings';
import { ListingTableActions } from './constants';
import { constructQueryString, getToken } from '../../utils/helper';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function assignReviewerFlow() {
    var request, _a, drsId, userId, cbk, response, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(ListingTableActions.ASSIGN_REVIEWER_REQUEST)];
            case 1:
                request = _b.sent();
                _a = request.payload, drsId = _a.drsId, userId = _a.userId, cbk = _a.cbk;
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                return [4 /*yield*/, call(putMethod, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/reviewer?assigned_to_user_id=").concat(userId)),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ListingTableActions.ASSIGN_REVIEWER_SUCCESS })];
            case 4:
                _b.sent();
                cbk();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_1 = _b.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.ASSIGN_REVIEWER_FAILURE, error: error_1 })];
            case 7:
                _b.sent();
                cbk();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getApplicationsFlow() {
    var request, _a, pageNo, pageSize, sortColumn, sortOrder, drsId, siteId, stateIds, zipState, systemName, customerName, startApplicationDate, endApplicationDate, startLastModifiedDate, endLastModifiedDate, type, installerName, userType, assignedTo, status_1, projectType, country, certificateApps, stateIdList, paramString, response, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ListingTableActions.GET_APPLICATIONS_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 8, , 10]);
                _a = request.payload, pageNo = _a.pageNo, pageSize = _a.pageSize, sortColumn = _a.sortColumn, sortOrder = _a.sortOrder, drsId = _a.drsId, siteId = _a.siteId, stateIds = _a.stateIds, zipState = _a.zipState, systemName = _a.systemName, customerName = _a.customerName, startApplicationDate = _a.startApplicationDate, endApplicationDate = _a.endApplicationDate, startLastModifiedDate = _a.startLastModifiedDate, endLastModifiedDate = _a.endLastModifiedDate, type = _a.type, installerName = _a.installerName, userType = _a.userType, assignedTo = _a.assignedTo, status_1 = _a.status, projectType = _a.projectType, country = _a.country, certificateApps = _a.certificateApps;
                stateIdList = stateIds.includes(-1) ? [] : stateIds;
                paramString = constructQueryString({
                    pageNumber: pageNo,
                    pageSize: pageSize,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                    drsId: encodeURIComponent(drsId),
                    zipState: encodeURIComponent(zipState),
                    siteId: encodeURIComponent(siteId),
                    systemName: encodeURIComponent(systemName),
                    customerName: encodeURIComponent(customerName),
                    startApplicationDate: startApplicationDate,
                    endApplicationDate: endApplicationDate,
                    startLastModifiedDate: startLastModifiedDate,
                    endLastModifiedDate: endLastModifiedDate,
                    applicationState: stateIdList,
                    type: type,
                    installerName: encodeURIComponent(installerName),
                    userType: userType,
                    assignedTo: assignedTo,
                    status: status_1,
                    projectType: projectType,
                    country: country,
                    certificateApps: certificateApps,
                });
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl('applications/' + paramString),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: ListingTableActions.GET_APPLICATIONS_SUCCESS,
                        applicationsList: response.result.applications,
                        applicationsCount: response.result.total_count,
                    })];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLICATIONS_FAILURE, error: response.result.message })];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_2 = _b.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getSiteSearchResultsFlow:', error_2);
                return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLICATIONS_FAILURE, error: error_2 })];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSiteSearchResultsFlow() {
    var request, searchValue, response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ListingTableActions.SITE_SEARCH_REQUEST)];
            case 1:
                request = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                searchValue = request.payload.searchValue;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("proxyApi/request"),
                        body: {
                            url: [settings.getApiUrl('api_internal/sites/search')],
                            method: ['GET'],
                            search_values: [searchValue],
                            search_type: ['all_stages'],
                        },
                        headers: { 'Content-Type': 'application/json', Authorization: getToken() },
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ListingTableActions.SITE_SEARCH_SUCCESS, siteSearchList: response.result })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ListingTableActions.SITE_SEARCH_FAILURE, error: response.result.message })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_3 = _a.sent();
                // eslint-disable-next-line no-console
                console.log('Saga: getSiteSearchResultsFlow:', error_3);
                return [4 /*yield*/, put({ type: ListingTableActions.SITE_SEARCH_FAILURE, error: error_3 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getReviewerListFlow() {
    var response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(ListingTableActions.GET_REVIEWER_LIST_REQUEST)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("admin/reviewers"),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ListingTableActions.GET_REVIEWER_LIST_SUCCESS, reviewerList: response.result })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_4 = _a.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.GET_REVIEWER_LIST_FAILURE, error: error_4 })];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getApplicationStatsFlow() {
    var request, countryCodes, response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(ListingTableActions.GET_APPLICATIONS_STATS_REQUEST)];
            case 1:
                request = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                countryCodes = request.payload.countryCodes;
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("admin/statusCount?countryCodes=".concat(countryCodes)),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLICATIONS_STATS_SUCCESS, appStatsList: response.result })];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_5 = _a.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLICATIONS_STATS_FAILURE, error: error_5 })];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// TODO: remove this; this is no more required
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function canApplyDRSForCertificationCourseFlow() {
    var request, siteId, endUrl, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ListingTableActions.CAN_APPLY_DRS_FOR_CC_REQUEST)];
            case 1:
                request = _a.sent();
                siteId = null;
                if (request.payload) {
                    siteId = request.payload.site_id;
                }
                endUrl = void 0;
                if (siteId === null) {
                    endUrl = "applications/certificationCourse";
                }
                else {
                    endUrl = "applications/certificationCourse?site_id=".concat(siteId);
                }
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl(endUrl),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: ListingTableActions.CAN_APPLY_DRS_FOR_CC_SUCCESS,
                        canApplyDRSForCC: getLodash(response.result, 'university_application', []),
                        drsIdForLeftPanel: getLodash(response.result, 'drs_id', ''),
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ListingTableActions.CAN_APPLY_DRS_FOR_CC_FAILURE, error: response.result.message })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_6 = _a.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.CAN_APPLY_DRS_FOR_CC_FAILURE, error: error_6 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAppliedSitesFlow() {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ListingTableActions.GET_APPLIED_SITES_REQUEST)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("applications/sites"),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLIED_SITES_SUCCESS, siteIds: response.result.siteIds })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLIED_SITES_FAILURE, error: response.result.message })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_7 = _a.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.GET_APPLIED_SITES_FAILURE, error: error_7 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDrsEligibleFlow() {
    var response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 11];
                return [4 /*yield*/, take(ListingTableActions.GET_DRS_ELIGIBLE_REQUEST)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 8, , 10]);
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("installer/drsEligibility"),
                        withBearer: true,
                    })];
            case 3:
                response = _a.sent();
                if (!response.success) return [3 /*break*/, 5];
                return [4 /*yield*/, put({
                        type: ListingTableActions.GET_DRS_ELIGIBLE_SUCCESS,
                        isDrsEligible: response.result.enable,
                        allowedCourseIds: response.result.courseIds,
                        ensembleCertified: response.result.ensembleCertified,
                    })];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, put({ type: ListingTableActions.GET_DRS_ELIGIBLE_FAILURE, error: response.result.message })];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_8 = _a.sent();
                return [4 /*yield*/, put({ type: ListingTableActions.GET_DRS_ELIGIBLE_FAILURE, error: error_8 })];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [3 /*break*/, 0];
            case 11: return [2 /*return*/];
        }
    });
}
