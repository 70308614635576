/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-shadow */
import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Link,
  CircularProgress,
  Box,
  Avatar,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableBody,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { cloneDeep, findIndex, get, isEmpty, isEqual } from 'lodash';
import Icons from './images';
import useStyles from './style';
import InProgressIcon from './images/inprogress.svg';
import { constructQueryString, getSolargrafToken } from '../../utils/helper';
import feature from '../../utils/feature';
import { GoToSolargrafPopup } from '../Solargraf';
import { CALIFORNIA } from '../../containers/Layouts/constants';
import AddSystemDialog from '../LeadManagement/addSystemDialog';
import { getSiteIdOfLead } from '../../containers/LeadManagement/helper';
import {
  setLeftPanelOpenList,
  setPreviousPanelLength,
  getSearchResult,
  searchForExistingLeadsWithSite,
  linkSiteWithLead,
} from '../../containers/LeadManagement/actions';
import { getApplicationBySite, getProjectDetails, setFromLeftPanel } from '../../containers/Layouts/actions';
import settings from '../../../../utils/settings';
import ServicesDialog from './ServicesDialog';
import { createApplication } from '../../../../containers/Installer/action';
import { getURL, parseParams } from '../../../../utils/helper';
import I18n from '../../../../config/locales';
import { isDrsEligible } from '../../../../containers/DRSListing/actions';

let timeout = null;
const SG_MEASUREMENT_PENDING = 'measurementsPending';

const LeftPanelAccordion = (props) => {
  const classes = useStyles();
  const [viewLinkSiteDialog, setLinkSiteDialog] = useState(false);
  const [siteSearchInLeadInProgress, setSiteSearchInLeadInProgress] = useState(false);
  const [siteSearchInLeadMsg, setSiteSearchInLeadMsg] = useState(false);
  const [selectedSite, setSelectedSite] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [activeQuotesTab, setActiveQuotesTab] = useState('');
  const [leftPanelList, setLeftPanelList] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [multiplePermitApplicationsOpen, setMultiplePermitApplicationsOpen] = useState(false);
  const [multiplePermitApplications, setMultiplePermitApplications] = useState(false);
  const [goToSolarGrafPopup, setGoToSolarGrafPopup] = useState(false);
  const [drsServicePopup, setDrsServicePopup] = useState(false);
  const [permitServicePopup, setPermitServicePopup] = useState(false);
  const [sgipServicePopup, setSgipServicePopup] = useState(false);
  const [applyForCC, setApplyForCC] = useState(true);
  const {
    isMobile,
    isLeftPaneOpen,
    leadDetails,
    systemDetails,
    enlightenManagerToken,
    searchForExistingLeadsWithSite,
    getSearchResult,
    linkSiteWithLead,
    searchResults,
    siteSearchInProgress,
    closeLeftPanel,
    location,
    companyId,
    leftPanelOpenList,
    setLeftPanelOpenList,
    getApplicationBySite,
    setFromLeftPanel,
    setPreviousPanelLength,
    previousPanelLength,
    projectDetails,
    systemDetailsLoading,
    siteDetails,
    featureList,
    belongsToUs,
    fromITK,
  } = props;
  const tabView = ['leads', 'permitting-assistance', 'sgip-incentive', 'design-review'].find((key) =>
    location.pathname.includes(key)
  );
  // dashboard URLS
  const drsDashboardURL = settings.drsUiUrl(getURL('manager/dashboard/design-review', fromITK));
  const sgipDashboardURL = settings.enlightenUrl(getURL(`manager/dashboard/sgip-incentive/applications`, fromITK));
  const permitDashboardURL = settings.enlightenUrl(getURL(`manager/dashboard/permitting-assistance`, fromITK));

  const [activeTab, setActiveTab] = useState(tabView);
  const currentState = get(leadDetails, 'current_state.state_id', 0) || 0;
  const sgToken = getSolargrafToken() || '';
  useEffect(() => {
    setLeftPanelList(getLeftPanelList());
    getSearchResult({ searchValue: '_ALL_SITES_', searchType: 'all_stages' });
  }, []);

  // to be integrated in enlm
  useEffect(() => {
    const projectId = get(props, 'leadDetails.sg_project_id', '');
    if (projectId) {
      const getProjectDetails = (sgToken) => {
        props.getProjectDetails({
          projectId,
          solargrafToken: sgToken,
          successCbk: () => () => {},
        });
      };
      getProjectDetails(getSolargrafToken());
    }
  }, [get(props, 'leadDetails.sg_project_id', '')]);

  useEffect(() => {
    setLeftPanelList(getLeftPanelList());
    if (activeIndex !== -1) {
      const openList = cloneDeep(leftPanelOpenList);
      openList[activeIndex].isOpen = true;
      setLeftPanelOpenList(openList);
    }
  }, [
    !isEmpty(leadDetails),
    systemDetails,
    activeQuotesTab,
    activeTab,
    activeIndex,
    isEditMode,
    projectDetails,
    siteDetails,
    featureList,
  ]);

  const getSystemId = () => {
    const { search } = location;
    const {
      journey_type,
      leadView,
      quoteView,
      origin,
      edit,
      siteId = '',
      req_source,
      fromSg,
      ...remainingQuery
    } = parseParams(search);
    const siteIdFromParam = get(props, 'match.params.source_id') || get(props, 'match.params.site_id') || siteId;
    const systemId = siteIdFromParam || getSiteIdOfLead(leadDetails) || get(systemDetails, 'id', '') || props.siteId;
    return systemId;
  };

  useEffect(() => {
    props.isDrsEligible({ site_id: getSystemId() });
  }, []);

  useEffect(() => {
    props.isDrsEligible();
  }, [!isEmpty(leadDetails), systemDetails]);

  useEffect(() => {
    const { search } = location;
    const { leadView, quoteView, edit } = parseParams(search);
    const { activeTab, activeQuotesTab } = getActiveTab(leadView, quoteView);
    const activeIndex = getActiveIndex(activeTab, leftPanelList);
    setActiveTab(activeTab);
    setActiveQuotesTab(activeQuotesTab);
    setActiveIndex(activeIndex);
    setIsEditMode(Boolean(edit));
    if (edit && (currentState > 6 || !quoteView)) {
      setIsEditMode(false);
    }
  }, [location, currentState]);

  useEffect(() => {
    const activeIndex = getActiveIndex(activeTab, leftPanelList);
    setActiveIndex(activeIndex);
  }, [activeTab, activeQuotesTab, leftPanelList]);

  useEffect(() => {
    if (leftPanelList.length && leftPanelList.length !== previousPanelLength) {
      const openList = leftPanelOpenList.map((list) => {
        list.isOpen = false;
        return list;
      });
      setPreviousPanelLength(leftPanelList.length);
      setLeftPanelOpenList(openList);
    }
  }, [leftPanelList.length]);

  const getActiveTab = (leadView = '', quotesTabKey = '') => {
    let activeTab = leadView || tabView || 'leads';
    let activeQuotesTab = '';
    switch (activeTab) {
      case 'quote':
        activeQuotesTab = quotesTabKey || 'system_configuration';
        break;
      case 'solargraf':
        activeQuotesTab = quotesTabKey || 'sg_design_setup';
        break;
      case 'permitting-assistance':
        activeTab = 'permit_assistance';
        break;
      case 'sgip-incentive':
        activeTab = 'sgip_application';
        break;
      case 'design-review':
        activeTab = 'design_review_service';
        break;
      default:
        break;
    }
    return { activeTab, activeQuotesTab };
  };

  const getActiveIndex = (activeTab, leftPanelList) => {
    const optionListArray = leftPanelList && leftPanelList.map((list) => list.optionslist);
    const activeIndex = findIndex(optionListArray, (optionList) => {
      const activeTabKey = optionList.find((option) => get(option, 'key', false) === activeTab);
      return activeTabKey;
    });
    return activeIndex;
  };

  const quotesLeftComponent = (leadDetails, leadURL, leadSearchParam) => {
    const isSGProjectLead = !isEmpty(get(leadDetails, 'sg_project_id', ''));
    const isSubQuoteClickable = (currentState > 2 && !isEditMode) || isSGProjectLead;
    const hideQuoteSubOptions = currentState <= 2 && activeTab !== 'solargraf' && activeTab !== 'quote';

    if (hideQuoteSubOptions && !isSGProjectLead) {
      return null;
    }

    const handleQuoteSwitch = (quoteKey, isSolargraf = false) => {
      if (isSolargraf && isEmpty(sgToken)) {
        setGoToSolarGrafPopup(true);
      } else {
        if (isSubQuoteClickable) {
          const leadView = isSolargraf ? 'solargraf' : 'quote';
          setFromLeftPanel(true);
          // for drs changing this
          // forwardTo(`/manager/dashboard/${leadURL}${leadSearchParam}&leadView=${leadView}&quoteView=${quoteKey}`);
          window.open(
            `${settings.enlightenUrl(
              `manager/dashboard/${leadURL}${leadSearchParam}&leadView=${leadView}&quoteView=${quoteKey}`
            )}`,
            '_self'
          );
        }
      }
    };

    if (activeTab === 'solargraf' || isSGProjectLead) {
      return (
        <Box className={classes.leftPanelQuotesList}>
          <Box
            className={clsx(classes.quotesWrapper, isSubQuoteClickable && classes.clickableQuotes)}
            onClick={() => handleQuoteSwitch('sg_design_setup', true)}
          >
            <Typography className={clsx(activeQuotesTab === 'sg_design_setup' && classes.quotesSelected)}>
              {I18n.t('serviceDialog.quoteSetUp')}
            </Typography>
          </Box>
          <Box
            className={clsx(
              classes.quotesWrapper,
              isSubQuoteClickable && classes.clickableQuotes,
              !isSGProjectLead && classes.accordionDetailsListDisabled
            )}
            onClick={() => handleQuoteSwitch('sg_proposal', true)}
          >
            <Typography className={clsx(activeQuotesTab === 'sg_proposal' && classes.quotesSelected)}>
              Proposal
            </Typography>
          </Box>
        </Box>
      );
    }
    return (
      <Box className={classes.leftPanelQuotesList}>
        <Box
          className={clsx(classes.quotesWrapper, isSubQuoteClickable && classes.clickableQuotes)}
          onClick={() => handleQuoteSwitch('system_configuration', false)}
        >
          {currentState <= 6 &&
          (isEditMode || currentState <= 2) &&
          (activeQuotesTab === 'system_configuration' || (currentState <= 2 && activeQuotesTab !== 'bom_pricing')) ? (
            <Avatar src={InProgressIcon} className={classes.quoteIcon} />
          ) : (
            <CheckCircleOutlineIcon className={classes.quoteCheckIcon} />
          )}
          <Typography className={clsx(activeQuotesTab === 'system_configuration' && classes.quotesSelected)}>
            {I18n.t('serviceDialog.systemConfiguration')}
          </Typography>
        </Box>
        <Box
          className={clsx(classes.quotesWrapper, isSubQuoteClickable && classes.clickableQuotes)}
          onClick={() => handleQuoteSwitch('bom_pricing', false)}
        >
          {currentState <= 6 &&
          (isEditMode || currentState <= 2) &&
          (activeQuotesTab === 'bom_pricing' || currentState <= 2) ? (
            <Avatar src={InProgressIcon} className={classes.quoteIcon} />
          ) : (
            <CheckCircleOutlineIcon className={classes.quoteCheckIcon} />
          )}
          <Typography className={clsx(activeQuotesTab === 'bom_pricing' && classes.quotesSelected)}>
            {I18n.t('serviceDialog.bomPricing')}
          </Typography>
        </Box>
      </Box>
    );
  };

  const getQuoteState = (isSGProjectLead, isMeasurementsPending) => {
    if (isMeasurementsPending) {
      return 'Proposal Ordered';
    } else if (
      (currentState > 2 &&
        (activeTab !== 'quote' || !isEditMode) &&
        ((activeTab === 'solargraf' && currentState > 6) || activeTab !== 'solargraf')) ||
      currentState > 6
    ) {
      return 'Completed';
    } else if (activeTab === 'quote' || activeTab === 'solargraf' || isSGProjectLead) {
      return 'In Progress';
    } else {
      return 'Not Started';
    }
  };

  const fromIframe = () => {
    const params = get(location, 'search') || '';
    const { origin = 'self' } = parseParams(params);
    return origin;
  };

  const isFromCA = () => {
    const state = get(siteDetails[0], 'state', '');
    return state === CALIFORNIA;
  };

  const createDRSApplication = () => {
    if (getSystemId() !== '') {
      props.createApplication({
        siteId: getSystemId(),
        successCbk: (drsId) => {
          window.open(settings.drsUiUrl(getURL(`manager/dashboard/design-review/${drsId}`, fromITK)), '_self');
        },
        applyForCC: applyForCC,
        installerCountry: props.countryCode,
      });
    }
  };

  const createSGIPApplication = () => {
    if (getSystemId() !== '') {
      window.open(
        settings.enlightenUrl(getURL(`manager/dashboard/sgip-incentive/new?siteId=${getSystemId()}`, fromITK)),
        '_self'
      );
    }
  };

  const createPermitApplication = () => {
    const { search } = location;
    const {
      journey_type,
      leadView,
      quoteView,
      origin,
      edit,
      siteId = '',
      req_source,
      fromSg,
      ...remainingQuery
    } = parseParams(search);
    const permitSearchParam = constructQueryString({ ...remainingQuery, origin: fromIframe() });
    if (getSystemId() !== '') {
      window.open(
        settings.enlightenUrl(`manager/dashboard/${getSystemId()}/permitting-assistance/new${permitSearchParam}`),
        '_self'
      );
    }
  };

  const getLeftPanelList = () => {
    const leadId = get(leadDetails, 'lead_id', '') || '';
    const { search } = location;
    const {
      journey_type,
      leadView,
      quoteView,
      origin,
      edit,
      siteId = '',
      req_source,
      fromSg,
      ...remainingQuery
    } = parseParams(search);
    const siteIdFromParam = get(props, 'match.params.source_id') || get(props, 'match.params.site_id') || siteId;
    const systemId = siteIdFromParam || getSiteIdOfLead(leadDetails) || get(systemDetails, 'id', '') || props.siteId;
    const currentStage = parseInt(get(systemDetails, 'stage', 0) || 0, 10);
    // currentStage = 0 -> site created
    // currentStage = 1 -> envoy started to report
    // currentStage = 2 -> micro-inverter started production and started to report
    const enlightenOrigin = settings.enlightenOrigin();

    // permit
    const permitList = get(systemDetails, 'permit', []) || [];
    const permitId = isEmpty(permitList) ? 'new' : permitList[0];
    const permitSearchParam = constructQueryString({ ...remainingQuery, origin: fromIframe() });
    const permitURL = `${systemId}/permitting-assistance/${permitId}${permitSearchParam}`;
    let permitProps = {};
    if (permitList.length > 1) {
      permitProps = {
        onClick: () => redirectToCreatePermit(permitURL),
      };
    }

    // sgip
    const sgipList = get(systemDetails, 'sgip', []) || [];
    const sgipURL = isEmpty(sgipList)
      ? `sgip-incentive/new?siteId=${systemId}`
      : `sgip-incentive/applications?siteId=${systemId}`;
    // drs
    const drsURL = settings.drsUiUrl(`manager/dashboard/design-review/${props.drsIdForLeftPanel}`);
    // lpp
    const lppURL = `labor-protection?siteId=${systemId}`;

    // lead
    const leadsList = get(systemDetails, 'leads', []) || [];
    const journeyType = get(leadDetails, 'journey_type', '') || '';
    const isSGProjectLead = !isEmpty(get(leadDetails, 'sg_project_id', ''));
    const isStoreOrderPlaced = !isEmpty(get(leadDetails, 'order_id', ''));
    let leadSearchParam = constructQueryString({ ...remainingQuery, journey_type: journeyType });
    const quoteParams = isSGProjectLead
      ? isEmpty(sgToken)
        ? `&leadView=leads`
        : `&leadView=solargraf&quoteView=sg_design_setup`
      : activeTab === 'solargraf'
      ? `&leadView=solargraf`
      : `&leadView=quote&quoteView=system_configuration`;
    const leadBaseURL = `${companyId}/leads/${leadId}`;
    const leadDetailsURL = `${leadBaseURL}${leadSearchParam}&leadView=leads`;
    const bomInLeadsUrl = `${leadBaseURL}${leadSearchParam}&leadView=bom`;
    const quoteURL = `${leadBaseURL}${leadSearchParam}${quoteParams}`;
    const storeOrderUrl = `${leadBaseURL}${leadSearchParam}&leadView=store_order`;
    const isMeasurementsPending = isEqual(get(projectDetails, 'state', ''), SG_MEASUREMENT_PENDING);
    const hideQuoteSubOptions =
      (currentState <= 2 && activeTab !== 'solargraf' && activeTab !== 'quote' && !isSGProjectLead) ||
      isMeasurementsPending;
    const disableBomCalculator = !belongsToUs || !feature.isFeatureEnabled('BOM_CALCULATOR');
    let leadOptionList = [
      {
        key: 'leads',
        value: 'Lead Details',
        link: leadDetailsURL,
      },
      {
        key: 'quote',
        value: `Quote: ${getQuoteState(isSGProjectLead, isMeasurementsPending)}`,
        subValue: quotesLeftComponent(leadDetails, leadBaseURL, leadSearchParam),
        link: quoteURL,
        disabled: hideQuoteSubOptions,
      },
    ];
    if (belongsToUs) {
      leadOptionList = [
        ...leadOptionList,
        {
          key: 'bom',
          value: `BOM Calculator`,
          link: bomInLeadsUrl,
          disabled: disableBomCalculator,
        },
      ];
    }
    if (isStoreOrderPlaced) {
      leadOptionList = [
        ...leadOptionList,
        {
          key: 'store_order',
          value: 'Store Order',
          link: storeOrderUrl,
        },
      ];
    }

    let leftPanelList = [
      // If lead is not associated with system the we should not show lead tab instead we need to show Status tab
      !isEmpty(leadDetails) && {
        key: 'lead',
        title: `LEAD: ${leadId}`,
        icon: 'lead',
        optionslist: leadOptionList,
      },
      {
        key: 'system_details',
        title: 'System Details',
        disabled: !(currentStage >= 0 && systemId),
        icon: 'systemDetails',
        optionslist: [
          {
            key: 'site_information',
            value: 'Site Information',
            link: `${enlightenOrigin}/activations/edit/${systemId}`,
            isExternalLink: true,
          },
          {
            key: 'device_details',
            value: 'Device Details',
            link: `${enlightenOrigin}/systems/${systemId}/devices`,
            isExternalLink: true,
            disabled: !(currentStage >= 2),
          },
          {
            key: 'array_builder',
            value: 'Array Builder',
            link: `${enlightenOrigin}/builder/en/${systemId}/panel`,
            isExternalLink: true,
          },
          {
            key: 'tariff_information',
            value: 'Tariff Information',
            link: `${enlightenOrigin}/tariff/?siteId=${systemId}&isAutoFillFeatureEnabled=0&isFeedbackEnabled=0&isChargeFromGridEnabled=1`,
            isExternalLink: true,
          },
          {
            key: 'battery_storage',
            value: 'Battery Storage',
            link: `${enlightenOrigin}/mobile/${systemId}/external/battery-storage`,
            isExternalLink: true,
          },
          {
            key: 'other_system_settings',
            value: 'Other System Settings',
            link: `${enlightenOrigin}/systems/${systemId}/details`,
            isExternalLink: true,
            disabled: !(currentStage >= 2),
          },
        ],
      },
      {
        key: 'services',
        title: 'Services',
        disabled: !(currentStage >= 0 && systemId),
        icon: 'services',
        optionslist: [
          {
            key: 'permit_assistance',
            value: 'Permit Assistance',
            link: permitURL,
            disabled: !systemId || !feature.isFeatureEnabled('PERMIT_ENABLED'),
            ...permitProps,
          },
          {
            key: 'sgip_application',
            value: 'SGIP Application',
            link: sgipURL,
            disabled: !isFromCA() || !systemId || !feature.isFeatureEnabled('INCENTIVE_PROGRAM'),
          },
          {
            key: 'design_review_service',
            value: 'Design Review Service',
            link: drsURL,
            isExternalLink: true,
            disabled: !systemId,
          },
          // External link not available
          // {
          //   key: 'solar_storage',
          //   value: 'Upgrade Solar / Add Storage'
          // },
          {
            key: 'lpp',
            value: 'Labor Protection Plan',
            link: lppURL,
            disabled: !(currentStage >= 2),
          },
        ],
      },
      {
        key: 'system_reporting',
        title: 'System Reporting',
        disabled: !(currentStage >= 2 && systemId),
        icon: 'systemReporting',
        optionslist: [
          // External link not available
          // {
          //   key: 'performance',
          //   value: 'Performance'
          // },
          {
            key: 'array_view',
            value: 'Array View',
            link: `${enlightenOrigin}/systems/${systemId}/arrays`,
            isExternalLink: true,
          },
          {
            key: 'graph_view',
            value: 'Graph View',
            link: `${enlightenOrigin}/systems/${systemId}/graphs`,
            isExternalLink: true,
          },
          {
            key: 'reports',
            value: 'Reports',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/reports`,
          },
          {
            key: 'events',
            value: 'Events',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/events`,
          },
          // External link not available
          // {
          //   key: 'live_status',
          //   value: 'Live Status'
          // },
          // {
          //   key: 'backup_history',
          //   value: 'Backup History'
          // }
        ],
      },
      {
        key: 'support_service',
        title: 'Support and Service',
        disabled: !(currentStage >= 2 && systemId),
        icon: 'supportService',
        optionslist: [
          {
            key: 'request_return',
            value: 'Request Return',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/request_return`,
          },
          {
            key: 'micro_replacement',
            value: 'Micro Replacement',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/install_replacement`,
          },
          {
            key: 'envoy_replacement',
            value: 'Envoy Replacement',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/envoy_replacement`,
          },
          {
            key: 'labor_reimbursement',
            value: 'Labor Reimbursement',
            isExternalLink: true,
            link: `${enlightenOrigin}/systems/${systemId}/details`,
          },
        ],
      },
    ];
    leftPanelList = leftPanelList.filter((list) => list);
    return leftPanelList;
  };

  const handleClose = () => {
    setLinkSiteDialog(false);
    setSearchValue('');
    setSelectedSite({});
  };

  const siteSelection = (e, selectedSite) => {
    clearTimeout(timeout);
    if (selectedSite) {
      setSelectedSite({ ...selectedSite });
      setSearchValue(selectedSite.label);
      setSiteSearchInLeadInProgress(true);
      searchForExistingLeadsWithSite({
        site_id: selectedSite.id,
        successCb: (result) => {
          const siteMessage =
            result.lead_exists === true
              ? 'This System is already linked to another lead. Please verify your selection before proceeding'
              : '';
          setSiteSearchInLeadInProgress(false);
          setSiteSearchInLeadMsg(siteMessage);
        },
        failureCb: (result) => {
          setSiteSearchInLeadInProgress(false);
        },
      });
    }
  };

  const handleSearch = (event) => {
    if (event) {
      const newValue = event.target.value;
      clearTimeout(timeout);
      setSearchValue(newValue);
      setSiteSearchInLeadMsg('');
      if (newValue && newValue.length > 0) {
        timeout = setTimeout(() => {
          getSearchResult({ searchValue: newValue, searchType: 'all_stages' });
        }, 1000);
      } else {
        getSearchResult({ searchValue: '_ALL_SITES_', searchType: 'all_stages' });
        setSelectedSite({});
        setSearchValue('');
        setSiteSearchInLeadMsg('');
      }
    }
  };

  const linkSystemToLead = (leadDetails) => {
    linkSiteWithLead({
      lead_id: leadDetails.lead_id,
      site_id: selectedSite.id,
      installer_lead_journey_id: leadDetails.journey_id,
      successCb: () => {
        window.location.reload();
      },
      failureCb: (result) => {
        console.log(result);
      },
    });
  };

  const handleTabClick = (option, data) => {
    const isSGProjectLead = !isEmpty(get(leadDetails, 'sg_project_id', ''));
    const { isExternalLink = false, link = '' } = option;
    const activeTabKey = get(data, 'key', '') || '';
    if (option.key === 'quote' && isSGProjectLead && isEmpty(sgToken)) {
      setGoToSolarGrafPopup(true);
    }
    setFromLeftPanel(activeTabKey === 'lead');
    if (isMobile && isLeftPaneOpen) {
      closeLeftPanel();
    }
    setIsEditMode(false);
    setMultiplePermitApplicationsOpen(false);
    if (isExternalLink) {
      if (option.key === 'design_review_service') {
        if (!isEmpty(props.drsIdForLeftPanel)) {
          window.open(link, '_self');
        } else {
          setDrsServicePopup(true);
        }
      } else {
        window.open(link, '_blank');
      }
    } else {
      // for drs changing this
      // forwardTo(`/manager/dashboard/${link}`);
      if (option.key === 'sgip_application') {
        const sgipList = get(systemDetails, 'sgip', []) || [];
        if (isEmpty(sgipList)) {
          setSgipServicePopup(true);
        } else {
          window.open(`${settings.enlightenUrl(`manager/dashboard/${link}`)}`, '_self');
        }
      } else if (option.key === 'permit_assistance') {
        const permitList = get(systemDetails, 'permit', []) || [];
        if (isEmpty(permitList)) {
          setPermitServicePopup(true);
        } else {
          window.open(`${settings.enlightenUrl(`manager/dashboard/${link}`)}`, '_self');
        }
      } else {
        window.open(`${settings.enlightenUrl(`manager/dashboard/${link}`)}`, '_self');
      }
    }
  };

  const handleAccordionChange = (index, expanded) => {
    const openList = cloneDeep(leftPanelOpenList);
    openList[index].isOpen = expanded;
    setLeftPanelOpenList(openList);
  };

  const redirectToCreatePermit = (link) => {
    const navigateToPermit = (result) => {
      if (result.status === 'success' && (get(result, 'permit_applications', []) || []).length > 1) {
        setMultiplePermitApplicationsOpen(true);
      } else {
        setMultiplePermitApplicationsOpen(false);
        handleTabClick({ isExternalLink: false, link });
      }
      setMultiplePermitApplications(get(result, 'permit_applications', []) || []);
    };
    getApplicationBySite({
      siteId: getSiteIdOfLead(leadDetails) || get(systemDetails, 'id', ''),
      cbk: (result) => navigateToPermit(result),
    });
  };

  const redirectToPermitView = (permittingObj) => {
    const systemId = get(permittingObj, 'site_id', '');
    const permitId = get(permittingObj, 'permit_id', '');
    const siteKey = systemId || 'self';
    const { search } = location;
    const { journey_type, leadView, quoteView, edit, siteId, req_source, fromSg, ...remainingQuery } =
      parseParams(search);
    const permitSearchParam = constructQueryString({ ...remainingQuery, origin: fromIframe() });
    const link = `${siteKey}/permitting-assistance/${permitId}${permitSearchParam}`;
    handleTabClick({ isExternalLink: false, link });
  };

  const totalCount = get(searchResults, 'total_count', 0) || 0;
  const result = get(searchResults, 'result', []) || [];

  return (
    <div className={classes.root}>
      <Accordion className={classes.accordion} disabled>
        <AccordionSummary />
      </Accordion>
      {leftPanelList &&
        leftPanelList.map((data, index) => {
          const { key, title, optionslist, disabled = false, icon = 'lead' } = data;
          const details = optionslist.map((option) => {
            if (!option) {
              return null;
            }
            const { key, value = '', subValue = '', disabled = false, onClick = '' } = option;
            const isActive = activeTab === key || (activeTab === 'solargraf' && key === 'quote');
            const isSystemDetailsLoading =
              !isActive && systemDetailsLoading && ['permit_assistance', 'sgip_application'].includes(key);
            const handleAccordionClick = isSystemDetailsLoading
              ? () => {}
              : onClick
              ? onClick
              : () => handleTabClick(option, data);
            return (
              <AccordionDetails
                key={key}
                className={clsx(
                  classes.accordionDetailsList,
                  disabled && classes.accordionDetailsListDisabled,
                  isSystemDetailsLoading && classes.disablePointer
                )}
              >
                <Typography
                  className={clsx(classes.defaultAccordionDetail, isActive && classes.selectedAccordionDetail)}
                  component="div"
                  onClick={handleAccordionClick}
                >
                  <Typography className={clsx(isSystemDetailsLoading && classes.accordionDetailsListDisabled)}>
                    {value}
                  </Typography>
                  {isSystemDetailsLoading && (
                    <CircularProgress className={classes.circularProgress} color="primary" size={20} />
                  )}
                </Typography>
                {subValue}
              </AccordionDetails>
            );
          });
          const LeftPaneIcon = Icons[icon];
          return (
            <Accordion
              className={classes.accordion}
              disabled={disabled}
              expanded={leftPanelOpenList[index].isOpen}
              key={key}
              onChange={(event, expanded) => handleAccordionChange(index, expanded)}
            >
              <AccordionSummary
                classes={{ expanded: classes.highlightHeading }}
                className={classes.accordionSummary}
                expandIcon={title ? <ExpandMoreIcon /> : <Fragment />}
                id={key}
              >
                <LeftPaneIcon />
                <Typography className={classes.heading}>{title}</Typography>
              </AccordionSummary>
              {details}
              {key === 'lead' && currentState > 2 && !getSiteIdOfLead(leadDetails) && (
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.addSystem}
                  onClick={() => setLinkSiteDialog(true)}
                  disabled={props.viewOnlyAccess}
                >
                  Add/Link System
                </Button>
              )}
            </Accordion>
          );
        })}
      <AddSystemDialog
        handleViewLinkSiteClose={handleClose}
        viewLinkSiteDialog={viewLinkSiteDialog}
        linkSystemToLead={linkSystemToLead}
        searchResults={searchResults}
        siteSearchInLeadInProgress={siteSearchInLeadInProgress}
        searchValue={searchValue}
        siteSearchInLeadMsg={siteSearchInLeadMsg}
        siteSelection={siteSelection}
        selectedSite={selectedSite}
        handleSearch={handleSearch}
        siteSearchInProgress={siteSearchInProgress}
        leadDetails={leadDetails}
        companyId={companyId}
      />
      {/* <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={viewLinkSiteDialog}
        id="confirmation"
        className={classes.addSystemDialog}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography className={classes.dialogHeader}>
            System Addition
            <CloseIcon onClick={handleClose} className={classes.close} size="large" />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography gutterBottom>
            To proceed further, please create a{' '}
            <Link className={classes.link} href={settings.enlightenMenuUrl('activations/new')}>
              new system activation
            </Link>{' '}
            in Enlighten that can be linked with this lead. If the system activation has already been started, please
            select the system ID from the below list.
          </Typography>
          <Autocomplete
            autoComplete
            getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
            filterOptions={x => x}
            options={totalCount ? result : []}
            value={searchValue}
            inputValue={searchValue}
            getOptionSelected={(option, value) => option.label === value}
            onChange={siteSelection}
            onInputChange={handleSearch}
            loading={siteSearchInProgress}
            closeIcon={isEmpty(searchValue) ? <Fragment /> : <CloseIcon fontSize="small" />}
            renderInput={params => <TextField {...params} required label="Please enter the system name" />}
            renderOption={option => (
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="body1" color="textPrimary">
                    {option.id} - {option.label}
                  </Typography>
                </Grid>
              </Grid>
            )}
          />
          <br />
          {siteSearchInLeadInProgress && <CircularProgress size={15} />}
          {siteSearchInLeadMsg.length > 0 && (
            <Typography variant="caption" className={classes.rejectButton}>
              {siteSearchInLeadMsg}
              <br />
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.buttonWrapper}>
          <Button onClick={handleClose} className={classes.rejectButton}>
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
            className={classes.acceptButton}
            disabled={!selectedSite.id || siteSearchInLeadInProgress}
            onClick={() => linkSystemToLead(leadDetails)}
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog> */}
      {multiplePermitApplicationsOpen && (
        <Dialog
          open={multiplePermitApplicationsOpen}
          onClose={() => setMultiplePermitApplicationsOpen(false)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle onClose={() => setMultiplePermitApplicationsOpen(false)}>
            <Typography className={classes.dialogHeader}>
              Select Application
              <IconButton
                className={classes.closeButton}
                aria-label="close"
                onClick={() => setMultiplePermitApplicationsOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerContainer}>
                      <Typography className={classes.headerText}>REQUEST ID</Typography>
                    </TableCell>
                    <TableCell className={classes.headerContainer}>
                      <Typography className={classes.headerText}>PERMIT ASSISTANCE STATUS</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {multiplePermitApplications &&
                    multiplePermitApplications.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          className={clsx(multiplePermitApplications.length - 1 === index && classes.tableRow)}
                        >
                          <TableCell>
                            {get(row, 'permit_id') && (
                              <Link
                                component="button"
                                variant="body2"
                                className={classes.linkColour}
                                onClick={() => {
                                  redirectToPermitView(row);
                                }}
                              >
                                {row.permit_id}
                              </Link>
                            )}
                            {!get(row, 'permit_id') && 'NA'}
                          </TableCell>

                          <TableCell>
                            {(get(row, 'installer_current_status.substate_name') || 'NA') === 'NA'
                              ? get(row, 'installer_current_status.state_name') || 'NA'
                              : get(row, 'installer_current_status.substate_name')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      )}
      {goToSolarGrafPopup && (
        <GoToSolargrafPopup
          handleClose={() => {
            setGoToSolarGrafPopup(false);
          }}
        />
      )}

      <ServicesDialog
        open={drsServicePopup}
        onClose={() => setDrsServicePopup(false)}
        redirectToServiceDashboard={() => {
          window.open(drsDashboardURL, '_self');
        }}
        type={'Design Review'}
        allowedCourseIds={props.allowedCourseIds}
        applyForCC={applyForCC}
        setApplyForCC={() => setApplyForCC(!applyForCC)}
        createNewApplication={() => createDRSApplication()}
      />
      <ServicesDialog
        open={sgipServicePopup}
        onClose={() => setSgipServicePopup(false)}
        redirectToServiceDashboard={() => {
          window.open(sgipDashboardURL, '_self');
        }}
        type={'SGIP'}
        createNewApplication={() => createSGIPApplication()}
      />
      <ServicesDialog
        open={permitServicePopup}
        onClose={() => setPermitServicePopup(false)}
        redirectToServiceDashboard={() => {
          window.open(permitDashboardURL, '_self');
        }}
        type={'Permit Assistance'}
        createNewApplication={() => createPermitApplication()}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  leadDetails: state.leadReducer.leadDetails,
  searchResults: state.leadReducer.searchResults,
  siteSearchInProgress: state.leadReducer.siteSearchInProgress,
  systemDetails: state.leadReducer.systemDetails,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  companyId: state.appReducer.companyId,
  leftPanelOpenList: state.leadReducer.leftPanelOpenList,
  previousPanelLength: state.leadReducer.previousPanelLength,
  siteDetails: state.appReducer.siteDetails,
  viewOnlyAccess: state.appReducer.viewOnlyAccess,
  systemDetailsLoading: state.leadReducer.systemDetailsLoading,
  projectDetails: state.defaultReducer.projectDetails,
  drsIdForLeftPanel: state.listingReducer.drsIdForLeftPanel,
  allowedCourseIds: state.listingReducer.allowedCourseIds,
  featureList: state.appReducer.featureList,
  belongsToUs: state.appReducer.belongsToUs,
  fromITK: state.appReducer.fromITK,
  siteId: state.installerReducer.siteId,
  countryCode: state.appReducer.countryCode,
});

const mapDispatchToProps = (dispatch) => ({
  setLeftPanelOpenList: (payload) => dispatch(setLeftPanelOpenList(payload)),
  getApplicationBySite: (payload) => dispatch(getApplicationBySite(payload)),
  setFromLeftPanel: (payload) => dispatch(setFromLeftPanel(payload)),
  setPreviousPanelLength: (previousPanelLength) => dispatch(setPreviousPanelLength(previousPanelLength)),
  getSearchResult: (payload) => dispatch(getSearchResult(payload)),
  searchForExistingLeadsWithSite: (payload) => dispatch(searchForExistingLeadsWithSite(payload)),
  linkSiteWithLead: (payload) => dispatch(linkSiteWithLead(payload)),
  getProjectDetails: (payload) => dispatch(getProjectDetails(payload)),
  isDrsEligible: (payload) => dispatch(isDrsEligible(payload)),
  createApplication: (payload) => dispatch(createApplication(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftPanelAccordion);
