import moment from 'moment';

const supportedLocales = ['en', 'fr', 'de', 'nl', 'en-AU'];

export let localeMoment = moment;

export default function getMoment(locale) {
  var momentVariable = moment;
  if (supportedLocales.includes(locale)) {
    momentVariable.locale(locale);
  }
  localeMoment = momentVariable;
}
