import { getValue, setValue } from './localStorage';
import { browserHistory as history } from '../routes/index';
import { isEmpty } from 'lodash';
import store from '../store';
export var getToken = function (value) {
    if (value) {
        return getValue(value);
    }
    return getValue('_enlighten_manager_token');
};
export var setToken = function (value) { return setValue('_enlighten_manager_token', value); };
export var isValidToken = function (token) {
    return !(!token || token === '' || token === -1 || token === '-1');
};
export var forwardTo = function (location) { return history.push(location); };
var defaultMessage = 'To download files, please disable popup blocker in your browser';
export var handlePopupBlocker = function (windowObj, message) {
    if (message === void 0) { message = defaultMessage; }
    if (windowObj === null || typeof windowObj === 'undefined')
        alert(message);
    else
        windowObj.focus();
};
export var constructQueryString = function (queryObj) {
    var queryString = '';
    Object.keys(queryObj).forEach(function (queryKey) {
        var queryConnector = queryString === '' ? '?' : '&';
        if (queryObj[queryKey]) {
            queryString = queryString + "".concat(queryConnector).concat(queryKey, "=").concat(queryObj[queryKey]);
        }
    });
    return queryString;
};
export var openInSameWindow = function (url) {
    window.open(url, '_self');
};
export var openInNewTab = function (url) {
    window.open(url, '_blank');
};
export var isCompanyIdValid = function (companyId) {
    var isValid = typeof companyId === 'number' && !isNaN(companyId) ? true : !(isEmpty(companyId) || isNaN(parseInt(companyId)));
    return isValid;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var statesEnumToList = function (statesEnum) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var list = [];
    for (var key in statesEnum) {
        list.push(statesEnum[key]);
    }
    return list;
};
export var getURL = function (url, fromITK) {
    if (fromITK) {
        return url.includes('?') ? "".concat(url, "&platform=itk") : "".concat(url, "?platform=itk");
    }
    return url;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var airbrakeProject = function () {
    var _a = store.getState().appReducer, airbrakeProjectId = _a.airbrakeProjectId, airbrakeProjectKey = _a.airbrakeProjectKey, companyId = _a.companyId;
    return {
        airbrakeProjectId: airbrakeProjectId,
        airbrakeProjectKey: airbrakeProjectKey,
        companyId: companyId,
    };
};
// val: COMMERCIAL
// return: Commercial
export var CapitalizeFirstLetter = function (val) {
    return val.charAt(0) + val.slice(1).toLowerCase();
};
export var parseParams = function (string) {
    var params = new URLSearchParams(string);
    var entries = params.entries();
    var result = {};
    Array.from(entries).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (value) {
            result[key] = value;
        }
    });
    return result;
};
export var getCountryBasedLocale = function (countryCode) {
    if (['DE', 'AT', 'CH'].includes(countryCode)) {
        return 'de';
    }
    else if (['NL', 'BE'].includes(countryCode)) {
        return 'nl';
    }
    else if (['FR'].includes(countryCode)) {
        return 'fr';
    }
    else {
        return 'en';
    }
};
