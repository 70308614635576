import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import DRSChats from '../../../../containers/Chats';
var useStyles = makeStyles(function (theme) { return ({
    permitChatWrapper: {
        height: '100%',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: theme.spacing(0.5),
    },
    permitChatContainer: {
        maxHeight: '600px',
        overflow: 'scroll',
        minHeight: '516px',
        height: '100%',
    },
    permitChatPaper: {
        height: '100%',
    },
}); });
var SendMessage = function () {
    var classes = useStyles();
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", className: classes.permitChatWrapper, children: _jsx(Grid, { item: true, xs: 12, className: classes.permitChatContainer, children: _jsx(Paper, { className: classes.permitChatPaper, children: _jsx(DRSChats, { isFromAdmin: true }) }) }) }) }));
};
export default SendMessage;
