/* eslint-disable camelcase */
export var ProjectDetailsKeys = {
    system_name: '',
    customer_name: '',
    address: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    zipcode: '',
    type: '',
    solar_system_size: '',
    battery_type: '',
    battery_quantity: 0,
    notes: '',
};
export var ProjectDocsKeys = {
    site_map_photos: '',
    sld_documents: '',
    msp_documents: '',
    other_documents: '',
    load_calculation_documents: '',
    survey_photos: '',
    resubmitted_documents: '',
};
export var ProjectDocsUrlKeys = {
    site_map_photos_url: '',
    sld_documents_url: '',
    msp_documents_url: '',
    other_documents_url: '',
    load_calculation_documents_url: '',
    survey_photos_url: '',
};
export var BatteryType = {
    NONE: 'None',
    IQ_BATTERY_3: 'IQ Battery 3',
    IQ_BATTERY_3T: 'IQ Battery 3T',
    IQ_BATTERY_5P: 'IQ Battery 5P',
};
export var ProjectDetailsErrorKeys = {
    system_name_error: '',
    customer_name_error: '',
    zipcode_error: '',
    type_error: '',
    address_error: '',
    solar_system_size_error: '',
    battery_type_error: '',
    battery_quantity_error: '',
    site_map_photos_error: '',
    sld_documents_error: '',
    load_calculation_documents_error: '',
};
