export const STATES_MAP = {
  0: { name: 'constants.applicationIncomplete', id: 0, exclude: true },
  1: {
    name: 'constants.newApplicationReceived',
    nameForInstaller: 'constants.newApplicationSubmitted',
    id: 1,
    color: '#0951BC',
  },
  2: {
    name: 'constants.newApplicationAssigned',
    nameForInstaller: 'constants.newApplicationSubmitted',
    id: 2,
    color: '#FFD401',
  },
  3: { name: 'constants.applicationUnderReview', id: 3, color: '#277DFE' },
  4: { name: 'constants.resubmissionRequested', id: 4, color: '#55D8FE' },
  5: {
    name: 'constants.resubmissionReceived',
    nameForInstaller: 'constants.resubmissionSubmitted',
    id: 5,
    color: '#490601',
  },
  6: { name: 'constants.applicationReviewCompleted', id: 6, color: '#AA0C00' },
  7: { name: 'constants.requestForTier2Approval', id: 7, color: '#1197BF' },
  8: { name: 'constants.tier2ApplicationAssigned', id: 8, color: '#FF8B00' },
  9: { name: 'constants.tier2ApprovalUnderProgress', id: 9, color: '#8300AA' },
  10: {
    name: 'constants.tier2ResubmissionRequested',
    nameForInstaller: 'constants.resubmissionRequested',
    id: 10,
    color: '#8D6022',
  },
  11: {
    name: 'constants.tier2ResubmissionReceived',
    nameForInstaller: 'constants.resubmissionSubmitted',
    id: 11,
    color: '#5C8167',
  },
  12: {
    name: 'constants.tier2ApprovalCompleted',
    nameForInstaller: 'constants.applicationReviewCompleted',
    id: 12,
    color: '#89BF11',
  },
};

// make this function to return only userspecific stages data
export function getPieChartDataArray(arr, userType) {
  const dataArray = [];
  if (userType === 'tier1') {
    arr.map((val) => {
      if (!STATES_MAP[val.state_id].exclude && val.state_id > 0 && val.state_id < 7) {
        dataArray.push({
          name: STATES_MAP[val.state_id].name,
          y: val.state_count,
          color: STATES_MAP[val.state_id].color,
        });
      }
    });
  } else if (userType === 'tier2') {
    arr.map((val) => {
      if (!STATES_MAP[val.state_id].exclude && val.state_id > 6 && val.state_id < 13) {
        dataArray.push({
          name: STATES_MAP[val.state_id].name,
          y: val.state_count,
          color: STATES_MAP[val.state_id].color,
        });
      }
    });
  } else {
    arr.map((val) => {
      if (!STATES_MAP[val.state_id].exclude) {
        dataArray.push({
          name: STATES_MAP[val.state_id].name,
          y: val.state_count,
          color: STATES_MAP[val.state_id].color,
        });
      }
    });
  }

  return dataArray;
}

export function getTotalCount(arr, userType) {
  let total = 0;
  if (userType === 'tier1') {
    arr.map((val) => {
      if (val.state_id > 0 && val.state_id < 7) {
        total += val.state_count;
      }
    });
  } else if (userType === 'tier2') {
    arr.map((val) => {
      if (val.state_id > 6 && val.state_id < 13) {
        total += val.state_count;
      }
    });
  } else {
    arr.map((val) => {
      if (val.state_id > 0) {
        total += val.state_count;
      }
    });
  }

  return total;
}

export function getEachStateCount(arr) {
  const stateAppCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arr.map((val) => {
    stateAppCount[val.state_id] = val.state_count;
  });
  return stateAppCount;
}
