const header = {
	header: {
		logOut: 'Déconnexion',
		enphaseStore: 'Magasin Enphase',
		enphaseSupport: 'Assistance Enphase ',
		comingSoon: 'Prochainement',
		new: 'Nouveau',
		dashboard: 'Tableau de bord',
		systems: 'Systèmes',
		account: 'Compte',
		services: 'Services',
		dataPrivacy: 'Données et confidentialité',
		support: 'Support',
		admin: 'Admin',
		adminNew: 'Admin (Nouveau)'
	}
};

export default header;
