import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Button } from '@material-ui/core';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ContainedButton from '../../ContainedButton';
import moment from 'moment';
import { get } from 'lodash';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    header: {
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(3.25),
        fontWeight: 'bold',
    },
    dialogTitle: {
        '& .MuiTypography-h6': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        paddingBottom: theme.spacing(1),
    },
    commentText: {
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(1.5),
        fontWeight: 'bold',
    },
    commentField: {
        marginBottom: theme.spacing(1.5),
    },
    rejectButton: {
        color: theme.tertiary.gray,
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(0, 2),
        height: theme.spacing(4),
    },
}); });
var StatusUpdateDialog = function (props) {
    var classes = useStyles();
    var open = props.open, handleClose = props.handleClose, handleContinue = props.handleContinue;
    var stateName = get(props.application, 'current_status.state_name', '');
    var getDisplayName = function () {
        if (stateName === 'Resubmission Requested') {
            return 'Resubmission Received';
        }
        return stateName;
    };
    return (_jsx(Fragment, { children: _jsxs(Dialog, { open: open, maxWidth: "md", onClose: handleClose, children: [_jsx(DialogTitle, { className: classes.dialogTitle, children: _jsx(Typography, { className: classes.header, children: I18n.t('statusDetails.updateStatus') }) }), _jsx(DialogContent, { children: _jsxs(Box, { children: [_jsx(Typography, { className: classes.commentText, children: getDisplayName() }), _jsxs(Typography, { children: [I18n.t('statusDetails.dateOfComplete'), ": ", moment(new Date()).format('DD/MM/YYYY')] })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, className: classes.rejectButton, children: I18n.t('buttons.cancel') }), _jsx(ContainedButton, { smaller: true, text: I18n.t('buttons.update'), handleClick: handleContinue })] })] }) }));
};
/**
 * Dialog to manually update the status of the DRS application
 * @param props {@link Props | Props interface}
 * @returns StatusUpdateDialog component
 */
export default StatusUpdateDialog;
