import React from 'react';

const LeadIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(416 -104)">
        <rect fill="none" width="20" height="20" transform="translate(-416 104)" />
        <path
          fill="#335977"
          d="M-407,108h-3.75a1.25,1.25,0,0,0-1.25,1.25V113a1.25,1.25,0,0,0,1.25,1.25H-407a1.25,1.25,0,0,0,1.25-1.25v-3.75A1.25,1.25,0,0,0-407,108Zm0,5h-3.75v-3.75H-407Z"
          transform="translate(-1.5 -1.5)"
        />
        <path
          fill="#335977"
          d="M-393,108h-3.75a1.25,1.25,0,0,0-1.25,1.25V113a1.25,1.25,0,0,0,1.25,1.25H-393a1.25,1.25,0,0,0,1.25-1.25v-3.75A1.25,1.25,0,0,0-393,108Zm0,5h-3.75v-3.75H-393Z"
          transform="translate(-6.75 -1.5)"
        />
        <path
          fill="#335977"
          d="M-407,122h-3.75a1.25,1.25,0,0,0-1.25,1.25V127a1.25,1.25,0,0,0,1.25,1.25H-407a1.25,1.25,0,0,0,1.25-1.25v-3.75A1.25,1.25,0,0,0-407,122Zm0,5h-3.75v-3.75H-407Z"
          transform="translate(-1.5 -6.75)"
        />
        <path
          fill="#335977"
          d="M-393,122h-3.75a1.25,1.25,0,0,0-1.25,1.25V127a1.25,1.25,0,0,0,1.25,1.25H-393a1.25,1.25,0,0,0,1.25-1.25v-3.75A1.25,1.25,0,0,0-393,122Zm0,5h-3.75v-3.75H-393Z"
          transform="translate(-6.75 -6.75)"
        />
      </g>
    </svg>
  );
};

export default LeadIcon;
