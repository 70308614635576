import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from 'react-redux';
import { DefaultFooter } from 'enlm_ui_common';
import { getAccountData } from '../../containers/Layouts/action';
import { forwardTo } from '../../utils/helper';
import { get } from 'lodash';
var Footer = function (props) {
    var accountDetail = props.accountDetail, locale = props.locale, loading = props.loading;
    var companyId = get(accountDetail, 'company_id', '') || '';
    return (_jsx(DefaultFooter, { forwardTo: forwardTo, locale: locale, companyId: companyId, currentlySending: loading, isQuotesExpanded: false, getAccountData: props.getAccountData }));
};
var mapDispatchToProps = function (dispatch) { return ({
    getAccountData: function (payload) { return dispatch(getAccountData(payload)); },
}); };
var mapStateToProps = function (state) { return ({
    locale: state.appReducer.locale,
    accountDetail: state.appReducer.accountDetail,
    loading: state.appReducer.loading,
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
