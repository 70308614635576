var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from 'react-redux';
import { Breadcrumb } from 'enlm_ui_common';
import { forwardTo } from '../../utils/helper';
import { setShowHidePage } from '../../containers/Layouts/action';
var Breadcrumbs = function (props) {
    return _jsx(Breadcrumb, __assign({}, props, { forwardTo: forwardTo, isDRS: true }));
};
var mapStateToProps = function (state) { return ({
    einOptIn: state.appReducer.einOptIn,
    countryCode: state.appReducer.countryCode,
    companyId: state.appReducer.companyId,
    companyInfo: state.appReducer.companyInfo,
    featureList: state.appReducer.featureList,
    accountData: state.appReducer.accountDetail,
    viewOnlyAccess: state.appReducer.viewOnlyAccess,
    locale: state.appReducer.locale,
    showPage: state.appReducer.showPage,
}); };
var mapDispatchToProps = function (dispatch) { return ({
    setShowHidePage: function (showPage) { return dispatch(setShowHidePage(showPage)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
