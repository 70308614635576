import React from 'react';

const SupportServiceIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(624 -572)">
        <rect fill="none" width="20" height="20" transform="translate(-624 572)" />
        <path
          fill="#335977"
          d="M-607.625,579h-.037a5.631,5.631,0,0,0-5.588-5,5.632,5.632,0,0,0-5.588,5h-.037A3.129,3.129,0,0,0-622,582.125a3.129,3.129,0,0,0,3.125,3.125h1.25v-5.625a4.38,4.38,0,0,1,4.375-4.375,4.38,4.38,0,0,1,4.375,4.375v6.25a2.5,2.5,0,0,1-1.981,2.444,2.5,2.5,0,0,0-2.394-1.819,2.5,2.5,0,0,0-2.5,2.5,2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.425-1.92,3.752,3.752,0,0,0,3.2-3.7v-.625a3.129,3.129,0,0,0,3.125-3.125A3.129,3.129,0,0,0-607.625,579Zm-13.125,3.125a1.877,1.877,0,0,1,1.875-1.875V584A1.877,1.877,0,0,1-620.75,582.125Zm7.5,8.125A1.251,1.251,0,0,1-614.5,589a1.251,1.251,0,0,1,1.25-1.25A1.251,1.251,0,0,1-612,589,1.251,1.251,0,0,1-613.25,590.25Zm5.625-6.25v-3.75a1.877,1.877,0,0,1,1.875,1.875A1.877,1.877,0,0,1-607.625,584Z"
          transform="translate(-0.75 -0.75)"
        />
      </g>
    </svg>
  );
};

export default SupportServiceIcon;
