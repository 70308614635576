const accountInfo = {
	accountInfo: {
		account_information: 'Información de mi cuenta',
		services: 'Mis servicios',
		data_privacy: 'Mis datos y privacidad',
		benefits: 'Beneficios',
		company: 'Perfil de la compañía',
		branches: 'Sucursales',
		users: 'Usuarios',
		area_coverage: 'Áreas de servicio',
		lead_program: 'Preferencias de clientes potenciales',
		ein_program: 'Clientes potenciales y Red de Instaladores de Enphase',
		ac_module_admin: 'Administración del módulo de CA',
		enphase_store: 'Tienda de Enphase',
		product_warranty_extension: 'Extensión de garantía del producto',
		lpp: 'Programa de protección laboral',
		permit_assistance: 'Asistencia para permisos',
		incentive_programs: 'Programas de incentivos',
		ein_merchandise: 'Productos de la EIN',
		financing: 'Financiamiento',
		training: 'Capacitación',
		collaterals: 'Material de marketing',
		my_leads: 'Mis clientes potenciales',
		lead_details: 'Detalles de solicitud de presupuesto',
		api_access: 'Acceso API',
		third_party: 'Generación de reportes de terceros',
		profiles: 'Perfiles de red eléctrica',
		createBranch: 'Añadir una sucursal',
		branch_profile: 'Perfil de la sucursal',
		new: 'Nuevo',
		applications: 'Aplicaciones',
		createUser: 'Añadir nuevo usuario',
		user_profile: 'Perfil de usuario',
		platform_discounts: 'Descuentos de la plataforma',
		account_details: 'Detalles de la cuenta',
		preferences: 'Preferencias',
		change_password: 'Cambiar contraseña',
		account: 'Cuenta',
		dashboard: 'Panel',
		systems: 'Sistemas',
		admin: 'Admin',
		einDashboard: 'Panel de EIN',
		einAdmin: 'Admin de EIN',
		edit: 'Editar',
		failureMessage: 'Algo salió mal, vuelve a intentarlo'
	}
};

export default accountInfo;
