import { ChatActionTypes } from './constants';
export function getMessagesList(drsId) {
    return { type: ChatActionTypes.GET_ALL_MESSAGES_REQUEST, drsId: drsId };
}
export function sendMessage(payload) {
    return { type: ChatActionTypes.POST_MESSAGE_REQUEST, payload: payload };
}
export function updateChatStatus(payload) {
    return { type: ChatActionTypes.MARK_MESSAGES_VIEWED_REQUEST, payload: payload };
}
