import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import I18n from '../../../../config/locales';
import { FIELD_REQUIRED } from '../../../../containers/Installer/constants';
import { Label } from '../../../Common/Label';
import PanelTemplate from '../../../PanelTemplate';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        panelContainer: {
            margin: 0,
            padding: 0,
            border: 'none',
        },
        documentWrapper: {
            padding: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        disableForm: {
            pointerEvents: 'none',
            opacity: 0.6,
        },
        gridContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        gridItem: (_a = {
                width: '48%',
                paddingTop: '24px !important'
            },
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _a),
        basicDetailsField: {
            maxWidth: theme.spacing(65),
            width: '100%',
        },
        labelColor: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontWeight: 400,
            fontSize: theme.spacing(2),
            transform: 'translate(0, -4px) scale(1)',
            textTransform: 'initial',
        },
    });
});
var SolarDetails = function (props) {
    var classes = useStyles();
    var solarSystemSize = props.solar_system_size, batteryType = props.battery_type, batteryQuantity = props.battery_quantity, handleChange = props.handleChange, disableForm = props.disableForm, solarSystemSizeError = props.solar_system_size_error, batteryTypeError = props.battery_type_error, batteryQuantityError = props.battery_quantity_error, type = props.type, countryCode = props.countryCode;
    var countryAndCourseWiseBatteryTypes = props.userSelectionData.batteryTypes.filter(function (bType) {
        return props.application.univ_course_lp_id
            ? bType.course.includes(props.application.univ_course_lp_id) && bType.country.includes(countryCode)
            : bType.country.includes(countryCode);
    });
    var showIQBattery3TNote = countryAndCourseWiseBatteryTypes.findIndex(function (data) { return data.type === 'IQ_BATTERY_3T'; }) !== -1;
    useEffect(function () {
        if (props.userSelectionData.batteryTypes &&
            props.userSelectionData.batteryTypes.filter(function (bType) { return bType.country.includes(countryCode); }).length === 1) {
            props.handleChange(countryAndCourseWiseBatteryTypes[0].type, 'battery_type', 'battery_type_error');
        }
    }, [props.userSelectionData, countryCode]);
    return (_jsx(PanelTemplate, { header: I18n.t('solarDetails.pageHeader'), className: classes.panelContainer, children: _jsxs(Box, { className: classes.documentWrapper, children: [_jsx(Grid, { container: true, className: classes.gridContainer, children: _jsx(Grid, { item: true, className: classes.gridItem, children: _jsx(TextField, { fullWidth: true, type: "number", label: _jsx(Label, { className: classes.labelColor, text: I18n.t('solarDetails.solarSystemSize'), isRequired: true }), value: solarSystemSize || '', onChange: function (e) { return handleChange(e.target.value, 'solar_system_size', 'solar_system_size_error'); }, error: solarSystemSizeError, helperText: solarSystemSizeError && I18n.t(FIELD_REQUIRED), id: "solar_system_size_error", disabled: disableForm, className: classes.basicDetailsField }) }) }), type !== 'Commercial' && (_jsxs(Grid, { container: true, className: classes.gridContainer, children: [_jsx(Grid, { item: true, className: classes.gridItem, children: _jsxs(TextField, { select: true, fullWidth: true, label: _jsx(Label, { className: classes.labelColor, text: I18n.t('applicationView.batteryType'), isRequired: true }), value: batteryType ||
                                    '' ||
                                    ((countryAndCourseWiseBatteryTypes === null || countryAndCourseWiseBatteryTypes === void 0 ? void 0 : countryAndCourseWiseBatteryTypes.length) === 1 ? countryAndCourseWiseBatteryTypes[0].type : ''), onChange: function (e) { return handleChange(e.target.value, 'battery_type', 'battery_type_error'); }, error: batteryTypeError, helperText: batteryTypeError
                                    ? I18n.t(FIELD_REQUIRED)
                                    : showIQBattery3TNote
                                        ? I18n.t('solarDetails.batteryTypeNote')
                                        : '', id: "battery_type_error", disabled: disableForm || (countryAndCourseWiseBatteryTypes === null || countryAndCourseWiseBatteryTypes === void 0 ? void 0 : countryAndCourseWiseBatteryTypes.length) === 1, className: classes.basicDetailsField, children: [_jsx(MenuItem, { value: "", className: classes.hide }), countryAndCourseWiseBatteryTypes.map(function (bType, idx) { return (_jsx(MenuItem, { value: bType.type, children: bType.name }, "".concat(bType.type).concat(idx))); })] }) }), _jsx(Grid, { item: true, className: classes.gridItem, children: _jsxs(TextField, { select: true, fullWidth: true, label: _jsx(Label, { className: classes.labelColor, text: I18n.t('applicationView.batteryQuantity'), isRequired: batteryType !== 'NONE' }), value: batteryQuantity || '', onChange: function (e) { return handleChange(e.target.value, 'battery_quantity', 'battery_quantity_error'); }, error: batteryQuantityError, helperText: batteryQuantityError && I18n.t(FIELD_REQUIRED), id: "battery_quantity_error", disabled: disableForm || batteryType === 'NONE', className: batteryType === 'NONE' ? classes.disableForm : classes.basicDetailsField, children: [_jsx(MenuItem, { value: "", className: classes.hide }), Array.from({ length: 16 }, function (_, i) { return i + 1; }).map(function (qty, idx) { return (_jsx(MenuItem, { value: qty, children: qty }, "".concat(qty).concat(idx))); })] }) })] }))] }) }));
};
export default SolarDetails;
