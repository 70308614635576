import React, { Fragment } from 'react';
import { airbrakeProject, forwardTo } from '../../utils/helper';
import { Notifier } from '@airbrake/browser';
import getConfig from '../../config/env';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { airbrakeProjectId, airbrakeProjectKey } = airbrakeProject();
    let airbrake = new Notifier({
      projectId: airbrakeProjectId,
      projectKey: airbrakeProjectKey,
      environment: getConfig().env,
    });

    // Ignore Type Errors from Development and QA4 Env
    airbrake.addFilter((notice) => {
      if (notice.context.environment === 'qa2' || notice.context.environment === 'production') {
        return notice;
      }
      return null;
    });
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    airbrake.notify({
      error: error,
      params: { info: info },
    });
  }

  render() {
    if (this.state.hasError) {
      forwardTo('/manager/dashboard/error');
    }
    return <Fragment>{this.props.children}</Fragment>;
  }
}

export default ErrorBoundary;
