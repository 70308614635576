const common = {
  error: {
    unauthorized: 'Onbevoegde toegang',
    required: 'Vereist'
  },
  common: {
    mandatory: 'Verplichte velden'
  },
  utils: {
    dashboard: 'Dashboard',
    systems: 'Systemen',
    account: 'Account',
    services: 'Diensten',
    dataPrivacy: 'Gegevens & Privacy',
    support: 'Ondersteuning',
    admin: 'Admin',
    defaultMessage: 'Om bestanden te downloaden, schakelt u de popup blocker in uw browser uit.',
    signInMessage: 'U moet zich aanmelden of aanmelden voordat u verder gaat.',
    commonApiMessage: 'Verzoek kon niet worden verwerkt',
    adminNew: 'Admin(Nieuw)'
  },
  message: {
    yelpWaitMessage: 'Wacht op Yelp om te updaten',
    googleWaitMessage: 'Wacht op Google place id om te updaten',
    successMessageUpload: 'Bestand succesvol geüpload.',
    failureMessageUpload: 'Er is iets misgegaan, probeer het alsjeblieft nog eens.',
    serviceAreasCreateSuccess: 'Serviceruimte met succes gecreëerd',
    serviceAreasUpdateSuccess: 'Servicegebied met succes geüpdatet',
    serviceAreasDeleteSuccess: 'Servicegebied met succes verwijderd',
    hardware_product: 'Hardware Product',
    rowsFailed: '%{failedCount} van de %{totalCount} rij(en) mislukt',
    accessRequestSuccess: 'Toegang met succes aangevraagd'
  },
  buttons: {
    cancel: 'Annuleer',
    edit: 'Bewerk',
    update: 'UPDATE',
    updateAccount: 'Account bijwerken',
    submit: 'Stuur een bericht naar',
    submitUpperCase: 'INDIENEN',
    back: 'Terug naar',
    save: 'Opslaan',
    show: 'Toon',
    add: 'Voeg toe',
    downloadSampleFile: 'Voorbeeldbestand downloaden',
    proceed: 'Proceed'
  }
};

export default common;
