const footer = {
  footer: {
    imageTitle: 'Bezoek de website van Enphase Energy',
    privacy: 'Privacy',
    terms: 'Voorwaarden',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Alle rechten voorbehouden.',
    language: 'Taal'
  }
};

export default footer;
