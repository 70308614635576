const footer = {
  footer: {
    imageTitle: 'Visita il sito Web di Enphase Energy',
    privacy: 'Privacy',
    terms: 'Termini',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Tutti i diritti riservati.',
    language: 'Lingua'
  }
};

export default footer;
