import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(35),
  },
  accordion: {
    boxShadow: 'none !important',
    background: 'none !important',
    margin: '0px !important',
    borderRadius: '0px !important',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    display: 'flex !important',
    letterSpacing: 0.08,
    color: '#335977 !important',
    fontSize: 16,
    minHeight: `${theme.spacing(6)}px !important`,
    padding: `${theme.spacing(0.75, 2.5)} !important`,
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px !important',
    },
  },
  highlightHeading: {
    '& p': {
      fontWeight: 'bold',
    },
    '& svg path': {
      strokeWidth: 0.5,
      stroke: '#335977',
    },
  },
  heading: {
    marginLeft: `${theme.spacing(0.5)}px !important`,
  },
  accordionDetailsList: {
    padding: 0,
    flexDirection: 'column',
    margin: theme.spacing(1, 4),
  },
  defaultAccordionDetail: {
    color: '#335977 !important',
    fontSize: `15px !important`,
    marginBottom: `${theme.spacing(1.5)}px !important`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectedAccordionDetail: {
    color: '#F37320 !important',
    fontWeight: 'bold !important',
  },
  accordionDetailsListDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  addSystem: {
    display: 'flex',
    width: 'calc(100% - 90px)',
    margin: '20px auto',
    textTransform: 'none',
  },
  dialogHeader: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  close: {
    opacity: 0.5,
    cursor: 'pointer',
  },
  content: {
    border: '1px solid #7070704D',
    margin: '0 20px',
    padding: '15px',
  },
  rejectButton: {
    color: theme.palette.primary.main,
    background: theme.tertiary.white,
  },
  acceptButton: {
    color: theme.tertiary.white,
  },
  link: {
    color: '#2679f6',
    cursor: 'pointer',
  },
  buttonWrapper: {
    paddingTop: theme.spacing(2.5),
  },
  addSystemDialog: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(1),
    },
  },
  leftPanelQuotesList: {
    fontSize: 12,
    letterSpacing: 0,
    color: '#335977',
    fontWeight: 'normal',
  },
  quotesWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 0, 1.5, 2),
  },
  clickableQuotes: {
    cursor: 'pointer',
  },
  quotesSelected: {
    fontWeight: 'bold',
  },
  quoteIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1.25),
    marginLeft: theme.spacing(0.25),
  },
  quoteCheckIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(1),
    fill: '#47B501',
  },
  backdrop: {
    zIndex: 15,
    color: theme.palette.primary.main,
  },
  headerText: {
    color: '#FFE785',
    fontSize: 13,
    fontWeight: 'bold',
  },
  headerContainer: {
    backgroundColor: '#335977',
  },
  dialogContent: {
    paddingTop: 0,
  },
  tableRow: {
    '& td': {
      borderBottom: 'none',
    },
  },
  closeButton: {
    padding: 0,
  },
  linkColour: {
    color: '#3B86FF',
  },
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
  disablePointer: {
    pointerEvents: 'none',
  },
}));

export default useStyles;
