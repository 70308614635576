export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_MOBILE_VIEW"] = "SET_MOBILE_VIEW";
    ActionTypes["REQUEST_FAILURE"] = "REQUEST_FAILURE";
    ActionTypes["SET_EMAIL_ID"] = "SET_EMAIL_ID";
    ActionTypes["SET_ENLIGHTEN_MANAGER_TOKEN"] = "SET_ENLIGHTEN_MANAGER_TOKEN";
    ActionTypes["ACCOUNT_DETAIL_REQUEST"] = "ACCOUNT_DETAIL_REQUEST";
    ActionTypes["ACCOUNT_DETAIL_SUCCESS"] = "ACCOUNT_DETAIL_SUCCESS";
    ActionTypes["ACCOUNT_DETAIL_FAILURE"] = "ACCOUNT_DETAIL_FAILURE";
    ActionTypes["UPDATE_ACCOUNT_SUCCESS"] = "UPDATE_ACCOUNT_SUCCESS";
    // Left Panel -Starts
    ActionTypes["GET_SITE_DETAILS_REQUEST"] = "GET_SITE_DETAILS_REQUEST";
    ActionTypes["GET_SITE_DETAILS_SUCCESS"] = "GET_SITE_DETAILS_SUCCESS";
    ActionTypes["GET_SITE_DETAILS_FAILURE"] = "GET_SITE_DETAILS_FAILURE";
    ActionTypes["SET_COMPANY_ID"] = "SET_COMPANY_ID";
    ActionTypes["SET_FROM_ITK"] = "SET_FROM_ITK";
    ActionTypes["SHOW_PAGE"] = "SHOW_PAGE";
    ActionTypes["SET_APPLICATIONS_FILTERS"] = "SET_APPLICATIONS_FILTERS";
    // Left Panel -Ends
})(ActionTypes || (ActionTypes = {}));
