import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Paper, Typography, makeStyles } from '@material-ui/core';
import getConfig from '../../config/env';
var useStyles = makeStyles(function (theme) { return ({
    banner: {
        padding: theme.spacing(2),
        backgroundColor: '#fefbe8',
        textAlign: 'center',
    },
    button: {
        backgroundColor: '#fbe59a',
    },
}); });
var Banner = function () {
    var classes = useStyles();
    return (_jsx(Paper, { className: classes.banner, elevation: 3, children: _jsxs(Typography, { variant: "body1", children: ["Need permit plan sets? Enphase permitting services support permit plan sets for all manufacturers, including Enphase IQ8s and IQ Battery 5Ps.", ' ', _jsx(Button, { className: classes.button, href: "".concat(getConfig().enlightenUrl, "/manager/dashboard/permitting-assistance"), children: "Request a Permit" })] }) }));
};
export default Banner;
