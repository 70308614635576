const header = {
	header: {
		logOut: 'Esci',
		enphaseStore: 'Negozio Enphase',
		enphaseSupport: 'Supporto Enphase ',
		comingSoon: 'Disponibile prossimamente',
		new: 'Nuovo',
		dashboard: 'Dashboard',
		systems: 'Sistemi',
		account: 'Account',
		services: 'Servizi',
		dataPrivacy: 'Dati e Privacy',
		support: 'Assistenza',
		admin: 'Amministrazione',
		adminNew: 'Amministratore (nuovo)'
	}
};

export default header;
