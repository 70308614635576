const footer = {
  footer: {
    imageTitle: 'Visite o site da Enphase Energy',
    privacy: 'Privacidade',
    terms: 'Termos',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Todos os direitos reservados.',
    language: 'Idioma'
  }
};

export default footer;