import leadIcon from './leadIcon';
import servicesIcon from './servicesIcon';
import supportServiceIcon from './supportServiceIcon';
import systemDetailsIcon from './systemDetailsIcon';
import systemReportingIcon from './systemReportingIcon';

export default {
  lead: leadIcon,
  services: servicesIcon,
  supportService: supportServiceIcon,
  systemDetails: systemDetailsIcon,
  systemReporting: systemReportingIcon,
};
