import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Tooltip } from '@material-ui/core';
import ContainedButton from '../../../ContainedButton';
import OutlinedButton from '../../../OutlinedButton';
import { theme } from '../../../../theme';
import clsx from 'clsx';
import FileUploadDialog from '../../FileUploadDialog';
import { get, isEmpty } from 'lodash';
import ApprovalDialog from '../../ApprovalDialog';
import { APPLICATION_STATES } from '../../../../utils/constant';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function () {
    var _a, _b, _c, _d;
    return ({
        adminActions: {
            marginTop: theme.spacing(1),
        },
        buttonsContainer: (_a = {
                display: 'flex',
                marginTop: theme.spacing(2.5)
            },
            _a[theme.breakpoints.down('xs')] = {
                display: 'block',
            },
            _a),
        buttonContainer: (_b = {
                width: '50%',
                '& .MuiButton-root.Mui-disabled': {
                    color: theme.tertiary.buttonColor,
                }
            },
            _b[theme.breakpoints.down('xs')] = {
                width: '100%',
            },
            _b),
        leftButton: (_c = {
                marginRight: theme.spacing(0.75)
            },
            _c[theme.breakpoints.down('xs')] = {
                marginRight: theme.spacing(0),
            },
            _c),
        rightButton: (_d = {
                marginLeft: theme.spacing(0.75)
            },
            _d[theme.breakpoints.down('xs')] = {
                marginLeft: theme.spacing(0),
                marginTop: theme.spacing(1.5),
            },
            _d),
    });
});
var AdminActions = function (props) {
    var classes = useStyles();
    var _a = useState(false), uploadDialogOpen = _a[0], setUploadDialogOpen = _a[1];
    var _b = useState(false), isUploadDesignDoc = _b[0], setIsUploadDesignDoc = _b[1];
    var _c = useState(false), isResubmissionRequest = _c[0], setIsResubmissionRequest = _c[1];
    var _d = useState(false), requestTier2 = _d[0], setRequestTier2 = _d[1];
    var _e = useState(false), isApprove = _e[0], setIsApprove = _e[1];
    var _f = useState([]), adminDocs = _f[0], setAdminDocs = _f[1];
    var _g = useState(''), approvalComments = _g[0], setApprovalComments = _g[1];
    var _h = useState(false), approvalInProgress = _h[0], setApprovalInProgress = _h[1];
    var _j = useState(false), approveButtonToolTip = _j[0], setApproveButtonToolTip = _j[1];
    var _k = useState(''), fileUploadDialogHeader = _k[0], setFileUploadDialogHeader = _k[1];
    var _l = useState(''), fileUploadDialogSecondaryHeader = _l[0], setFileUploadDilaogSecondaryHeader = _l[1];
    useEffect(function () {
        if (isEmpty(adminDocs)) {
            setAdminDocs(get(props.application, 'admin_documents', []));
        }
    }, [props.application]);
    var currentState = get(props.application, 'current_status.state_id', 0);
    var buttonsEnabled = props.userEmail === get(props.application, 'current_reviewer.user_info.email') || props.isServiceAdmin;
    var uploadDesignDocButtonEnabled = [
        APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id,
        APPLICATION_STATES.RESUBMISSION_RECEIVED.id,
        APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS.id,
        APPLICATION_STATES.TIER2_RESUBMISSION_RECEIVED.id,
        APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id,
        APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id,
    ].includes(currentState);
    var tier2RequestButtonEnabled = [
        APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id,
        APPLICATION_STATES.RESUBMISSION_RECEIVED.id,
    ].includes(currentState);
    var approveButtonEnabled = [
        APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS.id,
        APPLICATION_STATES.TIER2_RESUBMISSION_RECEIVED.id,
    ].includes(currentState);
    var requestResubmissionButtonEnabled = [
        APPLICATION_STATES.APPLICATION_UNDER_REVIEW.id,
        APPLICATION_STATES.RESUBMISSION_RECEIVED.id,
        APPLICATION_STATES.TIER2_APPROVAL_UNDER_PROGRESS.id,
        APPLICATION_STATES.TIER2_RESUBMISSION_RECEIVED.id,
    ].includes(currentState);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var sendMessage = function (body) {
        props.sendMessage({
            body: body,
            drsId: get(props.application, 'drs_id', ''),
            successCb: function () {
                window.location.reload();
            },
        });
    };
    // if tier 1 calls pass review completd , if tier 2 calls pass tier 2 approval completed
    // service admin can call anytime
    var handleUploadDesignDoc = function (files, comment) {
        var fileNames = [];
        if (files) {
            files.forEach(function (file) { return fileNames.push(file.file_name); });
        }
        var body = {
            subject: I18n.t('adminAction.designDocUpload'),
            body_content: comment,
            file_names: fileNames,
        };
        props.updateAdminDocs({
            files: files,
            drsId: get(props.application, 'drs_id', ''),
            isApprovalDocument: true,
            successCbk: function () {
                if (currentState !== APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id &&
                    currentState !== APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id) {
                    props.updateStatus({
                        drsId: get(props.application, 'drs_id', ''),
                        stateId: currentState < APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id
                            ? APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id
                            : APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id,
                        successCbk: function () { return sendMessage(body); },
                    });
                }
                else {
                    sendMessage(body);
                }
            },
        });
    };
    // only tier 1 admin can call this
    var handleRequestTier2Approval = function (files, comment) {
        files[0].comments = comment;
        props.updateAdminDocs({
            files: files,
            isApprovalDocument: true,
            drsId: get(props.application, 'drs_id', ''),
            successCbk: function () {
                props.requestTier2Action({
                    drsId: get(props.application, 'drs_id', ''),
                    successCbk: function () {
                        window.location.reload();
                    },
                });
            },
        });
    };
    var handleApprove = function () {
        setApprovalInProgress(true);
        var approvalDocuments = (get(props.application, 'approval_documents', []) || []);
        var fileNames = [];
        if (approvalDocuments) {
            approvalDocuments.forEach(function (file) { return fileNames.push(file.file_name); });
        }
        var body = {
            subject: I18n.t('adminAction.tier2ApprovalComment'),
            body_content: approvalComments,
            file_names: fileNames,
        };
        props.sendMessage({
            body: body,
            drsId: get(props.application, 'drs_id', ''),
            successCb: function () {
                props.updateStatus({
                    drsId: get(props.application, 'drs_id', ''),
                    stateId: APPLICATION_STATES.TIER2_APPROVAL_COMPLETED.id,
                    successCbk: function () {
                        window.location.reload();
                    },
                });
            },
        });
    };
    var handleRequestResubmission = function (files, comment) {
        // if tier 1 calls pass 3 , if tier 2 calls pass 7
        // service admin can call anytime
        var fileNames = [];
        if (files) {
            files.forEach(function (file) { return fileNames.push(file.file_name); });
        }
        var body = {
            subject: I18n.t('adminAction.resubmissionRequested'),
            body_content: comment,
            file_names: fileNames,
        };
        props.updateAdminDocs({
            files: files,
            isApprovalDocument: false,
            drsId: get(props.application, 'drs_id', ''),
            successCbk: function () {
                props.updateStatus({
                    drsId: get(props.application, 'drs_id', ''),
                    stateId: currentState <= APPLICATION_STATES.RESUBMISSION_RECEIVED.id
                        ? APPLICATION_STATES.RESUBMISSION_REQUESTED.id
                        : APPLICATION_STATES.TIER2_RESUBMISSION_REQUESTED.id,
                    successCbk: function () { return sendMessage(body); },
                });
            },
        });
    };
    return (_jsxs(Fragment, { children: [_jsx(Grid, { container: true, direction: "row", className: classes.adminActions, children: _jsxs(Grid, { item: true, xs: 12, children: [_jsx(ContainedButton, { text: I18n.t('adminAction.uploadSendInstaller'), handleClick: function () {
                                setUploadDialogOpen(true);
                                setIsUploadDesignDoc(true);
                                setRequestTier2(false);
                                setIsResubmissionRequest(false);
                                setFileUploadDialogHeader('Upload and Send to Installer');
                                setFileUploadDilaogSecondaryHeader("".concat(currentState > APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id
                                    ? I18n.t('adminAction.finalDrsUpload')
                                    : I18n.t('adminAction.drsShareInstaller')));
                            }, disabled: !buttonsEnabled || !uploadDesignDocButtonEnabled, adminActionButtons: true }), _jsxs(Box, { className: classes.buttonsContainer, children: [_jsx(Box, { className: clsx(classes.buttonContainer, classes.leftButton), children: currentState <= APPLICATION_STATES.APPLICATION_REVIEW_COMPLETED.id ? (_jsx(OutlinedButton, { text: I18n.t('adminAction.requestToTier2'), handleClick: function () {
                                            setUploadDialogOpen(true);
                                            setRequestTier2(true);
                                            setIsResubmissionRequest(false);
                                            setIsUploadDesignDoc(false);
                                            setFileUploadDialogHeader('Request for Tier 2 Approval');
                                            setFileUploadDilaogSecondaryHeader(I18n.t('adminAction.tier2Approval'));
                                        }, disabled: !buttonsEnabled || !tier2RequestButtonEnabled, adminActionButtons: true })) : (_jsx(Tooltip, { open: approveButtonToolTip && buttonsEnabled && approveButtonEnabled, title: I18n.t('adminAction.tier1DocUpload'), placement: "bottom-start", arrow: true, children: _jsx(Box, { onMouseEnter: function () { return setApproveButtonToolTip(!approveButtonToolTip); }, onMouseLeave: function () { return setApproveButtonToolTip(!approveButtonToolTip); }, children: _jsx(OutlinedButton, { text: I18n.t('adminAction.approveTier1Doc'), handleClick: function () {
                                                    setRequestTier2(false);
                                                    setIsResubmissionRequest(false);
                                                    setIsUploadDesignDoc(false);
                                                    setIsApprove(true);
                                                    setFileUploadDialogHeader(I18n.t('adminAction.approveTier1Doc'));
                                                    setFileUploadDilaogSecondaryHeader(I18n.t('adminAction.modifyUploadDrs'));
                                                }, disabled: !buttonsEnabled || !approveButtonEnabled, adminActionButtons: true }) }) })) }), _jsx(Box, { className: clsx(classes.buttonContainer, classes.rightButton), children: _jsx(OutlinedButton, { text: I18n.t('buttons.requestInformation'), handleClick: function () {
                                            setUploadDialogOpen(true);
                                            setIsResubmissionRequest(true);
                                            setIsUploadDesignDoc(false);
                                            setRequestTier2(false);
                                            setFileUploadDialogHeader(I18n.t('buttons.requestInformation'));
                                            setFileUploadDilaogSecondaryHeader(I18n.t('adminAction.shareInformation'));
                                        }, disabled: !buttonsEnabled || !requestResubmissionButtonEnabled, adminActionButtons: true }) })] })] }) }), _jsx(FileUploadDialog, { drsId: props.drsId, docType: 'admin_documents', fileArray: adminDocs, open: uploadDialogOpen, onPopupClose: function () { return setUploadDialogOpen(false); }, handleUploadDesignDoc: function (files, comment) { return handleUploadDesignDoc(files, comment); }, handleRequestResubmission: function (files, comment) { return handleRequestResubmission(files, comment); }, handleRequestTier2Approval: function (files, comment) { return handleRequestTier2Approval(files, comment); }, isUploadDesignDoc: isUploadDesignDoc, isResubmissionRequest: isResubmissionRequest, requestTier2: requestTier2, dialogHeader: fileUploadDialogHeader, secondaryHeaderText: fileUploadDialogSecondaryHeader }), _jsx(ApprovalDialog, { open: isApprove, onClose: function () {
                    setIsApprove(false);
                    setApprovalInProgress(false);
                }, handleApprove: handleApprove, approvalInProgress: approvalInProgress, application: props.application, approvalComments: approvalComments, setApprovalComments: function (value) { return setApprovalComments(value); }, dialogHeader: fileUploadDialogHeader, secondaryHeaderText: fileUploadDialogSecondaryHeader })] }));
};
export default AdminActions;
