import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
var useLabelStyles = makeStyles(function (theme) { return ({
    customLabel: {
        '& .Mui-focused': {
            '& label': {
                color: theme.palette.primary.main,
                alignItems: 'center',
            },
        },
        '& .MuiInputLabel-shrink': {
            '& label': {
                fontWeight: 400,
                color: theme.palette.grey,
                alignItems: 'center',
            },
        },
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
        },
    },
    label: {
        display: 'flex',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: '#43425D',
        fontSize: 14,
    },
    important: {
        color: 'red',
        marginTop: '-3px',
    },
}); });
export var Label = function (props) {
    var classes = useLabelStyles();
    var _a = props.isRequired, isRequired = _a === void 0 ? false : _a, children = props.children, text = props.text, _b = props.className, className = _b === void 0 ? '' : _b;
    var labelClassName = className ? clsx(classes.label, className) : classes.label;
    return (_jsxs(Typography, { component: "label", className: labelClassName, children: [text, children, isRequired && (_jsx(Typography, { className: classes.important, component: "span", children: "*" }))] }));
};
