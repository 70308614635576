import accountInfo from './accountInfo';
import header from './header';
import footer from './footer';
import common from './common';

const es = {
	...accountInfo,
	...header,
	...footer,
	...common
};

export default es;
