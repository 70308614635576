import { HeaderActionTypes } from './constants';
export function getABFeatureList(payload) {
    return { type: HeaderActionTypes.GET_AB_FEATURE_REQUEST, payload: payload };
}
export function getUserOwnPreferences() {
    return { type: HeaderActionTypes.GET_USER_OWN_PREFERENCES_REQUEST };
}
export function getUserDetails() {
    return { type: HeaderActionTypes.GET_USER_DETAILS };
}
export function getCompanyInfo(payload) {
    return { type: HeaderActionTypes.GET_COMPANY_INFO_REQUEST, payload: payload };
}
export function setSolargrafAccess(solargrafAccess) {
    return { type: HeaderActionTypes.SET_SOLARGRAF_ACCESS, solargrafAccess: solargrafAccess };
}
