import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
      width: 'auto',
    },
  },
  dialogTitle: {
    display: 'flex',
    '& h6': {
      fontSize: 20,
      color: '#4A4A4A',
      fontWeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1.5),
    },
  },
  closeButton: {
    display: 'flex',
    marginLeft: 'auto',
    padding: 0,
  },
  dialogContent: {
    margin: 0,
    border: '1px solid #c6c6c6',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  dialogParaText: {
    paddingTop: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
    justifyContent: 'flex-end',
  },
  buttonText: {
    color: 'white',
    fontSize: 15,
    textTransform: 'none',
    letterSpacing: 0.3,
  },
  cancelButton: {
    fontSize: 15,
    textTransform: 'none',
    letterSpacing: 0.3,
  },
}));

export default useStyles;
