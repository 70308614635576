import settings from '../../../utils/settings';

export const SOLARGRAF_REGISTER_LINK = 'https://www.solargraf.com/register/';
export const SOLARGRAF_DRAW_DESIGN_TUTORIAL =
  'https://www.youtube.com/embed/mJZF2LROdZs?rel=0&playlist=VIDEO_ID&loop=1';

export const FEATURES = {
  EIN_DASHBOARD: 'EIN_DASHBOARD',
  EIN_ADMIN: 'EIN_ADMIN',
  PERMIT_ENABLED: 'PERMIT_ENABLED',
  LEAD_OPT_IN: '21noveinprofile2019',
  EIN_OPT_IN: 'EIN_OPT_IN',
  LRE_ENABLED: 'LRE_ENABLED',
  INCENTIVE_PROGRAM: 'INCENTIVE_PROGRAM',
  EIN_MERCHANDISE: 'EIN_MERCHANDISE',
  PERMIT_ADMIN: 'PERMIT_ADMIN',
  SGIP_ADMIN: 'SGIP_ADMIN',
  SOLARGRAF_ENABLED: 'SOLARGRAF_ENABLED',
  SOLARGRAF_PLATFORM_INSTALLER: 'Solargraf_Platform_Installer',
  DRS_ENABLED_COMPANY: 'DRS_ENABLED_COMPANY',
  DRS_ENABLED_USER: 'DRS_ENABLED_USER',
  PERMIT_PITCH_IN_DRS: 'PERMIT_PITCH_IN_DRS',
};

const US = {
  support: 'https://enphase.com/en-us/support/contact-support',
  enlightenHelp: 'https://enphase.com/en-us/support/solar-professionals/software?modal-content=support-regions&m=mgr',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/en-us/'
    : 'https://store.enphase.com/storefront/en-us/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/en-us/legal/privacy-policy',
  terms: 'https://enphase.com/en-us/legal/terms-of-service',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '$',
};

const AU = {
  support: 'https://enphase.com/en-au/support/contact',
  enlightenHelp: 'https://enphase.com/en-au/support/solar-professionals/software',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/en-au/'
    : 'https://store.enphase.com/storefront/en-au/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/en-us/legal/privacy-policy',
  terms: 'https://enphase.com/en-us/legal/terms-of-service',
  drs_terms: 'https://enphase.com/en-au/permit-assistance-services-terms-conditions',
  currency: '$',
};

const DE = {
  support: 'https://enphase.com/de-de/support/kontaktieren',
  enlightenHelp: 'https://enphase.com/de-de/support/solarprofis/enlighten-manager?m=mgr',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/de-de/'
    : 'https://store.enphase.com/storefront/de-de/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/de-de/datenschutzrichtlinie',
  terms: 'https://enphase.com/de-de/legal/service-und-nutzungsbestimmungen',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '€',
};
const AT = {
  support: 'https://enphase.com/de-de/support/kontaktieren',
  enlightenHelp: 'https://enphase.com/de-de/support/solarprofis/enlighten-manager?m=mgr',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/de-de/'
    : 'https://store.enphase.com/storefront/de-de/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/en-us/datenschutzrichtlinie',
  terms: 'https://enphase.com/en-us/legal/service-und-nutzungsbestimmungen',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '€',
};
const NL = {
  support: 'https://enphase.com/nl-nl/support/contact',
  enlightenHelp: 'https://enphase.com/nl-nl/support/zonne-energieprofessionals/enlighten-manager?m=mgr',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/nl-nl/'
    : 'https://store.enphase.com/storefront/nl-nl/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/nl-nl/legal/privacybeleid',
  terms: 'https://enphase.com/nl-nl/legal/algemene-voorwaarden',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '€',
};

const NC = {
  support: 'https://enphase.com/en-au/support/contact',
  enlightenHelp: 'https://enphase.com/en-au/support/solar-professionals/software',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/en-au/'
    : 'https://store.enphase.com/storefront/en-au/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/en-us/legal/privacy-policy',
  terms: 'https://enphase.com/en-us/legal/terms-of-service',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};
const NZ = {
  support: 'https://enphase.com/en-au/support/contact',
  enlightenHelp: 'https://enphase.com/en-au/support/solar-professionals/software',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/en-au/'
    : 'https://store.enphase.com/storefront/en-au/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/en-us/legal/privacy-policy',
  terms: 'https://enphase.com/en-us/legal/terms-of-service',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};
const BE = {
  support: 'https://enphase.com/nl-nl/support/contact',
  enlightenHelp: 'https://enphase.com/nl-nl/support/zonne-energieprofessionals/enlighten-manager?m=mgr',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/nl-be/'
    : 'https://store.enphase.com/storefront/nl-be/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/nl-nl/legal/privacybeleid',
  terms: 'https://enphase.com/nl-nl/legal/algemene-voorwaarden',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '€',
};

const IN = {
  support: 'https://enphase.com/en-in/support/contact-support',
  enlightenHelp: 'https://enphase.com/en-us/support/solar-professionals/software?modal-content=support-regions&m=mgr',
  privacy: 'https://enphase.com/en-us/legal/privacy-policy',
  terms: 'https://enphase.com/en-us/legal/terms-of-service',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};

const MX = {
  support: 'https://enphase.com/es-lac/contact-support-es-lac',
  enlightenHelp: 'https://enphase.com/es-lac/soporte/profesionales/enlighten-manager?m=mgr',
  privacy: 'https://enphase.com/es-lac/condiciones-legales/politica-de-privacidad',
  terms: 'https://enphase.com/es-lac/condiciones-legales/terminos-y-condiciones',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};

const IT = {
  support: 'https://enphase.com/it-it/support/contact',
  enlightenHelp: 'https://www2.enphase.com/enlighten-help/it/?m=mgr',
  privacy: 'https://enphase.com/it-it/note-legali/informativa-sulla-privacy',
  terms: 'https://enphase.com/it-it/note-legali/condizioni-del-servizio',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};

const FR = {
  support: 'https://enphase.com/fr-fr/nous-contacter',
  enlightenHelp: 'https://enphase.com/fr-fr/support/professionnels/enlighten-manager?m=mgr',
  privacy: 'https://enphase.com/fr-fr/juridique/politique-de-confidentialite',
  terms: 'https://enphase.com/fr-fr/juridique/conditions-generales',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '€',
};

const CH = {
  support: 'https://enphase.com/fr-fr/nous-contacter',
  enlightenHelp: 'https://enphase.com/fr-fr/support/professionnels/enlighten-manager?m=mgr',
  privacy: 'https://enphase.com/fr-fr/juridique/politique-de-confidentialite',
  terms: 'https://enphase.com/fr-fr/juridique/conditions-generales',
  drs_terms: 'https://enphase.com/design-review-services-terms-conditions',
  currency: '₣',
};

const ES = {
  support: 'https://enphase.com/es-es/empresa/contacto',
  enlightenHelp: 'https://enphase.com/es-es/soporte/profesionales/software',
  enphaseStore: settings.enlightenMenuUrl().includes('qa2')
    ? 'https://store-qa2.enphase.com/storefront/es-es/'
    : 'https://store.enphase.com/storefront/es-es/?utm_source=enlighten-manager-home&utm_medium=referral',
  privacy: 'https://enphase.com/es-es/condiciones-legales/politica-de-privacidad',
  terms: 'https://enphase.com/es-es/condiciones-legales/terminos-y-condiciones',
  drs_terms: 'https://www4.enphase.com/en-us/legal/permit-assistance-services-terms-conditions',
  currency: '€',
};

export default { US, AU, DE, NL, BE, AT, IN, MX, NZ, NC, IT, FR, ES, CH };
