import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    display: 'flex',
    marginLeft: 'auto',
    padding: 0,
  },
  dialogContent: {
    margin: theme.spacing(0, 2.5, 2.5),
    border: '1px solid #c6c6c6',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1.5, 2.5),
    },
  },
  message: {
    color: '#43425D',
    fontSize: 16,
    margin: 0,
  },
  dialogTitle: {
    display: 'flex',
    '& h6': {
      fontSize: 20,
      color: '#4A4A4A',
      fontWeight: 600,
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 1.5),
    },
  },
  resendOtp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    color: '#43425D',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  resendOtpButton: {
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    fontSize: 14,
    color: '#007AFF',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  otpInput: {
    border: 'none',
    borderBottom: '1px solid #9F9E9E',
    width: `${theme.spacing(3)}px !important`,
    fontWeight: 'bold',
    fontSize: 24,
    color: '#43425D',
    [theme.breakpoints.down('xs')]: {
      width: `${theme.spacing(3)}px !important`,
    },
  },
  otpFocus: {
    outline: 'none',
  },
  otpContainer: {
    margin: theme.spacing(4, 2),
    justifyContent: 'center',
    '& .otpInputSeparator': {
      width: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(1),
      },
    },
  },
  otpOuterContainer: {
    border: '1px solid #c6c6c680',
    padding: theme.spacing(2, 10),
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2.5),
    },
  },
  enterOtp: {
    color: '#A3A3A3',
  },
  dialogContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
      width: 'auto',
    },
  },
  countDown: {
    color: '#FF8B00',
    fontSize: 14,
  },
  verificationMessage: {
    margin: theme.spacing(2.5, 0, 0),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      paddingRight: theme.spacing(0.5),
    },
  },
  verificationSuccess: {
    fontSize: 14,
    color: '#62B15A',
  },
  verificationFailure: {
    fontSize: 14,
    color: '#DE5246',
  },
  backdrop: {
    zIndex: 1301,
    color: theme.palette.primary.main,
  },
  linkSolargrafMessageOne: {
    color: '#4A4A4A',
    fontSize: 18,
    textAlign: 'center',
  },
  linkSolargrafMessageTwo: {
    color: '#4A4A4A',
    fontSize: 15,
    textAlign: 'center',
  },
  verificationCodeButton: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: 0,
    fontSize: 18,
    margin: theme.spacing(2.5, 0),
  },
}));

export default useStyles;
