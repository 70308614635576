import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// deprecated - can be removed later
const styles = (theme) => ({
  customLabel: {
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
        alignItems: 'center',
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
        alignItems: 'center',
      },
    },
    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'solid',
    },
  },
  label: {
    display: 'flex',
    fontWeight: 500,
    textTransform: 'capitalize',
    color: '#43425D',
    fontSize: 14,
  },
  important: {
    color: 'red',
    marginTop: '-3px',
  },
});

export const useLabelStyles = makeStyles(styles);

export const Label = (props) => {
  const classes = useLabelStyles();
  const { isRequired = false, children, text, className = '' } = props;
  const labelClassName = className ? clsx(classes.label, className) : classes.label;
  return (
    <Typography component="label" className={labelClassName}>
      {text}
      {children ? children : null}
      {isRequired && (
        <Typography className={classes.important} component="span">
          *
        </Typography>
      )}
    </Typography>
  );
};

export const labelStyles = styles;
