import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { DateRangePickerComponent } from 'materialui-daterange-picker';
import ContainedButton from '../ContainedButton';
import I18n from '../../config/locales';
var useStyles = makeStyles(function () { return ({
    dateRangeWrapper: {
        boxShadow: 'none',
        padding: '0px !important',
    },
}); });
var DatePicker = function (props) {
    var classes = useStyles();
    var open = props.open, handleClose = props.handleClose, handleSave = props.handleSave;
    var _a = useState({}), dateRange = _a[0], setDateRange = _a[1];
    var handleRangeSave = function () {
        handleSave(dateRange);
    };
    return (_jsxs(Dialog, { open: open, onClose: handleClose, children: [_jsx(DialogContent, { className: classes.dateRangeWrapper, children: _jsx(DateRangePickerComponent, { open: open, definedRanges: [], onChange: function (range) { return setDateRange(range); }, initialDateRange: dateRange }) }), _jsx(DialogActions, { children: _jsx(ContainedButton, { handleClick: handleRangeSave, text: I18n.t('buttons.ok') }) })] }));
};
export default DatePicker;
