var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { TextField, Typography, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEmpty } from 'lodash';
var useStyles = makeStyles(function (theme) { return ({
    displayOptionBox: {
        width: theme.spacing(28),
        height: theme.spacing(5, '!important'),
        '& .MuiAutocomplete-inputRoot': {
            flexWrap: 'nowrap',
            overflow: 'hidden',
            height: theme.spacing(4.4),
        },
    },
    displayOptionText: {
        fontSize: theme.spacing(1.9),
        lineHeight: theme.spacing(0.18),
    },
}); });
export var MultipleSelectAutoComplete = function (props) {
    var selectedValue = props.selectedValue, options = props.options, onChangeFunction = props.onChangeFunction;
    var classes = useStyles();
    return (_jsx(Autocomplete, { multiple: true, options: options, getOptionLabel: function (option) {
            return isEmpty(option.email)
                ? ''
                : "".concat(option.firstName, " ").concat(option.lastName, "\n                           ").concat(option.email);
        }, renderTags: function (value) {
            var numTags = value.length;
            return (_jsxs(Typography, { className: classes.displayOptionText, children: [value
                        .slice(0, 1)
                        .map(function (option) { return option.firstName.substring(0, 8); })
                        .join(', '), numTags > 1 && " +".concat(numTags - 1, " more")] }));
        }, value: selectedValue, onChange: function (e, value) { return onChangeFunction(value); }, renderInput: function (params) { return (_jsx(TextField, __assign({ className: classes.displayOptionBox }, params, { label: '', variant: "outlined", fullWidth: true }))); } }));
};
export default MultipleSelectAutoComplete;
