import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { get } from 'lodash';

import useStyles from './styles';
import I18n from '../../../config/locales';
import { SOLARGRAF_REGISTER_LINK } from '../../../config/constants';

const GoToSolargrafPopup = (props) => {
  const classes = useStyles();
  const { handleClose = () => {}, accountDetail = {} } = props;
  return (
    <Dialog open onClose={handleClose} aria-describedby="goto-solargraf-description" maxWidth="sm" fullWidth>
      <DialogContent id="goto-solargraf-description" className={classes.dialogContent}>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Typography component="div" className={classes.dialogBody}>
          <DialogContentText className={classes.message}>
            {I18n.t('accountInfo.createSolargrafAccountMessage', {
              email: get(accountDetail, 'email', ''),
            })}
          </DialogContentText>
          <DialogContentText className={classes.note}>
            {I18n.t('accountInfo.createSolargrafAccountNote')}
          </DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                window.open(SOLARGRAF_REGISTER_LINK, '_self');
              }}
              color="primary"
              variant="contained"
              className={classes.gotoSolargrafButton}
              disableElevation
            >
              {I18n.t('accountInfo.gotoSolargraf')}
              <DoubleArrowIcon color="tertiary" className={classes.doubleArrowButton} />
            </Button>
          </DialogActions>
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default GoToSolargrafPopup;
