const accountInfo = {
	accountInfo: {
		account_information: 'Mijn accountgegevens',
		services: 'Mijn diensten',
		data_privacy: 'Mijn gegevens & privacy',
		benefits: 'Voordelen',
		company: 'Bedrijfsprofiel',
		branches: 'Vestigingen',
		users: 'Gebruikers',
		area_coverage: 'Servicegebieden',
		lead_program: 'Leads Voorkeuren',
		ein_program: 'Leads & Enphase Installateursnetwerk',
		ac_module_admin: 'AC Module Administratie',
		enphase_store: 'Enphase Store',
		product_warranty_extension: 'Uitbreiding van de productgarantie',
		lpp: 'Programma voor arbeidsbescherming',
		permit_assistance: 'Bijstand bij het verlenen van vergunningen',
		incentive_programs: "incentive-programma's",
		ein_merchandise: 'EIN Merchandise',
		financing: 'Financiering',
		training: 'Opleiding',
		collaterals: 'Collaterals',
		my_leads: 'Mijn Leads',
		lead_details: 'Offerteaanvraag Details',
		api_access: 'API-toegang',
		third_party: 'Rapportage door derden',
		profiles: 'Rasterprofielen',
		createBranch: 'Filiaal toevoegen',
		branch_profile: 'Filiaalprofiel',
		new: 'Nieuw',
		applications: 'Toepassingen',
		createUser: 'Nieuwe gebruiker toevoegen',
		user_profile: 'Gebruikersprofiel',
		platform_discounts: 'Platform voor Kortingen',
		account_details: 'Accountgegevens',
		preferences: 'Voorkeuren',
		change_password: 'Wijzig wachtwoord',
		account: 'Rekening',
		dashboard: 'Dashboard',
		systems: 'Systemen',
		admin: 'Admin',
		einDashboard: 'EIN Dashboard',
		einAdmin: 'EIN Admin',
		edit: 'Bewerk',
		failureMessage: 'Er is iets misgegaan, probeer het alsjeblieft nog eens.'
	}
};

export default accountInfo;
