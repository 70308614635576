import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Box, TextField, } from '@material-ui/core';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ContainedButton from '../../ContainedButton';
import { get, isEmpty } from 'lodash';
import I18n from './../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    header: {
        color: theme.palette.primary.dark,
        fontSize: theme.spacing(3.25),
        fontWeight: 'bold',
    },
    close: {
        opacity: 0.23,
        cursor: 'pointer',
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    commentText: {
        fontSize: theme.spacing(1.75),
        marginBottom: theme.spacing(1.5),
    },
    commentField: {
        marginBottom: theme.spacing(1.5),
    },
    displayFlex: {
        display: 'flex',
    },
    footerText: {
        fontStyle: 'italic',
        padding: theme.spacing(0.5, 1),
        opacity: 0.8,
    },
    dialogActions: {
        padding: theme.spacing(1, 3, 2, 3),
        justifyContent: 'end',
    },
    secondaryHeaderText: {
        fontSize: 14,
        opacity: 0.8,
    },
}); });
var ApprovalDialog = function (props) {
    var classes = useStyles();
    var open = props.open, onClose = props.onClose, handleApprove = props.handleApprove, application = props.application, approvalComments = props.approvalComments, setApprovalComments = props.setApprovalComments;
    var approvalDocuments = get(application, 'approval_documents', []) || [];
    return (_jsx(Fragment, { children: _jsxs(Dialog, { open: open, maxWidth: "sm", children: [_jsxs(DialogTitle, { children: [_jsxs(Box, { className: classes.dialogTitle, children: [_jsx(Typography, { className: classes.header, children: props.dialogHeader }), _jsx(IconButton, { onClick: onClose, children: _jsx("div", { role: "close", children: _jsx(CloseIcon, { className: classes.close, fontSize: "large" }) }) })] }), _jsx(Typography, { className: classes.secondaryHeaderText, children: props.secondaryHeaderText })] }), _jsxs(DialogContent, { children: [I18n.t('approvalDialog.documentApprove'), !isEmpty(approvalDocuments) &&
                            approvalDocuments.map(function (document, idx) {
                                return (_jsx(Typography, { className: classes.commentText, children: document.file_name }, "".concat(document.file_name).concat(idx)));
                            }), _jsxs(Box, { children: [_jsx(Typography, { className: classes.commentText, children: I18n.t('approvalDialog.specificComments') }), _jsx(TextField, { value: approvalComments, onChange: function (e) { return setApprovalComments(e.target.value); }, multiline: true, rows: 2, rowsMax: 16, fullWidth: true, variant: "outlined", className: classes.commentField })] })] }), _jsxs(DialogActions, { className: classes.dialogActions, children: [_jsx(ContainedButton, { disabled: props.approvalInProgress, text: I18n.t('approvalDialog.approveSend'), handleClick: handleApprove }), _jsx(Typography, { className: classes.footerText, children: props.footerText })] })] }) }));
};
export default ApprovalDialog;
