const header = {
  header: {
    logOut: 'Wyloguj',
    enphaseStore: 'Sklep Enphase',
    enphaseSupport: 'Obsługa klienta Enphase',
    comingSoon: 'Już wkrótce'
  }
};

export default header;
