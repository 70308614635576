const accountInfo = {
  accountInfo: {
    account_information: 'Informações da minha conta',
    services: 'Meus serviços',
    data_privacy: 'Meus dados e privacidade',
    benefits: 'Benefícios',
    company: 'Perfil da empresa',
    branches: 'Filiais',
    users: 'Usuários',
    area_coverage: 'Áreas de Serviço',
    lead_program: 'Preferências de Leads',
    ein_program: 'Leads e Enphase Installer Network',
    ac_module_admin: 'Administração de Módulo CA',
    training_dashboard: 'Treinamento',
    enphase_store: 'Enphase Store',
    product_warranty_extension: 'Extensão da garantia do produto',
    lpp: 'Programa de proteção ao trabalho',
    permit_assistance: 'Permissão de assistência',
    incentive_programs: 'Programas de incentivos',
    ein_merchandise: 'Mercadoria EIN',
    design_tool: 'Ferramenta de design e proposta',
    design_review_service: 'Serviço de revisão de design',
    bom: undefined,
    financing: 'Financiamento',
    training: 'Treinamento',
    collaterals: 'Colaterais',
    my_leads: 'Meus Leads',
    lead_details: 'Detalhes de solicitação de cotação',
    api_access: 'Acesso API',
    third_party: 'Relatórios de terceiros',
    profiles: 'Perfis de rede',
    createBranch: 'Adicionar filial',
    branch_profile: 'Perfil de filial',
    new: 'Novo',
    applications: 'Aplicações',
    createUser: 'Adicionar novo usuário',
    user_profile: 'Perfil de usuário',
    platform_discounts: 'Descontos de plataforma',
    account_details: 'Detalhes da conta',
    preferences: 'Preferências',
    change_password: 'Mudar senha',
    account: 'Conta',
    dashboard: 'Painel',
    systems: 'Sistemas',
    admin: 'Admin',
    einDashboard: 'Painel EIN',
    einAdmin: 'Admin EIN',
    edit: 'Editar',
    gotoSolargraf: 'Ir para Solargraf',
    createSolargrafAccountMessage: undefined,
    createSolargrafAccountNote: undefined,
    verificationText: 'Verificação',
    solargrafOtpMessage: undefined,
    enterOtpText: 'Digite a OTP',
    didntReceivedOtpMessage: 'Não recebeu a OTP?',
    resendOtp: 'Reenviar a OTP',
    verificationSuccess: 'Verificado com sucesso!',
    verificationFailure: 'Informação incorreta',
    otpExpired: 'A OTP expirou. Solicite uma nova OTP',
    otpSentSuccessfully: 'OTP enviada com sucesso',
    otpSentFailed: 'Falha ao enviar a OTP, tente novamente mais tarde',
    linkSolargrafTitle: 'Vincule sua conta Solargraf',
    getVerificationCodeText: 'Obtenha o código de verificação',
    linkSolargrafMessageOne: 'Sua conta Enlighten precisa estar vinculada à conta Solargraf para usar esta opção.',
    linkSolargrafMessageTwo: 'Uma verificação única de seu endereço de e-mail será feita para concluir esta etapa.'
  }
};

export default accountInfo;
