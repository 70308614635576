import React from 'react';

const SystemReporting = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(988 -624)">
        <rect fill="none" width="20" height="20" transform="translate(-988 624)" />
        <path
          fill="#335977"
          d="M.625,0h3.75A.625.625,0,0,1,5,.625H5a.625.625,0,0,1-.625.625H.625A.625.625,0,0,1,0,.625H0A.625.625,0,0,1,.625,0Z"
          transform="translate(-981.75 635.25)"
        />
        <path
          fill="#335977"
          d="M.625,0h6.25A.625.625,0,0,1,7.5.625h0a.625.625,0,0,1-.625.625H.625A.625.625,0,0,1,0,.625H0A.625.625,0,0,1,.625,0Z"
          transform="translate(-981.75 632.125)"
        />
        <path
          fill="#335977"
          d="M.625,0H2.5a.625.625,0,0,1,.625.625h0A.625.625,0,0,1,2.5,1.25H.625A.625.625,0,0,1,0,.625H0A.625.625,0,0,1,.625,0Z"
          transform="translate(-981.75 638.375)"
        />
        <path
          fill="#335977"
          d="M-970.5,627.875h-1.875v-.625a1.25,1.25,0,0,0-1.25-1.25h-5a1.25,1.25,0,0,0-1.25,1.25v.625h-1.875a1.25,1.25,0,0,0-1.25,1.25V642.25a1.25,1.25,0,0,0,1.25,1.25h11.25a1.25,1.25,0,0,0,1.25-1.25V629.125A1.25,1.25,0,0,0-970.5,627.875Zm-8.125-.625h5v2.5h-5Zm8.125,15h-11.25V629.125h1.875V631h7.5v-1.875h1.875Z"
          transform="translate(-1.875 -0.75)"
        />
      </g>
    </svg>
  );
};

export default SystemReporting;
