const accountInfo = {
	accountInfo: {
		account_information: 'Mes informations de compte',
		services: 'Mes services',
		data_privacy: 'Mes données et confidentialité',
		benefits: 'Avantages',
		company: 'Profil de la société',
		branches: 'Agences',
		users: 'Utilisateurs',
		area_coverage: 'Zone de service',
		lead_program: 'Préférences des prospects',
		ein_program: 'Prospects et réseau d’installateurs Enphase',
		ac_module_admin: 'AC Administration du module',
		enphase_store: 'Magasin Enphase',
		product_warranty_extension: 'Extension de garantie de produit',
		lpp: 'Programme de protection de la main-d’œuvre',
		permit_assistance: 'Assistance aux permis',
		incentive_programs: 'Programmes incitatifs',
		ein_merchandise: 'Marchandise EIN',
		financing: 'Financement',
		training: 'Formation',
		collaterals: 'Garanties',
		my_leads: 'Mes prospects',
		lead_details: 'Détails de la demande de devis',
		api_access: 'Accès API',
		third_party: 'Rapports de tiers',
		profiles: 'Profils réseaux',
		createBranch: 'Ajouter une agence',
		branch_profile: 'Profil de la succursale',
		new: 'Nouveau',
		applications: 'Applications',
		createUser: 'Ajouter un nouvel utilisateur',
		user_profile: 'Profil utilisateur',
		platform_discounts: 'Remise de plateforme',
		account_details: 'Détails du compte',
		preferences: 'Préférences',
		change_password: 'Changer le mot de passe',
		account: 'Compte',
		dashboard: 'Tableau de bord',
		systems: 'Systèmes',
		admin: 'Admin',
		einDashboard: 'Tableau de bord EIN',
		einAdmin: 'Admin EIN',
		edit: 'Modifier',
		failureMessage: 'Une erreur est survenue, veuillez réessayer'
	}
};

export default accountInfo;
