import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    footerPaperWrapper: {
        height: '78px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3.375),
    },
    submitButton: {
        // try to move this to theme.pallete.tertiary.main
        color: theme.tertiary.main,
        marginLeft: theme.spacing(3.375),
        '&:hover': {
            backgroundColor: '#d15d13',
        },
    },
    saveCloseButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: '#f2ece9',
        },
    },
    disableForm: {
        pointerEvents: 'none',
        opacity: 0.6,
    },
}); });
var Footer = function (props) {
    var classes = useStyles();
    var handleSubmit = props.handleSubmit, isSaveAndClose = props.isSaveAndClose, submitButtonText = props.submitButtonText, handleSaveClose = props.handleSaveClose, disableButtons = props.disableButtons;
    return (_jsxs(Paper, { elevation: 2, className: classes.footerPaperWrapper, children: [isSaveAndClose && (_jsx(Button, { variant: "outlined", color: "primary", className: clsx(classes.saveCloseButton, disableButtons && classes.disableForm), onClick: handleSaveClose, children: I18n.t('buttons.saveClose') })), _jsx(Button, { variant: "contained", color: "primary", className: clsx(classes.submitButton, disableButtons && classes.disableForm), onClick: handleSubmit, endIcon: _jsx(ChevronRightIcon, {}), children: submitButtonText })] }));
};
export default Footer;
