const common = {
  error: {
    unauthorized: 'Accès non autorisé',
    required: 'Requis'
  },
  common: {
    mandatory: 'Champs obligatoires'
  },
  utils: {
    dashboard: 'Tableau de bord',
    systems: 'Systèmes',
    account: 'Compte',
    services: 'Services',
    dataPrivacy: 'Données et confidentialité',
    support: 'Support',
    admin: 'Admin',
    defaultMessage: 'Pour télécharger des fichiers, veuillez désactiver le bloqueur de publicité de votre navigateur',
    signInMessage: 'Vous devez vous connecter ou vous inscrire avant de continuer',
    commonApiMessage: 'La demande n’a pu être traitée',
    adminNew: 'Admin (Nouveau)'
  },
  message: {
    yelpWaitMessage: 'Attendre que Yelp se mette à jour',
    googleWaitMessage: 'Attendre que l’identifiant Place de Google se mette à jour',
    successMessageUpload: 'Fichier chargé avec succès.',
    failureMessageUpload: 'Une erreur est survenue, veuillez réessayer',
    serviceAreasCreateSuccess: 'Zone d’action créée avec succès',
    serviceAreasUpdateSuccess: 'Zone d’action mise à jour avec succès',
    serviceAreasDeleteSuccess: 'Zone d’action supprimée avec succès',
    hardware_product: 'Produit matériel',
    rowsFailed: '%{failedCount} sur %{totalCount} lignes ont un défaut',
    accessRequestSuccess: 'Accès demandé avec succès'
  },
  buttons: {
    cancel: 'Annuler',
    edit: 'Modifier',
    update: 'METTRE À JOUR',
    updateAccount: 'Mettre à jour le compte',
    submit: 'Soumettre',
    submitUpperCase: 'SOUMETTRE',
    back: 'Retour',
    save: 'Enregistrer',
    show: 'Afficher',
    add: 'Ajouter',
    downloadSampleFile: 'Télécharger l’exemple de fichier',
    proceed: 'Proceed'
  }
};

export default common;
