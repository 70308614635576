import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import { GoToSolargrafPopup, LinkWithSolargrafPopup, RedirectionConfirmPopup } from '../index';
import { getToken, getSolargrafToken, reDirectTo } from '../../../utils/helper';
import {
  getSolargrafSession,
  verifySolargrafSession,
  setSolargrafPopupDetails,
  verifySolargrafEmail,
} from '../../../containers/Layouts/actions';
import SnakBar from '../../SnakBar';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1400,
    color: theme.palette.primary.main,
  },
  snakBarClass: {
    '& .MuiAlert-root': {
      marginTop: 'auto',
      marginBottom: theme.spacing(3),
    },
  },
}));

let errorMessageTimeInterval = null;

const SolargrafPopupFlow = (props) => {
  const classes = useStyles();
  const {
    enlightenManagerToken,
    solargrafToken,
    solargrafPopupDetails,
    accountDetail,
    setSolargrafAccess,
    solargrafAccess,
  } = props;
  const { linkWithSolargrafSuccess, solargrafAccessAction, startSolargrafPopupFlow } = solargrafPopupDetails;

  const token = getToken() || enlightenManagerToken;
  const sgToken = getSolargrafToken() || solargrafToken;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [newSGToken, setNewSGToken] = useState(sgToken);
  const [gotoSolargraf, setGotoSolargraf] = useState(false);
  const [linkWithSolargraf, setLinkWithSolargraf] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showRedirectionConfirmPopup, setShowRedirectionConfirmPopup] = useState(false);

  const getSolargrafSessionSuccess = (newSolargrafToken) => {
    setNewSGToken(newSolargrafToken);
    setShowLoader(false);
    setLinkWithSolargraf(false);
    setShowRedirectionConfirmPopup(true);
  };

  const setErrorMessage = () => {
    clearTimeout(errorMessageTimeInterval);
    setShowErrorMessage(true);
    setShowLoader(false);
    errorMessageTimeInterval = setTimeout(() => {
      setShowErrorMessage(false);
    }, 4000);
  };

  const getNewSolargrafSession = () => {
    props.getSolargrafSession({
      token,
      successCbk: (response) => getSolargrafSessionSuccess(get(response, 'token', '')),
      failureCbk: setErrorMessage,
    });
  };

  const navigateToSolargraf = () => {
    if (isEmpty(sgToken)) {
      getNewSolargrafSession();
    } else {
      props.verifySolargrafSession({
        solargrafToken: sgToken,
        successCbk: () => getSolargrafSessionSuccess(sgToken),
        failureCbk: () => getNewSolargrafSession(),
      });
    }
  };

  const solargrafPopupFlow = useCallback(() => {
    const linkWithSolargrafPopup = () => {
      setShowLoader(false);
      setGotoSolargraf(false);
      setLinkWithSolargraf(true);
    };
    const gotoSolargrafPopup = () => {
      setShowLoader(false);
      setLinkWithSolargraf(false);
      reDirectTo('/manager/dashboard/design-tool');
      // setGotoSolargraf(true);
    };

    setShowLoader(true);
    if (solargrafAccess) {
      if (solargrafAccessAction) {
        setShowLoader(false);
        solargrafAccessAction();
      } else {
        navigateToSolargraf();
      }
    } else {
      props.verifySolargrafEmail({ token, successCbk: linkWithSolargrafPopup, failureCbk: gotoSolargrafPopup });
    }
    props.setSolargrafPopupDetails({
      startSolargrafPopupFlow: false,
    });
  }, [props.setSolargrafPopupDetails, solargrafAccess, solargrafAccessAction, token, props.verifySolargrafEmail]);

  useEffect(() => {
    if (startSolargrafPopupFlow) {
      solargrafPopupFlow();
    }
  }, [startSolargrafPopupFlow, solargrafPopupFlow]);

  const handleLinkSolargrafSuccess = () => {
    setSolargrafAccess(true);
    if (linkWithSolargrafSuccess) {
      setLinkWithSolargraf(false);
      linkWithSolargrafSuccess();
    } else {
      navigateToSolargraf();
    }
  };

  return (
    <Fragment>
      {gotoSolargraf && (
        <GoToSolargrafPopup handleClose={() => setGotoSolargraf(false)} accountDetail={accountDetail} />
      )}
      {linkWithSolargraf && (
        <LinkWithSolargrafPopup
          handleClose={() => setLinkWithSolargraf(false)}
          onSuccess={handleLinkSolargrafSuccess}
        />
      )}
      {showRedirectionConfirmPopup && (
        <RedirectionConfirmPopup
          handleClose={() => setShowRedirectionConfirmPopup(false)}
          sgToken={newSGToken}
          token={token}
        />
      )}
      {showLoader && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {showErrorMessage && (
        <SnakBar
          className={classes.snakBarClass}
          severity={'error'}
          message={'Someting went wrong, please try again later'}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  solargrafToken: state.defaultReducer.solargrafToken,
  enlightenManagerToken: state.appReducer.enlightenManagerToken,
  solargrafPopupDetails: state.defaultReducer.solargrafPopupDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getSolargrafSession: (payload) => dispatch(getSolargrafSession(payload)),
  verifySolargrafSession: (payload) => dispatch(verifySolargrafSession(payload)),
  setSolargrafPopupDetails: (payload) => dispatch(setSolargrafPopupDetails(payload)),
  verifySolargrafEmail: (payload) => dispatch(verifySolargrafEmail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolargrafPopupFlow);
