/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { call, take, put, takeLeading } from 'redux-saga/effects';
import { get, putMethod, post } from '../../../../utils/api';
import settings from '../../../../utils/settings';
// import { getStateIdForAPIMap, filterModelData } from './helper';
// import { localeMoment as moment } from '../../components/common/moment';
// import { get as lodashGet } from '../../utils/lodash';
// import { getToken, constructQueryString } from '../../utils/helper';

import {
  GET_LEADS_REQUEST,
  GET_LEADS_SUCCESS,
  GET_REQUEST_FAILURE,
  PUT_ACCEPT_LEAD_REQUEST,
  ACCEPT_LEAD_SUCCESS,
  GET_LEAD_DETAILS_REQUEST,
  LEAD_DETAILS_SUCCESS,
  POST_CREATE_QUOTE_REQUEST,
  POST_REQUEST_FAILURE,
  CREATE_QUOTE_SUCCESS,
  GET_QUOTE_S3_URL_REQUEST,
  GET_QUOTE_S3_URL_SUCCESS,
  PUT_QUOTE_UPLOAD_TO_S3_REQUEST,
  GET_QUOTE_FILE_FROM_S3_REQUEST,
  GET_PANEL_MFG_REQUEST,
  GET_PANEL_MFG_SUCCESS,
  GET_PANEL_MODEL_SUCCESS,
  PANEL_ACM_MANUFACTURERS,
  LEAD_CONTRACT_SHARED,
  GET_BOM_LIST_REQUEST,
  GET_BOM_LIST_SUCCESS,
  LEAD_REJECTED,
  GET_LEAD_S3_IMAGE_FILE_URL_REQUEST,
  LEAD_S3_IMAGE_FILE_URL_SUCCESS,
  GET_CART_URL_REQUEST,
  GET_CART_URL_REQUEST_SUCCESS,
  GET_UPDATED_TAX_AND_SHIPMENT_REQUEST,
  GET_MASTER_APPLIANCES_REQUEST,
  GET_MASTER_APPLIANCES_SUCCESS,
  SITE_SEARCH_REQUEST_LEAD,
  SITE_SEARCH_REQUEST_LEAD_SUCCESS,
  SITE_SEARCH_REQUEST_LEAD_FAILURE,
  SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST,
  SEARCH_FOR_SITE_ID_IN_LEADS_SUCCESS,
  SEARCH_FOR_SITE_ID_IN_LEADS_FAILURE,
  LINK_SITE_WITH_LEAD_REQUEST,
  LINK_SITE_WITH_LEAD_REQUEST_FAILURE,
  GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST,
  GET_MESSAGES_LIST_REQUEST,
  GET_MESSAGES_LIST_SUCCESS,
  GET_MESSAGES_LIST_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  GET_MESSAGE_FILE_S3_URL_REQUEST,
  PUT_S3_URL_REQUEST,
  GET_APPOINTMENT_HISTORY_FAILURE,
  GET_APPOINTMENT_HISTORY_SUCCESS,
  GET_APPOINTMENT_HISTORY_REQUEST,
  ACCEPT_APPOINTMENT_REQUEST,
  ACCEPT_APPOINTMENT_SUCCESS,
  ACCEPT_APPOINTMENT_FAILURE,
  CANCEL_APPOINTMENT_REQUEST,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_FAILURE,
  REJECT_APPOINTMENT_REQUEST,
  REJECT_APPOINTMENT_FAILURE,
  REJECT_APPOINTMENT_SUCCESS,
  LEAD_REQUEST_APPOINTMENT_REQUEST,
  LEAD_REQUEST_APPOINTMENT_SUCCESS,
  LEAD_REQUEST_APPOINTMENT_FAILURE,
  SYSTEM_DETAILS_REQUEST,
  SYSTEM_DETAILS_FAILURE,
  SYSTEM_DETAILS_SUCCESS,
  UNLINK_SITE_WITH_LEAD_REQUEST,
  UNLINK_SITE_WITH_LEAD_FAILURE,
  UNLINK_IN_PROGRESS,
  GET_FINANCE_PROVIDERS_REQUEST,
  GET_FINANCE_PROVIDERS_SUCCESS,
  GET_FINANCE_PROVIDERS_FAILURE,
  GET_PRESIGNED_URL_REQUEST,
  CONTRACT_FILE_UPLOAD_TO_S3_REQUEST,
  GET_CONTRACT_LEAD_JOURNEY_REQUEST,
  GET_QUOTE_PROPOSAL_REQUEST,
  GET_QUOTE_PROPOSAL_FAILURE,
  GET_CONTRACT_LEAD_JOURNEY_SUCCESS,
  CREATE_LEAD_REQUEST,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAILURE,
  SITE_SEARCH_LEAD_REQUEST,
  SITE_SEARCH_LEAD_SUCCESS,
  SITE_SEARCH_LEAD_FAILURE,
  GET_LEAD_FROM_JOURNEY_ID_REQUEST,
  GET_LEAD_FROM_JOURNEY_ID_SUCCESS,
  GET_LEAD_FROM_JOURNEY_ID_FAILURE,
  CHECK_EXISTING_EMAIL_REQUEST,
  CHECK_EXISTING_EMAIL_SUCCESS,
  CHECK_EXISTING_EMAIL_FAILURE,
  IS_ACCOUNT_IN_ENLIGHTEN_REQUEST,
  IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS,
  GET_SOLARGRAF_FINANCIALS_REQUEST,
  GET_SOLARGRAF_FINANCIALS_SUCCESS,
  GET_SOLARGRAF_FINANCIALS_FAILURE,
} from './constants';
import { getToken } from '../../../../utils/helper';

// export function* getLeadsFlow() {
//   while (true) {
//     const request = yield take(GET_LEADS_REQUEST);
//     const { companyId, pageNum, recordCount, filterParams = {}, criteria = '', successCb = () => {} } = request.payload;
//     let { sortColumn = '', sortOrder = '' } = request.payload;
//     const {
//       ho_name = '',
//       zip = '',
//       address = '',
//       l_generated_by = '',
//       l_requirement = '',
//       l_type = '',
//       q_status = ''
//     } = filterParams;
//     try {
//       let response;
//       const queryObject = {
//         installer_id: companyId,
//         page: pageNum,
//         size: recordCount,
//         ho_name: ho_name,
//         zip: zip,
//         address: address,
//         generated_by: l_generated_by,
//         requirement: l_requirement,
//         user_type: l_type,
//         status: q_status
//       };
//       let URL = `installers/leads${constructQueryString(queryObject)}`;
//       if (sortColumn !== '') {
//         if (sortColumn === 'l_generated_by') sortColumn = 'generated_by';
//         if (sortColumn === 'l_requirement') sortColumn = 'requirement';
//         else if (sortColumn === 'l_type') sortColumn = 'user_type';
//         else if (sortColumn === 'q_status') sortColumn = 'status';
//         URL += `&sort_by=${sortColumn}&sort_order=${sortOrder}`;
//       }
//       if (criteria !== '') {
//         URL += `&criteria=${criteria}`;
//       }
//       response = yield call(get, {
//         url: settings.digiCoreUrl(URL),
//         withBearer: true
//       });
//       const { success, status, result } = response;
//       if (success && status === 200) {
//         yield put({
//           type: GET_LEADS_SUCCESS,
//           leads: result.content,
//           totalElements: result.totalElements,
//           totalNewLeads: result.new_leads_count,
//           currentlySending: false,
//           message: ''
//         });
//         successCb();
//       } else {
//         yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
//       }
//     } catch (error) {
//       yield put({ type: GET_REQUEST_FAILURE, message: error });
//     }
//   }
// }

// export function* acceptLeadFlow() {
//   while (true) {
//     const request = yield take(PUT_ACCEPT_LEAD_REQUEST);
//     const {
//       companyId,
//       lead_id,
//       journey_type,
//       state,
//       rReason,
//       successCb = () => {},
//       dateOfCompletion,
//       lead_from_own_ho,
//       inactive_reason = ''
//     } = request.payload;
//     try {
//       let rURL = settings.digiCoreUrl(
//         `installers/lead_state?state=${state}&lead_id=${lead_id}&installer_id=${companyId}&journey_type=${journey_type}&inactive_reason=${inactive_reason}`
//       );
//       if (dateOfCompletion) {
//         rURL += `&completion_date=${encodeURIComponent(dateOfCompletion)}`;
//       }
//       if (state === LEAD_REJECTED.api_map) {
//         rURL += `&rejection_reason=${rReason}`;
//       }
//       let response;
//       response = yield call(putMethod, {
//         url: rURL,
//         withBearer: true
//       });
//       const { success, status, result } = response;
//       if (success && status === 200) {
//         const sId = getStateIdForAPIMap(state);
//         let msg = '';
//         let msgTitle = '';
//         if (sId === 2) {
//           msgTitle = 'Lead Accepted';
//           if (lead_from_own_ho) {
//             msg = 'You may now contact the homeowner and share a quote with them.';
//           } else {
//             msg = "Homeowner's details are now available. You may contact them and share a quote.";
//           }
//         } else if (state === LEAD_CONTRACT_SHARED.api_map) {
//           msgTitle = 'Confirmation required from homeowner';
//           msg =
//             'We’ve alerted the homeowner. This stage will be marked as complete only once the homeowner confirms that they have signed the contract with you.';
//         } else {
//           msg = 'Lead updated successfully.';
//         }
//         yield put({
//           type: ACCEPT_LEAD_SUCCESS,
//           companyId: result.installer_id,
//           lead_id: result.lead_id,
//           message: msg,
//           messageTitle: msgTitle,
//           leadStatus: result.is_active
//         });
//         successCb();
//       } else {
//         yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
//       }
//     } catch (error) {
//       yield put({ type: GET_REQUEST_FAILURE, message: error });
//     }
//   }
// }

export function* getLeadDetailsFlowWithCallback() {
  while (true) {
    const request = yield take(GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST);
    const { companyId, lead_id, journey_type, successCb, failureCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`installers/leads/${lead_id}?installer_id=${companyId}&journey_type=${journey_type}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: LEAD_DETAILS_SUCCESS,
          leadDetails: result,
          message: '',
        });
        successCb(result);
      } else {
        failureCb();
      }
    } catch (error) {
      failureCb();
    }
  }
}

// needed
export function* getLeadDetailsFlow() {
  while (true) {
    const request = yield take(GET_LEAD_DETAILS_REQUEST);
    const { companyId, lead_id, journey_type, redirect = () => {}, successCbk = () => {} } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`installers/leads/${lead_id}?installer_id=${companyId}&journey_type=${journey_type}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        if (result.current_state.state_id <= 0) {
          yield put({
            type: GET_REQUEST_FAILURE,
            message: 'You are not authorized to view this request',
          });
        } else {
          yield put({
            type: LEAD_DETAILS_SUCCESS,
            leadDetails: result,
            message: '',
            unlinkInProgress: false,
            leadStatus: result.is_active,
          });
          successCbk(result);
        }
      } else if (status === 401) {
        yield put({
          type: GET_REQUEST_FAILURE,
          message: "Oops! You don't have access to this lead. Please login with the correct credentials",
        });
        redirect();
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

// export function* createQuoteFlow() {
//   while (true) {
//     const request = yield take(POST_CREATE_QUOTE_REQUEST);
//     const { companyId, lead_id, qDetails, journey_type, successCb, send_quote_email = true } = request.payload;
//     const date = moment();
//     let message = '';
//     let messageTitle = '';
//     if (send_quote_email) {
//       message = 'Your Quote has been sent to the Homeowner. You may now contact them to discuss it further.';
//       messageTitle = 'Quote sent to homeowner';
//     }
//     try {
//       let response;
//       const queryObject = {
//         installer_id: companyId,
//         lead_id: lead_id,
//         journey_type: journey_type,
//         completion_date: date.format('YYYY-MM-DDTHH:mm:ssZZ'),
//         send_quote_email: send_quote_email,
//       };
//       response = yield call(post, {
//         url: settings.digiCoreUrl(`quotes${constructQueryString(queryObject)}`),
//         body: qDetails,
//         withBearer: true,
//       });
//       const { success, status, result } = response;
//       if (success && status === 200) {
//         yield put({
//           type: CREATE_QUOTE_SUCCESS,
//           qDetails: result,
//           message: message,
//           messageTitle: messageTitle,
//         });
//         successCb();
//       } else {
//         yield put({ type: POST_REQUEST_FAILURE, message: result.message || result.messages });
//       }
//     } catch (error) {
//       yield put({ type: POST_REQUEST_FAILURE, message: error });
//     }
//   }
// }

export function* quoteFileFlow() {
  while (true) {
    const request = yield take(GET_QUOTE_S3_URL_REQUEST);
    const { fileName, methodType, successCb, failureCb } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`quotes/presignedurl?file_name=${fileName}&http_method=${methodType}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_QUOTE_S3_URL_SUCCESS,
          preSignedS3Url: result,
        });
        successCb(result);
      } else {
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCb();
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

let fileLoading = false;
export function* leadImagesFileFlow() {
  while (true) {
    const request = yield take(GET_LEAD_S3_IMAGE_FILE_URL_REQUEST);
    const { fileName, methodType, successCb, failureCb } = request.payload;
    if (!fileLoading) {
      fileLoading = true;
      try {
        let response;
        response = yield call(get, {
          url: settings.digiCoreUrl(`leads/presignedurl?file_name=${fileName}&http_method=${methodType}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        fileLoading = false;
        if (success && status === 200) {
          yield put({
            type: LEAD_S3_IMAGE_FILE_URL_SUCCESS,
            preSignedS3Url: result,
          });
          successCb(result);
        } else {
          failureCb();
          yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        }
      } catch (error) {
        fileLoading = false;
        failureCb();
        yield put({ type: GET_REQUEST_FAILURE, message: error });
      }
    }
  }
}

export function* quoteUploadToS3Flow() {
  while (true) {
    const request = yield take(PUT_QUOTE_UPLOAD_TO_S3_REQUEST);
    const { preSignedS3Url, fName, fileObj, successCbS3, failureCbS3 } = request.payload;

    try {
      var formData = new FormData();
      formData.append('data', fileObj, fName);
      let contentDisposition = 'attachment; filename=' + fName;
      var requestOptions = {
        method: 'PUT',
        body: fileObj,
        headers: {
          'Content-Type': fileObj.type,
          'Content-Disposition': contentDisposition,
        },
      };
      // var requestOptions = {
      //   method: 'PUT',
      //   body: formData
      // };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        // console.log(data);
        const { status } = data;
        if (status === 200) {
          successCbS3();
        } else {
          failureCbS3();
        }
      });
    } catch (error) {
      yield put({ type: POST_REQUEST_FAILURE, message: error });
      failureCbS3();
    }
  }
}

export function* quoteDownloadFromS3Flow() {
  while (true) {
    const request = yield take(GET_QUOTE_FILE_FROM_S3_REQUEST);
    const { preSignedS3Url, successCbS3, failureCbS3 } = request.payload;

    try {
      var requestOptions = {
        method: 'GET',
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3(data);
        } else {
          failureCbS3(status === 404);
        }
      });
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCbS3(false);
    }
  }
}

export function* getPanelMfgFlow(payload) {
  while (true) {
    const request = yield take(GET_PANEL_MFG_REQUEST);
    const { p_type } = request.payload;
    try {
      if (p_type === 'acm') {
        yield put({
          type: GET_PANEL_MFG_SUCCESS,
          panelMfg: PANEL_ACM_MANUFACTURERS,
        });
      } else {
        let response;
        response = yield call(get, {
          url: settings.digiCoreUrl(`enl/pv_makes`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: GET_PANEL_MFG_SUCCESS,
            panelMfg: result,
          });
        } else {
          yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        }
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

// export function* getPanelModelFlow(payload) {
//   while (true) {
//     const request = yield take(GET_PANEL_MFG_REQUEST);
//     const { p_type, mfg_id, mfg_name } = request.payload;
//     try {
//       if (p_type === 'acm') {
//         let pModels = filterModelData(mfg_name, p_type);
//         yield put({
//           type: GET_PANEL_MODEL_SUCCESS,
//           panelModel: pModels,
//         });
//       } else {
//         if (mfg_id) {
//           let response;
//           response = yield call(get, {
//             url: settings.digiCoreUrl(`enl/pv_modules?maker_id=${mfg_id}`),
//             withBearer: true,
//           });
//           const { success, status, result } = response;
//           if (success && status === 200) {
//             yield put({
//               type: GET_PANEL_MODEL_SUCCESS,
//               panelModel: result,
//             });
//           } else {
//             yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
//           }
//         }
//       }
//     } catch (error) {
//       yield put({ type: GET_REQUEST_FAILURE, message: error });
//     }
//   }
// }

export function* getBOMListFlow(payload) {
  while (true) {
    const request = yield take(GET_BOM_LIST_REQUEST);
    const { pDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      let url = 'bom/getBOMList?';
      response = yield call(post, {
        url: settings.digiCoreUrl(url),
        // url: 'http://localhost:8090/api/v1' + url,
        withBearer: true,
        body: pDetails,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_BOM_LIST_SUCCESS,
          bom_items: result.bom_items,
          bomAPIResponse: result,
        });
        successCb(result.bom_items, result);
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getCartUrlFlow(payload) {
  while (true) {
    const request = yield take(GET_CART_URL_REQUEST);
    const { skuDetails, successCb, failureCb } = request.payload;
    try {
      let response;
      let url = 'store/addToCart';
      response = yield call(post, {
        url: settings.digiCoreUrl(url),
        withBearer: true,
        body: { skuDetails: skuDetails },
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_CART_URL_REQUEST_SUCCESS,
        });
        successCb(result);
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getUpdateTaxAndShipmentFlow(payload) {
  while (true) {
    const request = yield take(GET_UPDATED_TAX_AND_SHIPMENT_REQUEST);
    const { zipcode, bomItems, successCb, failureCb } = request.payload;
    try {
      let response;
      let url = 'store/getTaxAndShipmentInfo';
      response = yield call(post, {
        url: settings.digiCoreUrl(url),
        withBearer: true,
        body: { bom: bomItems, zipcode: zipcode },
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result);
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
        failureCb();
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* getMasterAppliancesFlow() {
  while (true) {
    const request = yield take(GET_MASTER_APPLIANCES_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`appliances`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_MASTER_APPLIANCES_SUCCESS,
          masterAppliances: result,
          currentlySending: false,
          message: '',
        });
      } else {
        yield put({ type: GET_REQUEST_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_REQUEST_FAILURE, message: error });
    }
  }
}

// needed
export function* getSiteSearchLeadFlow() {
  while (true) {
    const request = yield take(SITE_SEARCH_REQUEST_LEAD);
    try {
      let response;
      const { searchValue, searchType } = request.payload;
      response = yield call(post, {
        url: settings.getDrsUrl(`proxyApi/request`),
        body: {
          url: [settings.getApiUrl('api_internal/sites/search')],
          method: ['GET'],
          search_value: [searchValue.toString()],
          search_type: [searchType],
        },
        headers: { 'Content-Type': 'application/json', Authorization: getToken() },
      });
      if (response.success) {
        yield put({ type: SITE_SEARCH_REQUEST_LEAD_SUCCESS, searchList: response.result });
      } else {
        yield put({ type: SITE_SEARCH_REQUEST_LEAD_FAILURE, error: response.result.message });
      }
    } catch (error) {
      yield put({ type: SITE_SEARCH_REQUEST_LEAD_FAILURE, error: error });
    }
  }
}

// needed
export function* searchForSiteIdInLeadsFlow() {
  while (true) {
    const request = yield take(SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST);
    try {
      let response;
      const { site_id, successCb, failureCb } = request.payload;
      response = yield call(get, {
        url: settings.digiCoreUrl(`leads/isLeadPresent/${site_id}`),
        withBearer: true,
      });
      if (response.success) {
        yield put({ type: SEARCH_FOR_SITE_ID_IN_LEADS_SUCCESS, lead_exists: response.result.lead_exists });
        successCb(response.result);
      } else {
        yield put({ type: SEARCH_FOR_SITE_ID_IN_LEADS_FAILURE, error: response.result.message });
        failureCb(response.result.message);
      }
    } catch (error) {
      yield put({ type: SEARCH_FOR_SITE_ID_IN_LEADS_FAILURE, error: error });
    }
  }
}

// needed
export function* linkSiteWithLeadFlow(payload) {
  while (true) {
    const request = yield take(LINK_SITE_WITH_LEAD_REQUEST);
    const { site_id, lead_id, installer_lead_journey_id, successCb, failureCb } = request.payload;
    try {
      let response;
      let url = 'leads/updateSiteForLead';
      response = yield call(post, {
        url: settings.digiCoreUrl(url),
        withBearer: true,
        body: { site_id: site_id, lead_id: lead_id, installer_lead_journey_id: installer_lead_journey_id },
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result);
      } else {
        yield put({ type: LINK_SITE_WITH_LEAD_REQUEST_FAILURE, message: result.message || result.messages });
        failureCb();
      }
    } catch (error) {
      yield put({ type: LINK_SITE_WITH_LEAD_REQUEST_FAILURE, message: error });
      failureCb();
    }
  }
}

export function* unlinkSiteWithLeadFlow(payload) {
  while (true) {
    const request = yield take(UNLINK_SITE_WITH_LEAD_REQUEST);
    const { lead_id } = request.payload;
    try {
      let response;
      let url = 'leads/unlinkSiteForLead';
      response = yield call(putMethod, {
        url: settings.digiCoreUrl(url),
        withBearer: true,
        body: { lead_id: lead_id },
      });
      const { success, status, result } = response;
      if (!(success && status === 200)) {
        yield put({ type: UNLINK_SITE_WITH_LEAD_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: UNLINK_SITE_WITH_LEAD_FAILURE, message: error });
    }
  }
}

// export function* getLeadMessageListFlow() {
//   while (true) {
//     const request = yield take(GET_MESSAGES_LIST_REQUEST);
//     try {
//       let response;
//       const { lead_id, journey_type, installer_id, cbk = () => {} } = request.payload;
//       response = yield call(get, {
//         url: settings.digiCoreUrl(
//           `message_history?lead_id=${lead_id}&journey_type=${journey_type}&installer_id=${installer_id}&sort_by=message_date&sort_order=asc`
//         ),
//         withBearer: true,
//       });
//       if (response.success) {
//         const messageList = (response && response.result && response.result.message_history) || [];
//         // successCb();
//         yield put({
//           type: GET_MESSAGES_LIST_SUCCESS,
//           messageList,
//           unreadMessageCount: lodashGet(response, 'result.unread_msg_count'),
//         });
//         cbk();
//       }
//     } catch (error) {
//       yield put({ type: GET_MESSAGES_LIST_FAILURE, message: error });
//     }
//   }
// }

export function* sendLeadMessage() {
  while (true) {
    const request = yield take(SEND_MESSAGE_REQUEST);
    const { body, cbk } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.digiCoreUrl(`messages`),
        withBearer: true,
        ...body,
      });
      const { success, status } = response;
      if (success && status === 201) {
        yield put({ type: SEND_MESSAGE_SUCCESS });
        cbk();
      }
    } catch (error) {
      yield put({ type: SEND_MESSAGE_FAILURE });
      cbk();
    }
  }
}

let messageFileLoading = false;
export function* leadMessagesFileFlow() {
  while (true) {
    const request = yield take(GET_MESSAGE_FILE_S3_URL_REQUEST);
    const { fileName, methodType, successCb, failureCb } = request.payload;
    if (!messageFileLoading) {
      messageFileLoading = true;
      try {
        let response;
        response = yield call(get, {
          url: settings.digiCoreUrl(`leads/presignedurl?file_name=${fileName}&http_method=${methodType}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        messageFileLoading = false;
        if (success && status === 200) {
          successCb(result);
        } else {
          failureCb();
        }
      } catch (error) {
        messageFileLoading = false;
        failureCb();
      }
    }
  }
}

let putS3loading = false;
// eslint-disable-next-line require-yield
function* uploadLeadMessagesToS3Flow(action) {
  if (!putS3loading) {
    putS3loading = true;
    const { preSignedS3Url, fName, fileObj, successCbS3, failureCbS3, isDownload = false } = action.payload;
    try {
      var requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': isDownload ? 'application/octet-stream' : fileObj.type },
        body: fileObj,
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3();
        } else {
          failureCbS3();
        }
        putS3loading = false;
      });
    } catch (error) {
      failureCbS3();
      putS3loading = false;
    }
  }
}

export function* watchLastPutS3Flow() {
  yield takeLeading(PUT_S3_URL_REQUEST, uploadLeadMessagesToS3Flow);
}

export function* getLeadAppointmentHistoryFlow() {
  while (true) {
    const request = yield take(GET_APPOINTMENT_HISTORY_REQUEST);
    try {
      let response;
      const { lead_id, journey_type, installer_id, cbk = () => {} } = request.payload;
      response = yield call(get, {
        url: settings.digiCoreUrl(
          `appointment_history?lead_id=${lead_id}&journey_type=${journey_type}&installer_id=${installer_id}`
        ),
        withBearer: true,
      });
      if (response.success) {
        const appointmentHistoryList = (response && response.result && response.result.appointment_history) || [];
        yield put({
          type: GET_APPOINTMENT_HISTORY_SUCCESS,
          appointmentHistoryList,
        });
        cbk();
      }
    } catch (error) {
      yield put({ type: GET_APPOINTMENT_HISTORY_FAILURE, message: error });
    }
  }
}

export function* leadAcceptAppointmentFlow() {
  while (true) {
    const request = yield take(ACCEPT_APPOINTMENT_REQUEST);

    const { appointmentId, body, cbk, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(putMethod, {
        url: settings.digiCoreUrl(`accept_appointment/${appointmentId}`),
        withBearer: true,
        body,
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({ type: ACCEPT_APPOINTMENT_SUCCESS });
        cbk();
      } else if (status === 400) {
        yield put({ type: LEAD_REQUEST_APPOINTMENT_FAILURE });
        failureCb();
      }
    } catch (error) {
      yield put({ type: ACCEPT_APPOINTMENT_FAILURE });
      failureCb();
    }
  }
}

export function* leadCancelAppointmentFlow() {
  while (true) {
    const request = yield take(CANCEL_APPOINTMENT_REQUEST);
    const { appointmentId, body, cbk, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(putMethod, {
        url: settings.digiCoreUrl(`cancel_appointment/${appointmentId}`),
        withBearer: true,
        body,
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({ type: CANCEL_APPOINTMENT_SUCCESS });
        cbk();
      } else if (status === 400) {
        yield put({ type: LEAD_REQUEST_APPOINTMENT_FAILURE });
        failureCb();
      }
    } catch (error) {
      yield put({ type: CANCEL_APPOINTMENT_FAILURE });
      failureCb();
    }
  }
}

export function* leadRejectAppointmentFlow() {
  while (true) {
    const request = yield take(REJECT_APPOINTMENT_REQUEST);
    const { appointmentId, body, cbk, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(putMethod, {
        url: settings.digiCoreUrl(`reject_appointment/${appointmentId}`),
        withBearer: true,
        body,
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({ type: REJECT_APPOINTMENT_SUCCESS });
        cbk();
      } else if (status === 400) {
        yield put({ type: LEAD_REQUEST_APPOINTMENT_FAILURE });
        failureCb();
      }
    } catch (error) {
      yield put({ type: REJECT_APPOINTMENT_FAILURE });
      failureCb();
    }
  }
}

export function* leadRequestAppointmentFlow() {
  while (true) {
    const request = yield take(LEAD_REQUEST_APPOINTMENT_REQUEST);

    const { body, cbk, failureCb = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.digiCoreUrl(`request_appointment`),
        withBearer: true,
        body,
      });
      const { success, status } = response;
      if (success && status === 200) {
        yield put({ type: LEAD_REQUEST_APPOINTMENT_SUCCESS });
        cbk();
      } else if (status === 400) {
        yield put({ type: LEAD_REQUEST_APPOINTMENT_FAILURE });
        failureCb();
      }
    } catch (error) {
      yield put({ type: LEAD_REQUEST_APPOINTMENT_FAILURE });
      failureCb();
    }
  }
}

// needed
export function* getSystemDetails() {
  while (true) {
    const request = yield take(SYSTEM_DETAILS_REQUEST);
    const { siteId, systemNotAvailableCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`systems/system_platform_details?site_id=${siteId}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: SYSTEM_DETAILS_SUCCESS, systemDetails: result });
      } else if (status === 404) {
        yield put({ type: UNLINK_IN_PROGRESS, unlinkInProgress: true });
        systemNotAvailableCbk();
      } else {
        yield put({ type: SYSTEM_DETAILS_FAILURE, error: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: SYSTEM_DETAILS_FAILURE, error });
    }
  }
}

export function* getFinanceProvidersFlow() {
  while (true) {
    yield take(GET_FINANCE_PROVIDERS_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`finance_providers`),
        withBearer: true,
      });
      if (response.success) {
        const financeProvidersList = (response && response.result) || [];
        yield put({
          type: GET_FINANCE_PROVIDERS_SUCCESS,
          financeProvidersList,
        });
      }
    } catch (error) {
      yield put({ type: GET_FINANCE_PROVIDERS_FAILURE, message: error });
    }
  }
}
export function* getContractPresignedURL() {
  while (true) {
    const request = yield take(GET_PRESIGNED_URL_REQUEST);
    const { file_name, method_type = 'PUT', successCbk, failureCbk } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(
          `documents/presignedurl?folder_name=documents&file_name=${file_name}&http_method=${method_type}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCbk(result);
      } else {
        failureCbk();
      }
    } catch (error) {
      failureCbk();
    }
  }
}

export function* uploadContractToS3() {
  while (true) {
    const request = yield take(CONTRACT_FILE_UPLOAD_TO_S3_REQUEST);
    const {
      preSignedS3Url,
      fName,
      fileObj,
      successCbkS3,
      failureCbkS3,
      enableDirectDownload = false,
    } = request.payload;

    try {
      var formData = new FormData();
      formData.append('data', fileObj, fName);
      var requestOptions = {
        method: 'PUT',
        body: fileObj,
        headers: { 'Content-Type': enableDirectDownload ? 'application/octet-stream' : fileObj.type },
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbkS3(data);
        } else {
          failureCbkS3();
        }
      });
    } catch (error) {
      failureCbkS3();
    }
  }
}

export function* saveContractFileDetailsInLeadJourney() {
  while (true) {
    const request = yield take(GET_CONTRACT_LEAD_JOURNEY_REQUEST);
    const { fileDetails, journey_id, sendToHomeowner, dateOfCompletion, successCb, failureCb } = request.payload;
    const bodyObject = {
      contract_doc: fileDetails,
      lead_journey_id: journey_id,
      share_to_ho: sendToHomeowner,
      completion_date: dateOfCompletion,
    };
    try {
      let response;
      response = yield call(putMethod, {
        url: settings.digiCoreUrl(`installers/save_contract_document`),
        withBearer: true,
        body: bodyObject,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCb(result);
        yield put({
          type: GET_CONTRACT_LEAD_JOURNEY_SUCCESS,
          message:
            'We’ve alerted the homeowner. This stage will be marked as complete only once the homeowner confirms that they have signed the contract with you.',
          messageTitle: 'Confirmation required from homeowner',
        });
      } else {
        failureCb();
      }
    } catch (error) {
      failureCb();
    }
  }
}

// export function* getQuoteProposal() {
//   while (true) {
//     const request = yield take(GET_QUOTE_PROPOSAL_REQUEST);
//     const { file, tool, fileName, successCbk, failureCbk } = request.payload;
//     try {
//       var formData = new FormData();
//       formData.append('file', file);
//       formData.append('tool', tool);
//       formData.append('fileName', fileName);
//       var requestOptions = {
//         method: 'POST',
//         body: formData,
//         headers: { Authorization: `Bearer ${getToken()}` },
//       };

//       fetch(settings.digiCoreUrl(`quotes/proposal`), requestOptions).then(function (data) {
//         const { status } = data;
//         data.json().then((response) => {
//           if (status === 200) {
//             successCbk(response);
//           } else {
//             failureCbk();
//           }
//         });
//       });
//     } catch (error) {
//       failureCbk();
//     }
//   }
// }

export function* createSelfLeadFlow() {
  while (true) {
    const request = yield take(CREATE_LEAD_REQUEST);
    const { leadObject, successCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.digiCoreUrl(`create_self_gen_lead`),
        body: leadObject,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: CREATE_LEAD_SUCCESS,
          message: '',
        });
        successCbk(result.lead_journey);
      } else {
        yield put({ type: CREATE_LEAD_FAILURE, error: response.result.message });
      }
    } catch (error) {
      yield put({ type: CREATE_LEAD_FAILURE, error: error });
    }
  }
}

// export function* getSiteSearchLeadsFlow() {
//   while (true) {
//     const request = yield take(SITE_SEARCH_LEAD_REQUEST);
//     try {
//       let response;
//       const { searchValue = '' } = request.payload;
//       response = yield call(get, {
//         url: settings.getApiUrl(
//           `api_internal/sites?columns=system_id,name,city,state,system_id,zip,owner_info,address,country&limit=10&page=1&site[term][text]=${searchValue}&site[stage][text]=all`
//         ),
//         headers: { Authorization: getToken() },
//       });
//       if (response.success) {
//         yield put({ type: SITE_SEARCH_LEAD_SUCCESS, siteSearchList: response.result.sites });
//       } else {
//         yield put({ type: SITE_SEARCH_LEAD_FAILURE, error: response.result.message });
//       }
//     } catch (error) {
//       yield put({ type: SITE_SEARCH_LEAD_FAILURE, error: error });
//     }
//   }
// }

// needed
export function* getLeadFromJourneyId() {
  while (true) {
    const request = yield take(GET_LEAD_FROM_JOURNEY_ID_REQUEST);
    const { leadJourneyId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`installers/lead_details_by_journey_id?lead_journey_id=${leadJourneyId}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_LEAD_FROM_JOURNEY_ID_SUCCESS, leadDetails: result });
      } else {
        yield put({ type: GET_LEAD_FROM_JOURNEY_ID_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_LEAD_FROM_JOURNEY_ID_FAILURE });
    }
  }
}

export function* checkExistingEmailFlow() {
  while (true) {
    const request = yield take(CHECK_EXISTING_EMAIL_REQUEST);
    const { emailObject, successCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.digiCoreUrl(`users/validate_lead`),
        body: emailObject,
        withBearer: true,
      });
      const { status, result } = response;
      if (status === 200) {
        successCbk(result);
        yield put({ type: CHECK_EXISTING_EMAIL_SUCCESS });
      } else {
        yield put({ type: CHECK_EXISTING_EMAIL_FAILURE });
      }
    } catch (error) {
      yield put({ type: CHECK_EXISTING_EMAIL_FAILURE });
    }
  }
}

export function* isAccountInEnlightenFlow() {
  while (true) {
    const request = yield take(IS_ACCOUNT_IN_ENLIGHTEN_REQUEST);
    const { email } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.digiCoreUrl(`users/is_user_account_activated?email=${email}`),
        withBearer: true,
      });
      const { status, result } = response;
      if (status === 200) {
        yield put({ type: IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS, isHomeownerAccountInEnlighten: result });
      }
    } catch (error) {
      console.log('Saga: isAccountInEnlightenFlow ', error);
    }
  }
}

// needed
export function* getSolargrafFinancials() {
  while (true) {
    const request = yield take(GET_SOLARGRAF_FINANCIALS_REQUEST);
    const { projectPublicId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.solargrafApiUrl(`enlm/financials/${projectPublicId}`),
      });
      const { status, result } = response;
      if (status === 200) {
        yield put({ type: GET_SOLARGRAF_FINANCIALS_SUCCESS, solargrafFinancials: result });
      } else {
        yield put({ type: GET_SOLARGRAF_FINANCIALS_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_SOLARGRAF_FINANCIALS_FAILURE });
    }
  }
}
