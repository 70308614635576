import React from 'react';

const Services = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(-208)">
        <rect fill="none" width="20" height="20" transform="translate(208 0)" />
        <path
          fill="#335977"
          d="M226.257,13.925l-4.219-4.219a6.056,6.056,0,0,0,.214-1.58,6.12,6.12,0,0,0-9.5-5.108l4.241,4.241a1.225,1.225,0,0,1-1.733,1.733l-4.241-4.241a6.12,6.12,0,0,0,5.108,9.5,6.033,6.033,0,0,0,1.58-.214l4.218,4.218a3.063,3.063,0,1,0,4.333-4.331Zm-.867,3.465a1.837,1.837,0,0,1-2.6,0h0l-4.752-4.752a4.9,4.9,0,0,1-6.814-4.512,4.817,4.817,0,0,1,.171-1.265l3,3a2.45,2.45,0,1,0,3.465-3.465l-3-3a4.817,4.817,0,0,1,1.265-.171,4.9,4.9,0,0,1,4.512,6.814l4.752,4.752A1.838,1.838,0,0,1,225.391,17.391Z"
          transform="translate(-0.775 -0.775)"
        />
      </g>
    </svg>
  );
};

export default Services;
