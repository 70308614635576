// import {
//   LEAD_STATES,
//   PANEL_ACM_MANUFACTURERS,
//   PANEL_ACM_MODEL,
//   LEAD_CONTRACT_SHARED,
//   LEAD_CONTRACT_REJECTED,
//   ORDER_STATES,
//   BOM_INVERTERS,
//   STORAGE_LIST,
//   SOLARGRAF_ENCHARGE3_ID,
//   SOLARGRAF_ENCHARGE10_ID,
//   SG_BOM_INVERTERS
// } from './constants';
// import { get, isEmpty, isEqual } from '../../utils/lodash';
// import { localeMoment as moment } from '../../components/common/moment';
// import settings from '../../utils/settings';

import { get } from 'lodash';

// export function getLeadStateName(state_id, lead_obj) {
//   for (var i = 0; i < LEAD_STATES.length; i++) {
//     if (LEAD_STATES[i].id === state_id) {
//       if (LEAD_STATES[i].id === 6) {
//         let cJourney = get(lead_obj, 'contract_journey');
//         if (cJourney && cJourney.length > 0) {
//           switch (cJourney[cJourney.length - 1].state_id) {
//             case LEAD_CONTRACT_SHARED.id:
//               return LEAD_STATES[i + 1].name + ' (Awating Customer Confirmation)';
//             case LEAD_CONTRACT_REJECTED.id:
//               return LEAD_STATES[i + 1].name + ' (Request Rejected by Customer)';
//             default:
//               return LEAD_STATES[i].name;
//           }
//         } else {
//           return LEAD_STATES[i].name;
//         }
//       } else {
//         return LEAD_STATES[i].name;
//       }
//     }
//   }
//   if (state_id === -2) {
//     return 'Expired';
//   }
// }

// export function getOrderStateName(state_id) {
//   for (let i = 0; i < ORDER_STATES.length; i++) {
//     if (ORDER_STATES[i].id === state_id) {
//       return ORDER_STATES[i].name;
//     }
//   }
//   return null;
// }

// export function getAppliances(leadDetails, masterAppliances) {
//   const appliances = get(leadDetails, 'new_system.selected_appliances');
//   const appliances_sel_outage = get(leadDetails, 'new_system.appliances_selected_for_outage');
//   if (appliances) {
//     let r_data = [];
//     for (var i = 0; i < appliances.length; i++) {
//       const app = appliances[i];
//       for (let j = 0; j < masterAppliances.length; j++) {
//         const masterApp = masterAppliances[j];
//         if (masterApp.code === app) {
//           r_data.push({
//             name: masterApp.name,
//             category: masterApp.category,
//             code: masterApp.code,
//             selected_for_outage: appliances_sel_outage.includes(app)
//           });
//         }
//       }
//     }
//     return r_data;
//   } else {
//     return [];
//   }
// }

// export function presetStateDetails() {
//   const state_obj = { sD: [], current_state_level: 0 };
//   for (var i = 1; i < LEAD_STATES.length; i++) {
//     state_obj.sD.push({
//       header: LEAD_STATES[i].name,
//       id: LEAD_STATES[i].id,
//       subHeader: '',
//       actionDate: '',
//       showActionButton: false,
//       updateSiteId: false,
//       estimated_date_interval: LEAD_STATES[i].estimated_date_interval,
//       infoMsg: '',
//       stepName: LEAD_STATES[i].stepName
//     });
//   }
//   return state_obj;
// }

// export function presetOrderStateDetails() {
//   const state_obj = { sD: [], current_state_level: 0, current_step: 0 };
//   for (var i = 1; i < ORDER_STATES.length; i++) {
//     if (ORDER_STATES[i].id > 0) {
//       state_obj.sD.push({
//         header: ORDER_STATES[i].name,
//         id: ORDER_STATES[i].id,
//         subHeader: '',
//         actionDate: '',
//         showActionButton: false,
//         estimated_date_interval: ORDER_STATES[i].estimated_date_interval,
//         infoMsg: ''
//       });
//     }
//   }
//   return state_obj;
// }

// export function getOrderStatus(leadDetails) {
//   const order_status = get(leadDetails, 'order_journey');
//   let stateDetails = presetOrderStateDetails();
//   if (order_status) {
//     for (let i = 0; i < order_status.length; i++) {
//       const state = order_status[i];
//       if (stateDetails.current_state_level < state.state_id) {
//         stateDetails.current_state_level = state.state_id;
//       } else {
//         //check order cancelled
//         if (state.state_id === ORDER_STATES[0].id) {
//           stateDetails.current_state_level = ORDER_STATES[0].id;
//         }
//       }
//       for (let j = 0; j < stateDetails.sD.length; j++) {
//         if (stateDetails.sD[j].id === state.state_id) {
//           stateDetails.sD[j].header = getOrderStateName(get(state, 'state_id')) || get(state, 'state');
//           stateDetails.sD[j].subHeader = 'Completed On';
//           stateDetails.sD[j].actionDate = state.completion_date;
//           stateDetails.current_step += 1;
//         }
//       }
//     }
//     if (stateDetails.current_state_level !== 0 && stateDetails.current_state_level !== ORDER_STATES[0]) {
//       for (var k = stateDetails.current_state_level - 80; k < stateDetails.sD.length; k++) {
//         stateDetails.sD[k].subHeader = 'Estimated Date';
//         const preDate = moment(stateDetails.sD[k - 1].actionDate);
//         stateDetails.sD[k].actionDate = preDate.add(stateDetails.sD[k].estimated_date_interval, 'days');
//       }
//     }
//   } else {
//     stateDetails.sD = [];
//   }
//   return stateDetails;
// }

export function getSiteIdOfLead(leadDetails) {
  if (!get(leadDetails, 'site_linked_for_lead')) {
    if (get(leadDetails, 'existing_system.site_id')) {
      return get(leadDetails, 'existing_system.site_id');
    }
  } else {
    if (get(leadDetails, 'linked_site_id')) {
      return get(leadDetails, 'linked_site_id');
    }
  }
  return null;
}

// export function getStatusDetails(leadDetails) {
//   const states = get(leadDetails, 'states');
//   const stateDetails = presetStateDetails();
//   const cJourney = get(leadDetails, 'contract_journey');
//   let siteIdAvailable = false;
//   if (get(leadDetails, 'site_linked_for_lead')) {
//     if (get(leadDetails, 'linked_site_id')) {
//       siteIdAvailable = true;
//     }
//   } else {
//     if (get(leadDetails, 'existing_system.site_id')) {
//       siteIdAvailable = true;
//     }
//   }
//   if (states) {
//     for (var i = 0; i < states.length; i++) {
//       const state = states[i];
//       if (stateDetails.current_state_level < state.state_id) {
//         stateDetails.current_state_level = state.state_id;
//       }
//       for (var j = 0; j < stateDetails.sD.length; j++) {
//         if (stateDetails.sD[j].id === state.state_id) {
//           stateDetails.sD[j].header = getLeadStateName(get(state, 'state_id')) || get(state, 'state');
//           stateDetails.sD[j].subHeader = 'Completed On';
//           stateDetails.sD[j].actionDate = state.completion_date;
//         }
//       }
//     }
//     // Processing Upcoming States
//     for (var k = stateDetails.current_state_level + 1; k < stateDetails.sD.length; k++) {
//       stateDetails.sD[k].subHeader = 'Estimated Date';
//       const preDate = moment(stateDetails.sD[k - 1].actionDate);
//       stateDetails.sD[k].actionDate = preDate.add(stateDetails.sD[k].estimated_date_interval, 'days');
//       if (
//         stateDetails.sD[k - 1].showActionButton === false &&
//         stateDetails.current_state_level >= 4 &&
//         stateDetails.sD[k].id - 1 === stateDetails.current_state_level
//       ) {
//         if (stateDetails.current_state_level === 6) {
//           if (cJourney && cJourney.length > 0) {
//             //check for journey status
//             if (cJourney[cJourney.length - 1].state_id === LEAD_CONTRACT_SHARED.id) {
//               stateDetails.sD[k].infoMsg = 'Awaiting Customer Confirmation';
//               stateDetails.sD[k].actionDate = moment(cJourney[cJourney.length - 1].completion_date);
//               stateDetails.sD[k].subHeader = 'Requested On';
//             }
//             switch (cJourney[cJourney.length - 1].state_id) {
//               case LEAD_CONTRACT_SHARED.id:
//                 stateDetails.sD[k].infoMsg = 'Awaiting Customer Confirmation';
//                 stateDetails.sD[k].actionDate = moment(cJourney[cJourney.length - 1].completion_date);
//                 stateDetails.sD[k].subHeader = 'Requested On';
//                 break;
//               case LEAD_CONTRACT_REJECTED.id:
//                 stateDetails.sD[k].infoMsg = 'Request Rejected by Customer';
//                 stateDetails.sD[k].actionDate = moment(cJourney[cJourney.length - 1].completion_date);
//                 stateDetails.sD[k].subHeader = 'Requested On';
//                 stateDetails.sD[k].showActionButton = true;
//                 break;
//               default:
//                 break;
//             }
//           } else {
//             stateDetails.sD[k].showActionButton = true;
//           }
//         } else if (stateDetails.current_state_level === 7) {
//           if (!siteIdAvailable) {
//             stateDetails.sD[k].updateSiteId = true;
//           } else {
//             stateDetails.sD[k].showActionButton = true;
//           }
//         } else {
//           stateDetails.sD[k].showActionButton = true;
//         }
//       }
//     }
//     return stateDetails;
//   } else {
//     stateDetails.sD = [];
//     return stateDetails;
//   }
// }

// export function showUnderEstimateSection(leadDetails, f_type) {
//   const p_type = leadDetails.product_type;
//   const displaySolarFor = [ 'solar', 'solarstorage' ];
//   const displayStorageFor = [ 'storage', 'solarstorage' ];
//   if (f_type === 'solar' && displaySolarFor.includes(p_type)) {
//     return true;
//   } else if (f_type === 'storage' && displayStorageFor.includes(p_type)) {
//     return true;
//   } else {
//     return false;
//   }
// }

// export function getCustomerType(leadDetails) {
//   const c_type = leadDetails.customer_type;
//   switch (c_type) {
//     case 'new':
//       return 'New to Enphase';
//     case 'existing':
//       return 'Existing Enphase Customer';
//     default:
//       return 'Not Available';
//   }
// }

// export function getUpgradeType(leadDetails) {
//   if (leadDetails.customer_type === 'new') {
//     return 'new_customer';
//   } else {
//     let eSystem = leadDetails.existing_system;
//     let pvModules = eSystem.solar.pv_modules;
//     if (pvModules && pvModules.length > 0) {
//       let acm = false;
//       let micro = false;
//       for (let i = 0; i < pvModules.length; i++) {
//         const pvM = pvModules[i];
//         if (pvM.type === 'ACM') {
//           acm = true;
//         }
//         if (pvM.type === 'Micro') {
//           micro = true;
//         }
//       }
//       if (acm) {
//         //if acm present don't need to check micro availability
//         return 'acm_upgrade';
//       } else if (micro) {
//         return 'micro_upgrade';
//       } else {
//         return 'Not Available';
//       }
//     } else {
//       return 'Not Available';
//     }
//   }
// }

// export function getRequirementType(leadDetails) {
//   let solar_req = false;
//   let storage_req = false;
//   let micro_req = false;
//   let storage_req_only = false;

//   if (get(leadDetails, 'new_system.solar_requirement')) {
//     if (get(leadDetails, 'new_system.solar_requirement.pv_size')) {
//       solar_req = true;
//     }
//     if (get(leadDetails, 'new_system.solar_requirement.pv_modules')) {
//       let pvModule = leadDetails.new_system.solar_requirement.pv_modules;
//       for (let i = 0; i < pvModule.length; i++) {
//         const element = pvModule[i];
//         if (element.type === 'Micro') {
//           micro_req = true;
//           break;
//         }
//       }
//     }
//   }
//   if (get(leadDetails, 'new_system.storage_requirement.storage_capacity')) {
//     storage_req = true;
//   }

//   if (
//     get(leadDetails, 'lead_source') === 'Upgrade Program' &&
//     getGeneratedBy(leadDetails) === 'Homeowner' &&
//     get(leadDetails, 'system_sized_by') === 'Not Sized'
//   ) {
//     storage_req_only = true;
//   }

//   if (solar_req && storage_req && micro_req) {
//     return 'Add Storage & Upgrade Solar';
//   } else if (solar_req && storage_req && !micro_req) {
//     return 'Solar & Storage';
//   } else if (solar_req && !storage_req && micro_req) {
//     return 'Upgrade Solar';
//   } else if (solar_req && !storage_req && !micro_req) {
//     return 'Solar Only';
//   } else if (!solar_req && storage_req && micro_req) {
//     return 'Add Storage & Upgrade Microinverters';
//   } else if ((!solar_req && storage_req && !micro_req) || storage_req_only) {
//     return 'Storage Only';
//   } else if (!solar_req && !storage_req && micro_req) {
//     return 'Upgrade Microinverters';
//   } else {
//     return getLeadRequirement(leadDetails);
//   }
// }

// export function getLeadType(leadDetails) {
//   const p_type = leadDetails.product_type;
//   const c_type = leadDetails.customer_type;
//   let l_type = '';
//   switch (p_type) {
//     case 'solar':
//       l_type = 'Solar';
//       break;
//     case 'solarstorage':
//       l_type = 'Solar & Storage';
//       break;
//     case 'storage':
//       l_type = 'Storage';
//       break;
//     default:
//       l_type = 'Upgrade';
//       break;
//   }
//   if (c_type === 'new') {
//     l_type += ' - New User';
//     return l_type;
//   } else if (c_type === 'existing') {
//     l_type += ' - Existing User';
//     return l_type;
//   } else {
//     return l_type;
//   }
// }

// export function getGeneratedBy(leadDetails) {
//   const createdByType = get(leadDetails, 'created_by_utype', '')
//     ? get(leadDetails, 'created_by_utype', '').toLowerCase()
//     : '';
//   switch (createdByType) {
//     case 'ho':
//     case 'homeowner':
//       return 'Homeowner';
//     case 'installer':
//       return 'Installer';
//     default:
//       return 'NA';
//   }
// }

// export function isUpgradeLead(leadDetails) {
//   const leadType = get(leadDetails, 'lead_type');
//   if (isEmpty(leadType)) {
//     return (
//       get(leadDetails, 'customer_type', '') === 'existing' &&
//       get(leadDetails, 'created_by_utype') === 'HO' &&
//       get(leadDetails, 'system_sized_by') === 'Not Sized'
//     );
//   } else {
//     return leadType === 'upgrade_program';
//   }
// }

// export function getLeadRequirement(leadDetails) {
//   const p_type = leadDetails.product_type;
//   switch (p_type) {
//     case 'solar':
//       return 'Solar';
//     case 'solarstorage':
//       return 'Solar & Storage';
//     case 'storage':
//       return 'Storage';
//     default:
//       return 'Upgrade';
//   }
// }

// export function getCustomerTypeListing(leadDetails) {
//   const c_type = leadDetails.customer_type;
//   if (c_type === 'new') {
//     return 'New User';
//   } else if (c_type === 'existing') {
//     return 'Existing User';
//   } else {
//     return 'Not Available';
//   }
// }

// export function getFullName(leadDetails) {
//   const fullName = (get(leadDetails, 'first_name') || '') + ' ' + (get(leadDetails, 'last_name') || '');
//   if (fullName === ' ') {
//     return 'Not Available';
//   }
//   return fullName;
// }

// export function getFullAddress(leadDetails) {
//   let aArray = [];
//   if (get(leadDetails, 'address.address1')) {
//     aArray.push(get(leadDetails, 'address.address1'));
//   }
//   if (get(leadDetails, 'address.address2')) {
//     aArray.push(get(leadDetails, 'address.address2'));
//   }
//   if (get(leadDetails, 'address.city')) {
//     aArray.push(get(leadDetails, 'address.city'));
//   }
//   if (get(leadDetails, 'address.state')) {
//     aArray.push(get(leadDetails, 'address.state'));
//   }
//   if (get(leadDetails, 'address.country')) {
//     aArray.push(get(leadDetails, 'address.country'));
//   }

//   let aString = aArray.join(', ');

//   if (aString === '') {
//     return 'Not Available';
//   }
//   return aString;
// }

// export function getAPIMapForStateId(state_id) {
//   for (var i = 0; i < LEAD_STATES.length; i++) {
//     if (LEAD_STATES[i].id === state_id) {
//       return LEAD_STATES[i].api_map;
//     }
//   }
// }

// export function getStateIdForAPIMap(state) {
//   for (var i = 0; i < LEAD_STATES.length; i++) {
//     if (LEAD_STATES[i].api_map === state) {
//       return LEAD_STATES[i].id;
//     }
//   }
// }

// export function filterModelData(mfgName, panel_type) {
//   let mfgId = '';
//   let panelModels = [];
//   if (panel_type === 'acm') {
//     for (let i = 0; i < PANEL_ACM_MANUFACTURERS.length; i++) {
//       if (PANEL_ACM_MANUFACTURERS[i].name === mfgName) {
//         mfgId = PANEL_ACM_MANUFACTURERS[i].id;
//       }
//     }
//     for (let i = 0; i < PANEL_ACM_MODEL.length; i++) {
//       if (mfgId === PANEL_ACM_MODEL[i].mfg_id) {
//         panelModels.push(PANEL_ACM_MODEL[i]);
//       }
//     }
//   }
//   return panelModels;
// }

// export function getLeadReceivedDate(leadDetails) {
//   for (let i = 0; i < leadDetails.states.length; i++) {
//     const state = leadDetails.states[i];
//     if (state.state_id === 1) {
//       let localTime = moment(state.created_time).local();
//       return localTime.format('DD MMM YYYY hh:mm A');
//     }
//   }
// }

// export function getPanelQtyForACM(panel_mfg, pv_size) {
//   for (let i = 0; i < PANEL_ACM_MANUFACTURERS.length; i++) {
//     const element = PANEL_ACM_MANUFACTURERS[i];
//     if (element.name === panel_mfg && pv_size !== '') {
//       let panel_qty = Math.ceil(pv_size * 1000 / element.calc_divisible_by);
//       return panel_qty;
//     }
//   }
//   return '';
// }

// export function validateStorage(pv_size, storage_value_sel) {
//   let res = { validation_check: false, valid: false, msg: '', storage_value_expected: '' };
//   if (pv_size !== '' && pv_size) {
//     let storage_expectation = (pv_size / 2.5 * 3.3).toFixed(1);
//     if (storage_value_sel !== '') {
//       if (storage_value_sel >= storage_expectation) {
//         res.validation_check = true;
//         res.valid = true;
//         res.storage_value_expected = storage_expectation;
//       } else {
//         res.validation_check = true;
//         res.valid = false;
//         let cVal = STORAGE_LIST.reduce((prev, curr) => {
//           return Math.abs(curr - storage_expectation) < Math.abs(prev - storage_expectation) ? curr : prev;
//         });
//         if (STORAGE_LIST.indexOf(cVal) < STORAGE_LIST.length - 1) {
//           cVal = STORAGE_LIST[STORAGE_LIST.indexOf(cVal) + 1];
//         }
//         // res.msg = `Minimum ${cVal} kWh of storage is needed to support the entered solar system size`;
//         res.msg = 'Note: A split solar system installation may be needed to support the selected storage and PV size.';
//       }
//     } else {
//       res.storage_value_expected = storage_expectation;
//     }
//   }
//   return res;
// }

// export function getPanelMfgId(mfg_name, panel_type, panelMfg) {
//   if (panel_type === 'acm') {
//     for (let i = 0; i < PANEL_ACM_MANUFACTURERS.length; i++) {
//       if (PANEL_ACM_MANUFACTURERS[i].name === mfg_name) {
//         return PANEL_ACM_MANUFACTURERS[i].id;
//       }
//     }
//   } else {
//     for (let i = 0; i < panelMfg.length; i++) {
//       if (panelMfg[i].name === mfg_name) {
//         return panelMfg[i].id;
//       }
//     }
//   }
// }

// export function isDisabled(leadDetails, field) {
//   const notSizedSystem = get(leadDetails, 'system_sized_by') === 'Not Sized';
//   const productType = get(leadDetails, 'product_type');
//   const isSolarProductType = productType === 'solar' || productType === 'solarstorage';
//   const isStorageProductType = productType === 'storage' || productType === 'solarstorage';
//   switch (field) {
//     case 'pv_size':
//       if (get(leadDetails, 'new_system.solar_requirement.pv_size') || (notSizedSystem && isSolarProductType)) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'storage_capacity':
//       if (
//         get(leadDetails, 'new_system.storage_requirement.storage_capacity') ||
//         (notSizedSystem && isStorageProductType)
//       ) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'panel_type_acm':
//       if (get(leadDetails, 'new_system.solar_requirement.pv_size') || (notSizedSystem && isSolarProductType)) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'panel_type_normal':
//       if (
//         (get(leadDetails, 'new_system.solar_requirement.pv_size') && get(leadDetails, 'journey_type') === 'Turnkey') ||
//         (notSizedSystem && isSolarProductType)
//       ) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'panel_mfg_model_qty':
//       if (get(leadDetails, 'new_system.solar_requirement.pv_size') || (notSizedSystem && isSolarProductType)) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'inverter':
//       let tK = false;
//       let rMicro = false;
//       if (get(leadDetails, 'new_system.solar_requirement.pv_size') && get(leadDetails, 'journey_type') === 'Turnkey') {
//         tK = true;
//       }
//       if (get(leadDetails, 'new_system.solar_requirement.pv_modules')) {
//         let pvModules = get(leadDetails, 'new_system.solar_requirement.pv_modules');
//         for (let i = 0; i < pvModules.length; i++) {
//           const pvM = pvModules[i];
//           if (pvM.type === 'Micro') {
//             rMicro = true;
//           }
//         }
//       }
//       if (rMicro || tK || (notSizedSystem && isSolarProductType)) {
//         return false;
//       } else {
//         return true;
//       }
//     case 'total_price':
//       if (get(leadDetails, 'journey_type') === 'Turnkey') {
//         return false;
//       } else {
//         return true;
//       }
//     case 'installation_price':
//       if (get(leadDetails, 'journey_type') === 'Installation Only') {
//         return false;
//       } else {
//         return true;
//       }
//     case 'incentives':
//       return false;
//     case 'final_price':
//       return false;
//     case 'payback_period':
//       if (get(leadDetails, 'new_system.solar_requirement.pv_size') || (notSizedSystem && isSolarProductType)) {
//         return false;
//       } else {
//         //its a storage requirement, so disable it
//         return true;
//       }
//     default:
//       return true;
//   }
// }

// export function validateIndividual(qData, product_type, leadDetails, inpt_item) {
//   let fValidation = validateQuoteForm(qData, product_type, leadDetails);
//   if (!fValidation.qDE[inpt_item].error) {
//     return true;
//   } else {
//     return false;
//   }
// }

// export function validateQuoteForm(qData, product_type, leadDetails, isSystemConfiguration = false) {
//   let fValidation = {
//     eFound: false,
//     qDE: {
//       pv_size: { error: false, msg: '' },
//       storage_capacity: { error: false, msg: '' },
//       panel_type: { error: false, msg: '' },
//       panel_qty: { error: false, msg: '' },
//       tilt: { error: false, msg: '' },
//       inverter_type: { error: false, msg: '' },
//       inverter_qty: { error: false, msg: '' },
//       encharge10_qty: { error: false, msg: '' },
//       encharge3_qty: { error: false, msg: '' },
//       quote_url: { error: false, msg: '' },
//       total_price: { error: false, msg: '' },
//       system_price: { error: false, msg: '' },
//       installation_price: { error: false, msg: '' },
//       incentives: { error: false, msg: '' },
//       final_price: { error: false, msg: '' },
//       electricity_savings: { error: false, msg: '' },
//       savings: { error: false, msg: '' },
//       payback_period: { error: false, msg: '' },
//       s3_quote_file_name: { error: false, msg: '' },
//       panel_mfg: { error: false, msg: '' },
//       panel_model: { error: false, msg: '' },
//       installer_note: { error: false, msg: '' },
//       uploadedDocs: { error: false, msg: [] },
//       cash_quote: { error: false, msg: [] },
//       loan_quote: { error: false, msg: [] },
//       lq_total_price: { error: false, msg: [] },
//       lq_installation_price: { error: false, msg: [] },
//       lq_incentives: { error: false, msg: [] },
//       lq_final_price: { error: false, msg: [] },
//       lq_electricity_savings: { error: false, msg: [] },
//       lq_savings: { error: false, msg: [] },
//       lq_payback_period: { error: false, msg: [] },
//       lq_loan_amount: { error: false, msg: [] },
//       lq_interest_rate: { error: false, msg: [] },
//       lq_loan_term_in_months: { error: false, msg: [] },
//       lq_downpayment: { error: false, msg: [] },
//       lq_monthly_payment: { error: false, msg: [] },
//       lq_selected_finance_provider: { error: false, msg: [] },
//       lq_custom_finance_provider: { error: false, msg: [] }
//     }
//   };

//   if (isSystemConfiguration) {
//     fValidation = systemConfigurationValidation(qData, product_type, leadDetails, fValidation);
//   } else {
//     fValidation = systemConfigurationValidation(qData, product_type, leadDetails, fValidation);
//     if (qData['cash_quote']) {
//       if (!isDisabled(leadDetails, 'total_price')) {
//         if (qData.total_price < 0 || qData.total_price > 1000000 || qData.total_price === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.total_price.error = true;
//           fValidation.qDE.total_price.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.total_price.error = false;
//           fValidation.qDE.total_price.msg = '';
//         }
//       }
//       if (!isDisabled(leadDetails, 'incentives')) {
//         if (qData.incentives < 0 || qData.incentives > 1000000 || qData.incentives === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.incentives.error = true;
//           fValidation.qDE.incentives.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.incentives.error = false;
//           fValidation.qDE.incentives.msg = '';
//         }
//       }
//       if (!isDisabled(leadDetails, 'final_price')) {
//         if (qData.final_price < 0 || qData.final_price > 1000000 || qData.final_price === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.final_price.error = true;
//           fValidation.qDE.final_price.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.final_price.error = false;
//           fValidation.qDE.final_price.msg = '';
//         }
//       }
//       if (!isDisabled(leadDetails, 'payback_period')) {
//         if (qData.payback_period < 0 || qData.payback_period > 1000000 || qData.payback_period === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.payback_period.error = true;
//           fValidation.qDE.payback_period.msg = 'Payback Period should be between 0 to 50';
//         } else {
//           fValidation.qDE.payback_period.error = false;
//           fValidation.qDE.payback_period.msg = '';
//         }
//       }
//       if (qData.electricity_savings < 0 || qData.electricity_savings > 1000000 || qData.electricity_savings === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.electricity_savings.error = true;
//         fValidation.qDE.electricity_savings.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.electricity_savings.error = false;
//         fValidation.qDE.electricity_savings.msg = '';
//       }
//       if (qData.savings < 0 || qData.savings > 1000000 || qData.savings === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.savings.error = true;
//         fValidation.qDE.savings.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.savings.error = false;
//         fValidation.qDE.savings.msg = '';
//       }
//       if (!isDisabled(leadDetails, 'installation_price')) {
//         if (qData.installation_price < 0 || qData.installation_price > 1000000 || qData.installation_price === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.installation_price.error = true;
//           fValidation.qDE.installation_price.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.installation_price.error = false;
//           fValidation.qDE.installation_price.msg = '';
//         }
//       }
//     }

//     if (qData['loan_quote']) {
//       if (!isDisabled(leadDetails, 'total_price')) {
//         if (qData.lq_total_price < 0 || qData.lq_total_price > 1000000 || qData.lq_total_price === '') {
//           fValidation.eFound = true;
//           fValidation.qDE.lq_total_price.error = true;
//           fValidation.qDE.lq_total_price.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.total_price.error = false;
//           fValidation.qDE.total_price.msg = '';
//         }
//       }
//       if (!isDisabled(leadDetails, 'installation_price')) {
//         if (
//           qData.lq_installation_price < 0 ||
//           qData.lq_installation_price > 1000000 ||
//           qData.lq_installation_price === ''
//         ) {
//           fValidation.eFound = true;
//           fValidation.qDE.lq_installation_price.error = true;
//           fValidation.qDE.lq_installation_price.msg = 'Amount should be between 0 to 1,000,000';
//         } else {
//           fValidation.qDE.lq_installation_price.error = false;
//           fValidation.qDE.lq_installation_price.msg = '';
//         }
//       }
//       if (qData.lq_incentives < 0 || qData.lq_incentives > 1000000 || qData.lq_incentives === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_incentives.error = true;
//         fValidation.qDE.lq_incentives.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_incentives.error = false;
//         fValidation.qDE.lq_incentives.msg = '';
//       }
//       if (qData.lq_final_price < 0 || qData.lq_final_price > 1000000 || qData.lq_final_price === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_final_price.error = true;
//         fValidation.qDE.lq_final_price.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_final_price.error = false;
//         fValidation.qDE.lq_final_price.msg = '';
//       }
//       if (
//         qData.lq_electricity_savings < 0 ||
//         qData.lq_electricity_savings > 1000000 ||
//         qData.lq_electricity_savings === ''
//       ) {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_electricity_savings.error = true;
//         fValidation.qDE.lq_electricity_savings.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_electricity_savings.error = false;
//         fValidation.qDE.lq_electricity_savings.msg = '';
//       }
//       if (qData.lq_savings < 0 || qData.lq_savings > 1000000 || qData.lq_savings === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_savings.error = true;
//         fValidation.qDE.lq_savings.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_savings.error = false;
//         fValidation.qDE.lq_savings.msg = '';
//       }
//       if (qData.lq_loan_amount < 0 || qData.lq_loan_amount > 1000000 || qData.lq_loan_amount === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_loan_amount.error = true;
//         fValidation.qDE.lq_loan_amount.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_loan_amount.error = false;
//         fValidation.qDE.lq_loan_amount.msg = '';
//       }
//       if (qData.lq_downpayment < 0 || qData.lq_downpayment > 1000000) {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_downpayment.error = true;
//         fValidation.qDE.lq_downpayment.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_downpayment.error = false;
//         fValidation.qDE.lq_downpayment.msg = '';
//       }
//       if (qData.lq_monthly_payment < 0 || qData.lq_monthly_payment > 1000000 || qData.lq_monthly_payment === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_monthly_payment.error = true;
//         fValidation.qDE.lq_monthly_payment.msg = 'Amount should be between 0 to 1,000,000';
//       } else {
//         fValidation.qDE.lq_monthly_payment.error = false;
//         fValidation.qDE.lq_monthly_payment.msg = '';
//       }
//       if (qData.lq_selected_finance_provider === 'Other' && qData.lq_custom_finance_provider === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_custom_finance_provider.error = true;
//         fValidation.qDE.lq_custom_finance_provider.msg = 'Please enter a name';
//       } else if (qData.lq_selected_finance_provider === 'Other' && qData.lq_custom_finance_provider.length > 32) {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_custom_finance_provider.error = true;
//         fValidation.qDE.lq_custom_finance_provider.msg = 'Maximum character limit for name exceeded';
//       } else if (
//         qData.lq_selected_finance_provider === 'Other' &&
//         !qData.lq_custom_finance_provider.match(/^[a-z|A-Z|0-9]+$/)
//       ) {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_custom_finance_provider.error = true;
//         fValidation.qDE.lq_custom_finance_provider.msg = 'Please enter a valid name';
//       } else {
//         fValidation.qDE.lq_custom_finance_provider.error = false;
//         fValidation.qDE.lq_custom_finance_provider.msg = '';
//       }
//       if (qData.lq_interest_rate < 0.01 || qData.lq_interest_rate > 50.0 || qData.lq_interest_rate === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_interest_rate.error = true;
//         fValidation.qDE.lq_interest_rate.msg = 'Interest rate should be between 0.01% to 50.00%';
//       } else {
//         fValidation.qDE.lq_interest_rate.error = false;
//         fValidation.qDE.lq_interest_rate.msg = '';
//       }
//       if (
//         qData.lq_loan_term_in_months < 1 ||
//         qData.lq_loan_term_in_months > 600 ||
//         qData.lq_loan_term_in_months === ''
//       ) {
//         fValidation.eFound = true;
//         fValidation.qDE.lq_loan_term_in_months.error = true;
//         fValidation.qDE.lq_loan_term_in_months.msg = 'Loan term should be between 1 to 600';
//       } else {
//         fValidation.qDE.lq_loan_term_in_months.error = false;
//         fValidation.qDE.lq_loan_term_in_months.msg = '';
//       }
//     }
//   }
//   return fValidation;
// }

// function systemConfigurationValidation(qData, product_type, leadDetails, fieldValidation) {
//   let fValidation = fieldValidation;
//   if (!isDisabled(leadDetails, 'pv_size')) {
//     if (qData.pv_size < 0 || qData.pv_size > 1000 || qData.pv_size === '') {
//       fValidation.eFound = true;
//       fValidation.qDE.pv_size.error = true;
//       fValidation.qDE.pv_size.msg = 'Number should be between 0 to 1000';
//     } else {
//       fValidation.qDE.pv_size.error = false;
//       fValidation.qDE.pv_size.msg = '';
//     }
//   }
//   if (!isDisabled(leadDetails, 'panel_mfg_model_qty')) {
//     if (qData.panel_mfg === '') {
//       fValidation.eFound = true;
//       fValidation.qDE.panel_mfg.error = true;
//       fValidation.qDE.panel_mfg.msg = 'Manufacturer Selection Required';
//     } else {
//       fValidation.qDE.panel_mfg.error = false;
//       fValidation.qDE.panel_mfg.msg = '';
//     }

//     if (qData.panel_model === '') {
//       fValidation.eFound = true;
//       fValidation.qDE.panel_model.error = true;
//       fValidation.qDE.panel_model.msg = 'Model Selection Required';
//     } else {
//       fValidation.qDE.panel_model.error = false;
//       fValidation.qDE.panel_model.msg = '';
//     }

//     if (qData.panel_qty === '' || qData.panel_qty < 0 || qData.panel_qty > 100) {
//       fValidation.eFound = true;
//       fValidation.qDE.panel_qty.error = true;
//       fValidation.qDE.panel_qty.msg = 'Number should be between 0 to 100';
//     } else {
//       fValidation.qDE.panel_qty.error = false;
//       fValidation.qDE.panel_qty.msg = '';
//     }
//   }
//   if (!isDisabled(leadDetails, 'panel_type_normal')) {
//     if (qData.panel_type === 'normal' && qData.inverter_type === '') {
//       fValidation.eFound = true;
//       fValidation.qDE.inverter_type.error = true;
//       fValidation.qDE.inverter_type.msg = 'Inverter Selection Required';
//     } else {
//       fValidation.qDE.inverter_type.error = false;
//       fValidation.qDE.inverter_type.msg = '';
//     }
//   }
//   if (!isDisabled(leadDetails, 'storage_capacity')) {
//     if (product_type !== 'storage') {
//       let vStorage = validateStorage(qData.pv_size, qData.storage_capacity);
//       if (vStorage.validation_check && !vStorage.valid) {
//         //do not flag so that it will allow submit
//         // fValidation.eFound = true;
//         fValidation.qDE.storage_capacity.error = true;
//         fValidation.qDE.storage_capacity.msg = vStorage.msg;
//       } else {
//         fValidation.qDE.storage_capacity.error = false;
//         fValidation.qDE.storage_capacity.msg = '';
//       }
//     } else {
//       if (qData.storage_capacity === '') {
//         fValidation.eFound = true;
//         fValidation.qDE.storage_capacity.error = true;
//         fValidation.qDE.storage_capacity.msg = 'Storage Selection Required';
//       } else {
//         fValidation.qDE.storage_capacity.error = false;
//         fValidation.qDE.storage_capacity.msg = '';
//       }
//     }
//   }
//   return fValidation;
// }

// export function getUrlToStoreWithRedirectOptions(cartUrl, journey_id, lead_id, currentUrl) {
//   let redirectAPI = settings.digiCoreUrl(
//     'installers/order_placed?order_id%3D%26payment_id%3D%26sgip_promo_code%3D%26permit_promo_code%3D%26journey_id=' +
//       journey_id
//   );
//   return cartUrl + '&redirect_api=' + redirectAPI + '&redirect_url=' + currentUrl + '%26req_source=enstore';
// }

// export function getExpiryOfLead(hours) {
//   let d = '';
//   if (hours > 24) {
//     // var milliseconds = hours * 60 * 60 * 1000;
//     // d = moment.duration(Math.abs(milliseconds)).format('days');
//     d = Math.floor(hours / 24) + ' days';
//   } else {
//     d = hours + ' hours';
//   }

//   if (hours > 0) {
//     return `Expires in ${d}`;
//   } else {
//     return `Expired ${d} ago`;
//   }
// }

// export function getBBMappingForInverter(inv) {
//   for (let i = 0; i < BOM_INVERTERS.length; i++) {
//     const inverter = BOM_INVERTERS[i];
//     if (inverter.value === inv) {
//       return inverter.bb_mapping;
//     }
//   }
//   return false;
// }

// export function formatDistance(leadDetails) {
//   if (get(leadDetails, 'travelInfo')) {
//     let tInfo = get(leadDetails, 'travelInfo');
//     if (tInfo.metric_pref && tInfo.distance.value) {
//       if (tInfo.metric_pref === 'mi') {
//         return (tInfo.distance.value * 0.00062137).toFixed();
//       } else if (tInfo.metric_pref === 'km') {
//         return (tInfo.distance.value / 1000).toFixed();
//       } else {
//         return false;
//       }
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// }

// export function getBOMListData(bomItems, order_from_store, qDetails) {
//   let dArray = [];
//   if (order_from_store) {
//     dArray.push([ 'Model Number', 'Description', 'Qty', 'Total Price' ]);
//   } else {
//     dArray.push([ 'Model Number', 'Description', 'Qty' ]);
//   }
//   for (let i = 0; i < bomItems.length; i++) {
//     const bom = bomItems[i];
//     if (order_from_store) {
//       dArray.push([ bom.sku, bom.item_name, bom.item_count, bom.price_after_discount ]);
//     } else {
//       dArray.push([ bom.sku, bom.item_name, bom.item_count ]);
//     }
//   }
//   if (order_from_store) {
//     //add empty line
//     dArray.push([]);
//     dArray.push([ 'Total (taxes & shipping extra)', '', '', qDetails.system_price ]);
//     dArray.push([ 'Tax', '', '', qDetails.system_price_tax ]);
//     dArray.push([ 'Shipping Cost', '', '', qDetails.system_price_shipment ]);
//     dArray.push([]);
//     dArray.push([ 'Shipping To', getShippingTo(qDetails.shipment_info) || 'NA' ]);
//     dArray.push([ 'Carrier Info', getShipmentOption(qDetails.shipment_info) || 'NA' ]);
//   }
//   return dArray;
// }

// export function getExistingSystemUrl(leadDetails) {
//   let url = settings.enlightenMenuUrl(`systems/${leadDetails.existing_system.site_id}`);
//   return url;
// }

// export function getShippingTo(shipping_info) {
//   if (isEqual(shipping_info, {})) {
//     return null;
//   } else {
//     let sTo = shipping_info.shipping_to;
//     if (sTo.zipcode && sTo.region && sTo.country_id) {
//       return sTo.zipcode + ', ' + sTo.region + ', ' + sTo.country_id;
//     } else {
//       return null;
//     }
//   }
// }

// export function getShipmentOption(shipping_info) {
//   if (isEqual(shipping_info, {})) {
//     return null;
//   } else {
//     if (shipping_info.carrier_title && shipping_info.method_title) {
//       return shipping_info.carrier_title + ' - ' + shipping_info.method_title;
//     } else {
//       return null;
//     }
//   }
// }

// export function limitCharWithEllipse(textToTransform, charLimit) {
//   if (!textToTransform || textToTransform === '') {
//     return '--';
//   } else {
//     if (textToTransform.length > charLimit) {
//       return textToTransform.slice(0, charLimit) + '..';
//     } else {
//       return textToTransform;
//     }
//   }
// }

// export function getBomItemsFromMaterials(materials, selectedProposalOption) {
//   let skusIncludeToBom = { sku_mapping: '' };
//   let bomParams = {
//     skus_include_to_bom: [ skusIncludeToBom ],
//     rqd_panel_type: '',
//     panel_mfg: '',
//     purchase_type: 'NEW',
//     existing_envoy_s_count: 0
//   };

//   const filteredMaterialDetails = materials.filter(material => {
//     const { materialType, proposalIds } = material;
//     if (proposalIds && proposalIds.length > 0 && proposalIds.includes(selectedProposalOption)) {
//       const hasBatteryBackup = materialType === 'battery_backup';
//       const hasInverters = materialType === 'inverter';
//       const hasPanel = materialType === 'panel';
//       return hasBatteryBackup || hasInverters || hasPanel;
//     }
//     return false;
//   });

//   filteredMaterialDetails &&
//     filteredMaterialDetails.forEach(material => {
//       const { materialType, parentId, count = 0, isAcModule = false, name, manufacturer } = material;
//       if (materialType === 'battery_backup') {
//         const key =
//           parentId === SOLARGRAF_ENCHARGE3_ID ? 'e3_count' : parentId === SOLARGRAF_ENCHARGE10_ID ? 'e10_count' : '';
//         if (key) {
//           bomParams[key] = count;
//         }
//       }
//       if (materialType === 'panel' && isAcModule) {
//         bomParams['rqd_panel_type'] = name;
//         bomParams['panel_mfg'] = get(manufacturer, 'name', '');
//         bomParams['panel_count'] = count;
//         skusIncludeToBom = {
//           ...skusIncludeToBom,
//           count
//         };
//       }
//       if (materialType === 'inverter') {
//         const inverter = SG_BOM_INVERTERS.find(inverter => inverter.id === parentId);
//         if (inverter) {
//           skusIncludeToBom = {
//             ...skusIncludeToBom,
//             sku_mapping: inverter.bb_mapping
//           };
//         }
//       }
//     });
//   bomParams['skus_include_to_bom'] = [ skusIncludeToBom ];
//   return bomParams;
// }
