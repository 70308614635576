import header from './header';
import accountInfo from './accountInfo';
import footer from './footer';
import common from './common';

const nl = {
	...header,
	...accountInfo,
	...footer,
	...common
};

export default nl;
