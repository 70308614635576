import React, { Component } from 'react';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import renderHTML from 'react-render-html';
import { isArray } from 'lodash';

// available severity are error, warning, info, success
class SimpleAlert extends Component {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  handleClose = (event, reason) => {
    this.setState({ open: false });
    if (reason === 'clickaway') {
      return;
    }
  };

  render() {
    const { message, severity } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.handleClose.bind(this)}
      >
        <Alert onClose={this.handleClose.bind(this)} severity={severity}>
          {isArray(message) ? message : renderHTML(message)}
        </Alert>
      </Snackbar>
    );
  }
}

export default SimpleAlert;
