import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
  CircularProgress,
  Backdrop,
  Button,
} from '@material-ui/core';
import OtpInput from 'react-otp-input';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './styles';
import { SuccessIcon, FailureIcon } from '../images';
import I18n from '../../../config/locales';
import { requestSolargrafAccess, grantSolargrafAccess } from '../../../containers/Layouts/actions';
import SnakBar from '../../SnakBar';

let timerInterval = null;
let otpVerificationTimeout = null;
let timeout = null;

const LinkWithSolargrafPopup = (props) => {
  const classes = useStyles();
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState(<Fragment />);
  const { handleClose = () => {}, onSuccess } = props;
  const fiveMintues = 60 * 15;
  let timer = fiveMintues;

  const getMinutesSeconds = (newTimer) => {
    let minutes = parseInt(newTimer / 60, 10);
    let seconds = parseInt(newTimer % 60, 10);
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes} : ${seconds}`;
  };

  const [countDown, setCountDown] = useState(getMinutesSeconds(timer));

  const countDownTimer = () => {
    timerInterval = setInterval(() => {
      if (--timer <= 0) {
        setCountDown(I18n.t('accountInfo.otpExpired'));
        clearInterval(timerInterval);
      } else {
        const newCountDown = getMinutesSeconds(timer);
        setCountDown(newCountDown);
      }
    }, 1000);
  };

  const renderVerificationSuccess = () => (
    <Fragment>
      <SuccessIcon />
      <Typography className={classes.verificationSuccess}>{I18n.t('accountInfo.verificationSuccess')}</Typography>
    </Fragment>
  );

  const renderVerificationFailure = () => (
    <Fragment>
      <FailureIcon />
      <Typography className={classes.verificationFailure}>{I18n.t('accountInfo.verificationFailure')}</Typography>
    </Fragment>
  );

  const handleSetOtp = (newOtp) => {
    setVerificationMessage(<Fragment />);
    clearTimeout(otpVerificationTimeout);
    if (newOtp.length === 6) {
      otpVerificationTimeout = setTimeout(() => {
        setShowLoader(true);
        props.grantSolargrafAccess({
          newOtp,
          successCbk: () => {
            setShowLoader(false);
            setVerificationMessage(renderVerificationSuccess());
            setTimeout(() => {
              onSuccess();
            }, 1000);
          },
          failureCbk: () => {
            setShowLoader(false);
            setVerificationMessage(renderVerificationFailure());
          },
        });
      }, 1000);
    }
    setOtp(newOtp);
  };

  const handlePopupClose = () => {
    clearInterval(timerInterval);
    handleClose();
  };

  const setSuccessFailureMessage = (messages, type) => {
    clearTimeout(timeout);
    setMessage(messages);
    setMessageType(type);
    timeout = setTimeout(() => {
      setMessage('');
      setMessageType('');
    }, 4000);
  };

  const handleSendOtp = (showOtp = false) => {
    setShowLoader(true);
    setVerificationMessage(<Fragment />);
    setOtp('');
    props.requestSolargrafAccess({
      successCbk: () => {
        setShowLoader(false);
        clearInterval(timerInterval);
        timer = showOtp ? fiveMintues : fiveMintues + 1;
        countDownTimer();
        if (showOtp) {
          setShowOtpSection(true);
        } else {
          setSuccessFailureMessage(I18n.t('accountInfo.otpSentSuccessfully'), 'success');
        }
      },
      failureCbk: () => {
        setShowLoader(false);
        if (showOtp) {
          setSuccessFailureMessage(I18n.t('accountInfo.failureMessage'), 'failure');
        } else {
          setSuccessFailureMessage(I18n.t('accountInfo.otpSentFailed'), 'failure');
        }
      },
    });
  };

  const getDialogContent = () => {
    if (showOtpSection) {
      return (
        <Fragment>
          <DialogContentText className={classes.message}>{I18n.t('accountInfo.solargrafOtpMessage')}</DialogContentText>
          <Typography component="div" className={classes.otpOuterContainer}>
            <Typography className={classes.enterOtp}>{I18n.t('accountInfo.enterOtpText')}</Typography>
            <OtpInput
              value={otp}
              onChange={(newOtp) => handleSetOtp(newOtp)}
              numInputs={6}
              separator={<span className="otpInputSeparator" />}
              containerStyle={classes.otpContainer}
              inputStyle={classes.otpInput}
              focusStyle={classes.otpFocus}
              placeholder="******"
              isInputNum
            />
            <Typography className={classes.countDown}>{countDown}</Typography>
          </Typography>
          <Typography className={classes.resendOtp} component="div">
            {I18n.t('accountInfo.didntReceivedOtpMessage')}
            <Typography className={classes.resendOtpButton} component="div" onClick={() => handleSendOtp(false)}>
              {I18n.t('accountInfo.resendOtp')}
            </Typography>
          </Typography>
          <Typography className={classes.verificationMessage}>{verificationMessage}</Typography>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <DialogContentText className={classes.linkSolargrafMessageOne}>
            {I18n.t('accountInfo.linkSolargrafMessageOne')}
          </DialogContentText>
          <DialogContentText className={classes.linkSolargrafMessageTwo}>
            {I18n.t('accountInfo.linkSolargrafMessageTwo')}
          </DialogContentText>
          <Button
            color="primary"
            variant="contained"
            className={classes.verificationCodeButton}
            disableElevation
            onClick={() => handleSendOtp(true)}
          >
            {I18n.t('accountInfo.getVerificationCodeText')}
          </Button>
        </Fragment>
      );
    }
  };

  const getDialogTitle = () => {
    return showOtpSection ? I18n.t('accountInfo.verificationText') : I18n.t('accountInfo.linkSolargrafTitle');
  };

  return (
    <Fragment>
      <Dialog
        open
        onClose={handlePopupClose}
        aria-labelledby="link-solargraf-dialog-title"
        aria-describedby="link-solargraf-description"
        maxWidth="sm"
        classes={{ paper: classes.dialogContainer }}
        fullWidth
      >
        <DialogTitle disableTypography id="link-solargraf-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h6">{getDialogTitle()}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handlePopupClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="link-solargraf-description" className={classes.dialogContent}>
          {getDialogContent()}
        </DialogContent>
      </Dialog>
      {messageType && message && <SnakBar message={message} severity={messageType} />}
      {showLoader && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  requestSolargrafAccess: (payload) => dispatch(requestSolargrafAccess(payload)),
  grantSolargrafAccess: (payload) => dispatch(grantSolargrafAccess(payload)),
});

export default connect(null, mapDispatchToProps)(LinkWithSolargrafPopup);
