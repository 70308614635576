import I18n from './i18n';
import en from './en';
import fr from './fr';
import en_AU from './en-AU';
import de from './de';
import nl from './nl';

I18n.translations = { en, fr, de, nl, 'en-AU': en_AU };

export default I18n;
