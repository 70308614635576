import React from 'react';

function Solid(props) {
  const { disabled } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <defs />
      <g transform="translate(-24 -24)">
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M148.638,50.45a1.759,1.759,0,0,0,2.487,0l6.632-6.632a1.759,1.759,0,1,0-2.487-2.487l-3.63,3.63v-19.2a1.759,1.759,0,1,0-3.517,0v19.2l-3.63-3.63a1.759,1.759,0,1,0-2.487,2.487Z"
          transform="translate(-108.881 0)"
        />
        <path
          fill={disabled ? '#919191' : '#016ae3'}
          d="M56.241,232a1.759,1.759,0,0,0-1.759,1.759v13.483H27.517V233.759a1.759,1.759,0,1,0-3.517,0v14.069a2.931,2.931,0,0,0,2.931,2.931H55.069A2.931,2.931,0,0,0,58,247.828V233.759A1.759,1.759,0,0,0,56.241,232Z"
          transform="translate(0 -192.759)"
        />
      </g>
    </svg>
  );
}

export default Solid;
