import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PageHeader from '../../PageHeader';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    dialogHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(0.25),
    },
    closeButton: {
        padding: 0,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 2, 2),
        fontSize: 15,
    },
    button: {
        color: theme.tertiary.white,
        marginLeft: theme.spacing(1),
        position: 'relative',
        top: theme.spacing(0.3),
    },
}); });
var InfoDialog = function (props) {
    var classes = useStyles();
    var openDialog = props.openDialog, handleClose = props.handleClose, content = props.content;
    return (_jsx("div", { children: _jsxs(Dialog, { open: openDialog, onClose: handleClose, fullWidth: true, maxWidth: "sm", children: [_jsxs(DialogTitle, { className: classes.dialogHeader, disableTypography: true, children: [_jsx(PageHeader, { text: I18n.t('infoDialog.notes') }), _jsx(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: handleClose, children: _jsx(CloseIcon, {}) })] }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: content }) })] }) }));
};
export default InfoDialog;
