const footer = {
  footer: {
    imageTitle: 'Visit the Enphase Energy website',
    privacy: 'Privacy',
    terms: 'Terms',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. All rights reserved.',
    language: 'Language'
  }
};

export default footer;
