const accountInfo = {
	accountInfo: {
		account_information: 'Meine Kontoeinstellungen',
		services: 'Meine Services',
		data_privacy: 'Meine Daten & Privatsphäre',
		benefits: 'Vorteile',
		company: 'Firmenprofil',
		branches: 'Niederlassungen',
		users: 'Benutzer',
		area_coverage: 'Servicebereiche',
		lead_program: 'Leads-Einstellungen',
		ein_program: 'Leads & Enphase Installer Network',
		ac_module_admin: 'Wechselstrom-Modulverwaltung',
		enphase_store: 'Enphase Store',
		product_warranty_extension: 'Produktgarantie-Verlängerung',
		lpp: 'Arbeitsschutzprogramm',
		permit_assistance: 'Genehmigungsunterstützung',
		incentive_programs: 'Förderprogramme',
		ein_merchandise: 'EIN Merchandise',
		financing: 'Finanzierung',
		training: 'Training',
		collaterals: 'Sicherheiten',
		my_leads: 'Meine Leads',
		lead_details: 'Angebotsanfrage-Details',
		api_access: 'API-Zugriff',
		third_party: 'Meldungen durch Dritte',
		profiles: 'Netzprofile',
		createBranch: 'Niederlassung hinzufügen',
		branch_profile: 'Niederlassungsdetails',
		new: 'Neu',
		applications: 'Anwendungen',
		createUser: 'Neuen Benutzer hinzufügen',
		user_profile: 'Benutzerprofil',
		platform_discounts: 'Plattform-Rabatte',
		account_details: 'Kontodetails',
		preferences: 'Einstellungen',
		change_password: 'Passwort ändern',
		account: 'Konto',
		dashboard: 'Dashboard',
		systems: 'Systeme',
		admin: 'Administrator',
		einDashboard: 'EIN-Dashboard',
		einAdmin: 'EIN-Administrator',
		edit: 'Bearbeiten',
		failureMessage: 'Es ist ein Fehler aufgetreten, versuchen Sie es bitte erneut'
	}
};

export default accountInfo;
