import { ListingTableActions } from './constants';
export function assignReviewer(payload) {
    return { type: ListingTableActions.ASSIGN_REVIEWER_REQUEST, payload: payload };
}
export function getApplications(payload) {
    return { type: ListingTableActions.GET_APPLICATIONS_REQUEST, payload: payload };
}
export function getSiteSearchResults(payload) {
    return { type: ListingTableActions.SITE_SEARCH_REQUEST, payload: payload };
}
export function getReviewerList() {
    return { type: ListingTableActions.GET_REVIEWER_LIST_REQUEST };
}
export function getApplicationStats(payload) {
    return { type: ListingTableActions.GET_APPLICATIONS_STATS_REQUEST, payload: payload };
}
export function getAppliedSites() {
    return { type: ListingTableActions.GET_APPLIED_SITES_REQUEST };
}
export function isDrsEligible() {
    return { type: ListingTableActions.GET_DRS_ELIGIBLE_REQUEST };
}
