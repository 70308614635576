const common = {
  error: { unauthorized: 'Acesso não autorizado', required: 'Requeridas' },
  common: { mandatory: 'Campos obrigatórios' },
  utils: {
    dashboard: 'Painel',
    systems: 'Sistemas',
    account: 'Conta',
    services: 'Serviços',
    dataPrivacy: 'Dados privados',
    support: 'Suporte',
    admin: 'Admin',
    defaultMessage: 'Para baixar arquivos, desative o bloqueador de pop-ups em seu navegador',
    signInMessage: 'Você precisa fazer login ou inscrever-se antes de continuar',
    commonApiMessage: 'A solicitação não pôde ser processada',
    adminNew: 'Admin (Novo)'
  },
  message: {
    yelpWaitMessage: 'Aguarde a atualização do Yelp',
    googleWaitMessage: 'Aguarde até que o ID do local do Google seja atualizado',
    successMessageUpload: 'Arquivo carregado com sucesso.',
    failureMessageUpload: 'Alguma coisa deu errado. Por favor tente outra vez',
    serviceAreasCreateSuccess: 'Área de serviço criada com sucesso',
    serviceAreasUpdateSuccess: 'Área de serviço atualizada com sucesso',
    serviceAreasDeleteSuccess: 'Área de serviço excluída com sucesso',
    hardware_product: 'Produto de Hardware',
    rowsFailed: '%{failedCount}fora das %{totalCount} linhas falhou',
    accessRequestSuccess: 'Acesso solicitado com sucesso'
  },
  buttons: {
    cancel: 'Cancelar',
    edit: 'Editar',
    update: 'ATUALIZAR',
    updateAccount: 'Atualizar conta',
    submit: 'Enviar',
    submitUpperCase: 'ENVIAR',
    back: 'Voltar',
    save: 'Salve',
    show: 'Mostrar',
    add: 'Adicionar',
    downloadSampleFile: 'Baixar arquivo de amostra',
    proceed: 'Continuar',
    close: 'Perto'
  }
};

export default common;