var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChatActionTypes } from './constants';
var initialState = {
    message: '',
    messageType: '',
    currentlySending: false,
    messageList: [],
    unreadMessagesCount: 0,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types , @typescript-eslint/no-explicit-any
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ChatActionTypes.GET_ALL_MESSAGES_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ChatActionTypes.GET_ALL_MESSAGES_SUCCESS:
            return __assign(__assign({}, state), { messageList: action.messages, unreadMessagesCount: action.unread_messages_count });
        case ChatActionTypes.GET_ALL_MESSAGES_FAILURE:
            return __assign(__assign({}, state), { messageType: 'error', message: action.message, currentlySending: false });
        case ChatActionTypes.POST_MESSAGE_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ChatActionTypes.POST_MESSAGE_FAILURE:
            return __assign(__assign({}, state), { messageType: 'error', message: action.message, currentlySending: false });
        default:
            return state;
    }
};
export default reducer;
