import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ApplicantDetailsView from '../ApplicantDetailsView';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    panelHead: {
        background: theme.tertiary.panelHead,
        color: theme.tertiary.mainText,
        minHeight: '40px !important',
        height: '40px',
        cursor: 'unset !important',
    },
    panelDetails: {
        flexDirection: 'column',
    },
    heading: {
        fontWeight: 'bold',
        flexBasis: '90%',
        flexShrink: 0,
    },
}); });
var ApplicantDetails = function (props) {
    var classes = useStyles();
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Accordion, { expanded: true, children: [_jsx(AccordionSummary, { className: classes.panelHead, children: _jsx(Typography, { className: classes.heading, children: I18n.t('applicationDetails.applicantDetails') }) }), _jsx(AccordionDetails, { className: classes.panelDetails, children: _jsx(ApplicantDetailsView, { application: props.application }) })] }) }) }) }));
};
export default ApplicantDetails;
