var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ListingTableActions } from './constants';
var initialState = {
    message: '',
    messageType: '',
    currentlySending: false,
    siteSearchList: { total_count: 0, result: [] },
    getApplicationsRequestPending: false,
    drsEligibleRequestLoading: false,
    applicationsList: [],
    applicationsCount: 0,
    reviewerList: [],
    appStatsList: [],
    siteIds: [],
    drsIdForLeftPanel: '',
    isDrsEligible: false,
    allowedCourseIds: [],
    ensembleCertified: false,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types , @typescript-eslint/no-explicit-any
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ListingTableActions.SITE_SEARCH_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ListingTableActions.SITE_SEARCH_SUCCESS:
            return __assign(__assign({}, state), { siteSearchList: action.siteSearchList, currentlySending: false });
        case ListingTableActions.SITE_SEARCH_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case ListingTableActions.GET_APPLICATIONS_REQUEST:
            return __assign(__assign({}, state), { getApplicationsRequestPending: true });
        case ListingTableActions.GET_APPLICATIONS_SUCCESS:
            return __assign(__assign({}, state), { applicationsList: action.applicationsList, applicationsCount: action.applicationsCount, getApplicationsRequestPending: false });
        case ListingTableActions.GET_APPLICATIONS_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', getApplicationsRequestPending: false });
        case ListingTableActions.GET_REVIEWER_LIST_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ListingTableActions.GET_REVIEWER_LIST_SUCCESS:
            return __assign(__assign({}, state), { reviewerList: action.reviewerList, currentlySending: false });
        case ListingTableActions.GET_REVIEWER_LIST_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case ListingTableActions.GET_APPLICATIONS_STATS_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ListingTableActions.GET_APPLICATIONS_STATS_SUCCESS:
            return __assign(__assign({}, state), { appStatsList: action.appStatsList, currentlySending: false });
        case ListingTableActions.GET_APPLICATIONS_STATS_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case ListingTableActions.CAN_APPLY_DRS_FOR_CC_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ListingTableActions.CAN_APPLY_DRS_FOR_CC_SUCCESS:
            return __assign(__assign({}, state), { drsIdForLeftPanel: action.drsIdForLeftPanel, currentlySending: false });
        case ListingTableActions.CAN_APPLY_DRS_FOR_CC_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case ListingTableActions.GET_APPLIED_SITES_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case ListingTableActions.GET_APPLIED_SITES_SUCCESS:
            return __assign(__assign({}, state), { siteIds: action.siteIds, currentlySending: false });
        case ListingTableActions.GET_APPLIED_SITES_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', currentlySending: false });
        case ListingTableActions.GET_DRS_ELIGIBLE_REQUEST:
            return __assign(__assign({}, state), { drsEligibleRequestLoading: true });
        case ListingTableActions.GET_DRS_ELIGIBLE_SUCCESS:
            return __assign(__assign({}, state), { drsEligibleRequestLoading: false, isDrsEligible: action.isDrsEligible, allowedCourseIds: action.allowedCourseIds, ensembleCertified: action.ensembleCertified });
        case ListingTableActions.GET_DRS_ELIGIBLE_FAILURE:
            return __assign(__assign({}, state), { message: action.error, messageType: 'error', drsEligibleRequestLoading: false });
        default:
            return state;
    }
};
export default reducer;
