export const GET_LRE_PLAN_REQUEST = 'GET_LRE_PLAN_REQUEST';
export const GET_LRE_PLAN_SUCCESS = 'GET_LRE_PLAN_SUCCESS';
export const GET_LRE_PLAN_FAILURE = 'GET_LRE_PLAN_FAILURE';
export const GET_SITE_LIST_REQUEST = 'GET_SITE_LIST_REQUEST';
export const GET_SITE_LIST_SUCCESS = 'GET_SITE_LIST_SUCCESS';
export const GET_SITE_LIST_FAILURE = 'GET_SITE_LIST_FAILURE';
export const GET_PLAN_BY_SITES = 'GET_PLAN_BY_SITES';
export const GET_PLAN_BY_SITES_SUCCESS = 'GET_PLAN_BY_SITES_SUCCESS';
export const GET_PLAN_BY_SITES_FAILURE = 'GET_PLAN_BY_SITES_FAILURE';
export const GET_LRE_ITEMS = 'GET_LRE_ITEMS';
export const GET_LRE_ITEMS_SUCCESS = 'GET_LRE_ITEMS_SUCCESS';
export const GET_LRE_ITEMS_FAILURE = 'GET_LRE_ITEMS_FAILURE';
export const UPDATE_LRE_ITEMS = 'UPDATE_LRE_ITEMS';
export const UPDATE_LRE_SUCCESS = 'UPDATE_LRE_SUCCESS';
export const UPDATE_LRE_FAILURE = 'UPDATE_LRE_FAILURE';
export const ADD_ITEMS_TO_CART = 'ADD_ITEMS_TO_CART';
export const ADD_ITEMS_TO_CART_SUCCESS = 'ADD_ITEMS_TO_CART_SUCCESS';
export const ADD_ITEMS_TO_CART_FAILURE = 'ADD_ITEMS_TO_CART_FAILURE';
export const GET_SELECTED_ITEMS = 'GET_SELECTED_ITEMS';
export const GET_SELECTED_ITEMS_SUCCESS = 'GET_SELECTED_ITEMS_SUCCESS';
export const GET_SELECTED_ITEMS_FAILURE = 'GET_SELECTED_ITEMS_FAILURE';
export const SET_PURCHASED_LIST = 'SET_PURCHASED_LIST';
export const SET_PURCHASE = 'SET_PURCHASE';
export const SET_TRANSACTION_STATUS = 'SET_TRANSACTION_STATUS';
export const GET_AGREEMENT_REQUEST = 'GET_AGREEMENT_REQUEST';
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS';
export const GET_AGREEMENT_FAILURE = 'GET_AGREEMENT_FAILURE';
export const CREATE_SYSTEM_REQUEST = 'CREATE_SYSTEM_REQUEST';
export const CREATE_SYSTEM_SUCCESS = 'CREATE_SYSTEM_SUCCESS';
export const CREATE_SYSTEM_FAILURE = 'CREATE_SYSTEM_FAILURE';
