import {
  SET_COUNTRY_CODE_REQUEST,
  SET_MOBILE_VIEW,
  SET_ENLIGHTEN_MANAGER_TOKEN,
  GET_APPLICATION_NOTIFICATIONS_REQUEST,
  GET_APPLICATION_NOTIFICATIONS_FAILURE,
  GET_APPLICATION_NOTIFICATIONS_SUCCESS,
  READ_APPLICATION_NOTIFICATIONS_REQUEST,
  READ_APPLICATION_NOTIFICATIONS_FAILURE,
  SET_FEATURE_LIST,
  GET_SOLARGRAF_SESSION_SUCCESS,
  GET_SOLARGRAF_POPUP_DETAILS_REQUEST,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  SET_FROM_LEFT_TAB,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
} from './constants';

let initialState = {
  country: 'US',
  isMobileView: false,
  enlightenManagerToken: '',
  currentlySending: false,
  notificationRequestSending: false,
  fromLeftPanel: false,
  projectDetails: {},
  notificationList: [],
  unseenNotificationCount: '',
  message: '',
  messageType: '',
  featureList: [],
  solargrafToken: '',
  solargrafPopupDetails: {
    linkWithSolargrafSuccess: '',
    solargrafAccessAction: '',
    startSolargrafPopupFlow: false,
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRY_CODE_REQUEST:
      return { ...state, country: action.payload };
    case SET_MOBILE_VIEW:
      return { ...state, isMobileView: action.isMobileView };
    case SET_ENLIGHTEN_MANAGER_TOKEN:
      return { ...state, enlightenManagerToken: action.enlightenManagerToken };

    case GET_APPLICATION_NOTIFICATIONS_REQUEST:
      return { ...state, notificationRequestSending: true };
    case GET_APPLICATION_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationList: action.notificationList,
        notificationRequestSending: false,
        unseenNotificationCount: action.unseenNotificationCount,
      };
    case GET_APPLICATION_NOTIFICATIONS_FAILURE:
      return { ...state, message: action.error, messageType: 'error', notificationRequestSending: false };
    case READ_APPLICATION_NOTIFICATIONS_REQUEST:
      return { ...state };
    case READ_APPLICATION_NOTIFICATIONS_FAILURE:
      return { ...state, message: action.error, messageType: 'error' };
    case SET_FEATURE_LIST:
      return { ...state, featureList: action.featureList };
    case GET_SOLARGRAF_SESSION_SUCCESS:
      return {
        ...state,
        solargrafToken: action.solargrafToken,
      };
    case GET_SOLARGRAF_POPUP_DETAILS_REQUEST:
      return { ...state, solargrafPopupDetails: { ...state.solargrafPopupDetails, ...action.payload } };
    case GET_APPLICATION_REQUEST:
      return { ...state, currentlySending: true };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_APPLICATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case SET_FROM_LEFT_TAB:
      return { ...state, fromLeftPanel: action.fromLeftPanel };
    case GET_PROJECT_DETAILS_REQUEST:
      return { ...state, currentlySending: true };
    case GET_PROJECT_DETAILS_SUCCESS:
      return { ...state, currentlySending: false, projectDetails: action.projectDetails };
    case GET_PROJECT_DETAILS_FAILURE:
      return { ...state, currentlySending: false };
    default:
      return state;
  }
}

export default reducer;
