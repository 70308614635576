import React from 'react';

const SystemDetails = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g transform="translate(0 -0.164)">
        <rect fill="none" width="20" height="20" transform="translate(0 0.164)" />
        <path
          fill="#335977"
          d="M19.552,8.442l-4.1-3.2.567-.567,2.394,1.865a.632.632,0,0,0,.384.132.63.63,0,0,0,.384-1.128L13.509,1.132a.629.629,0,1,0-.769,1l2.268,1.764-.567.567-3.453-2.7a.643.643,0,0,0-.781,0L1.656,8.435a.626.626,0,0,0-.107.888.632.632,0,0,0,.882.107l.611-.479v7.8a1.264,1.264,0,0,0,1.26,1.26h12.6a1.264,1.264,0,0,0,1.26-1.26V8.958l.611.479a.63.63,0,0,0,.882-.107.618.618,0,0,0,.139-.391A.639.639,0,0,0,19.552,8.442Zm-7.688,8.311H9.344V11.712h2.521Zm5.041,0H13.125V11.712a1.264,1.264,0,0,0-1.26-1.26H9.344a1.264,1.264,0,0,0-1.26,1.26v5.041H4.3V7.969L10.6,3.06l6.3,4.915Z"
          transform="translate(-0.522 -0.37)"
        />
      </g>
    </svg>
  );
};

export default SystemDetails;
