const common = {
  error: {
    unauthorized: 'Unbefugter Zugriff',
    required: 'Erforderlich'
  },
  common: {
    mandatory: 'Pflichtfelder'
  },
  utils: {
    dashboard: 'Dashboard',
    systems: 'Systeme',
    account: 'Konto',
    services: 'Services',
    dataPrivacy: 'Daten / Datenschutz',
    support: 'Support',
    admin: 'Administrator',
    defaultMessage: 'Um Dateien herunterladen zu können, deaktivieren Sie bitte den Popup-Blocker in Ihrem Browser.',
    signInMessage: 'Sie müssen sich anmelden oder registrieren, um fortfahren zu können.',
    commonApiMessage: 'Die Anfrage konnte nicht verarbeitet werden.',
    adminNew: 'Admin(Neu)'
  },
  message: {
    yelpWaitMessage: 'Bitte warten Sie, bis Yelp aktualisiert wurde.',
    googleWaitMessage: 'Bitte warten Sie, bis die Google Place ID aktualisiert wurde.',
    successMessageUpload: 'Datei erfolgreich hochgeladen.',
    failureMessageUpload: 'Es ist ein Fehler aufgetreten, versuchen Sie es bitte erneut',
    serviceAreasCreateSuccess: 'Servicebereich erfolgreich erstellt',
    serviceAreasUpdateSuccess: 'Servicebereich erfolgreich aktualisiert',
    serviceAreasDeleteSuccess: 'Servicebereich erfolgreich gelöscht',
    hardware_product: 'Hardware-Produkt',
    rowsFailed: '%{failedCount} von %{totalCount} Zeilen fehlgeschlagen',
    accessRequestSuccess: 'Zugriff erfolgreich angefordert'
  },
  buttons: {
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    update: 'AKTUALISIEREN',
    updateAccount: 'Konto aktualisieren',
    submit: 'Senden',
    submitUpperCase: 'SENDEN',
    back: 'Zurück',
    save: 'Speichern',
    show: 'Anzeigen',
    add: 'Hinzufügen',
    downloadSampleFile: 'Musterdatei herunterladen',
    proceed: 'Proceed'
  }
};

export default common;
