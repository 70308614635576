import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    margin: theme.spacing(2.5),
    padding: '0px !important',
    border: '1px solid #c6c6c6',
  },
  dialogBody: {
    padding: theme.spacing(0, 5, 2.5),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2.5, 2.5),
    },
  },
  gotoSolargrafButton: {
    color: '#ffffff',
    display: 'flex',
    margin: '16px auto',
    borderRadius: 0,
    textTransform: 'none',
    fontSize: 18,
    width: theme.spacing(30),
    fontWeight: 'bold',
  },
  doubleArrowButton: {
    paddingLeft: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  closeButton: {
    display: 'flex',
    marginLeft: 'auto',
  },
  message: {
    color: '#43425D',
    fontSize: 18,
    marginBottom: theme.spacing(2.5),
  },
  note: {
    fontSize: 14,
    color: '#4A4A4A',
  },
}));

export default useStyles;
