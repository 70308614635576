import store from '../../../store';
import { FEATURES } from '../config/constants';
import { isEmpty, get } from 'lodash';

class Feature {
  getFeatureList() {
    return store.getState().appReducer.featureList;
  }

  isFeatureEnabled(featureName) {
    const featureList = this.getFeatureList();
    if (!isEmpty(featureList)) {
      const featureId = FEATURES[featureName] || '';
      const featureDetail = featureList.find((feature) => feature.feature_id === featureId);
      return get(featureDetail, 'is_enabled', false) || false;
    }
    return false;
  }
}

export default new Feature();
