import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) { return ({
    buttonStyle: {
        fontSize: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        textTransform: 'none',
        height: theme.spacing(4.75),
        padding: theme.spacing(0, 3),
        border: '1px solid',
        borderColor: theme.tertiary.buttonColor,
        color: theme.tertiary.buttonColor,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: '#f2ece9',
        },
    },
    adminActionButtons: {
        width: '100%',
        fontSize: theme.spacing(1.5),
        overflow: 'auto',
        textOverflow: 'ellipsis',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    disableStyle: {
        opacity: 0.5,
    },
    smaller: {
        fontSize: theme.spacing(1.7),
        padding: theme.spacing(0, 2),
        height: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    noMargin: {
        margin: 0,
    },
}); });
var OutlinedButton = function (props) {
    var classes = useStyles();
    var text = props.text, _a = props.size, size = _a === void 0 ? 'small' : _a, handleClick = props.handleClick, adminActionButtons = props.adminActionButtons, disabled = props.disabled, smaller = props.smaller, noMargin = props.noMargin;
    var classNames = clsx(classes.buttonStyle, disabled && classes.disableStyle, adminActionButtons && classes.adminActionButtons, smaller && classes.smaller, noMargin && classes.noMargin);
    return (_jsx(Button, { onClick: function (e) { return handleClick(e); }, size: size, className: classNames, disabled: disabled, disableRipple: true, children: text }));
};
export default OutlinedButton;
