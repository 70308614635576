export const GET_LEADS_REQUEST = 'GET_LEADS_REQUEST';
export const GET_LEADS_SUCCESS = 'GET_LEADS_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const PUT_ACCEPT_LEAD_REQUEST = 'PUT_ACCEPT_LEAD_REQUEST';
export const ACCEPT_LEAD_SUCCESS = 'ACCEPT_LEAD_SUCCESS';

// needed
export const GET_LEAD_DETAILS_REQUEST = 'GET_LEAD_DETAILS_REQUEST';
export const LEAD_DETAILS_SUCCESS = 'LEAD_DETAILS_SUCCESS';

export const POST_CREATE_QUOTE_REQUEST = 'POST_CREATE_QUOTE_REQUEST';
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS';
export const POST_REQUEST_FAILURE = 'POST_REQUEST_FAILURE';
export const GET_QUOTE_S3_URL_REQUEST = 'GET_QUOTE_S3_URL_REQUEST';
export const GET_QUOTE_S3_URL_SUCCESS = 'GET_QUOTE_S3_URL_SUCCESS';
export const PUT_QUOTE_UPLOAD_TO_S3_REQUEST = 'PUT_QUOTE_UPLOAD_TO_S3_REQUEST';
export const PUT_QUOTE_UPLOAD_SUCCESS = 'PUT_QUOTE_UPLOAD_SUCCESS';
export const GET_QUOTE_FILE_FROM_S3_REQUEST = 'GET_QUOTE_FILE_FROM_S3_REQUEST';
export const GET_QUOTE_FILE_FROM_S3_SUCCESS = 'GET_QUOTE_FILE_FROM_S3_SUCCESS';
export const GET_PANEL_MFG_REQUEST = 'GET_PANEL_MFG_REQUEST';
export const GET_PANEL_MFG_SUCCESS = 'GET_PANEL_MFG_SUCCESS';
export const GET_PANEL_MODEL_REQUEST = 'GET_PANEL_MODEL_REQUEST';
export const GET_PANEL_MODEL_SUCCESS = 'GET_PANEL_MODEL_SUCCESS';
export const GET_BOM_LIST_REQUEST = 'GET_BOM_LIST_REQUEST';
export const GET_BOM_LIST_SUCCESS = 'GET_BOM_LIST_SUCCESS';
export const GET_LEAD_S3_IMAGE_FILE_URL_REQUEST = 'GET_LEAD_S3_IMAGE_FILE_URL_REQUEST';
export const LEAD_S3_IMAGE_FILE_URL_SUCCESS = 'LEAD_S3_IMAGE_FILE_URL_SUCCESS';
export const GET_CART_URL_REQUEST = 'GET_CART_URL_REQUEST';
export const GET_CART_URL_REQUEST_SUCCESS = 'GET_CART_URL_REQUEST_SUCCESS';
export const GET_UPDATED_TAX_AND_SHIPMENT_REQUEST = 'GET_UPDATED_TAX_AND_SHIPMENT_REQUEST';
export const GET_UPDATED_TAX_AND_SHIPMENT_SUCCESS = 'GET_UPDATED_TAX_AND_SHIPMENT_SUCCESS';
export const GET_MASTER_APPLIANCES_REQUEST = 'GET_MASTER_APPLIANCES_REQUEST';
export const GET_MASTER_APPLIANCES_SUCCESS = 'GET_MASTER_APPLIANCES_SUCCESS';

// needed
export const SITE_SEARCH_REQUEST_LEAD = 'SITE_SEARCH_REQUEST_LEAD';
export const SITE_SEARCH_REQUEST_LEAD_SUCCESS = 'SITE_SEARCH_REQUEST_LEAD_SUCCESS';
export const SITE_SEARCH_REQUEST_LEAD_FAILURE = 'SITE_SEARCH_REQUEST_LEAD_FAILURE';

export const RESET_SITE_SEARCH_RESULT_LEAD_REQUEST = 'RESET_SITE_SEARCH_RESULT_LEAD_REQUEST';

// needed
export const SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST = 'SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST';
export const SEARCH_FOR_SITE_ID_IN_LEADS_SUCCESS = 'SEARCH_FOR_SITE_ID_IN_LEADS_SUCCESS';
export const SEARCH_FOR_SITE_ID_IN_LEADS_FAILURE = 'SEARCH_FOR_SITE_ID_IN_LEADS_FAILURE';

// needed
export const LINK_SITE_WITH_LEAD_REQUEST = 'LINK_SITE_WITH_LEAD_REQUEST';
export const LINK_SITE_WITH_LEAD_REQUEST_SUCCESS = 'LINK_SITE_WITH_LEAD_REQUEST_SUCCESS';
export const LINK_SITE_WITH_LEAD_REQUEST_FAILURE = 'LINK_SITE_WITH_LEAD_REQUEST_FAILURE';

export const GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST = 'GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST';

export const GET_MESSAGES_LIST_REQUEST = 'GET_MESSAGES_LIST_REQUEST';
export const GET_MESSAGES_LIST_SUCCESS = 'GET_MESSAGES_LIST_SUCCESS';
export const GET_MESSAGES_LIST_FAILURE = 'GET_MESSAGES_LIST_FAILURE';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';

export const GET_MESSAGE_FILE_S3_URL_REQUEST = 'GET_MESSAGE_FILE_S3_URL_REQUEST';
export const GET_MESSAGE_FILE_S3_URL_FAILURE = 'GET_MESSAGE_FILE_S3_URL_FAILURE';
export const GET_MESSAGE_FILE_S3_URL_SUCCESS = 'GET_MESSAGE_FILE_S3_URL_SUCCESS';

export const PUT_S3_URL_REQUEST = 'PUT_S3_URL_REQUEST';

export const GET_APPOINTMENT_HISTORY_REQUEST = 'GET_APPOINTMENT_HISTORY_REQUEST';
export const GET_APPOINTMENT_HISTORY_SUCCESS = 'GET_APPOINTMENT_HISTORY_SUCCESS';
export const GET_APPOINTMENT_HISTORY_FAILURE = 'GET_APPOINTMENT_HISTORY_FAILURE';

export const ACCEPT_APPOINTMENT_REQUEST = 'ACCEPT_APPOINTMENT_REQUEST';
export const ACCEPT_APPOINTMENT_FAILURE = 'ACCEPT_APPOINTMENT_FAILURE';
export const ACCEPT_APPOINTMENT_SUCCESS = 'ACCEPT_APPOINTMENT_SUCCESS';

export const CANCEL_APPOINTMENT_REQUEST = 'CANCEL_APPOINTMENT_REQUEST';
export const CANCEL_APPOINTMENT_FAILURE = 'CANCEL_APPOINTMENT_FAILURE';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';

export const REJECT_APPOINTMENT_REQUEST = 'REJECT_APPOINTMENT_REQUEST';
export const REJECT_APPOINTMENT_FAILURE = 'REJECT_APPOINTMENT_FAILURE';
export const REJECT_APPOINTMENT_SUCCESS = 'REJECT_APPOINTMENT_SUCCESS';

export const LEAD_REQUEST_APPOINTMENT_REQUEST = 'LEAD_REQUEST_APPOINTMENT_REQUEST';
export const LEAD_REQUEST_APPOINTMENT_FAILURE = 'LEAD_REQUEST_APPOINTMENT_FAILURE';
export const LEAD_REQUEST_APPOINTMENT_SUCCESS = 'LEAD_REQUEST_APPOINTMENT_SUCCESS';

// needed
export const SYSTEM_DETAILS_REQUEST = 'SYSTEM_DETAILS_REQUEST';
export const SYSTEM_DETAILS_FAILURE = 'SYSTEM_DETAILS_FAILURE';
export const SYSTEM_DETAILS_SUCCESS = 'SYSTEM_DETAILS_SUCCESS';

export const UNLINK_SITE_WITH_LEAD_REQUEST = 'UNLINK_SITE_WITH_LEAD_REQUEST';
export const UNLINK_SITE_WITH_LEAD_FAILURE = 'UNLINK_SITE_WITH_LEAD_FAILURE';
export const UNLINK_IN_PROGRESS = 'UNLINK_IN_PROGRESS';

export const GET_FINANCE_PROVIDERS_REQUEST = 'GET_FINANCE_PROVIDERS_REQUEST';
export const GET_FINANCE_PROVIDERS_FAILURE = 'GET_FINANCE_PROVIDERS_FAILURE';
export const GET_FINANCE_PROVIDERS_SUCCESS = 'GET_FINANCE_PROVIDERS_SUCCESS';
export const GET_PRESIGNED_URL_REQUEST = 'GET_PRESIGNED_URL_REQUEST';

export const GET_QUOTE_PROPOSAL_REQUEST = 'GET_QUOTE_PROPOSAL_REQUEST';
export const GET_QUOTE_PROPOSAL_FAILURE = 'GET_QUOTE_PROPOSAL_FAILURE';

export const CONTRACT_FILE_UPLOAD_TO_S3_REQUEST = 'CONTRACT_FILE_UPLOAD_TO_S3_REQUEST';

export const GET_CONTRACT_LEAD_JOURNEY_REQUEST = 'GET_CONTRACT_LEAD_JOURNEY_REQUEST';

export const GET_CONTRACT_LEAD_JOURNEY_SUCCESS = 'GET_CONTRACT_LEAD_JOURNEY_SUCCESS';

// needed
export const SET_LEFT_PANEL_OPEN_LIST = 'SET_LEFT_PANEL_OPEN_LIST';

// needed
export const SET_LEAD_DETAILS = 'SET_LEAD_DETAILS';

// needed
export const SET_PREVIOUS_PANEL_LENGTH = 'SET_PREVIOUS_PANEL_LENGTH';

export const ENLM_SEND_SG_QUOTE = 'ENLM_SEND_SG_QUOTE';

export const CREATE_LEAD_REQUEST = 'CREATE_LEAD_REQUEST';
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS';
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE';

export const SITE_SEARCH_LEAD_REQUEST = 'SITE_SEARCH_LEAD_REQUEST';
export const SITE_SEARCH_LEAD_SUCCESS = 'SITE_SEARCH_LEAD_SUCCESS';
export const SITE_SEARCH_LEAD_FAILURE = 'SITE_SEARCH_LEAD_FAILURE';
export const ENLM_OPEN_SG_PROPOSAL = 'ENLM_OPEN_SG_PROPOSAL';

export const ENLM_OPEN_SG_PROJECT = 'ENLM_OPEN_SG_PROJECT';

export const ENLM_SAVE_SG_PROJECT_ID_SUCCESS = 'ENLM_SAVE_SG_PROJECT_ID_SUCCESS';

// needed
export const GET_LEAD_FROM_JOURNEY_ID_REQUEST = 'GET_LEAD_FROM_JOURNEY_ID_REQUEST';
export const GET_LEAD_FROM_JOURNEY_ID_SUCCESS = 'GET_LEAD_FROM_JOURNEY_ID_SUCCESS';
export const GET_LEAD_FROM_JOURNEY_ID_FAILURE = 'GET_LEAD_FROM_JOURNEY_ID_FAILURE';

export const ENLM_ORDER_PROPOSAL_OK_CLICK = 'ENLM_ORDER_PROPOSAL_OK_CLICK';
export const SG_MEASUREMENT_PENDING = 'measurementsPending';

export const CHECK_EXISTING_EMAIL_REQUEST = 'CHECK_EXISTING_EMAIL_REQUEST';
export const CHECK_EXISTING_EMAIL_SUCCESS = 'CHECK_EXISTING_EMAIL_SUCCESS';
export const CHECK_EXISTING_EMAIL_FAILURE = 'CHECK_EXISTING_EMAIL_FAILURE';

export const IS_ACCOUNT_IN_ENLIGHTEN_REQUEST = 'IS_ACCOUNT_IN_ENLIGHTEN_REQUEST';
export const IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS = 'IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS';

// needed
export const GET_SOLARGRAF_FINANCIALS_REQUEST = 'GET_SOLARGRAF_FINANCIALS_REQUEST';
export const GET_SOLARGRAF_FINANCIALS_SUCCESS = 'GET_SOLARGRAF_FINANCIALS_SUCCESS';
export const GET_SOLARGRAF_FINANCIALS_FAILURE = 'GET_SOLARGRAF_FINANCIALS_FAILURE';

export const LEAD_STATES = [
  { id: -1, name: 'Rejected', estimated_date_interval: 0, api_map: 'EPRejectedByInstaller' },
  { id: 0, name: 'System Estimation', estimated_date_interval: 0, api_map: 'EPRecommended' },
  { id: 1, name: 'Lead Received', api_map: 'EPDetailsSentToInstaller' },
  {
    id: 2,
    name: 'Lead Claimed',
    stepName: 'Lead Review',
    estimated_date_interval: 1,
    api_map: 'EPAcceptedByInstaller',
  },
  {
    id: 3,
    name: 'Quote Sent to Homeowner',
    stepName: 'Quote Creation',
    estimated_date_interval: 1,
    api_map: 'EPQuoteGenerated',
  },
  {
    id: 4,
    name: 'Quote Seen by Homeowner',
    stepName: 'Homeowner views the quote',
    estimated_date_interval: 0,
    api_map: 'EPQuoteSeen',
  },
  {
    id: 5,
    name: 'Appointment Set Up',
    stepName: 'Appointment Setting',
    estimated_date_interval: 1,
    api_map: 'EPInstallerContacted',
  },
  { id: 6, name: 'Site Assesed', stepName: 'Site Assesment', estimated_date_interval: 3, api_map: 'EPSiteAssesed' },
  {
    id: 7,
    name: 'Contract Signed',
    stepName: 'Contract Signature',
    estimated_date_interval: 4,
    api_map: 'EPContractSigned',
  },
  {
    id: 8,
    name: 'Permit Completed',
    stepName: 'Permit Completion',
    estimated_date_interval: 15,
    api_map: 'EPPermitCompleted',
  },
  {
    id: 9,
    name: 'Installation Done',
    stepName: 'Installation',
    estimated_date_interval: 10,
    api_map: 'EPInstallationDone',
  },
  {
    id: 10,
    name: 'System Operational',
    stepName: 'System Activation',
    estimated_date_interval: 1,
    api_map: 'EPSystemReady',
  },
];

export const ORDER_STATES = [
  { id: -21, name: 'Order Cancelled', api_map: 'EPOrderCancelled', estimated_date_interval: 0 },
  { id: 81, name: 'Order Processing', api_map: 'EPOrderProcessing', estimated_date_interval: 0 },
  { id: 82, name: 'Order Confirmed', api_map: 'EPOrderConfirmed', estimated_date_interval: 1 },
  { id: 83, name: 'Order Shipped', api_map: 'EPOrderShipped', estimated_date_interval: 2 },
  { id: 84, name: 'Order Delivered', api_map: 'EPOrderDelivered', estimated_date_interval: 10 },
];

export const LEAD_CONTRACT_SHARED = {
  id: 61,
  name: 'Contract Shared',
  estimated_date_interval: 0,
  api_map: 'EPContractShared',
};

export const LEAD_REJECTED = {
  id: -1,
  name: 'Rejected',
  estimated_date_interval: 0,
  api_map: 'EPRejectedByInstaller',
};

export const LEAD_CONTRACT_REJECTED = {
  id: -3,
  name: 'Contract Rejected',
  estimated_date_interval: 0,
  api_map: 'EPContractRejected',
};

export const CONFIRMATION_TERMS = (isUpgrade = false) => [
  isUpgrade
    ? 'You are expected to respond and contact each lead within 1 business day'
    : 'You are expected to respond and contact each lead within 2 business days',
  'Enphase will assist in scheduling, appointment, project updates and technical support',
  'As part of the program only Enphase products shall be used',
  'You will take appropriate technical and organizational measures to ensure that processing of any customer personal data provided in this lead is done in compliance with applicable privacy or data protection laws.',
  'You will comply with all the <a href="https://enphase.com/en-us/legal/enphase-installer-network-terms">terms and conditions</a> applicable to your participation in this program.',
];

export const LEAD_LISTING_COLUMNS = [
  { id: 'ho_name', label: 'HOME OWNER NAME', minWidth: 170 },
  { id: 'zip', label: 'ZIP', minWidth: 100 },
  { id: 'address', label: 'ADDRESS', minWidth: 170 },
  // { id: 'l_type', label: 'LEAD TYPE', minWidth: 100 },
  { id: 'l_generated_by', label: 'GENERATED BY', minWidth: 100 },
  { id: 'l_requirement', label: 'REQUIREMENT', minWidth: 100 },
  { id: 'l_type', label: 'TYPE', minWidth: 100 },
  { id: 'q_status', label: 'STATUS', minWidth: 100 },
  { id: 'r_date', label: 'RECEIVED ON', minWidth: 100 },
  { id: 'actions', label: '', minWidth: 100 },
];

export const BOM_INVERTERS = [
  {
    name: 'Enphase IQ 7',
    value: 'Enphase IQ 7',
    id: 1,
    bb_mapping: 'iq7_60_micro',
    show_for_new_ho: true,
    sdt_mapping: 'IQ7',
  },
  {
    name: 'Enphase IQ 7X',
    value: 'Enphase IQ 7X',
    id: 2,
    bb_mapping: 'iq7x_96_2_micro',
    show_for_new_ho: true,
    sdt_mapping: 'IQ7X',
  },
  {
    name: 'Enphase IQ 7A',
    value: 'Enphase IQ 7A',
    id: 3,
    bb_mapping: 'iq7a_72_2_micro',
    show_for_new_ho: true,
    sdt_mapping: 'IQ7A',
  },
  { name: 'Enphase IQ 6+', value: 'Enphase IQ 6+', id: 4, bb_mapping: '', show_for_new_ho: false, sdt_mapping: 'IQ6+' },
  { name: 'Enphase IQ 6', value: 'Enphase IQ 6', id: 5, bb_mapping: '', show_for_new_ho: false, sdt_mapping: 'IQ6' },
  {
    name: 'Enphase IQ7-PD-72',
    value: 'Enphase IQ7-PD-72',
    id: 6,
    bb_mapping: 'iq_pd_72_1micro_kit',
    show_for_new_ho: false,
    sdt_mapping: 'IQ7-PD-72',
  },
  {
    name: 'Enphase IQ7-PD-84',
    value: 'Enphase IQ7-PD-84',
    id: 7,
    bb_mapping: 'iq_pd_84_1micro_kit',
    show_for_new_ho: false,
    sdt_mapping: 'IQ7-PD-84',
  },
  {
    name: 'Enphase IQ7+',
    value: 'Enphase IQ7+',
    id: 8,
    bb_mapping: 'iq7_plus_72_micro',
    show_for_new_ho: true,
    sdt_mapping: 'IQ7+',
  },
];

export const STORAGE_LIST = [3.4, 6.7, 10.1, 13.4, 16.8, 20.2, 23.5, 26.9, 30.2, 33.6, 37, 40.3];

export const PANEL_ACM_MANUFACTURERS = [
  // Removing LONGi: But id needs to be maintained
  // { name: 'LONGi', value: 'LONGi', id: 1, calc_divisible_by: 300 },
  // Removing Solaria
  // { name: 'Solaria', value: 'Solaria', id: 2, calc_divisible_by: 355 },
  { name: 'Hanwha', value: 'Hanwha', id: 3, calc_divisible_by: 300 },
];

export const PANEL_ACM_MODEL = [
  { mfg_id: 1, name: 'LONGi 300-AC', value: 'LONGi 300-AC', id: 1 },
  { mfg_id: 2, name: 'Solaria PowerXT-355R-AC', value: 'Solaria PowerXT-355R-AC', id: 2 },
  { mfg_id: 3, name: 'Hanwha Q.PEAK DUO BLK-G6+/AC 340', value: 'Hanwha Q.PEAK DUO BLK-G6+/AC 340', id: 3 },
];

export const QUOTE_FILE_UPLOAD_SIZE = 20;

export const REJECT_RESONS = [
  { id: 1, reason: 'Homeowner address is too far' },
  { id: 2, reason: 'Lack of time/resources to service this lead' },
  { id: 3, reason: 'Already in contact with this homeowner' },
  { id: 4, reason: 'System requirement is too small' },
  { id: 5, reason: 'System requirement is too big' },
];

export const REJECT_RESONS_FOR_NOT_ORDERING_FROM_STORE = [
  { id: 1, reason: 'I already have the product inventory' },
  { id: 2, reason: 'I buy products on credit from a distributor' },
  { id: 3, reason: 'Store price is too high' },
];

export const BUY_FROM_ENPHASE_BENIFITS = [
  {
    id: 1,
    benifit: 'Qualified Lead with Enphase sales support through the entire process',
    est_val: '>$1000',
    isBold: false,
    havingLink: false,
    linkLabel: '',
    linkHref: '',
  },
  {
    id: 2,
    benifit: 'Access to SGIP application service',
    est_val: '$400',
    isBold: false,
    havingLink: true,
    linkLabel: 'view details',
    linkHref: 'https://enphase.com/en-us/sgip-overview',
  },
  {
    id: 3,
    benifit: '5-Years Labor Protection Program for each micro installed',
    est_val: '$300',
    isBold: false,
    havingLink: true,
    linkLabel: 'view details',
    linkHref: 'https://enphase.com/en-us/lpp-overview',
  },
  {
    id: 4,
    benifit: 'Access to Permit assistance service',
    est_val: '$300',
    isBold: false,
    havingLink: true,
    linkLabel: 'view details',
    linkHref: 'https://enphase.com/en-us/permit-overview',
  },
  {
    id: 5,
    benifit: 'Flexible delivery options',
    est_val: '$200',
    isBold: false,
    havingLink: false,
    linkLabel: '',
    linkHref: '',
  },
  {
    id: 6,
    benifit: 'Total Estimated Value',
    est_val: '>$2200',
    isBold: true,
    havingLink: false,
    linkLabel: '',
    linkHref: '',
  },
];

export const TOOLTIP_TURNKEY =
  'Turn-key Services means installer provides all bill of material, designing, permitting, installation and commissioning as applicable.';
export const TOOLTIP_INSTALLATION_ONLY =
  'Installation Only Services means homeowner procures Enphase bill of material from Enphase Store. All other materials and services are provided directly by the installer (wiring, racking, designing, permitting, installation and commissioning as applicable)';

export const quoteDataObj = {
  pv_size: '',
  storage_capacity: '',
  panel_type: '',
  panel_qty: '',
  tilt: '',
  inverter_type: '',
  inverter_qty: '',
  encharge10_qty: '',
  encharge3_qty: '',
  quote_url: '',
  total_price: '',
  system_price: '',
  installation_price: '',
  incentives: '',
  final_price: '',
  savings: '',
  payback_period: '',
  panel_model: '',
  panel_mfg: '',
  s3_quote_file_name: '',
  bom_items: [],
  incentives_list: [],
  order_from_store: null,
  reason_for_not_ordering: '',
  custom_reason: '',
  system_price_tax: '',
  system_price_shipment: '',
  shipment_info: {},
  installer_note: '',
  can_share_SDT_pdf: false,
  cash_quote: true,
  loan_quote: false,
  lq_total_price: '',
  lq_installation_price: '',
  lq_incentives: '',
  lq_final_price: '',
  lq_savings: '',
  lq_payback_period: '',
  lq_loan_amount: '',
  lq_interest_rate: '',
  lq_loan_term_in_months: '',
  lq_downpayment: '',
  lq_monthly_payment: '',
  lq_selected_finance_provider: '',
  lq_custom_finance_provider: '',
};

export const SOLARGRAF_ENCHARGE3_ID = 'a2a58461-53eb-4a5b-8bf5-4a73b4f1a73c';
export const SOLARGRAF_ENCHARGE10_ID = '5f47d35f-3908-4011-afc5-6d5df35d5e71';

export const SG_BOM_INVERTERS = [
  {
    id: '6369',
    bb_mapping: 'iq7_60_micro',
    sdt_mapping: 'IQ7',
  },
  {
    id: '6370',
    bb_mapping: 'iq7_plus_72_micro',
    sdt_mapping: 'IQ7+',
  },
  {
    id: '6371',
    bb_mapping: 'iq7x_96_2_micro',
    sdt_mapping: 'IQ7X',
  },
  {
    id: 'd1f3ffb3-5311-4828-a55d-22765ef5e94c',
    bb_mapping: 'iq7a_72_2_micro',
    sdt_mapping: 'IQ7A',
  },
  {
    id: '0a46a664-356e-4c0f-9457-f87928f106dd',
    bb_mapping: 'iq7a_72_2_micro',
    sdt_mapping: 'IQ7A',
  },
];
