import React from 'react';

const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0,0H24V24H0Z" />
    <path
      fill="#62b15a"
      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default SuccessIcon;
