import React from 'react';

function ChatSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <defs />
      <g transform="translate(5.625 5.625)">
        <path
          fill="#fff"
          d="M108.188,96H96.938a.938.938,0,0,0,0,1.875h11.25a.938.938,0,0,0,0-1.875Z"
          transform="translate(-96 -96)"
        />
      </g>
      <g transform="translate(5.625 9.375)">
        <path
          fill="#fff"
          d="M104.438,160h-7.5a.938.938,0,0,0,0,1.875h7.5a.938.938,0,0,0,0-1.875Z"
          transform="translate(-96 -160)"
        />
      </g>
      <path
        fill="#fff"
        d="M20.625,0H3.75A3.754,3.754,0,0,0,0,3.75V22.5a.937.937,0,0,0,.54.849.951.951,0,0,0,.4.088.937.937,0,0,0,.6-.218L6.9,18.75H20.625A3.754,3.754,0,0,0,24.375,15V3.75A3.754,3.754,0,0,0,20.625,0ZM22.5,15a1.877,1.877,0,0,1-1.875,1.875H6.563a.936.936,0,0,0-.6.218L1.875,20.5V3.75A1.877,1.877,0,0,1,3.75,1.875H20.625A1.877,1.877,0,0,1,22.5,3.75Z"
      />
      <g transform="translate(7.5 7.5)">
        <path
          fill="#fff"
          d="M146.75,128a.938.938,0,0,0,0,1.875,1.877,1.877,0,0,1,1.875,1.875v15.861l-3.165-2.531a.945.945,0,0,0-.585-.2H131.75A1.877,1.877,0,0,1,129.875,143v-.937a.938.938,0,0,0-1.875,0V143a3.754,3.754,0,0,0,3.75,3.75h12.795l4.431,3.546a.938.938,0,0,0,1.524-.733V131.75A3.754,3.754,0,0,0,146.75,128Z"
          transform="translate(-128 -128)"
        />
      </g>
    </svg>
  );
}

export default ChatSvg;
