var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AdminActionTypes } from './constants';
var initialState = {
    message: '',
    messageType: '',
    currentlySending: false,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types , @typescript-eslint/no-explicit-any
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case AdminActionTypes.UPDATE_ADMIN_DOCS_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case AdminActionTypes.UPDATE_ADMIN_DOCS_SUCCESS:
            return __assign(__assign({}, state), { message: action.message, messageType: 'success', currentlySending: false });
        case AdminActionTypes.UPDATE_ADMIN_DOCS_FAILURE:
            return __assign(__assign({}, state), { message: action.message, messageType: 'failure', currentlySending: false });
        case AdminActionTypes.UPDATE_STATUS_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case AdminActionTypes.UPDATE_STATUS_SUCCESS:
            return __assign(__assign({}, state), { message: action.message, messageType: 'success', currentlySending: false });
        case AdminActionTypes.UPDATE_STATUS_FAILURE:
            return __assign(__assign({}, state), { message: action.message, messageType: 'failure', currentlySending: false });
        case AdminActionTypes.REQUEST_TIER2_APPROVAL_REQUEST:
            return __assign(__assign({}, state), { currentlySending: true });
        case AdminActionTypes.REQUEST_TIER2_APPROVAL_SUCCESS:
            return __assign(__assign({}, state), { message: action.message, messageType: 'success', currentlySending: false });
        case AdminActionTypes.REQUEST_TIER2_APPROVAL_FAILURE:
            return __assign(__assign({}, state), { message: action.message, messageType: 'failure', currentlySending: false });
        default:
            return state;
    }
};
export default reducer;
