const common = {
  error: {
    unauthorized: 'Acceso no autorizado',
    required: 'Requerido'
  },
  common: {
    mandatory: 'Campos obligatorios'
  },
  utils: {
    dashboard: 'Panel',
    systems: 'Sistemas',
    account: 'Cuenta',
    services: 'Servicios',
    dataPrivacy: 'Datos y privacidad',
    support: 'Soporte técnico',
    admin: 'Admin',
    defaultMessage: 'Para descargar archivos, desactiva el bloqueador de ventanas emergentes en tu navegador',
    signInMessage: 'Debes iniciar sesión o registrarte antes de continuar',
    commonApiMessage: 'No se pudo procesar la solicitud',
    adminNew: 'Admin (nuevo)'
  },
  message: {
    yelpWaitMessage: 'Espera hasta que Yelp se actualice',
    googleWaitMessage: 'Espera hasta que la place id de Google se actualice',
    successMessageUpload: 'Archivo cargado correctamente.',
    failureMessageUpload: 'Algo salió mal, vuelve a intentarlo',
    serviceAreasCreateSuccess: 'Área de servicio creada correctamente',
    serviceAreasUpdateSuccess: 'Área de servicio actualizada correctamente',
    serviceAreasDeleteSuccess: 'Área de servicio eliminada correctamente',
    hardware_product: 'Producto de hardware',
    rowsFailed: '%{failedCount} de %{totalCount} filas han fallado',
    accessRequestSuccess: 'Acceso solicitado correctamente'
  },
  buttons: {
    cancel: 'Cancelar',
    edit: 'Editar',
    update: 'ACTUALIZAR',
    updateAccount: 'Actualizar cuenta',
    submit: 'Enviar',
    submitUpperCase: 'ENVIAR',
    back: 'Volver',
    save: 'Guardar',
    show: 'Mostrar',
    add: 'Añadir',
    downloadSampleFile: 'Descargar archivo de muestra',
    proceed: 'Proceed'
  }
};

export default common;
