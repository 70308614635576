import { AdminActionTypes } from './constants';
export function updateAdminDocs(payload) {
    return { type: AdminActionTypes.UPDATE_ADMIN_DOCS_REQUEST, payload: payload };
}
export function updateStateActionFunction(payload) {
    return { type: AdminActionTypes.UPDATE_STATUS_REQUEST, payload: payload };
}
export function requestTier2Action(payload) {
    return { type: AdminActionTypes.REQUEST_TIER2_APPROVAL_REQUEST, payload: payload };
}
export function downloadAll(payload) {
    return { type: AdminActionTypes.DOWNLOAD_ALL_FILES_REQUEST, payload: payload };
}
export function setStatusToUnderReview(payload) {
    return { type: AdminActionTypes.SET_STATUS_TO_UNDER_REVIEW_REQUEST, payload: payload };
}
