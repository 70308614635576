const common = {
  error: {
    unauthorized: 'Unauthorized Access',
    required: 'Required'
  },
  common: {
    mandatory: 'Mandatory Fields'
  },
  utils: {
    dashboard: 'Dashboard',
    systems: 'Systems',
    account: 'Account',
    services: 'Services',
    dataPrivacy: 'Data & Privacy',
    support: 'Support',
    admin: 'Admin',
    defaultMessage: 'To download files, please disable popup blocker in your browser',
    signInMessage: 'You need to sign in or sign up before continuing',
    commonApiMessage: 'Request could not be processed',
    adminNew: 'Admin(New)'
  },
  message: {
    yelpWaitMessage: 'Wait for Yelp to update',
    googleWaitMessage: 'Wait for Google place id to update',
    successMessageUpload: 'File uploaded successfully.',
    failureMessageUpload: 'Something went wrong, please try again',
    serviceAreasCreateSuccess: 'Service area created successfully',
    serviceAreasUpdateSuccess: 'Service area updated successfully',
    serviceAreasDeleteSuccess: 'Service area deleted successfully',
    hardware_product: 'Hardware Product',
    rowsFailed: '%{failedCount} out of %{totalCount} rows failed',
    accessRequestSuccess: 'Access Requested Successfully'
  },
  buttons: {
    cancel: 'Cancel',
    edit: 'Edit',
    update: 'UPDATE',
    updateAccount: 'Update Account',
    submit: 'Submit',
    submitUpperCase: 'SUBMIT',
    back: 'Back',
    save: 'Save',
    show: 'Show',
    add: 'Add',
    downloadSampleFile: 'Download Sample File',
    proceed: 'Proceed',
    close: 'Close'
  }
};

export default common;
