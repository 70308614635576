var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { DefaultLayout, AdminLayout } from '../containers/Layouts/index';
import DesignReviewService from '../containers/DesignReviewService';
import DRSListing from '../containers/DRSListing';
import RequestDetails from '../containers/Admin/RequestDetails';
import ApplicationStatus from '../containers/Installer/ApplicationStatus';
export var browserHistory = createBrowserHistory();
var Routes = /** @class */ (function (_super) {
    __extends(Routes, _super);
    function Routes() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Routes.prototype.render = function () {
        return (_jsx(Router, { history: browserHistory, children: _jsxs(Switch, { children: [_jsx(Route, { exact: true, path: "/drs", children: _jsx("div", { children: "hello" }) }), _jsx(AdminLayout, { exact: true, path: "/request/:drs_id", component: RequestDetails }), _jsx(DefaultLayout, { exact: true, path: "/manager/dashboard/design-review/:drs_id", component: DesignReviewService }), _jsx(DefaultLayout, { exact: true, path: "/manager/dashboard/design-review/:drs_id/application-status", component: ApplicationStatus }), _jsx(DefaultLayout, { exact: true, path: "/manager/dashboard/:site_id/design-review/:drs_id", component: DesignReviewService }), _jsx(DefaultLayout, { exact: true, path: "/manager/dashboard/:site_id/design-review/:drs_id/application-status", component: ApplicationStatus }), _jsx(DefaultLayout, { exact: true, path: "/manager/dashboard/design-review", component: DRSListing })] }) }));
    };
    return Routes;
}(Component));
export default Routes;
