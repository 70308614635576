const footer = {
  footer: {
    imageTitle: 'Visita el sitio web de Enphase Energy.',
    privacy: 'Privacidad',
    terms: 'Términos',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Todos los derechos reservados.',
    language: 'Idioma'
  }
};

export default footer;
