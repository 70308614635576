import React from 'react';

const HelpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
      <g transform="translate(0.5 0.5)">
        <circle
          fill="none"
          stroke="rgba(105, 103, 102, 0.84)"
          strokeLinecap="round"
          strokeMiterlimit={10}
          cx="6"
          cy="6"
          r="6"
        />
        <path
          fill="rgba(105, 103, 102, 0.84)"
          d="M19.863,17.253v-.39a1.26,1.26,0,0,1,.285-.856l.637-.875a1.6,1.6,0,0,0,.446-1,.854.854,0,0,0-.922-.884.973.973,0,0,0-.989.9.084.084,0,0,1-.1.076l-.6-.1a.084.084,0,0,1-.076-.1,1.7,1.7,0,0,1,1.788-1.512,1.6,1.6,0,0,1,1.72,1.616,1.979,1.979,0,0,1-.485,1.236l-.637.875a.98.98,0,0,0-.247.713v.3a.09.09,0,0,1-.095.095h-.627A.088.088,0,0,1,19.863,17.253Zm-.047.913a.09.09,0,0,1,.1-.095h.723a.09.09,0,0,1,.095.095v.818a.09.09,0,0,1-.095.095h-.723a.09.09,0,0,1-.1-.095Z"
          transform="translate(-14.252 -9.819)"
        />
      </g>
    </svg>
  );
};

export default HelpIcon;
