import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import ErrorBoundary from '../lib/components/ErrorBoundary';
import { theme } from '../lib/theme';
import store from '../lib/store';
import Routes from '../lib/routes';

/**
 * Primary component to integrate MUI theme, store, airbrake
 * @returns routes with MUI theme, store integrated
 */
const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
