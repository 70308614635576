import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import { find, get } from 'lodash';
import { APPLICATION_STATES } from '../../../utils/constant';
import { statesEnumToList } from '../../../utils/helper';
import I18n from '../../../config/locales';
var SelectMultipleOptions = function (props) {
    var selectMultipleRender = function (selected, optionList) {
        optionList.push({ name: 'All', nameForInstaller: 'All', id: -1 });
        return selected
            .map(function (ele) {
            return I18n.t(get(find(optionList, function (option) {
                return option.id === ele;
            }), props.isInstaller ? 'nameForInstaller' : 'name'));
        })
            .join(', ');
    };
    return (_jsxs(Select, { className: props.classes.styleSelect, classes: {
            root: props.classes.selectInput,
        }, MenuProps: props.SelectMenuProps, onChange: function (e) { return props.handleStatusChange(e.target.value); }, value: props.stateIds, renderValue: function (selected) {
            return selectMultipleRender(selected, statesEnumToList(APPLICATION_STATES));
        }, multiple: true, children: [_jsxs(MenuItem, { value: -1, children: [_jsx(Checkbox, { className: props.classes.inputCheckbox, checked: props.stateIds.includes(-1) }), _jsx(ListItemText, { primary: I18n.t('drsListingTable.all') })] }, props.index), statesEnumToList(APPLICATION_STATES).map(function (state, ind) {
                if (props.showStateFilter(state.id)) {
                    if ([APPLICATION_STATES.NEW_APPLICATION_RECEIVED.id, APPLICATION_STATES.RESUBMISSION_RECEIVED.id].includes(state.id)) {
                        return (_jsxs(MenuItem, { value: state.id, children: [_jsx(Checkbox, { className: props.classes.inputCheckbox, checked: props.stateIds.includes(state.id) }), _jsx(ListItemText, { primary: props.isInstaller ? I18n.t(state.nameForInstaller) : I18n.t(state.name) })] }, 'state-' + ind));
                    }
                    else {
                        return (_jsxs(MenuItem, { value: state.id, children: [_jsx(Checkbox, { className: props.classes.inputCheckbox, checked: props.stateIds.includes(state.id) }), _jsx(ListItemText, { primary: I18n.t(state.name) })] }, 'state-' + ind));
                    }
                }
            })] }));
};
export default SelectMultipleOptions;
