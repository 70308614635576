const common = {
  error: {
    unauthorized: 'Accesso non autorizzato',
    required: 'Obbligatorio'
  },
  common: {
    mandatory: 'Campi obbligatori'
  },
  utils: {
    dashboard: 'Dashboard',
    systems: 'Sistemi',
    account: 'Account',
    services: 'Servizi',
    dataPrivacy: 'Dati e Privacy',
    support: 'Assistenza',
    admin: 'Amministrazione',
    defaultMessage: 'Per scaricare i file, devi disabilitare il blocco popup del browser',
    signInMessage: 'Prima di continuare, devi accedere o registrarti',
    commonApiMessage: 'Non è stato possibile elaborare la richiesta',
    adminNew: 'Amministratore (nuovo)'
  },
  message: {
    yelpWaitMessage: "Attendi l'aggiornamento Yelp",
    googleWaitMessage: "Attendi l'aggiornamento dell'ID Google Places",
    successMessageUpload: 'File caricato correttamente.',
    failureMessageUpload: 'Si è verificato un errore, riprovare',
    serviceAreasCreateSuccess: 'Area dei servizi creata correttamente',
    serviceAreasUpdateSuccess: 'Area dei servizi aggiornata correttamente',
    serviceAreasDeleteSuccess: 'Area dei servizi eliminata correttamente',
    hardware_product: 'Prodotto hardware',
    rowsFailed: '%{failedCount} su %{totalCount} righe non riuscite',
    accessRequestSuccess: 'Accesso richiesto correttamente'
  },
  buttons: {
    cancel: 'Annulla',
    edit: 'Modifica',
    update: 'AGGIORNA',
    updateAccount: 'Aggiorna account',
    submit: 'Invia',
    submitUpperCase: 'INVIA',
    back: 'Indietro',
    save: 'Salva',
    show: 'Mostra',
    add: 'Aggiungi',
    downloadSampleFile: 'Scarica file di esempio',
    proceed: 'Proceed'
  }
};

export default common;
