import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Accordion, AccordionDetails, Box } from '@material-ui/core';
import ApplicantDetailsView from '../ApplicantDetailsView';
import ViewApplication from '../images/ViewApplication';
import clsx from 'clsx';
import I18n from '../../../../config/locales';
var useStyles = makeStyles(function (theme) { return ({
    panelHead: {
        background: theme.tertiary.panelHead,
        color: theme.tertiary.mainText,
        minHeight: '40px !important',
        height: '40px',
        cursor: 'unset !important',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    displayFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    panelDetails: {
        flexDirection: 'column',
    },
    heading: {
        fontWeight: 'bold',
    },
    highlightedColor: {
        color: '#016AE3',
        marginLeft: theme.spacing(1),
    },
    pointerBox: {
        cursor: 'pointer',
    },
}); });
var ApplicantDetails = function (props) {
    var classes = useStyles();
    return (_jsx(Fragment, { children: _jsx(Grid, { container: true, direction: "row", children: _jsx(Grid, { item: true, xs: 12, children: _jsxs(Accordion, { expanded: true, children: [_jsxs(Box, { className: clsx(classes.displayFlex, classes.panelHead), children: [_jsx(Typography, { className: classes.heading, children: I18n.t('applicationDetails.applicationDetails') }), _jsxs(Box, { display: "flex", alignItems: "center", className: classes.pointerBox, onClick: props.redirectToApplication, children: [_jsx(ViewApplication, { disabled: false }), _jsx(Typography, { className: classes.highlightedColor, children: I18n.t('applicationDetails.viewApplication') })] })] }), _jsx(AccordionDetails, { className: classes.panelDetails, children: _jsx(ApplicantDetailsView, { projectDetails: props.projectDetails }) })] }) }) }) }));
};
export default ApplicantDetails;
