const designReviewService = {
  All: 'Alle',
  Document: 'Dokument',
  Image: 'Bild',
  File: 'Datei',
  drsDashboard: {
    pageHeader: 'Entwurfsprüfungsanfrage',
    createNewTicket: 'Eine neue Anfrage erstellen',
    filter: 'Filtern',
    all: 'Alle',
    tier1: 'Stufe 1',
    tier2: 'Stufe 2',
    country: 'Land',
    us: 'USA',
    australia: 'Australien',
    hideChart: 'Diagramm ausblenden',
    currentlyAssigned: 'Derzeit zugewiesen',
    overAllStatus: 'Gesamtstatus des Antrages',
    totalNoOf: 'Total number of ',
    applications: 'Applications',
    allApplication: 'Alle Anträge',
    note: 'Hinweis: Die untenstehende Tabelle ist gefiltert mit kommerziellen Designprüfungsanträgen. Bitte',
    clickHere: 'hier klicken',
    viewAllApplication: 'um alle Anträge anzuzeigen',
    projectType: 'WOHNIMMOBILIEN',
    service: 'Designprüfungsdienst',
    assignedTo: 'Zuweisen an',
    assign: 'Zuweisen',
    germany: 'Deutschland',
    belgium: 'Belgien',
    austria: 'Österreich',
    netherlands: 'Niederlande',
    france: 'France',
    switzerland: 'Switzerland',
  },
  applicationStatus: {
    pageHeader: 'Entwurfsprüfungsdienst',
    applicationSubmitted: 'Antrag eingereicht für den Enphase Speicher Zertifizierungskurs',
    resubmittedRequest: 'Erforderliche Informationen erneut einreichen',
    shareInformation:
      'Wir werden die bereitgestellten Informationen mit dem Enphase-Team teilen, um sie weiter zu prüfen.',
    sendEnPhaseTeam: 'An Enphase-Team senden',
  },
  designReviewService: {
    requestedId: 'Anfrage-ID',
    enphaseStorageCourse: 'Teil des Enphase Speicher Zertifizierungskurses',
  },
  projectDetails: {
    communicateEnphase: 'Kommunizieren Sie mit Enphase',
    headerText: 'Bewerbung einreichen',
    applicationSubmitted: 'Antrag eingereicht',
    paymentFailed: 'Bezahlung fehlgeschlagen',
    applicationSuccess: 'Herzlichen Glückwunsch! Ihr Antrag ist eingereicht worden.',
    applicationFailure: 'Oops! Wir konnten Ihre Transaktion nicht abschließen.',
    paymentStatusSuccess:
      'Unser Team wird sich innerhalb von 5 Werktage mit Ihnen in Verbindung setzen, um Ihren Designprüfungsantrag zu besprechen.',
    paymentStatusFailure: 'Bitte versuchen Sie es erneut oder kontaktieren Sie',
    documents: 'Dokumente',
    siteMap: 'Lageplan',
    singleLineDiagram: 'Schaltplan',
    msp: 'Hauptverteilerschrank',
    additionalDoc: 'Zusätzliche Dokumente',
    loadCalculation: 'Lastberechnung',
    batterySizingTool: 'Speicherdimensionierungs Werkzeug',
    siteSurvey: 'Systemdaten',
    additionalDetails: 'Weitere Details',
    photoUpload: 'Bilder hochladen (Übersichtsbilder falls vorhanden)',
    notes: 'Anmerkungen',
    backupLoadDetails: 'Notlast Details',
    batterySizingDetails: 'Batteriegrösse Details',
    siteSurveyDetails: 'Systemdaten',
  },
  requestDetails: {
    siteMapPhotosUrl: 'Lageplan',
    sldDocumentsUrl: 'Schaltplan',
    installationPhotosUrl: 'Installationsfotos',
    loadCalculationDocumentsUrl: 'Dokumente zur Lastberechnung',
    mspDocumentsUrl: 'Bild der Hauptverteilerschrank',
    otherDocumentsUrl: 'Dokumente',
    surveyPhotosUrl: 'Bestandsfotos',
    heading: 'Details zum Designprüfungsantrag',
    requestId: 'ANFRAGE-ID',
    installerName: 'NAME DES INSTALLATEURS',
    systemId: 'SYSTEM-ID',
    systemName: 'SYSTEM-/PROJEKTNAME',
    customerName: 'KUNDENNAME',
    zipState: 'PLZ / BUNDESLAND',
    status: 'STATUS',
    type: 'TYP',
    assignedTo: 'ZUGEWIESEN AN',
    createdOn: 'ERSTELLT AM',
    updatedOn: 'AKTUALISIERT AM',
    documents: 'Dokumente',
    downloadAll: 'Alle herunterladen',
    links: 'Links',
    installer: 'INSTALLATEUR',
    admin: 'ADMIN',
    today: 'Heute',
    pastDays: 'Letzte 7 Tage',
    monthToData: 'Monat bis heute',
    yearToDate: 'Jahr bis heute',
    specificDate: 'Bestimmtes Datum',
    allDatesBefore: 'Alle Daten davor',
    allDatesAfter: 'Alle Daten danach',
    dateRange: 'Datumsbereich',
    clearDate: 'Daten löschen',
    utilityBill: 'Stromrechnung',
    equipmentElevation: 'Gerätehöhe',
    planSet: 'Plan-Set',
  },
  chats: {
    fileUploadIssue:
      'Wir haben derzeit Probleme mit dem Hochladen von Dateien, bitte versuchen Sie es später noch einmal.',
    others: 'Andere',
    downloading: 'Herunterladen',
    downloaded: 'Heruntergeladen',
    fromEnphase: 'Von Enphase',
    fromInstaller: 'Vom Installateur',
    fromUnknown: 'Von Unbekannt',
    notifyViaEmail:
      'Sie können dieses Fenster schließen, nachdem Sie Ihre Nachricht gesendet haben. Wir werden Sie per E-Mail benachrichtigen, wenn eine Antwort auf Ihre Anfrage verfügbar ist.',
    query:
      'Dieser Abschnitt kann verwendet werden, um Fragen zu Ihrem Designprüfungsantrag zu stellen und Ihre Gespräche im Auge zu behalten.',
    typeMessage: 'Nachricht eingeben',
    communicateWithEnphase: 'Kommunizieren Sie mit Enphase',
    communicateWithInstaller: 'Mit dem Installateur in Kontakt treten',
    submitted: 'eingereicht',
    maxFileSizeError1: 'Die maximale Dateigröße sollte ',
    maxFileSizeError2: ' Mb betragen',
  },
  approvalDialog: {
    documentApprove: 'Ich genehmige das/die folgende(n) Designprüfungsdokument(e)',
    specificComments: 'Wenn Sie bestimmte Kommentare teilen möchten geben Sie diese bitte unten ein',
    approveSend: 'Genehmigen und an den Installateur senden',
  },
  fileUpload: {
    fileValidation: 'Bitte wählen Sie eine Datei aus oder geben Sie Kommentare ein, um fortzufahren...',
    reasonResubmission: 'Grund für die erneute Einreichung',
    validationReasonResubmission: 'Bitte geben Sie den Grund für die erneute Einreichung an',
    fileMaxSize: `Die Dateigröße ist größer als %{fileSize} MB...`,
    fileMaxSizeLabel: 'Die Dateigröße ist größer als',
    shareComments: 'Wenn Sie bestimmte Kommentare teilen möchten geben Sie diese bitte unten ein',
    uploadFiles: 'Dateien hochladen',
    selectFiles: 'Dateien auswählen',
    dropFile: 'Ziehe die Dateien hier hin, um sie hochzuladen',
    or: 'oder',
  },
  infoDialog: {
    notes: 'Notizen',
  },
  adminAction: {
    designDocUpload: 'Design-Dokument hochgeladen',
    tier2ApprovalComment: 'Genehmigungskommentare für Stufe 2',
    resubmissionRequested: 'Erneute Einreichung angefordert',
    uploadSendInstaller: 'Hochladen und an den Installateur senden',
    finalDrsUpload:
      'Bitte laden Sie die abschließenden Designprüfungsdokumente hoch, um sie mit dem Installateur zu teilen. Wenn Sie die hochgeladenen Dokumente der Stufe 1 genehmigen möchten und diese mit dem Installateur teilen wollen, kehren Sie bitte zum vorherigen Bildschirm zurück und klicken Sie auf die Schaltfläche „Stufe 1-Dokument genehmigen“.',
    drsShareInstaller:
      'Bitte laden Sie die abschließenden Designprüfungsdokumente hoch, um sie mit dem Installateur zu teilen',
    requestToTier2: 'Anfrage für Genehmigung der Stufe 2',
    tier2Approval: 'Bitte laden Sie die Designprüfungsdokumente hoch, um sie dem Team der Stufe 2 vorzulegen.',
    tier1DocUpload: 'Genehmigung des von Stufe 1 hochgeladenen Dokuments',
    approveTier1Doc: 'Genehmigung des Stufe 1 Dokuments',
    modifyUploadDrs:
      'Wir werden die Dokumente nach Genehmigung mit den Installateuren teilen. Wenn Sie das Designprüfungsdokument ändern und eine neue Version hochladen möchten, gehen Sie bitte zum vorherigen Bildschirm zurück und klicken Sie auf „Hochladen und an den Installateur senden“.',
    shareInformation: 'Bitte teilen Sie die Informationen mit, die Sie vom Installateur benötigen.',
  },
  applicationDetails: {
    applicantDetails: 'Antragsteller Details',
    applicationDetails: 'Antragsdetails',
    viewApplication: 'Anfrage anzeigen',
  },
  applicationView: {
    systemProjectName: 'System-/Projektname',
    systemId: 'System-ID',
    type: 'Typ',
    solarSize: 'PV-Leistung (KWac)',
    storageSize: 'Speichergröße (kWh)',
    batteryType: 'Batterie Typ',

    batteryQuantity: 'Anzahl der Batterie(n)',
    customerName: 'Kundenname',
    zip: 'PLZ',
    State: 'Status',
    address: 'Adresse',
    installerName: 'Name des Installateurs',
    installerCompanyName: 'Installateur-Firmenname',
    installerContactNumber: 'Installateur-Kontaktnummer',
    installerEmailAddress: 'Installateur-E-Mail-Adresse',
    notes: 'Anmerkungen',
    receivedOn: 'Erhalten am',
    uploadedOn: 'Hochgeladen am',
    noDoc: 'Keine Dokumente verfügbar',
    resubmissionReceived: 'Neueinreichung erhalten',
  },
  statusDetails: {
    applicationStatus: 'Einreichungsstatus',
    updateStatus: 'Status aktualisieren',
    downloadfailed: 'Download Fehlgeschlagen',
    download: 'Download',
    dateOfComplete: 'Datum der Fertigstellung',
    update: 'Hochladen',
  },
  datePicker: {
    datePickerInline: 'Datumsauswahl',
    changeDate: 'Datum ändern',
  },
  installerAction: {
    downloadReviewDoc: 'Dokument zur Überprüfung herunterladen',
    shareYourFeedback: 'Teilen Sie Ihr Feedback mit',
    resubmitDoc: 'Erforderliche Informationen erneut einreichen',
  },
  status: {
    updatedOn: 'Aktualisiert am',
    downloadReviewDoc: 'Dokument zur Überprüfung herunterladen',
    totalAmount: 'Gesamtbetrag zu zahlen',
    exclusiveTaxes: 'ausschließlich aller Steuern',
    enphaseService1: 'Ich habe die',
    enphaseService2: 'für Enphase Design Review Services gelesen und bin damit einverstanden.',
    redirectNotification: 'Sie werden zur Zahlungsseite weitergeleitet, um die Transaktion abzuschließen',
    noChargeNotification: 'Die Einreichung dieses Antrags ist für Sie kostenlos',
    partOfCourse: ' als Teil Ihres Zertifizierungskurses.',
  },
  docUpload: {
    addFile: 'Datei hinzufügen',
    add: 'Hinzufügen',
    addImage: 'Bild hinzufügen',
    addDocument: 'Dokument hinzufügen',
    downloadTemplate: 'Vorlage herunterladen',
    shareDocLink: 'Link zum Dokument freigeben',
    linkFromGoogleDrive: 'Link von GoogleDrive usw.',
    giveLink: 'Geben Sie uns einen Link von GoogleDrive, Dropbox usw.',
  },
  buttons: {
    sendInstaller: 'An den Installateur senden',
    requestInformation: 'Mehr Informationen anfordern',
    requestTier2: 'Anfrage für Genehmigung der Stufe 2',
    proceedButtonYes: 'Ja',
    proceedButtonText: 'Fortfahren',
    closeButtonText: 'Nein',
    footerSubmitButton: 'Senden',
    back: 'Zurück',
    confirm: 'Bestätigen',
    footerCloseButton: 'Schließen',
    update: 'Hochladen',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    proceed: 'Fortfahren',
    view: 'Anzeigen',
    ok: 'Ok',
    saveClose: 'speichern und schließen',
    owner: 'Eigentümer',
  },
  constants: {
    fieldRequired: 'Dieses Feld ist erforderlich',
    documentFieldRequired: 'Bitte laden Sie ein Dokument hoch oder geben Sie einen Link ein',
    linkError: 'Der angegebene Link ist nicht gültig',
    applicationIncomplete: 'Bewerbung unvollständig',
    newApplicationReceived: 'Neuer Antrag erhalten',
    resubmissionRequested: 'Erneute Einreichung angefordert',
    newApplicationAssigned: 'Neuer Antrag zugewiesen',
    newApplicationSubmitted: 'Neuer Antrag eingereicht',
    applicationUnderReview: 'Antrag wird geprüft',
    resubmissionReceived: 'Neueinreichung erhalten',
    State: 'Status',

    resubmissionSubmitted: 'Neueinreichung Eingereicht',
    applicationReviewCompleted: 'Antragsprüfung abgeschlossen',
    requestForTier2Approval: 'Beantragte Genehmigung der Stufe 2',
    tier2ApplicationAssigned: 'Stufe 2 Antrag zugewiesen',
    tier2ApprovalUnderProgress: 'Stufe-2-Zulassung in Bearbeitung',
    tier2ResubmissionRequested: 'Stufe 2 Neueinreichung angefordert',
    tier2ResubmissionReceived: 'Stufe 2 Neueinreichung erhalten',
    tier2ApprovalCompleted: 'Stufe-2-Zulassung abgeschlossen',
    defaultMessage: 'Um Dateien herunterzuladen, deaktivieren Sie bitte den Popup-Blocker in Ihrem Browser',
    rowsPerPage: 'Zeilen pro Seite:',
    of: 'von',
  },
  drsListingTable: {
    noRequestAvailable: 'Keine Anfragen verfügbar',
    notAssigned: 'Nicht zugewiesen',
    application: 'Anmeldung',
    messages: 'Nachrichten',
    message: 'Nachrichten',
    all: 'Alle',
  },
  fileUploadDialog: {
    documentUpload: 'Checkliste zum Hochladen von Dokumenten',
    fileTypeValidation: 'Bitte wählen Sie den Dateityp, um fortzufahren...',
    fileSizeValidation: `Die Dateigröße ist größer als %{fileSize} MB...`,
    maxFileSize: 'Maximale Dateigröße',
    addSpecificComment: 'Wenn Sie bestimmte Kommentare teilen möchten geben Sie diese bitte unten ein',
    poor: 'Schlecht',
    great: 'Toll',
    feedback: 'Rückmeldung',
    recommendSurvey:
      'Auf einer Skala von 0 bis 10: Wie zufrieden sind Sie mit dieser Website, die für die Einreichung der Designprüfung verwendet wurde?',
  },
  prefillingDialog: {
    beforeSubmit:
      'Einige der Felder in diesem Antrag wurden bereits mit Daten aus Enlighten ausgefüllt. Bitte überprüfen Sie die Angaben sorgfältig, bevor Sie sie einreichen.',
  },
  basicDetails: {
    projectDetails: 'Projektdetails',
    residential: `Wohnimmobilien = Vertrag mit Hauseigentümern von Einfamilienhäusern. 
Gewerblich = Vertrag mit Eigentümern von Mehrfamilienhäusern/Gewerbeimmobilien.`,
    customerName: 'Kundenname',
    zip: 'PLZ',
    owner: 'Owner',
  },
  microInvererDetails: {
    microInverterType: 'Mikrowechselrichter Typ',
    pageHeader: 'Mikrowechselrichterdetails',
  },
  solarDetails: {
    pageHeader: 'PV-Anlage Details',
    solarSystemSize: 'Größe der PV-Anlage (kWac)',
    storageSize: 'Speichergröße (kWh)',
    kWh: 'kWh',
    batteryTypeNote: 'Eine IQ Battery 10T entspricht drei IQ Battery 3T',
  },
  notification: {
    noMoreNotification: 'Es werden keine Benachrichtigungen mehr angezeigt',
    noNotification: 'Keine Benachrichtigungen verfügbar',
    serviceNotification: 'Service-Meldungen',
    viewAll: 'Alle anzeigen',
  },
  leadManagement: {
    selectSystemID:
      'Wählen Sie die System-ID aus der folgenden Liste aus, wenn die Systemaktivierung bereits gestartet wurde',
    systemNameValidation: 'Bitte geben Sie den Systemnamen ein',
    createSystem: 'Ein neues System erstellen',
    systemName: 'Name des Systems',
    type: 'Typ',
    siteInformation: 'Site Information',
    Residential: 'Wohnimmobilien',
    Commercial: 'Gewerblich',
    other: 'Sonstiges',
    thirdParty: 'Im Besitz Dritter (z.B.: PPA oder geleast)',
    collapse: 'Zusammenklappen',
    expand: 'Ausklappen',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    phone: 'Telefon',
    residential: 'Wohnimmobilien',
    commercial: 'Gewerblich',
    invalidEmail: 'Ungültige E-Mail',
    invalidPhoneNumber: 'Ungültige Telefonnummer',
    required: 'Erforderlich',
    enlightenVersion: 'Version aufklären',
    ownerReceive: 'Der Besitzer erhält MyEnlighten zur Überwachung der Systemleistung.',
    location: 'Standort',
    streetAddress: 'Straße und Hausnummer',
    zip: 'Zip Code',
    zipCode: 'Postleitzahl',
    country: 'Land',
    state: 'Staat/Provinz',
    city: 'Stadt',
    noOfInverter: 'Anzahl der Mikrowechselrichter',
    customerName: 'Kundenname',
    enphaseGuideLine:
      'Enphase stellt Anleitungen und Ressourcen zur Verfügung, um Installateure bei der Planung eines Systems innerhalb geeigneter Parameter zu unterstützen. Der Installateur und qualifizierte Elektriker sind für das endgültige Design und die Umsetzung verantwortlich.',
    confirm: 'Ich bestätige das Folgende',
    authorized:
      'Ich bin befugt, mich im Namen des Systemeigentümers/Hosts für Enlighten zu registrieren, der mir die Erlaubnis erteilt hat, diese Informationen in seinem Namen an Enphase zu übermitteln.',
    readUnderstand: 'Ich habe die Enphase Richtlinien gelesen und verstanden',
    privacyPolicy: 'Datenschutzrichtlinie',
    and: 'und',
    terms: 'Nutzungsbedingungen',
    termsAndCondition: 'Allgemeinen Geschäftsbedingungen',
    policy: 'die „Richtlinien“',
    acknowledge: '	Der Systembesitzer/Host hat bestätigt, dass er die Richtlinien gelesen und sie versteht.',
    informedSystemOwner:
      'Ich habe dem Systembesitzer/Host mitgeteilt, dass mein Unternehmen in der Lage sein wird, ihre Energieverbrauchsmengen und -zeiten zu überwachen und dass sie diese Erlaubnis jederzeit widerrufen können',
  },
  serviceDialog: {
    quoteSetUp: 'Angebot einrichten',
    systemConfiguration: 'System-Konfiguration',
    bomPricing: 'Stückliste & Preisgestaltung',
    projectDesign:
      'Dieses Projekt wird derzeit von Sofdesk entworfen. Sie ist jetzt noch nicht zugänglich, wird aber zur Verfügung stehen, sobald wir sie fertiggestellt haben.',
    redirect: 'Sie werden dann zu Ihrem Dashboard im Solargraf Design und Vorschlag-Tool weitergeleitet.',
    wishToProceed: 'Möchten Sie fortfahren?',
    designProposalTool: 'Entwurfs- und Vorschlagswerkzeug',
    measurementInProgress: 'Maßnahmen in Bearbeitung',
    permitAssistanceStatus: 'STATUS DER GENEHMIGUNGSUNTERSTÜTZUNG',
  },

  breadCrumb: {
    dashboard: 'Dashboard',
    SGIPApplication: 'SGIP-Anträge',
  },
};

export default designReviewService;
