import {
  SET_COUNTRY_CODE_REQUEST,
  SET_MOBILE_VIEW,
  SET_ENLIGHTEN_MANAGER_TOKEN,
  GET_APPLICATION_NOTIFICATIONS_REQUEST,
  READ_APPLICATION_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATION_AS_SEEN_REQUEST,
  DELETE_READ_NOTIFICATION_REQUEST,
  SET_FEATURE_LIST,
  GET_SOLARGRAF_SESSION,
  VERIFY_SOLARGRAF_SESSION,
  VERIFY_SOLARGRAF_EMAIL,
  GET_SOLARGRAF_POPUP_DETAILS_REQUEST,
  GET_SOLARGRAF_ACCESS,
  SET_SOLARGRAF_ACCESS_GRANT,
  GET_APPLICATION_REQUEST,
  SET_FROM_LEFT_TAB,
  GET_PROJECT_DETAILS_REQUEST,
} from './constants';

export function setCountryCode(payload) {
  return { type: SET_COUNTRY_CODE_REQUEST, payload };
}

export function setMobileView(isMobileView) {
  return { type: SET_MOBILE_VIEW, isMobileView };
}

export function setEnlightenManagerToken(enlightenManagerToken) {
  return { type: SET_ENLIGHTEN_MANAGER_TOKEN, enlightenManagerToken };
}

export function getApplicationNotifications(payload) {
  return { type: GET_APPLICATION_NOTIFICATIONS_REQUEST, payload };
}

export function readApplicationNotification(payload) {
  return { type: READ_APPLICATION_NOTIFICATIONS_REQUEST, payload };
}

export function markNotificationSeen(payload) {
  return { type: MARK_NOTIFICATION_AS_SEEN_REQUEST, payload };
}

export function deleteReadNotification(payload) {
  return { type: DELETE_READ_NOTIFICATION_REQUEST, payload };
}

export function setFeatureList(featureList) {
  return { type: SET_FEATURE_LIST, featureList };
}

export function getSolargrafSession(payload) {
  return { type: GET_SOLARGRAF_SESSION, payload };
}

export function verifySolargrafSession(payload) {
  return { type: VERIFY_SOLARGRAF_SESSION, payload };
}

export function verifySolargrafEmail(payload) {
  return { type: VERIFY_SOLARGRAF_EMAIL, payload };
}

export function setSolargrafPopupDetails(payload) {
  return { type: GET_SOLARGRAF_POPUP_DETAILS_REQUEST, payload };
}

export function requestSolargrafAccess(payload) {
  return { type: GET_SOLARGRAF_ACCESS, payload };
}

export function grantSolargrafAccess(payload) {
  return { type: SET_SOLARGRAF_ACCESS_GRANT, payload };
}

export function getApplicationBySite(payload) {
  return { type: GET_APPLICATION_REQUEST, payload };
}

export function setFromLeftPanel(fromLeftPanel) {
  return { type: SET_FROM_LEFT_TAB, fromLeftPanel };
}

export function getProjectDetails(payload) {
  return { type: GET_PROJECT_DETAILS_REQUEST, payload };
}
