const header = {
	header: {
		logOut: 'Abmelden',
		enphaseStore: 'Enphase Store',
		enphaseSupport: 'Enphase Support ',
		comingSoon: 'Demnächst',
		new: 'Neu',
		dashboard: 'Dashboard',
		systems: 'Systeme',
		account: 'Konto',
		services: 'Services',
		dataPrivacy: 'Daten / Datenschutz',
		support: 'Support',
		admin: 'Administrator',
		adminNew: 'Admin(Neu)'
	}
};

export default header;
