import React from 'react';

const FailureIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="none" d="M0,0H24V24H0Z" />
    <path
      fill="#de5246"
      d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
      transform="translate(0 0)"
    />
    <path fill="none" d="M0,0H24V24H0Z" />
  </svg>
);

export default FailureIcon;
