import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import createSagaMiddleware from 'redux-saga';
var sagaMiddleware = createSagaMiddleware();
var middlewares = [sagaMiddleware];
var middleware = applyMiddleware.apply(void 0, middlewares);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
}
function configureStore() {
    var storeObj = createStore(rootReducer, middleware);
    storeObj.runSaga = sagaMiddleware.run(rootSaga);
    return storeObj;
}
var store = configureStore();
export default store;
