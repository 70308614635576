const accountInfo = {
	accountInfo: {
		account_information: 'My Account Information',
		services: 'My Services',
		data_privacy: 'My Data & Privacy',
		benefits: 'Benefits',
		company: 'Company Profile',
		branches: 'Branch Offices',
		users: 'Users',
		area_coverage: 'Service Areas',
		lead_program: 'Leads Preferences',
		ein_program: 'Leads & Enphase Installer Network',
		//TODO - new translations added
		ac_module_admin: 'AC Module Administration',
		//TODO ends
		enphase_store: 'Enphase Store',
		product_warranty_extension: 'Product Warranty Extension',
		lpp: 'Labor Protection Program',
		permit_assistance: 'Permit Assistance',
		incentive_programs: 'Incentive Programs',
		ein_merchandise: 'EIN Merchandise',
		//TODO - new translations added
		design_tool: 'Design and Proposal Tool',
               bom: 'BOM Calculator',
		//TODO ends
		financing: 'Financing',
		training: 'Training',
		collaterals: 'Collaterals',
		my_leads: 'My Leads',
		lead_details: 'Quote Request Details',
		api_access: 'API Access',
		third_party: '3rd Party Reporting',
		profiles: 'Grid Profiles',
		createBranch: 'Add Branch Office',
		branch_profile: 'Branch Profile',
		new: 'New',
		back: 'Back',
		applications: 'Applications',
		createUser: 'Add New User',
		user_profile: 'User Profile',
		platform_discounts: 'Platform Discounts',
		account_details: 'Account Details',
		preferences: 'Preferences',
		change_password: 'Change Password',
		design_review: 'Design Review Service',
		account: 'Account',
		dashboard: 'Dashboard',
		systems: 'Systems',
		admin: 'Admin',
		einDashboard: 'EIN Dashboard',
		einAdmin: 'EIN Admin',
		edit: 'Edit',
		//TODO
		gotoSolargraf: 'Go to Solargraf',
		createSolargrafAccountMessage:
			'We were not able to find an active account on Solargraf with %{email}, please create an account or buy a subscription.',
		createSolargrafAccountNote:
			'Note: if you have already have a Solargraf account, please change the Solargraf email id to Enlighten email',
		verificationText: 'Verification',
		solargrafOtpMessage:
			'We have sent a 6 digit verification code to your email. Please enter the code below to link your Enlighten manager with Solargraf',
		enterOtpText: 'Enter OTP',
		didntReceivedOtpMessage: 'Didn’t receive the OTP?',
		resendOtp: 'Resend OTP',
		verificationSuccess: 'Verified Successfully!',
		verificationFailure: 'Incorrect information',
		otpExpired: 'OTP expired. Please request for a new OTP',
		otpSentSuccessfully: 'OTP sent successfully',
		otpSentFailed: 'Failed to sent OTP, Please try again later',
		linkSolargrafTitle: 'Link your Solargraf Account',
		getVerificationCodeText: 'Get Verification Code',
		linkSolargrafMessageOne:
			'Your Enlighten account needs to be linked to your Solargraf account to use this option.',
		linkSolargrafMessageTwo: 'A one time verification of your email address will be done to complete this step.',
		failureMessage: 'Something went wrong, please try again',
		application_status: 'Application Status',
	}
};

export default accountInfo;
