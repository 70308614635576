import { call, take, put } from 'redux-saga/effects';
import { get, putMethod, deleteMethod, post } from '../../../../utils/api';
import settings from '../../../../utils/settings';
import {
  GET_APPLICATION_NOTIFICATIONS_REQUEST,
  GET_APPLICATION_NOTIFICATIONS_SUCCESS,
  GET_APPLICATION_NOTIFICATIONS_FAILURE,
  READ_APPLICATION_NOTIFICATIONS_REQUEST,
  READ_APPLICATION_NOTIFICATIONS_FAILURE,
  MARK_NOTIFICATION_AS_SEEN_REQUEST,
  DELETE_READ_NOTIFICATION_REQUEST,
  GET_SOLARGRAF_SESSION,
  GET_SOLARGRAF_SESSION_SUCCESS,
  VERIFY_SOLARGRAF_SESSION,
  VERIFY_SOLARGRAF_EMAIL,
  GET_SOLARGRAF_ACCESS,
  SET_SOLARGRAF_ACCESS_GRANT,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
} from './constants';
import { setSolargrafToken } from '../../utils/helper';
import { getToken } from '../../../../utils/helper';

export function* getApplicationNotificationsFlow() {
  while (true) {
    const request = yield take(GET_APPLICATION_NOTIFICATIONS_REQUEST);
    try {
      let response;
      const { successCbk = () => {}, page = 0, size = 20 } = request.payload;
      response = yield call(get, {
        url: settings.einCoreUrl(`notifications/?page=${page}&size=${size}`),
        withBearer: true,
      });
      if (response.success) {
        const notificationList = response.result.notices || [];
        const unseenNotificationCount = response.result.unseen_notifications_count;
        successCbk(notificationList);
        yield put({
          type: GET_APPLICATION_NOTIFICATIONS_SUCCESS,
          notificationList: notificationList,
          unseenNotificationCount: unseenNotificationCount,
        });
      } else {
        yield put({
          type: GET_APPLICATION_NOTIFICATIONS_FAILURE,
          error: response.result.message || response.result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_APPLICATION_NOTIFICATIONS_FAILURE, error });
      console.log('Saga: getApplicationNotifications ', error);
    }
  }
}

export function* readApplicationNotificationFlow() {
  while (true) {
    const request = yield take(READ_APPLICATION_NOTIFICATIONS_REQUEST);
    try {
      let response;
      const { installerId, successCbk = () => {} } = request.payload;
      response = yield call(putMethod, {
        url: settings.einCoreUrl(`notifications/${installerId}`),
        withBearer: true,
      });
      if (response.success) {
        successCbk();
      } else {
        yield put({
          type: READ_APPLICATION_NOTIFICATIONS_FAILURE,
          error: response.result.message || response.result.messages,
        });
      }
    } catch (error) {
      yield put({ type: READ_APPLICATION_NOTIFICATIONS_FAILURE, error });
      console.log('Saga: readApplicationNotificationFlow ', error);
    }
  }
}

export function* markNotificationSeenFlow() {
  while (true) {
    const request = yield take(MARK_NOTIFICATION_AS_SEEN_REQUEST);
    try {
      let response;
      const { lastUpdatedAt, successCbk = () => {} } = request.payload;
      response = yield call(putMethod, {
        url: settings.einCoreUrl(`notifications/seen/${lastUpdatedAt}`),
        withBearer: true,
      });
      if (response.success) {
        successCbk();
      } else {
        console.log('Saga: readApplicationNotificationFlow ');
      }
    } catch (error) {
      console.log('Saga: readApplicationNotificationFlow ', error);
    }
  }
}

export function* deleteApplicationNotificationFlow() {
  while (true) {
    const request = yield take(DELETE_READ_NOTIFICATION_REQUEST);
    try {
      let response;
      const { notificationId, successCbk = () => {} } = request.payload;
      response = yield call(deleteMethod, {
        url: settings.einCoreUrl(`notifications/${notificationId}`),
        withBearer: true,
      });
      if (response.success) {
        successCbk();
      } else {
        console.log('Saga: deleteApplicationNotificationFlow ', response.result.message || response.result.messages);
      }
    } catch (error) {
      console.log('Saga: deleteApplicationNotificationFlow ', error);
    }
  }
}

export function* getSolargrafSession() {
  while (true) {
    const request = yield take(GET_SOLARGRAF_SESSION);
    const { token, successCbk, failureCbk } = request.payload;
    try {
      let response;
      response = yield call(post, {
        url: settings.solargrafApiUrl(`enlm/sessions?token=${token}`),
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        setSolargrafToken(result.token);
        yield put({
          type: GET_SOLARGRAF_SESSION_SUCCESS,
          solargrafToken: result.token,
        });
        successCbk(result);
      } else {
        failureCbk();
      }
    } catch (error) {
      failureCbk();
    }
  }
}

export function* verifySolargrafSession() {
  while (true) {
    const request = yield take(VERIFY_SOLARGRAF_SESSION);
    const { solargrafToken, successCbk, failureCbk } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.solargrafApiUrl(`me`),
        withBearer: true,
        externalServiceToken: solargrafToken,
      });
      const { success, status } = response;
      if (success && status === 200) {
        successCbk();
      } else {
        failureCbk();
      }
    } catch (error) {
      failureCbk();
    }
  }
}

export function* verifySolargrafEmail() {
  while (true) {
    const request = yield take(VERIFY_SOLARGRAF_EMAIL);
    const { token, successCbk = () => {}, failureCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.solargrafApiUrl(`enlm/verification?token=${token}`),
      });
      const { success, status } = response;
      if (success && status === 200) {
        successCbk();
      } else {
        failureCbk();
      }
    } catch (error) {
      failureCbk();
    }
  }
}

let requestSent = false;
export function* requestSolargrafAccess() {
  while (true) {
    const request = yield take(GET_SOLARGRAF_ACCESS);
    const { successCbk = () => {}, failureCbk = () => {} } = request.payload;
    if (!requestSent) {
      try {
        requestSent = true;
        const response = yield call(post, {
          url: settings.getDrsUrl(`proxyApi/request`),
          body: { url: [settings.getApiUrl('api_internal/solargraf/request_access')], method: ['POST'] },
          headers: { 'Content-Type': 'application/json', Authorization: getToken() },
        });
        const { success, status } = response;
        if (success && status === 200) {
          successCbk();
        } else {
          failureCbk();
        }
        requestSent = false;
      } catch (error) {
        failureCbk();
        requestSent = false;
      }
    }
  }
}

let grantAccessRequestSent = false;
export function* grantSolargrafAccess() {
  while (true) {
    const request = yield take(SET_SOLARGRAF_ACCESS_GRANT);
    const { newOtp, successCbk = () => {}, failureCbk = () => {} } = request.payload;
    if (!grantAccessRequestSent) {
      try {
        grantAccessRequestSent = true;
        const response = yield call(post, {
          url: settings.getDrsUrl(`proxyApi/request`),
          body: {
            url: [settings.getApiUrl(`api_internal/solargraf/grant_access`)],
            method: ['POST'],
            otp: [newOtp.toString()],
          },
          headers: { 'Content-Type': 'application/json', Authorization: getToken() },
        });
        const { success, status } = response;
        if (success && status === 200) {
          successCbk();
        } else {
          failureCbk();
        }
        grantAccessRequestSent = false;
      } catch (error) {
        failureCbk();
        grantAccessRequestSent = false;
      }
    }
  }
}

let applicationloading = false;
export function* getApplicationBySiteFlow() {
  while (true) {
    const request = yield take(GET_APPLICATION_REQUEST);
    const { siteId = '', cbk = () => {} } = request.payload;
    if (!applicationloading) {
      try {
        let response;
        applicationloading = true;
        response = yield call(get, {
          url: settings.getPermittingUrl(`application_by_site?site_id=${siteId}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({ type: GET_APPLICATION_SUCCESS });
          cbk(result);
        } else {
          yield put({ type: GET_APPLICATION_FAILURE });
          cbk(result);
        }
        applicationloading = false;
      } catch (error) {
        yield put({ type: GET_APPLICATION_FAILURE });
        cbk({ status: 'failed' });
        applicationloading = false;
      }
    }
  }
}

export function* getProjectDetails() {
  while (true) {
    const request = yield take(GET_PROJECT_DETAILS_REQUEST);
    const { projectId, solargrafToken, successCbk = () => {} } = request.payload;

    try {
      let response;
      response = yield call(get, {
        url: settings.solargrafApiUrl(`projects/${projectId}`),
        withBearer: true,
        externalServiceToken: solargrafToken,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_PROJECT_DETAILS_SUCCESS, projectDetails: result });
        successCbk();
      } else {
        yield put({ type: GET_PROJECT_DETAILS_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_PROJECT_DETAILS_FAILURE });
    }
  }
}
