import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

const withLoadingScreen = (WrappedComponent, waitFor) => {
  const isEmpty = (object) => {
    return Object.values(object).every((x) => x === null || x === '');
  };
  return class LoadingScreen extends React.Component {
    constructor() {
      super();
      this.state = {
        loading: true,
      };
    }
    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        let allLoaded = true;
        waitFor.map((waitForKey) => {
          if (isEmpty(this.props[waitForKey])) {
            allLoaded = false;
          }
        });
        if (allLoaded === true) {
          this.setState({ loading: false });
        }
      }
    }
    render() {
      return (
        <React.Fragment>
          {this.state.loading && (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
                width: '100vw',
              }}
            >
              <CircularProgress style={{ zIndex: 15 }} />
            </div>
          )}
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
};

export default withLoadingScreen;
