import { jsx as _jsx } from "react/jsx-runtime";
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
var useStyles = makeStyles(function (theme) { return ({
    header: {
        fontSize: theme.spacing(4.375),
        fontWeight: 700,
        color: '#335977',
        marginBottom: theme.spacing(1),
    },
}); });
var PageHeader = function (props) {
    var classes = useStyles();
    var text = props.text, _a = props.className, className = _a === void 0 ? '' : _a;
    var classname = clsx(classes.header, !isEmpty(className) && className);
    return (_jsx(Typography, { className: classname, variant: "h5", component: "h5", children: text }));
};
export default PageHeader;
