import {
  GET_LEADS_REQUEST,
  PUT_ACCEPT_LEAD_REQUEST,
  GET_LEAD_DETAILS_REQUEST,
  POST_CREATE_QUOTE_REQUEST,
  GET_QUOTE_S3_URL_REQUEST,
  GET_QUOTE_FILE_FROM_S3_REQUEST,
  PUT_QUOTE_UPLOAD_TO_S3_REQUEST,
  GET_PANEL_MFG_REQUEST,
  GET_BOM_LIST_REQUEST,
  GET_LEAD_S3_IMAGE_FILE_URL_REQUEST,
  GET_CART_URL_REQUEST,
  GET_UPDATED_TAX_AND_SHIPMENT_REQUEST,
  GET_MASTER_APPLIANCES_REQUEST,
  SITE_SEARCH_REQUEST_LEAD,
  SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST,
  LINK_SITE_WITH_LEAD_REQUEST,
  GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST,
  GET_MESSAGES_LIST_REQUEST,
  SEND_MESSAGE_REQUEST,
  GET_MESSAGE_FILE_S3_URL_REQUEST,
  PUT_S3_URL_REQUEST,
  GET_APPOINTMENT_HISTORY_REQUEST,
  ACCEPT_APPOINTMENT_REQUEST,
  CANCEL_APPOINTMENT_REQUEST,
  REJECT_APPOINTMENT_REQUEST,
  LEAD_REQUEST_APPOINTMENT_REQUEST,
  SYSTEM_DETAILS_REQUEST,
  UNLINK_SITE_WITH_LEAD_REQUEST,
  GET_FINANCE_PROVIDERS_REQUEST,
  GET_PRESIGNED_URL_REQUEST,
  CONTRACT_FILE_UPLOAD_TO_S3_REQUEST,
  GET_CONTRACT_LEAD_JOURNEY_REQUEST,
  GET_QUOTE_PROPOSAL_REQUEST,
  SET_LEFT_PANEL_OPEN_LIST,
  SET_LEAD_DETAILS,
  SET_PREVIOUS_PANEL_LENGTH,
  CREATE_LEAD_REQUEST,
  SITE_SEARCH_LEAD_REQUEST,
  GET_LEAD_FROM_JOURNEY_ID_REQUEST,
  CHECK_EXISTING_EMAIL_REQUEST,
  IS_ACCOUNT_IN_ENLIGHTEN_REQUEST,
  GET_SOLARGRAF_FINANCIALS_REQUEST,
} from './constants';

export function getLeads(payload) {
  return { type: GET_LEADS_REQUEST, payload };
}

export function acceptLead(payload) {
  return { type: PUT_ACCEPT_LEAD_REQUEST, payload };
}

// needed
export function getLeadDetails(payload) {
  return { type: GET_LEAD_DETAILS_REQUEST, payload };
}

export function createQuote(payload) {
  return { type: POST_CREATE_QUOTE_REQUEST, payload };
}

export function getQuoteS3Url(payload) {
  return { type: GET_QUOTE_S3_URL_REQUEST, payload };
}

export function putQuoteToS3(payload) {
  return { type: PUT_QUOTE_UPLOAD_TO_S3_REQUEST, payload };
}

export function getQuoteFromS3(payload) {
  return { type: GET_QUOTE_FILE_FROM_S3_REQUEST, payload };
}

export function getPanelMfg(payload) {
  return { type: GET_PANEL_MFG_REQUEST, payload };
}

export function getPanelModel(payload) {
  return { type: GET_PANEL_MFG_REQUEST, payload };
}

export function getBOMList(payload) {
  return { type: GET_BOM_LIST_REQUEST, payload };
}

export function getLeadImageFromS3(payload) {
  return { type: GET_LEAD_S3_IMAGE_FILE_URL_REQUEST, payload };
}

export function getCartUrl(payload) {
  return { type: GET_CART_URL_REQUEST, payload };
}

export function getUpdatedTaxAndShipment(payload) {
  return { type: GET_UPDATED_TAX_AND_SHIPMENT_REQUEST, payload };
}

export function getMasterAppliances(payload) {
  return { type: GET_MASTER_APPLIANCES_REQUEST, payload };
}

// needed
export function getSearchResult(payload) {
  return { type: SITE_SEARCH_REQUEST_LEAD, payload };
}

// needed
export function searchForExistingLeadsWithSite(payload) {
  return { type: SEARCH_FOR_SITE_ID_IN_LEADS_REQUEST, payload };
}

// needed
export function linkSiteWithLead(payload) {
  return { type: LINK_SITE_WITH_LEAD_REQUEST, payload };
}

export function unlinkSiteWithLead(payload) {
  return { type: UNLINK_SITE_WITH_LEAD_REQUEST, payload };
}

export function getLeadDetailsCustom(payload) {
  return { type: GET_LEAD_DETAILS_WITH_CALLBACK_REQUEST, payload };
}
export function getMessagesList(payload) {
  return { type: GET_MESSAGES_LIST_REQUEST, payload };
}

export function sendMessage(payload) {
  return { type: SEND_MESSAGE_REQUEST, payload };
}

export function getMessageFileS3URL(payload) {
  return { type: GET_MESSAGE_FILE_S3_URL_REQUEST, payload };
}

export function putMessageFileToS3(payload) {
  return { type: PUT_S3_URL_REQUEST, payload };
}
export function getAppointmentHistory(payload) {
  return { type: GET_APPOINTMENT_HISTORY_REQUEST, payload };
}

export function acceptAppointment(payload) {
  return { type: ACCEPT_APPOINTMENT_REQUEST, payload };
}

export function cancelAppointment(payload) {
  return { type: CANCEL_APPOINTMENT_REQUEST, payload };
}

export function rejectAppointment(payload) {
  return { type: REJECT_APPOINTMENT_REQUEST, payload };
}

export function requestAppointment(payload) {
  return { type: LEAD_REQUEST_APPOINTMENT_REQUEST, payload };
}

// needed
export function getSystemDetails(payload) {
  return { type: SYSTEM_DETAILS_REQUEST, payload };
}

export function getFinanceProviders(payload) {
  return { type: GET_FINANCE_PROVIDERS_REQUEST, payload };
}

export function getContractPresingedURL(payload) {
  return { type: GET_PRESIGNED_URL_REQUEST, payload };
}

export function uploadContractToS3(payload) {
  return { type: CONTRACT_FILE_UPLOAD_TO_S3_REQUEST, payload };
}

export function saveContractFileDetailsInLeadJourney(payload) {
  return { type: GET_CONTRACT_LEAD_JOURNEY_REQUEST, payload };
}

export function getQuoteProposal(payload) {
  return { type: GET_QUOTE_PROPOSAL_REQUEST, payload };
}

// needed
export function setLeftPanelOpenList(leftPanelOpenList) {
  return { type: SET_LEFT_PANEL_OPEN_LIST, leftPanelOpenList };
}

// needed
export function setLeadDetails(leadDetails) {
  return { type: SET_LEAD_DETAILS, leadDetails };
}

// needed
export function setPreviousPanelLength(previousPanelLength) {
  return { type: SET_PREVIOUS_PANEL_LENGTH, previousPanelLength };
}

export function createSelfLead(payload) {
  return { type: CREATE_LEAD_REQUEST, payload };
}

export function getSiteSearchLeads(payload) {
  return { type: SITE_SEARCH_LEAD_REQUEST, payload };
}

// needed
export function getLeadFromJourneyId(payload) {
  return { type: GET_LEAD_FROM_JOURNEY_ID_REQUEST, payload };
}

export function checkExistingEmail(payload) {
  return { type: CHECK_EXISTING_EMAIL_REQUEST, payload };
}

export function isAccountInEnlighten(payload) {
  return { type: IS_ACCOUNT_IN_ENLIGHTEN_REQUEST, payload };
}

// needed
export function getSolargrafFinancials(payload) {
  return { type: GET_SOLARGRAF_FINANCIALS_REQUEST, payload };
}
