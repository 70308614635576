const footer = {
  footer: {
    imageTitle: 'Visitez le site web Enphase Energy',
    privacy: 'Confidentialité',
    terms: 'Conditions',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Tous droits réservés.',
    language: 'Langue'
  }
};

export default footer;
