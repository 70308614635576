const accountInfo = {
	accountInfo: {
		account_information: 'Informazioni sul mio account',
		services: 'I miei servizi',
		data_privacy: 'I miei dati e la mia Privacy',
		benefits: 'Vantaggi',
		company: 'Profilo aziendale',
		branches: 'Filiali',
		users: 'Utente',
		area_coverage: 'Aree dei servizi',
		lead_program: 'Preferenze lead',
		ein_program: 'Lead ed Enphase Installer Network',
		ac_module_admin: 'Amministrazione del modulo CA',
		enphase_store: 'Negozio Enphase',
		product_warranty_extension: 'Estensione della garanzia dei prodotti',
		lpp: 'Programma di tutela del lavoro',
		permit_assistance: 'Assistenza consentita',
		incentive_programs: 'Programmi di incentivi',
		ein_merchandise: 'Articoli promozionali EIN',
		financing: 'Finanziamento',
		training: 'Formazione',
		collaterals: 'Articoli coordinati',
		my_leads: 'I miei lead',
		lead_details: 'Dettagli richiesta preventivo',
		api_access: 'Accesso API',
		third_party: 'Segnalazioni di terze parti',
		profiles: 'Profili rete',
		createBranch: 'Aggiungi filiale',
		branch_profile: 'Profilo filiale',
		new: 'Nuovo',
		applications: 'Applicazioni',
		createUser: 'Aggiungi nuovo utente',
		user_profile: 'Profilo utente',
		platform_discounts: 'Sconti piattaforme',
		account_details: 'Dettagli account',
		preferences: 'Preferenze',
		change_password: 'Cambia password',
		account: 'Account',
		dashboard: 'Dashboard',
		systems: 'Sistemi',
		admin: 'Amministrazione',
		einDashboard: 'Dashboard EIN',
		einAdmin: 'Amministrazione EIN',
		edit: 'Modifica',
		failureMessage: 'Si è verificato un errore, riprovare'
	}
};

export default accountInfo;
