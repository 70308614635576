import { call, take, put } from 'redux-saga/effects';
import { get, post } from '../../../../utils/api';
import settings from '../../../../utils/settings';
import {
  GET_LRE_PLAN_REQUEST,
  GET_LRE_PLAN_SUCCESS,
  GET_LRE_PLAN_FAILURE,
  GET_SITE_LIST_REQUEST,
  GET_SITE_LIST_SUCCESS,
  GET_SITE_LIST_FAILURE,
  GET_LRE_ITEMS,
  GET_LRE_ITEMS_SUCCESS,
  GET_LRE_ITEMS_FAILURE,
  UPDATE_LRE_ITEMS,
  UPDATE_LRE_SUCCESS,
  UPDATE_LRE_FAILURE,
  ADD_ITEMS_TO_CART,
  ADD_ITEMS_TO_CART_SUCCESS,
  ADD_ITEMS_TO_CART_FAILURE,
  GET_SELECTED_ITEMS,
  GET_SELECTED_ITEMS_SUCCESS,
  GET_SELECTED_ITEMS_FAILURE,
  GET_PLAN_BY_SITES,
  GET_PLAN_BY_SITES_SUCCESS,
  GET_PLAN_BY_SITES_FAILURE,
  GET_AGREEMENT_REQUEST,
  GET_AGREEMENT_FAILURE,
  CREATE_SYSTEM_SUCCESS,
  CREATE_SYSTEM_FAILURE,
  CREATE_SYSTEM_REQUEST,
  GET_AGREEMENT_SUCCESS,
} from './constants';
import { getToken } from '../../../../utils/helper';

export function* getLREPlansFlow() {
  while (true) {
    const request = yield take(GET_LRE_PLAN_REQUEST);
    const { companyId } = request.payload;
    try {
      const url = `enp_lre/getPlans?company_id=${companyId}`;
      const response = yield call(get, { url: settings.digiCoreUrl(url), withBearer: true });
      const { success, status, result } = response;
      const LREPlans = [];
      if (success && status === 200) {
        result.lre_plans.forEach((item) => {
          const price = {};
          item.price_list.forEach((p) => {
            price[p.label] = p.price;
            price[`discount_${p.label}`] = ((100 - item.discount_percentage) * p.price) / 100;
          });
          item.price = price;
          item.extension = Number((item && item.validity_in_years) || 0);
          delete item.price_list;
          if (item.sku === '3-YEAR-EXTN') {
            LREPlans[0] = item;
          } else {
            LREPlans[1] = item;
          }
        });
        yield put({ type: GET_LRE_PLAN_SUCCESS, LREPlans });
      } else {
        yield put({ type: GET_LRE_PLAN_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_LRE_PLAN_FAILURE });
    }
  }
}

export function* getSiteListsFlow() {
  while (true) {
    const request = yield take(GET_SITE_LIST_REQUEST);
    const { successCbk } = request.payload;
    try {
      const response = yield call(post, {
        url: settings.getDrsUrl(`proxyApi/request`),
        body: { url: [settings.getApiUrl('api_internal/sites/')], method: ['GET'], 'site[country][text]': ['us'] },
        headers: { 'Content-Type': 'application/json', Authorization: getToken() },
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_SITE_LIST_SUCCESS, siteList: result });
        successCbk();
      } else {
        yield put({ type: GET_LRE_PLAN_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_SITE_LIST_FAILURE });
    }
  }
}

export function* getLREItems() {
  while (true) {
    const request = yield take(GET_LRE_ITEMS);
    try {
      const { siteIds = [], successCbk = () => {}, isUpdateLreItems = true } = request.payload;
      const url = `enp_lre/getLRE`;
      const response = yield call(post, {
        url: settings.digiCoreUrl(url),
        body: { site_ids: siteIds },
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        if (isUpdateLreItems) {
          yield put({ type: GET_LRE_ITEMS_SUCCESS, lreItems: result });
          successCbk();
        } else {
          yield put({ type: GET_LRE_ITEMS_SUCCESS });
          successCbk(result.lr_items);
        }
      } else {
        yield put({ type: GET_LRE_ITEMS_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_LRE_ITEMS_FAILURE });
    }
  }
}

export function* getPlansBySiteId() {
  while (true) {
    const request = yield take(GET_PLAN_BY_SITES);
    try {
      const { siteIds = [] } = request.payload;
      const url = `enp_lre/getPlansBySiteId`;
      const response = yield call(post, {
        url: settings.digiCoreUrl(url),
        body: { site_ids: siteIds },
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_PLAN_BY_SITES_SUCCESS, lrePlansBySiteId: result.plan_data });
      } else {
        yield put({ type: GET_PLAN_BY_SITES_FAILURE, message: response.result.message || response.result.messages });
      }
    } catch (error) {
      yield put({ type: GET_PLAN_BY_SITES_FAILURE, message: error });
    }
  }
}

export function* updateLREItems() {
  while (true) {
    const request = yield take(UPDATE_LRE_ITEMS);
    try {
      const { siteDetails, successCbk } = request.payload;
      const url = `enp_lre`;
      const response = yield call(post, {
        url: settings.digiCoreUrl(url),
        body: { lre_items: siteDetails },
        withBearer: true,
      });
      const { success, status } = response;
      if (success && status === 200) {
        successCbk();
        yield put({ type: UPDATE_LRE_SUCCESS });
      } else {
        yield put({ type: UPDATE_LRE_FAILURE });
      }
    } catch (error) {
      yield put({ type: UPDATE_LRE_FAILURE });
    }
  }
}

export function* addItemsToCart() {
  while (true) {
    const request = yield take(ADD_ITEMS_TO_CART);
    try {
      const { skuDetails, successCbk = () => {} } = request.payload;
      const url = `store/addToCartLPP`;
      const response = yield call(post, {
        url: settings.digiCoreUrl(url),
        body: { skuDetails },
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCbk(result);
        yield put({ type: ADD_ITEMS_TO_CART_SUCCESS });
      } else {
        yield put({ type: ADD_ITEMS_TO_CART_FAILURE });
      }
    } catch (error) {
      yield put({ type: ADD_ITEMS_TO_CART_FAILURE });
    }
  }
}

export function* getSelectedItems() {
  while (true) {
    const request = yield take(GET_SELECTED_ITEMS);
    try {
      const { companyId, successCbk = () => {} } = request.payload;
      const url = `enp_lre/getLREByCompanyId?company_id=${companyId}`;
      const response = yield call(get, {
        url: settings.digiCoreUrl(url),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        const lrePlans = result && result.lre_plans ? result.lre_plans : [];
        successCbk(lrePlans);
        yield put({ type: GET_SELECTED_ITEMS_SUCCESS });
      } else {
        yield put({ type: GET_SELECTED_ITEMS_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_SELECTED_ITEMS_FAILURE });
    }
  }
}

export function* getAgreementFileFlow() {
  while (true) {
    const request = yield take(GET_AGREEMENT_REQUEST);
    try {
      const { siteId, successCbk = () => {} } = request.payload;
      const response = yield call(get, {
        url: settings.digiCoreUrl(`enp_lre/getLPP?site_id=${siteId}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        successCbk(result);
        yield put({ type: GET_AGREEMENT_SUCCESS });
      } else {
        yield put({ type: GET_AGREEMENT_FAILURE, message: result.reason });
      }
    } catch (error) {
      yield put({ type: GET_AGREEMENT_FAILURE, error });
    }
  }
}

// needed
export function* createSystem() {
  while (true) {
    const request = yield take(CREATE_SYSTEM_REQUEST);
    try {
      const { system, successCbk = () => {} } = request.payload;
      const response = yield call(post, {
        url: settings.digiCoreUrl('systems/new'),
        body: system,
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 201 && result.system_id) {
        successCbk(result);
        yield put({ type: CREATE_SYSTEM_SUCCESS });
      } else {
        yield put({ type: CREATE_SYSTEM_FAILURE, message: 'Something went wrong! Please try again later' });
      }
    } catch (error) {
      yield put({ type: CREATE_SYSTEM_FAILURE, message: 'Something went wrong! Please try again later' });
    }
  }
}
