/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
import {
  GET_LEADS_SUCCESS,
  GET_REQUEST_FAILURE,
  ACCEPT_LEAD_SUCCESS,
  LEAD_DETAILS_SUCCESS,
  GET_LEADS_REQUEST,
  PUT_ACCEPT_LEAD_REQUEST,
  GET_LEAD_DETAILS_REQUEST,
  POST_CREATE_QUOTE_REQUEST,
  CREATE_QUOTE_SUCCESS,
  POST_REQUEST_FAILURE,
  GET_QUOTE_S3_URL_SUCCESS,
  PUT_QUOTE_UPLOAD_SUCCESS,
  GET_PANEL_MFG_SUCCESS,
  GET_PANEL_MODEL_SUCCESS,
  GET_BOM_LIST_SUCCESS,
  LEAD_S3_IMAGE_FILE_URL_SUCCESS,
  GET_MASTER_APPLIANCES_SUCCESS,
  SITE_SEARCH_REQUEST_LEAD,
  SITE_SEARCH_REQUEST_LEAD_SUCCESS,
  SITE_SEARCH_REQUEST_LEAD_FAILURE,
  LINK_SITE_WITH_LEAD_REQUEST,
  LINK_SITE_WITH_LEAD_REQUEST_SUCCESS,
  LINK_SITE_WITH_LEAD_REQUEST_FAILURE,
  GET_MESSAGES_LIST_REQUEST,
  GET_MESSAGES_LIST_SUCCESS,
  GET_MESSAGES_LIST_FAILURE,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  GET_APPOINTMENT_HISTORY_REQUEST,
  GET_APPOINTMENT_HISTORY_SUCCESS,
  GET_APPOINTMENT_HISTORY_FAILURE,
  SYSTEM_DETAILS_REQUEST,
  SYSTEM_DETAILS_FAILURE,
  SYSTEM_DETAILS_SUCCESS,
  UNLINK_SITE_WITH_LEAD_FAILURE,
  UNLINK_IN_PROGRESS,
  GET_FINANCE_PROVIDERS_REQUEST,
  GET_FINANCE_PROVIDERS_SUCCESS,
  GET_FINANCE_PROVIDERS_FAILURE,
  GET_CONTRACT_LEAD_JOURNEY_SUCCESS,
  GET_CONTRACT_LEAD_JOURNEY_REQUEST,
  SET_LEFT_PANEL_OPEN_LIST,
  SET_LEAD_DETAILS,
  SET_PREVIOUS_PANEL_LENGTH,
  SITE_SEARCH_LEAD_REQUEST,
  SITE_SEARCH_LEAD_FAILURE,
  SITE_SEARCH_LEAD_SUCCESS,
  GET_LEAD_FROM_JOURNEY_ID_REQUEST,
  GET_LEAD_FROM_JOURNEY_ID_SUCCESS,
  GET_LEAD_FROM_JOURNEY_ID_FAILURE,
  CREATE_LEAD_REQUEST,
  CREATE_LEAD_SUCCESS,
  CREATE_LEAD_FAILURE,
  CHECK_EXISTING_EMAIL_REQUEST,
  CHECK_EXISTING_EMAIL_SUCCESS,
  CHECK_EXISTING_EMAIL_FAILURE,
  IS_ACCOUNT_IN_ENLIGHTEN_REQUEST,
  IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS,
  GET_SOLARGRAF_FINANCIALS_REQUEST,
  GET_SOLARGRAF_FINANCIALS_SUCCESS,
  GET_SOLARGRAF_FINANCIALS_FAILURE,
} from './constants';

let initialState = {
  error: '',
  currentlySending: false,
  leads: [],
  totalElements: 0,
  leadDetails: {},
  message: '',
  messageTitle: '',
  messageType: '',
  preSignedS3Url: '',
  panelMfg: [],
  panelModel: [],
  showSuccessDialog: false,
  bomItem: [],
  totalNewLeads: 0,
  bomAPIResponse: {},
  masterAppliances: [],
  siteSearchInProgress: false,
  searchResults: { total_count: 0, result: [] },
  messageList: [],
  appointmentHistoryList: [],
  systemDetails: {},
  unlinkInProgress: false,
  leadStatus: true,
  financeProvidersList: [],
  leftPanelOpenList: [
    {
      key: 'lead',
      isOpen: false,
    },
    {
      key: 'system_details',
      isOpen: false,
    },
    {
      key: 'services',
      isOpen: false,
    },
    {
      key: 'system_reporting',
      isOpen: false,
    },
    {
      key: 'support_service',
      isOpen: false,
    },
  ],
  siteSearchList: [],
  currentlySendingSelfLead: false,
  validatingSelfLead: false,
  isHomeownerAccountInEnlighten: false,
  systemDetailsLoading: false,
  solargrafFinancials: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // needed
    case SET_LEFT_PANEL_OPEN_LIST:
      return { ...state, leftPanelOpenList: action.leftPanelOpenList };
    // ends
    case PUT_ACCEPT_LEAD_REQUEST:
      return { ...state, currentlySending: true };
    case GET_LEADS_REQUEST:
      return { ...state, currentlySending: true };
    // needed
    case GET_LEAD_DETAILS_REQUEST:
      return { ...state, currentlySending: true, showSuccessDialog: false, message: '', messageType: '' };
    // needed
    case LINK_SITE_WITH_LEAD_REQUEST:
      return { ...state, currentlySending: true };
    case LINK_SITE_WITH_LEAD_REQUEST_SUCCESS:
      return { ...state, currentlySending: false };
    case LINK_SITE_WITH_LEAD_REQUEST_FAILURE:
      return { ...state, currentlySending: false };
    // ends
    case POST_CREATE_QUOTE_REQUEST:
      const loading = !action.payload.fromOrder;
      return { ...state, currentlySending: loading };
    // needed
    case SITE_SEARCH_REQUEST_LEAD:
      return { ...state, siteSearchInProgress: true };
    case SITE_SEARCH_REQUEST_LEAD_SUCCESS:
      return {
        ...state,
        siteSearchInProgress: false,
        searchResults: action.searchList,
      };
    case SITE_SEARCH_REQUEST_LEAD_FAILURE:
      return {
        ...state,
        siteSearchInProgress: false,
        messageType: 'error',
        message: action.error,
      };
    case SYSTEM_DETAILS_REQUEST:
      return { ...state, systemDetailsLoading: true };
    case SYSTEM_DETAILS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.error,
        showSuccessDialog: false,
        systemDetailsLoading: false,
      };
    case SYSTEM_DETAILS_SUCCESS:
      return {
        ...state,
        systemDetails: action.systemDetails,
        systemDetailsLoading: false,
      };
    // ends
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.leads,
        totalElements: action.totalElements,
        totalNewLeads: action.totalNewLeads,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case ACCEPT_LEAD_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        companyId: action.companyId,
        lead_id: action.lead_id,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
        leadStatus: action.leadStatus,
      };
    case GET_CONTRACT_LEAD_JOURNEY_REQUEST:
      return {
        ...state,
        currentlySending: true,
      };
    case GET_CONTRACT_LEAD_JOURNEY_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        messageTitle: action.messageTitle,
      };
    // needed
    case LEAD_DETAILS_SUCCESS:
      return {
        ...state,
        leadDetails: action.leadDetails,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        showSuccessDialog: true,
        unlinkInProgress: action.unlinkInProgress,
        leadStatus: action.leadStatus,
      };
    case CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        qDetails: action.qDetails,
        messageType: 'success',
        message: action.message,
        showSuccessDialog: true,
        messageTitle: action.messageTitle,
      };
    case GET_QUOTE_S3_URL_SUCCESS:
      return {
        ...state,
        preSignedS3Url: action.preSignedS3Url,
      };
    case LEAD_S3_IMAGE_FILE_URL_SUCCESS:
      return {
        ...state,
        preSignedS3Url: action.preSignedS3Url,
      };
    case PUT_QUOTE_UPLOAD_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
      };
    case GET_PANEL_MFG_SUCCESS:
      return {
        ...state,
        panelMfg: action.panelMfg,
      };
    case GET_PANEL_MODEL_SUCCESS:
      return {
        ...state,
        panelModel: action.panelModel,
      };
    // needed
    case GET_REQUEST_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
        showSuccessDialog: false,
      };
    case POST_REQUEST_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
        showSuccessDialog: false,
      };
    case UNLINK_SITE_WITH_LEAD_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
        showSuccessDialog: false,
      };
    case GET_BOM_LIST_SUCCESS:
      return {
        ...state,
        bomItem: action.bom_items,
        bomAPIResponse: action.bomAPIResponse,
      };
    case GET_MASTER_APPLIANCES_SUCCESS:
      return {
        ...state,
        masterAppliances: action.masterAppliances,
        currentlySending: false,
        messageType: 'success',
      };
    case GET_MESSAGES_LIST_REQUEST:
      return { ...state };
    case GET_MESSAGES_LIST_SUCCESS:
      return {
        ...state,
        messageList: action.messageList,
        unreadMessageCount: action.unreadMessageCount,
      };
    case GET_MESSAGES_LIST_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    case SEND_MESSAGE_REQUEST:
      return { ...state };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
      };
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    case GET_APPOINTMENT_HISTORY_REQUEST:
      return { ...state };
    case GET_APPOINTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        appointmentHistoryList: action.appointmentHistoryList,
      };
    case GET_APPOINTMENT_HISTORY_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    case UNLINK_IN_PROGRESS:
      return {
        ...state,
        unlinkInProgress: action.unlinkInProgress,
        systemDetailsLoading: false,
      };
    case GET_FINANCE_PROVIDERS_REQUEST:
      return { ...state };
    case GET_FINANCE_PROVIDERS_SUCCESS:
      return {
        ...state,
        financeProvidersList: action.financeProvidersList,
      };
    case GET_FINANCE_PROVIDERS_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    // needed
    case SET_LEAD_DETAILS:
      return {
        ...state,
        leadDetails: action.leadDetails,
      };
    // needed
    case SET_PREVIOUS_PANEL_LENGTH:
      return {
        ...state,
        previousPanelLength: action.previousPanelLength,
      };
    // ends
    case SITE_SEARCH_LEAD_REQUEST:
      return {
        ...state,
      };
    case SITE_SEARCH_LEAD_SUCCESS:
      return {
        ...state,
        siteSearchList: action.siteSearchList,
      };
    case SITE_SEARCH_LEAD_FAILURE:
      return {
        ...state,
        messageType: 'error',
        message: action.message,
      };
    // needed
    case GET_LEAD_FROM_JOURNEY_ID_REQUEST:
      return { ...state, currentlySending: true };
    case GET_LEAD_FROM_JOURNEY_ID_SUCCESS:
      return {
        ...state,
        leadDetails: action.leadDetails,
        currentlySending: false,
        messageType: 'success',
        message: '',
      };
    case GET_LEAD_FROM_JOURNEY_ID_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: 'Failed to open project, please try again later',
      };
    // ends
    case CREATE_LEAD_REQUEST:
      return {
        ...state,
        currentlySendingSelfLead: true,
      };
    case CREATE_LEAD_SUCCESS:
      return {
        ...state,
        currentlySendingSelfLead: false,
      };
    case CREATE_LEAD_FAILURE:
      return {
        ...state,
        currentlySendingSelfLead: false,
        messageType: 'error',
        message: action.error,
      };
    case CHECK_EXISTING_EMAIL_REQUEST:
      return {
        ...state,
        validatingSelfLead: true,
      };
    case CHECK_EXISTING_EMAIL_SUCCESS:
      return {
        ...state,
        validatingSelfLead: false,
      };
    case CHECK_EXISTING_EMAIL_FAILURE:
      return {
        ...state,
        validatingSelfLead: false,
      };
    case IS_ACCOUNT_IN_ENLIGHTEN_REQUEST:
      return {
        ...state,
      };
    case IS_ACCOUNT_IN_ENLIGHTEN_SUCCESS:
      return {
        ...state,
        isHomeownerAccountInEnlighten: action.isHomeownerAccountInEnlighten,
      };
    // needed
    case GET_SOLARGRAF_FINANCIALS_SUCCESS:
      return {
        ...state,
        solargrafFinancials: action.solargrafFinancials,
      };
    case GET_SOLARGRAF_FINANCIALS_FAILURE:
      return {
        ...state,
        solargrafFinancials: {},
      };
    // ends
    default:
      return state;
  }
}

export default reducer;
