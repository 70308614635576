export var ChatActionTypes;
(function (ChatActionTypes) {
    ChatActionTypes["GET_ALL_MESSAGES_REQUEST"] = "GET_ALL_MESSAGES_REQUEST";
    ChatActionTypes["GET_ALL_MESSAGES_SUCCESS"] = "GET_ALL_MESSAGES_SUCCESS";
    ChatActionTypes["GET_ALL_MESSAGES_FAILURE"] = "GET_ALL_MESSAGES_FAILURE";
    ChatActionTypes["POST_MESSAGE_REQUEST"] = "POST_MESSAGE_REQUEST";
    ChatActionTypes["POST_MESSAGE_SUCCESS"] = "POST_MESSAGE_SUCCESS";
    ChatActionTypes["POST_MESSAGE_FAILURE"] = "POST_MESSAGE_FAILURE";
    ChatActionTypes["MARK_MESSAGES_VIEWED_REQUEST"] = "MARK_MESSAGES_VIEWED_REQUEST";
    ChatActionTypes["MARK_MESSAGES_VIEWED_SUCCESS"] = "MARK_MESSAGES_VIEWED_SUCCESS";
    ChatActionTypes["MARK_MESSAGES_VIEWED_FAILURE"] = "MARK_MESSAGES_VIEWED_FAILURE";
})(ChatActionTypes || (ChatActionTypes = {}));
