export var AdminActionTypes;
(function (AdminActionTypes) {
    AdminActionTypes["UPDATE_ADMIN_DOCS_REQUEST"] = "UPDATE_ADMIN_DOCS_REQUEST";
    AdminActionTypes["UPDATE_ADMIN_DOCS_SUCCESS"] = "UPDATE_ADMIN_DOCS_SUCCESS";
    AdminActionTypes["UPDATE_ADMIN_DOCS_FAILURE"] = "UPDATE_ADMIN_DOCS_FAILURE";
    AdminActionTypes["UPDATE_STATUS_REQUEST"] = "UPDATE_STATUS_REQUEST";
    AdminActionTypes["UPDATE_STATUS_SUCCESS"] = "UPDATE_STATUS_SUCCESS";
    AdminActionTypes["UPDATE_STATUS_FAILURE"] = "UPDATE_STATUS_FAILURE";
    AdminActionTypes["REQUEST_TIER2_APPROVAL_REQUEST"] = "REQUEST_TIER2_APPROVAL_REQUEST";
    AdminActionTypes["REQUEST_TIER2_APPROVAL_SUCCESS"] = "REQUEST_TIER2_APPROVAL_SUCCESS";
    AdminActionTypes["REQUEST_TIER2_APPROVAL_FAILURE"] = "REQUEST_TIER2_APPROVAL_FAILURE";
    AdminActionTypes["DOWNLOAD_ALL_FILES_REQUEST"] = "DOWNLOAD_ALL_FILES_REQUEST";
    AdminActionTypes["DOWNLOAD_ALL_FILES_SUCCESS"] = "DOWNLOAD_ALL_FILES_SUCCESS";
    AdminActionTypes["DOWNLOAD_ALL_FILES_FAILURE"] = "DOWNLOAD_ALL_FILES_FAILURE";
    AdminActionTypes["SET_STATUS_TO_UNDER_REVIEW_REQUEST"] = "SET_STATUS_TO_UNDER_REVIEW_REQUEST";
    AdminActionTypes["SET_STATUS_TO_UNDER_REVIEW_SUCCESS"] = "SET_STATUS_TO_UNDER_REVIEW_SUCCESS";
    AdminActionTypes["SET_STATUS_TO_UNDER_REVIEW_FAILURE"] = "SET_STATUS_TO_UNDER_REVIEW_FAILURE";
})(AdminActionTypes || (AdminActionTypes = {}));
