import React from 'react';

function Forward(props) {
  const { disabled } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34.3" height="30" viewBox="0 0 34.3 30">
      <defs />
      <g transform="translate(0 -32.109)">
        <g transform="translate(0 32.109)">
          <path
            fill={disabled ? '#919191' : '#016ae3'}
            d="M33.881,45.385,21.026,32.53a1.428,1.428,0,0,0-2.438,1.01v5.72A19.294,19.294,0,0,0,.9,53.1,22.4,22.4,0,0,0,.01,60.266c.008.275.01.355.01.412a1.428,1.428,0,0,0,2.656.731,21.952,21.952,0,0,1,5.378-6.267A14.672,14.672,0,0,1,18.588,51.9V59.25a1.428,1.428,0,0,0,2.438,1.01L33.881,47.4A1.428,1.428,0,0,0,33.881,45.385ZM21.444,55.8V50.68a1.428,1.428,0,0,0-1.079-1.385,8.539,8.539,0,0,0-.854-.156,17.384,17.384,0,0,0-13.2,3.741,22.134,22.134,0,0,0-3.126,2.953l.022-.114c.114-.586.26-1.192.443-1.829A16.437,16.437,0,0,1,19.738,42.1l.229.005a1.428,1.428,0,0,0,1.478-1.427V36.988L30.851,46.4Z"
            transform="translate(0 -32.109)"
          />
        </g>
      </g>
    </svg>
  );
}

export default Forward;
