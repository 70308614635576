import React from 'react';
import clsx from 'clsx';
import { Dialog, DialogTitle, Typography, DialogContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { SOLARGRAF_DRAW_DESIGN_TUTORIAL } from '../../../config/constants';

const useStyles = makeStyles((theme) => ({
  quoteDialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    fontSize: 25,
    color: '#4A4A4A',
  },
  knowMoreDialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    width: theme.spacing(10),
    margin: theme.spacing(2, 0),
  },
  button: {
    borderRadius: 0,
    color: '#FFFFFF',
    textTransform: 'none',
    fontWeight: 'bold',
  },
}));
const KnowMorePopup = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth id="knowMoreVideoDialog">
      <DialogTitle disableTypography className={classes.quoteDialogTitle}>
        <Typography className={classes.dialogTitle}>How to use Design and Proposal tool</Typography>
      </DialogTitle>
      <DialogContent className={classes.knowMoreDialogContent}>
        <iframe
          src={SOLARGRAF_DRAW_DESIGN_TUTORIAL}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          height={300}
          allowFullScreen
          title="video"
        />
        <Button
          className={clsx(classes.button, classes.backButton)}
          color="primary"
          variant="contained"
          onClick={() => onClose()}
          disableElevation
        >
          Back
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default KnowMorePopup;
