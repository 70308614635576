/* eslint-disable camelcase */
/* eslint-disable no-useless-escape */
/* eslint-disable no-shadow */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  CircularProgress,
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { get, isEmpty, uniq, values } from 'lodash';
import AddSystemSection from './addSystemSection';
import { createSystem } from '../../containers/Systems/actions';
import { linkSiteWithLead, getSolargrafFinancials } from '../../containers/LeadManagement/actions';
import I18n from '../../../../config/locales';

const style = (theme) => ({
  dialogHeader: {
    color: '#335977',
    fontSize: '29px',
    fontWeight: 'Bold',
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: 0.23,
    cursor: 'pointer',
  },
  content: {
    margin: '0 20px',
    padding: '1px',
    color: '#2C2C2C !important',
  },
  paperContent: {
    border: '1px solid #7070704D',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  },
  radioInputField: {
    width: 'auto',
  },
  dialogRejectButton: {
    color: '#70707081',
    background: theme.palette.secondary.main,
  },
  orDivider: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(2, 0, 1.5),
    color: '#325977',
  },
  acceptButton: {
    color: theme.palette.primary.white,
  },
  autoCompleteField: {
    maxWidth: theme.spacing(55),
    marginTop: theme.spacing(1),
  },
  actionContainer: {
    borderTop: '2px solid #70707033',
    padding: theme.spacing(2.5),
  },
  alreadyLinkedMessage: {
    color: theme.palette.primary.main,
  },
});

const StyledDialog = withStyles({
  root: {
    zIndex: '700 !important',
  },
})(Dialog);

class AddSystemDialog extends React.Component {
  constructor(props) {
    super(props);
    this.maps = window.google && window.google.maps;
    this.detailsPrefilled = false;
    this.addressFromGoogleTriggered = false;
    this.state = { ...this.initializeState() };
  }
  // to be done in enlm also
  componentDidMount() {
    const { leadDetails } = this.props;
    const projectId = get(leadDetails, 'sg_project_id', '') || '';
    const projectPublicId = get(leadDetails, 'sg_project_public_id', '') || '';
    if (projectId) {
      this.props.getSolargrafFinancials({ projectPublicId });
    }
  }

  componentDidUpdate() {
    if (!this.detailsPrefilled && !isEmpty(this.props.leadDetails)) {
      const { leadDetails, solargrafFinancials } = this.props;
      const projectId = get(leadDetails, 'sg_project_id', '') || '';
      const { first_name = '', last_name = '', phone = '', email = '' } = leadDetails;
      const ownerData = {
        first_name: first_name || '',
        last_name: last_name || '',
        phone: phone || '',
        email: email || '',
      };
      if (projectId && !isEmpty(solargrafFinancials)) {
        const fullAddress = get(solargrafFinancials, 'address');
        const materials = get(solargrafFinancials, 'materials');
        if (!this.addressFromGoogleTriggered) {
          this.getAddressFromLocation(fullAddress);
          this.addressFromGoogleTriggered = true;
        }
        if (!isEmpty(this.state.googleAddress)) {
          const inverter = materials.find((material) => material.materialType === 'inverter');
          const inverterCount = inverter ? inverter.count : '0';
          this.setState({
            address: { ...this.state.googleAddress },
            owner: { ...ownerData },
            system: { ...this.state.system, expected_pcu_count: inverterCount },
          });
          this.detailsPrefilled = true;
        }
      } else if (!projectId) {
        const address = get(leadDetails, 'address', '') || '';
        const {
          address1: street1 = '',
          address2: street2 = '',
          city = '',
          country = '',
          state = '',
          zip: postal_code = '',
        } = address;
        this.setState({ address: { street1, street2, city, country, state, postal_code }, owner: { ...ownerData } });
        this.detailsPrefilled = true;
      }
    }
  }

  initializeState = () => {
    return {
      anyErrors: false,
      errors: {
        street1: false,
        city: false,
        state: false,
        country: false,
        postal_code: false,
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
        system_name: false,
        source: false,
        expected_pcu_count: false,
        // is_lease: false,
        // installer: true,
        // operational: true
      },
      isNewSystem: '',
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        postal_code: '',
      },
      owner: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      system: {
        system_name: '',
        // reference: '',
        source: '',
        is_lease: false,
        expected_pcu_count: '0',
        installer: '',
        operational: '',
      },
      googleAddress: {},
      isConfirmChecked: false,
      isExpanded: false,
      isInValidEmail: false,
      isInValidPhone: false,
    };
  };

  handleClosePopup = () => {
    this.setState({ ...this.initializeState() });
    this.detailsPrefilled = false;
    this.addressFromGoogleTriggered = false;
    this.props.handleViewLinkSiteClose();
  };

  setOwnerInfo = (e, key, validate = true) => {
    const value = e.target.value;
    const newOwner = { ...this.state.owner };
    newOwner[key] = value;
    this.setState({ owner: newOwner });
    if (validate) {
      const errors = this.getErrors(key, value);
      const anyErrors = this.anyErrors(errors);
      this.setState({ errors, anyErrors });
    }
  };

  setSystemInfo = (e, key, validate = true, isCheckbox = false) => {
    const value = isCheckbox ? e.target.checked : e.target.value;
    const newSystem = { ...this.state.system };
    newSystem[key] = value;
    this.setState({ system: newSystem });
    if (validate) {
      const errors = this.getErrors(key, value);
      const anyErrors = this.anyErrors(errors);
      this.setState({ errors, anyErrors });
    }
  };

  setAddressInfo = (e, key, validate = true) => {
    const value = e.target.value;
    const newAddress = { ...this.state.address };
    newAddress[key] = value;
    this.setState({ address: newAddress });
    if (validate) {
      const errors = this.getErrors(key, value);
      const anyErrors = this.anyErrors(errors);
      this.setState({ errors, anyErrors });
    }
  };

  setAddress = (newAddress) => {
    const newErrors = { ...this.state.errors };
    newErrors.street1 = false;
    newErrors.city = false;
    newErrors.state = false;
    newErrors.country = false;
    newErrors.postal_code = false;
    const anyErrors = this.anyErrors(newErrors);
    this.setState({ address: newAddress, errors: newErrors, anyErrors });
  };

  setIsNewSystem = (isNewSystem) => {
    this.setState({ isNewSystem });
  };

  linkSystemToLead = (siteID) => {
    const { leadDetails } = this.props;
    const { lead_id, journey_id } = leadDetails;
    this.props.linkSiteWithLead({
      lead_id,
      site_id: siteID,
      installer_lead_journey_id: journey_id,
      successCb: () => {
        window.location.reload();
      },
      failureCb: (result) => {
        console.log(result);
      },
    });
  };

  proceedSubmit = async () => {
    const { isNewSystem, system, address, owner } = this.state;
    const { selectedSite, companyId } = this.props;
    if (!isNewSystem && selectedSite.id) {
      return this.linkSystemToLead(selectedSite.id);
    }
    if (isNewSystem) {
      const errors = this.getErrors('', '');
      const anyErrors = this.anyErrors(errors);
      if (anyErrors) {
        this.setState({ errors, anyErrors, isExpanded: true });
      } else {
        const systemPayload = { ...system, installer: companyId, address, owner };
        await this.props.createSystem({
          system: systemPayload,
          successCbk: (response) => this.linkSystemToLead(response.system_id),
        });
      }
    } else {
      this.linkSystemToLead(selectedSite.id);
    }
  };

  getErrors = (key, value) => {
    let newErros = { ...this.state.errors };
    const emailCheck = (emailValue) => {
      if (isEmpty(emailValue === null ? '' : emailValue.toString())) {
        this.setState({ isInValidEmail: false });
        return true;
      } else {
        const emailFormat =
          /^(([^<>()\[\]\\.,;:\u00C0-\u00FF\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/;
        const isValidEmail = emailValue?.match(emailFormat);
        this.setState({ isInValidEmail: !isValidEmail });
        return !isValidEmail;
      }
    };

    const phoneCheck = (phoneValue) => {
      const normalizedPhone = phoneValue?.toString() || '';
      const isInvalidPhone = normalizedPhone.length !== 10;
      this.setState({ isInValidPhone: isInvalidPhone });
      return isInvalidPhone;
    };

    if (key === '') {
      Object.keys(newErros).forEach((allKey) => {
        const data = { ...this.state.address, ...this.state.system, ...this.state.owner };
        if (allKey === 'email') {
          newErros[allKey] = emailCheck(data[allKey]);
        } else if (allKey === 'phone') {
          newErros[allKey] = phoneCheck(data[allKey]);
        } else {
          newErros[allKey] = isEmpty(data[allKey] === null ? '' : data[allKey].toString());
        }
      });
    } else if (key === 'email') {
      newErros[key] = emailCheck(value);
    } else if (key === 'phone') {
      newErros[key] = phoneCheck(value);
    } else {
      newErros[key] = isEmpty(value === null ? '' : value.toString());
    }
    return newErros;
  };

  anyErrors = (newErrors = this.state.errors) => {
    const errorValues = uniq(values(newErrors));
    return errorValues.length === 1 ? errorValues[0] === true : true;
  };

  getAddressFromLocation = (address) => {
    const geocoder = new this.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === this.maps.GeocoderStatus.OK) {
        const address = this.getFilteredAddressObject(results[0]);
        this.setState({ googleAddress: address });
      } else {
        this.setState({ googleAddress: { street1: '', street2: '', city: '', state: '', zip: '', country: '' } });
      }
    });
  };

  getFilteredAddressObject = (addressObject) => {
    let addresses = addressObject.address_components;
    const streetNumber = addresses.find(
      (address) => address.types.includes('street_number') || address.types.includes('premise')
    );
    const route = addresses.find(
      (address) => address.types.includes('route') || address.types.includes('sublocality_level_1')
    );
    const address1 = streetNumber && route ? [streetNumber.long_name, route.long_name].join(', ') : '';
    const city = addresses.find((address) => address.types.includes('locality'));
    const state = addresses.find((address) => address.types.includes('administrative_area_level_1'));
    const postal_code = addresses.find((address) => address.types.includes('postal_code'));
    const country = addresses.find((address) => address.types.includes('country'));
    const addressObj = {
      street1: address1.split(',')[0],
      street2: address1.split(',')[1] ? address1.split(',')[1].trim() : '',
      city: (city && (city.long_name || city.short_name)) || '',
      state: (state && (state.short_name || state.long_name)) || '',
      postal_code: (postal_code && (postal_code.short_name || postal_code.long_name)) || '',
      country: (country && (country.short_name || country.long_name)) || '',
    };
    return addressObj;
  };

  render() {
    const {
      viewLinkSiteDialog,
      searchResults,
      siteSearchInLeadInProgress,
      searchValue,
      siteSearchInLeadMsg,
      siteSelection,
      handleSearch,
      siteSearchInProgress,
      classes,
    } = this.props;

    return (
      <StyledDialog
        onClose={this.handleClosePopup}
        aria-labelledby="customized-dialog-title"
        open={viewLinkSiteDialog}
        id="confirmation"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClosePopup}>
          <Typography className={classes.dialogHeader}>
            System Addition
            <CloseIcon onClick={this.handleClosePopup} className={classes.close} size="large" />
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <AddSystemSection
            setAddressInfo={this.setAddressInfo}
            setAddress={this.setAddress}
            setOwnerInfo={this.setOwnerInfo}
            setSystemInfo={this.setSystemInfo}
            setIsNewSystem={this.setIsNewSystem}
            address={this.state.address}
            owner={this.state.owner}
            system={this.state.system}
            isNewSystem={this.state.isNewSystem}
            errors={this.state.errors}
            isConfirmChecked={this.state.isConfirmChecked}
            isExpanded={this.state.isExpanded}
            isInValidEmail={this.state.isInValidEmail}
            isInValidPhone={this.state.isInValidPhone}
            toggleExpansion={() => this.setState({ isExpanded: !this.state.isExpanded })}
            handleConfirmCheckedChange={() => this.setState({ isConfirmChecked: !this.state.isConfirmChecked })}
          />
          <Typography className={classes.orDivider}>OR</Typography>
          <Box className={classes.paperContent}>
            <FormControlLabel
              className={classes.radioInputField}
              value={this.state.isNewSystem === false}
              control={
                <Radio
                  color="primary"
                  size="medium"
                  classes={{ padding: 0 }}
                  onClick={() => this.setIsNewSystem(false)}
                  checked={this.state.isNewSystem === false}
                />
              }
              label={<Typography>{I18n.t('leadManagement.selectSystemID')}</Typography>}
            />
            <Autocomplete
              autoComplete
              className={classes.autoCompleteField}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
              filterOptions={(x) => x}
              options={searchResults.total_count ? searchResults.result : []}
              value={searchValue}
              inputValue={searchValue}
              getOptionSelected={(option, value) => option.label === value}
              onChange={siteSelection}
              onInputChange={handleSearch}
              loading={siteSearchInProgress}
              closeIcon={isEmpty(searchValue) ? <Fragment /> : <CloseIcon fontSize="small" />}
              renderInput={(params) => (
                <TextField {...params} required label={I18n.t('leadManagement.systemNameValidation')} />
              )}
              renderOption={(option) => (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body1" color="textPrimary">
                      {option.id} - {option.label}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
            <br />
            {siteSearchInLeadInProgress && <CircularProgress size={15} />}
            {siteSearchInLeadMsg.length > 0 && (
              <Typography variant="caption" className={classes.alreadyLinkedMessage}>
                {siteSearchInLeadMsg}
                <br />
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Button onClick={this.handleClosePopup} className={classes.dialogRejectButton}>
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            disableElevation
            className={classes.acceptButton}
            disabled={this.state.isNewSystem ? !this.state.isConfirmChecked : !this.props.selectedSite.id}
            onClick={() => this.proceedSubmit()}
          >
            Proceed
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  linkSiteWithLead: (payload) => dispatch(linkSiteWithLead(payload)),
  createSystem: (payload) => dispatch(createSystem(payload)),
  getSolargrafFinancials: (payload) => dispatch(getSolargrafFinancials(payload)),
});

const mapStateToProps = (state) => ({
  solargrafFinancials: state.leadReducer.solargrafFinancials,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(AddSystemDialog));
