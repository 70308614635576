const footer = {
  footer: {
    imageTitle: 'Besuchen Sie die Enphase Energy Website',
    privacy: 'Datenschutz',
    terms: 'AGB',
    enphaseText: '©2008-%{currentYear} Enphase Energy Inc. Alle Rechte vorbehalten.',
    language: 'Sprache'
  }
};

export default footer;
