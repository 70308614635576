export const SET_COUNTRY_CODE_REQUEST = 'SET_COUNTRY_CODE_REQUEST';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const SET_ENLIGHTEN_MANAGER_TOKEN = 'SET_ENLIGHTEN_MANAGER_TOKEN';

export const GET_APPLICATION_NOTIFICATIONS_REQUEST = 'GET_APPLICATION_NOTIFICATIONS_REQUEST';
export const GET_APPLICATION_NOTIFICATIONS_FAILURE = 'GET_APPLICATION_NOTIFICATIONS_FAILURE';
export const GET_APPLICATION_NOTIFICATIONS_SUCCESS = 'GET_APPLICATION_NOTIFICATIONS_SUCCESS';

export const READ_APPLICATION_NOTIFICATIONS_REQUEST = 'READ_APPLICATION_NOTIFICATIONS_REQUEST';
export const READ_APPLICATION_NOTIFICATIONS_FAILURE = 'READ_APPLICATION_NOTIFICATIONS_FAILURE';

export const MARK_NOTIFICATION_AS_SEEN_REQUEST = 'MARK_NOTIFICATION_AS_SEEN_REQUEST';

export const DELETE_READ_NOTIFICATION_REQUEST = 'DELETE_READ_NOTIFICATION_REQUEST';

export const SET_FEATURE_LIST = 'SET_FEATURE_LIST';

export const GET_SOLARGRAF_SESSION = 'GET_SOLARGRAF_SESSION';
export const GET_SOLARGRAF_SESSION_SUCCESS = 'GET_SOLARGRAF_SESSION_SUCCESS';

export const VERIFY_SOLARGRAF_SESSION = 'VERIFY_SOLARGRAF_SESSION';

export const VERIFY_SOLARGRAF_EMAIL = 'VERIFY_SOLARGRAF_EMAIL';

export const GET_SOLARGRAF_POPUP_DETAILS_REQUEST = 'GET_SOLARGRAF_POPUP_DETAILS_REQUEST';

export const GET_SOLARGRAF_ACCESS = 'GET_SOLARGRAF_ACCESS';
export const SET_SOLARGRAF_ACCESS_GRANT = 'SET_SOLARGRAF_ACCESS_GRANT';

export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILURE = 'GET_APPLICATION_FAILURE';

export const SET_FROM_LEFT_TAB = 'SET_FROM_LEFT_TAB';

export const GET_PROJECT_DETAILS_REQUEST = 'GET_PROJECT_DETAILS_REQUEST';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE';

export const CALIFORNIA = 'CA';
