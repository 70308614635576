const designReviewService = {
  All: 'Tout',
  Document: 'Document',
  Image: 'Image',
  File: 'Fichier',
  drsDashboard: {
    pageHeader: 'Demandes de service de revue de conception',
    createNewTicket: 'Créer une nouvelle demande',
    filter: 'Filtre',
    all: 'Tout',
    tier1: 'Niveau 1',
    tier2: 'Niveau 2',
    country: 'Pays',
    us: 'États-Unis',
    australia: 'Australie',
    hideChart: 'Cacher le graphique',
    currentlyAssigned: 'Actuellement assigné',
    overAllStatus: 'Statut général de la demande',
    totalNoOf: 'Nombre total de',
    applications: 'Demandes',
    allApplication: 'Toutes les demandes',
    note: 'Note : Le tableau ci-dessous est filtré avec les demandes d’examen des dessins et modèles commerciaux. Veuillez',
    clickHere: 'cliquer ici',
    viewAllApplication: 'pour afficher toutes les demandes',
    projectType: 'RÉSIDENTIEL',
    service: 'DRS',
    assignedTo: 'Assigner à',
    assign: 'Assigner',
    germany: 'Allemagne',
    belgium: 'Belgique',
    austria: 'Autriche',
    netherlands: 'Pays-Bas',
    france: 'France',
    switzerland: 'Suisse',
  },
  applicationStatus: {
    pageHeader: 'Service de revue de conception',
    applicationSubmitted: 'Demande soumise pour le cours de certification sur le stockage Enphase',
    resubmittedRequest: 'Renvoyer les informations demandées',
    shareInformation:
      'Nous partagerons les informations fournies avec l’équipe d’Enphase pour les examiner plus en détail.',
    sendEnPhaseTeam: 'Envoyer à l’équipe Enphase',
  },
  designReviewService: {
    requestedId: 'Identifiant de la demande',
    enphaseStorageCourse: 'Partie du cours de certification sur le stockage Enphase',
  },
  projectDetails: {
    communicateEnphase: 'Contacter Enphase',
    headerText: 'Soumettre une demande',
    applicationSubmitted: 'Demande soumise',
    paymentFailed: 'Échec du paiement',
    applicationSuccess: 'Félicitations ! Votre demande a été soumise.',
    applicationFailure: 'Oups ! Nous n’avons pas été en mesure d’achever votre transaction.',
    paymentStatusSuccess:
      'Notre équipe vous contactera dans les 48 heures pour votre demande d’examen de la conception.',
    paymentStatusFailure: 'Veuillez réessayer ou contacter',
    documents: 'Documents',
    siteMap: 'Plan du site',
    singleLineDiagram: 'Tableau électrique principal',
    msp: 'Coffret de branchement',
    additionalDoc: 'Documents complémentaires',
    loadCalculation: 'Calcul de la consommation électrique',
    additionalDetails: 'Détails supplémentaires',
    photoUpload: 'Téléchargement de photos (photos de la visite sur site si disponible)',
    notes: 'Remarques',
    backupLoadDetails: 'Détails sur la charge secourue',
    batterySizingTool: 'Outil de dimensionnement de',
    batterySizingDetails: 'Détails sur le dimensionnement de la batterie',
    siteSurvey: 'Etude de site',
    siteSurveyDetails: "Détails sur l'étude de site",
  },
  requestDetails: {
    siteMapPhotosUrl: 'Plan du site',
    sldDocumentsUrl: 'Schéma unifilaire (SLD)',
    installationPhotosUrl: 'Photos de l’installation',
    loadCalculationDocumentsUrl: 'Documents de calcul de la charge',
    mspDocumentsUrl: 'Image du tableau électrique principal',
    otherDocumentsUrl: 'Documents',
    surveyPhotosUrl: 'Photos de l’enquête',
    heading: 'Détails de la demande d’examen de la conception',
    requestId: 'IDENTIFIANT DE LA DEMANDE',
    installerName: 'NOM DE L’INSTALLATEUR',
    systemId: 'IDENTIFIANT DU SYSTÈME',
    systemName: 'NOM DU SYSTÈME / PROJET',
    customerName: 'NOM DU CLIENT',
    zipState: 'CODE POSTAL / ÉTAT',
    status: 'STATUT',
    type: 'TYPE',
    assignedTo: 'ASSIGNÉ À',
    createdOn: 'CRÉÉ LE',
    updatedOn: 'MIS À JOUR LE',
    documents: 'Documents',
    downloadAll: 'Tout télécharger',
    links: 'Liens',
    installer: 'INSTALLATEUR',
    admin: 'ADMIN',
    today: 'Aujourd’hui',
    pastDays: '7 derniers jours',
    monthToData: 'Depuis le début du mois',
    yearToDate: 'Depuis le début de l’année',
    specificDate: 'Date précise',
    allDatesBefore: 'Toutes les dates avant',
    allDatesAfter: 'Toutes les dates après',
    dateRange: 'Intervalle de dates',
    clearDate: 'Effacer les dates',
    utilityBill: "Facture du fournisseur d'électricité",
    equipmentElevation: 'Hauteur de l’équipement',
    planSet: 'Ensemble de plans',
  },
  chats: {
    fileUploadIssue:
      'Nous rencontrons des problèmes avec le téléchargement des fichiers, veuillez réessayer plus tard.',
    others: 'Autres',
    downloading: 'téléchargement',
    downloaded: 'téléchargé',
    fromEnphase: 'Depuis Enphase',
    fromInstaller: 'Depuis l’installateur',
    fromUnknown: 'Depuis Inconnu',
    tier1Admin: 'TIER1_ADMIN',
    tier2Admin: 'TIER2_ADMIN',
    reviewer: 'EXAMINATEUR',
    serviceAdmin: 'SERVICE_ADMIN',
    default: 'par défaut',
    notifyViaEmail:
      'Vous pouvez fermer cette fenêtre après avoir envoyé votre message. Nous vous informerons par courrier électronique lorsqu’une réponse à votre demande sera disponible.',
    query:
      'Cette section peut être utilisée pour poser des questions concernant votre demande d’examen de la conception et pour garder une trace de vos conversations.',
    typeMessage: 'Taper un message',
    communicateWithEnphase: 'Communiquer avec Enphase',
    communicateWithInstaller: 'Communiquer avec l’installateur',
    submitted: 'soumis',
    maxFileSizeError1: 'La taille maximale du fichier doit être de ',
    maxFileSizeError2: ' Mo',
  },
  approvalDialog: {
    documentApprove: 'J’approuve le(s) document(s) d’examen de la conception suivant(s)',
    specificComments: ' Si vous souhaitez faire part de commentaires spécifiques, veuillez les indiquer ci-dessous',
    approveSend: 'Approuver et envoyer à l’installateur',
  },
  fileUpload: {
    fileValidation: 'Veuillez sélectionner un fichier ou entrer des commentaires pour continuer...',
    reasonResubmission: 'Motif de la nouvelle soumission',
    validationReasonResubmission: 'Veuillez indiquer la raison de la nouvelle soumission',
    fileMaxSize: 'La taille du fichier est supérieure à %{fileSize} Mo...',
    fileMaxSizeLabel: 'La taille du fichier est supérieure à ',
    shareComments: 'Si vous souhaitez faire part de commentaires spécifiques, veuillez les indiquer ci-dessous',
    uploadFiles: 'Télécharger les fichiers',
    selectFiles: 'Sélectionner les fichiers',
    dropFile: 'Insérer des fichier ici pour importer',
    or: 'ou',
  },
  infoDialog: {
    notes: 'Notes',
  },
  adminAction: {
    designDocUpload: 'Document de conception téléchargé',
    tier2ApprovalComment: 'Commentaires d’approbation de niveau 2',
    resubmissionRequested: 'Nouvelle soumission demandée',
    uploadSendInstaller: 'Télécharger et envoyer à l’installateur',
    finalDrsUpload:
      'Veuillez télécharger le(s) document(s) final(s) de l’examen de la conception pour le(s) communiquer à l’installateur. Si vous souhaitez approuver les documents téléchargés de niveau 1 et la même chose avec l’installateur, retournez à l’écran précédent et cliquez sur le bouton « Approuver le document de niveau 1 ».',
    drsShareInstaller:
      'Veuillez télécharger le(s) document(s) final(s) de l’examen de la conception pour le(s) communiquer à l’installateur',
    requestToTier2: 'Demande d’approbation de niveau 2',
    tier2Approval:
      'Veuillez télécharger le(s) document(s) de révision de la conception pour le(s) partager avec l’équipe de niveau 2 pour approbation.',
    tier1DocUpload: 'Approuver le document téléchargé par le niveau 1',
    approveTier1Doc: 'Approbation du document de niveau 1',
    modifyUploadDrs:
      'Nous partagerons le(s) document(s) avec les installateurs après approbation. Si vous souhaitez modifier et télécharger un nouveau document d’examen de la conception, revenez à l’écran précédent et cliquez sur « Télécharger et envoyer à l’installateur ».',
    shareInformation: "Veuillez nous communiquer les informations dont vous avez besoin de la part de l'installateur",
  },
  applicationDetails: {
    applicantDetails: 'Détails du demandeur',
    applicationDetails: 'Détails de la demande',
    viewApplication: 'Voir la demande',
  },
  applicationView: {
    systemProjectName: 'Nom du système/projet',
    systemId: 'Identifiant du système',
    type: 'Type',
    solarSize: 'Taille du système solaire (KWc)',
    batteryType: 'Type de batterie',
    batteryQuantity: 'Quantité de batterie',
    storageSize: 'Taille du stockage (kWh)',
    customerName: 'Nom du client',
    zip: 'Code postal',
    State: 'État',
    address: 'Adresse',
    installerName: 'Nom de l’installateur',
    installerCompanyName: 'Nom de la société de l’installateur',
    installerContactNumber: 'Numéro de contact de l’installateur',
    installerEmailAddress: 'Adresse email de l’installateur',
    notes: 'Remarques',
    receivedOn: 'Reçu le',
    uploadedOn: 'Téléchargé le',
    noDoc: 'Pas de documents disponibles',
    resubmissionReceived: 'Nouvelle soumission reçue',
  },
  statusDetails: {
    applicationStatus: 'Statut de la demande',
    updateStatus: 'Statut de la mise à jour',
    downloadfailed: 'Échec du téléchargement',
    download: 'Télécharger',
    dateOfComplete: 'Date d’achèvement',
    update: 'Mettre à jour',
  },
  datePicker: {
    datePickerInline: 'Sélecteur de date en ligne',
    changeDate: 'modifier la date',
  },
  installerAction: {
    downloadReviewDoc: 'Télécharger le document d’examen',
    shareYourFeedback: 'Partager vos commentaires',
    resubmitDoc: 'Renvoyer les informations demandées',
  },
  status: {
    updatedOn: 'Mis à jour le',
    downloadReviewDoc: 'Télécharger le document d’examen',
    totalAmount: 'Montant total à payer',
    exclusiveTaxes: 'hors taxes',
    enphaseService: 'J’ai lu et compris les services Enphase',
    redirectNotification: 'Vous serez redirigé vers la page de paiement pour terminer la transaction',
    noChargeNotification: 'L’enregistrement de cette demande d’examen de la conception ne vous sera pas facturée',
    partOfCourse: ' dans le cadre de votre cours de certification.',
  },
  docUpload: {
    addFile: 'Ajouter un fichier',
    add: 'Ajouter',
    addImage: 'Ajouter des images',
    addDocument: 'Ajouter des documents',
    downloadTemplate: 'Télécharger le modèle',
    shareDocLink: 'Partager le lien du document',
    linkFromGoogleDrive: 'Lien depuis GoogleDrive, etc.',
    giveLink: 'Ajoutez un lien depuis GoogleDrive, Dropbox, etc.',
  },
  buttons: {
    sendInstaller: 'Envoyer à l’installateur',
    requestInformation: "Demander plus d'informations",
    requestTier2: 'Demande d’approbation de niveau 2',
    proceedButtonYes: 'Oui',
    proceedButtonText: 'Continuer',
    closeButtonText: 'Non',
    footerSubmitButton: 'Soumettre',
    back: 'Retour',
    confirm: 'Confirmer',
    footerCloseButton: 'Fermer',
    update: 'Mettre à jour',
    cancel: 'Annuler',
    proceed: 'Continuer',
    view: 'Afficher',
    ok: 'Ok',
    edit: 'Edit',
    saveClose: 'Enregistrer et fermer',
  },
  constants: {
    fieldRequired: 'Ce champ est obligatoire',
    documentFieldRequired: 'Veuillez télécharger un document ou saisir un lien',
    linkError: "Le lien fourni n'est pas valide",
    australiaCountryCode: 'AU',
    applicationIncomplete: 'Demande incomplète',
    newApplicationReceived: 'Nouvelle demande reçue',
    newApplicationAssigned: 'Nouvelle demande attribuée',
    newApplicationSubmitted: 'Nouvelle demande soumise',
    applicationUnderReview: 'Demande en cours d’examen',

    resubmissionRequested: 'Nouvelle soumission demandée',

    resubmissionReceived: 'Nouvelle soumission reçue',
    requestForTier2Approval: 'Approbation de niveau 2 demandée',
    tier2ApplicationAssigned: 'Demande de niveau 2 attribuée',
    tier2ApprovalUnderProgress: 'Approbation de niveau 2 en cours',
    tier2ResubmissionRequested: 'Nouvelle soumission de niveau 2 demandée',
    tier2ResubmissionReceived: 'Nouvelle soumission de niveau 2 reçue',
    resubmissionSubmitted: 'Nouvelle soumission soumise',
    tier2ApprovalCompleted: 'Approbation de niveau 2 terminée',
    applicationReviewCompleted: 'Examen de la demande terminé',
    defaultMessage:
      'Pour télécharger les fichiers, veuillez désactiver le bloqueur de fenêtres contextuelles dans votre navigateur',
    rowsPerPage: 'Lignes par page:',
    of: 'de',
  },
  drsListingTable: {
    noRequestAvailable: 'Aucune demande disponible',
    notAssigned: 'Non attribué',
    application: 'demande',
    messages: 'Messages',
    message: 'Message',
    all: 'Tout',
  },
  fileUploadDialog: {
    documentUpload: 'Checklist des documents à importer',
    fileTypeValidation: 'Veuillez sélectionner le type de fichier pour continuer...',
    fileSizeValidation: 'La taille du fichier est supérieure à %{fileSize} Mo...',
    maxFileSize: 'Taille maximale du fichier',
    addSpecificComment: 'Si vous souhaitez faire part de commentaires spécifiques, veuillez les indiquer ci-dessous',
    poor: 'Médiocre',
    great: 'Excellent',
    feedback: 'Retour',
    recommendSurvey:
      'Sur une échelle de 0 à 10, à combien estimeriez-vous vôtre satisfaction sur l’utilisation de ce site web pour la demande de revue de design ?',
  },
  prefillingDialog: {
    beforeSubmit:
      ' Certains champs de cette application ont été pré-remplis à l’aide de données existantes dans Enlighten. Veuillez lire attentivement les détails avant de soumettre votre demande.',
  },
  basicDetails: {
    projectDetails: 'Détails du projet',
    residential:
      'Résidentiel = contrat avec les propriétaires d’une maison individuelle. Commercial = contrat avec des propriétaires d’immeubles collectifs ou commerciaux.',
    customerName: 'Nom du client',
    zip: 'Code postal',

    owner: 'Propriétaire',
  },
  microInvererDetails: {
    microInverterType: 'Type de micro-onduleur',
    pageHeader: 'Détails du micro-onduleur',
  },
  solarDetails: {
    pageHeader: 'Détails de l’installation photovoltaïque',
    solarSystemSize: 'Taille du système solaire (kWc)',
    storageSize: 'Taille du stockage (kWh)',
    kWh: 'kWh',

    batteryType: 'Type de batterie',
    batteryTypeNote: 'Une IQ Battery 10T est équivalente à 3 IQ Battery 3T',
    batteryQuantity: 'Quantité de batterie',
  },
  notification: {
    noMoreNotification: 'Plus de notifications à afficher',
    noNotification: 'Aucune notification disponible',
    serviceNotification: 'Notifications de service',
    viewAll: 'Tour voir',
  },
  leadManagement: {
    selectSystemID:
      'Sélectionnez l’identifiant du système dans la liste ci-dessous si l’activation du système a déjà été lancée',
    systemNameValidation: 'Veuillez saisir le nom du système',
    createSystem: 'Créer un nouveau système',
    systemName: 'Nom du système',
    type: 'Type',

    siteInformation: 'Informations du site',
    Residential: 'Résidentiel',
    Commercial: 'Commercial',

    thirdParty: 'Propriété de tiers (« ex » : AAE ou location)',
    collapse: 'Réduire',
    expand: 'Développer',
    firstName: 'Prénom',
    lastName: 'Nom de famille',

    email: 'Email',
    phone: 'Téléphone',
    invalidEmail: 'Email invalide',
    residential: 'Résidentiel',
    commercial: 'Commercial',
    other: 'Autre',
    invalidPhoneNumber: 'Numéro de téléphone invalide',
    required: 'Requis',
    enlightenVersion: 'Version d’Enlighten',
    ownerReceive: 'Le propriétaire recevra <b>MyEnlighten</b> pour le contrôle des performances du système.',
    location: 'Localisation',
    streetAddress: 'Adresse',
    zip: 'Code postal',
    zipCode: 'Code postal',
    country: 'Pays',
    state: 'État/Province',
    city: 'Ville',
    noOfInverter: 'Nombre de micro-onduleurs',
    customerName: 'Nom du client',
    enphaseGuideLine:
      'Enphase fournit des conseils et des ressources pour aider les installateurs à concevoir un système dans le respect des paramètres appropriés. L’installateur et l’électricien qualifié sont responsables de la conception et de la mise en œuvre finales.',
    confirm: 'Je confirme ce qui suit',
    authorized:
      'Je suis autorisé à m’inscrire à Enlighten au nom du propriétaire ou de l’hôte du système, qui m’a donné la permission de soumettre ces informations à Enphase en son nom.',
    readUnderstand: 'J’ai lu et compris la',
    privacyPolicy: 'Politique de confidentialité',
    and: 'et les',
    terms: 'Conditions de service',
    policy: 'les « Politiques » d’Enphase',
    acknowledge: 'Le propriétaire ou l’hôte du système a reconnu avoir lu et compris les politiques.',
    informedSystemOwner:
      'J’ai informé le propriétaire ou l’hôte du système que mon entreprise pourra contrôler sa consommation d’énergie et les heures d’utilisation, et qu’il peut révoquer cette autorisation à tout moment',
    termsAndCondition: 'Termes et Conditions',
  },
  serviceDialog: {
    quoteSetUp: 'Faire un devis pour la configuration',
    systemConfiguration: 'Configuration du système',
    bomPricing: 'Nomenclature et prix',
    projectDesign:
      'Ce projet est actuellement conçu par Sofdesk. Il est actuellement inaccessible, mais il sera disponible dès qu’il sera prêt.',
    redirect: 'Vous serez redirigé vers votre tableau de bord sur l’outil de conception et de proposition Solargraf.',
    wishToProceed: 'Souhaitez-vous poursuivre ?',
    designProposalTool: 'Outil de design et de propositions',
    measurementInProgress: 'Mesures en cours',
    permitAssistanceStatus: "Statut de permission d'assistance",
  },
  breadCrumb: {
    dashboard: 'Tableau de bord',
    SGIPApplication: 'Applications SGIP',
  },
};

export default designReviewService;
