import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function HelpIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 27.341 26.341">
      <g transform="translate(0.955 -0.069)">
        <path
          d="M12.68,17.58a.66.66,0,0,1-.65-.66v-.29a4.88,4.88,0,0,1,2.35-4.14A3.17,3.17,0,1,0,9.52,9.81a.67.67,0,0,1-.66.66.65.65,0,0,1-.66-.65h0a4.49,4.49,0,1,1,6.88,3.8,3.61,3.61,0,0,0-1.74,3v.28a.65.65,0,0,1-.66.68Z"
          fill={props.storke}
        />
        <path
          d="M12.69,20a.93.93,0,1,1,.93-.93.93.93,0,0,1-.93.93Zm0-1.31a.39.39,0,1,0,.38.38h0a.38.38,0,0,0-.38-.34Z"
          fill={props.storke}
        />
        <path
          d="M12.69,25.38A12.85,12.85,0,0,1,5,22.76L2.05,24.21a.65.65,0,0,1-.88-.29.62.62,0,0,1,0-.59l1.46-2.92a12.67,12.67,0,1,1,10.06,5ZM5,21.32a.62.62,0,0,1,.42.15,11.4,11.4,0,1,0-1.55-1.56.68.68,0,0,1,.08.71l-.76,1.53,1.52-.76A.59.59,0,0,1,5,21.32Z"
          fill={props.storke}
        />
      </g>
    </SvgIcon>
  );
}

export default HelpIcon;
