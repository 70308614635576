var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { take, put, call, takeLeading } from 'redux-saga/effects';
import { get, patchMethod, post } from '../../utils/api';
import { ChatActionTypes } from './constants';
import settings from '../../utils/settings';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMessageListFlow() {
    var request, drsId, response, _a, messages, unread_messages_count, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(ChatActionTypes.GET_ALL_MESSAGES_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                drsId = request.drsId;
                return [4 /*yield*/, call(get, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/messages/?sort_by=message_date&sort_order=asc")),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                _a = response.result || {}, messages = _a.messages, unread_messages_count = _a.unread_messages_count;
                return [4 /*yield*/, put({
                        type: ChatActionTypes.GET_ALL_MESSAGES_SUCCESS,
                        messages: messages,
                        unread_messages_count: unread_messages_count,
                    })];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_1 = _b.sent();
                return [4 /*yield*/, put({ type: ChatActionTypes.GET_ALL_MESSAGES_FAILURE, message: error_1 })];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
var loading = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setMessageFlow(action) {
    var _a, body, drsId, successCb, response, success, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!!loading) return [3 /*break*/, 7];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                loading = true;
                _a = action.payload, body = _a.body, drsId = _a.drsId, successCb = _a.successCb;
                return [4 /*yield*/, call(post, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/messages/new")),
                        body: body,
                        withBearer: true,
                    })];
            case 2:
                response = _b.sent();
                success = response.success;
                if (!success) return [3 /*break*/, 4];
                return [4 /*yield*/, put({ type: ChatActionTypes.POST_MESSAGE_SUCCESS })];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4:
                loading = false;
                successCb();
                return [3 /*break*/, 7];
            case 5:
                error_2 = _b.sent();
                return [4 /*yield*/, put({ type: ChatActionTypes.POST_MESSAGE_FAILURE, message: error_2 })];
            case 6:
                _b.sent();
                loading = false;
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateChatStatusFlow() {
    var request, _a, drsId, lastMsgDate, response, message, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!true) return [3 /*break*/, 9];
                return [4 /*yield*/, take(ChatActionTypes.MARK_MESSAGES_VIEWED_REQUEST)];
            case 1:
                request = _b.sent();
                _b.label = 2;
            case 2:
                _b.trys.push([2, 6, , 8]);
                _a = request.payload, drsId = _a.drsId, lastMsgDate = _a.lastMsgDate;
                return [4 /*yield*/, call(patchMethod, {
                        url: settings.getDrsUrl("applications/".concat(drsId, "/messages/").concat(lastMsgDate)),
                        withBearer: true,
                    })];
            case 3:
                response = _b.sent();
                if (!response.success) return [3 /*break*/, 5];
                message = (response.result || {}).message;
                return [4 /*yield*/, put({
                        type: ChatActionTypes.MARK_MESSAGES_VIEWED_SUCCESS,
                        message: message,
                    })];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_3 = _b.sent();
                return [4 /*yield*/, put({ type: ChatActionTypes.MARK_MESSAGES_VIEWED_FAILURE, message: error_3 })];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [3 /*break*/, 0];
            case 9: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function watchLastMessageFlow() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(ChatActionTypes.POST_MESSAGE_REQUEST, setMessageFlow)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
