/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  TextField,
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Slide,
  Snackbar,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import Fade from '@material-ui/core/Fade';
import ChatSvg from './images/ChatSvg';
import Icons from './images';
import { isEmpty, find, get, isEqual } from 'lodash';
import moment from 'moment';
import I18n from '../../config/locales';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/nl';
moment.locale(I18n.locale);
const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: theme.spacing(0.4),
    width: 'fit-content',
    borderRadius: theme.spacing(1),
    display: 'flex',
    pointerEvents: 'none',
    padding: theme.spacing(0.4, 1),
    flexDirection: 'column',
    color: '#545456',
    wordBreak: 'break-word',
  },
  listItemLeft: {
    backgroundColor: theme.tertiary.white,
    border: 'solid 1px #c5c5c5',
    alignSelf: 'flex-start',
    marginRight: theme.spacing(6),
  },
  listItemRight: {
    border: 'solid 1px #f37321',
    backgroundColor: 'rgba(243, 115, 33, 0.05)',
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(6),
  },
  chatList: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    background: theme.tertiary.primaryMain,
    color: theme.tertiary.white,
    borderTopRightRadius: theme.spacing(0.5),
    borderTopLeftRadius: theme.spacing(0.5),
  },
  litstItemHeader: {
    fontSize: 'xx-small',
    flex: 'inherit',
  },
  listItemAdminHeader: {
    color: theme.tertiary.primaryMain,
  },
  listItemHeaderRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  senderListItemHeaderRow: {
    justifyContent: 'flex-end',
  },
  dateText: {
    marginLeft: theme.spacing(3),
  },
  contentText: {
    fontSize: 'small',
    overflowWrap: 'anywhere',
    alignSelf: 'flex-start',
    userSelect: 'auto',
  },
  messageSection: {
    flexGrow: 1,
    margin: '0 !important',
  },
  messageAttachmentSection: {
    flexGrow: 1,
    margin: '0 !important',
    '& .MuiInputBase-root': {
      display: 'flex',
      flexDirection: 'column-reverse',
      paddingTop: theme.spacing(2.5),
    },
    '& .MuiInputAdornment-root': {
      marginBottom: theme.spacing(2.5),
    },
  },
  mainDialog: {
    '& .MuiDialog-paper': {
      minWidth: '30%',
      border: '1px solid #F37320',
      borderRadius: '10px 10px 0px 0px',
      minHeight: '70%',
    },
  },
  fabProgress: {
    margin: 5,
  },
  listItemAttachment: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  fileName: {
    paddingLeft: theme.spacing(2),
    fontSize: 'xx-small',
    fontWeight: 'bold',
    overflowWrap: 'anywhere',
    userSelect: 'auto',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(0),
  },
  inputAdornment: {
    alignSelf: 'flex-start',
    width: '-webkit-fill-available',
  },
  selectedAttachment: {
    borderRadius: 0,
    justifyContent: 'space-between',
    flexGrow: 1,
    width: 'inherit',
  },
  dialogContent: {
    padding: 0,
  },
  chatNotes: {
    background: '#f6f6f6',
    padding: theme.spacing(1),
    color: '#939598',
    textAlign: 'center',
    fontSize: 'xx-small',
  },
  dialogActions: {
    alignItems: 'inherit',
    flexDirection: 'column',
  },
  messageInfo: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'grey',
    boxShadow: 'none',
    padding: theme.spacing(0, 1),
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  messageTextFieldRow: {
    display: 'flex',
    margin: '0 !important',
  },
  snackbar: {
    position: 'absolute',
    width: '98%',
    '& .MuiSnackbarContent-root': {
      marginTop: theme.spacing(3),
      backgroundColor: 'grey',
      flexWrap: 'nowrap',
    },
  },
  snackbarPadding: {
    paddingTop: theme.spacing(11.5),
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  chatBoxWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    maxHeight: theme.spacing(72.5),
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftPadding: {
    paddingLeft: theme.spacing(2),
  },
  chatBoxTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    background: theme.tertiary.primaryMain,
    color: theme.tertiary.white,
    '& svg': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  chatBoxTitle: {
    paddingLeft: theme.spacing(1.5),
    fontWeight: 'bold',
  },
}));

const FILE_UPLOAD_SIZE_LIMIT = 100;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScrollDialog(props) {
  const [messageBody, setMessageBody] = useState('');
  const {
    messages = [],
    sendMessage,
    appId,
    fetchAllChats,
    getPresingedURL,
    uploadToS3,
    open,
    handleClose,
    updateChatStatus,
    unreadMessageCount,
    isFromAdmin = false,
    isFromApplicationStatus = false,
    isInstaller,
    getS3DownloadUrl,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [fileUploadStatus, setFileUploadStatus] = useState('idle');
  const [uploadingFileName, setUploadingFileName] = useState('');
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState('');
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const [chatInfoEnabled, setChatInfoEnabled] = useState(false);
  const [sendMessageCount, setSendMessageCount] = useState(0);
  const [totalMessageCount, setTotalMessageCount] = useState(-1);
  const scrollToBottom = () => {
    if (document.getElementById('messageUnorderedList')) {
      document.getElementById('messageUnorderedList').scrollTop =
        document.getElementById('messageUnorderedList').scrollHeight;
    }
  };
  const resetFileUploadStatus = () => {
    setFileUploadStatus('idle');
    setUploadingFileName('');
    setUploadError(false);
  };

  useEffect(() => {
    if (messages.length > 0) {
      const isMessageListUpdated = totalMessageCount !== messages.length;
      const lastMessage = messages[messages.length - 1];
      if (isMessageListUpdated) {
        scrollToBottom();
      }
      if (unreadMessageCount > 0) {
        const lastMessageDate = moment(lastMessage.messageDate).valueOf();
        updateChatStatus({ drsId: appId, lastMsgDate: lastMessageDate });
      }
      setTotalMessageCount(messages.length);
    }
    const interval = setInterval(() => {
      fetchAllChats();
    }, 10000);
    return () => clearInterval(interval);
  }, [fetchAllChats]);

  const handleMessageSend = () => {
    setChatInfoEnabled(true);
    setSendMessageCount(sendMessageCount + 1);
    const body = {
      subject: 'Others',
      body_content: messageBody,
      file_names: isEmpty(uploadingFileName) ? [] : [uploadingFileName],
    };
    sendMessage({
      body,
      drsId: appId,
      successCb: () => {
        fetchAllChats();
      },
    });
    setMessageBody('');
    resetFileUploadStatus();
  };

  const handleMessageBody = (event) => {
    setMessageBody(event.target.value);
  };

  const handleFileUpload = (event) => {
    setFileUploadStatus('in_progress');
    let qPDF = event.target.files[0];
    let fTypeArray = qPDF.name.split('.');
    let fType = fTypeArray[fTypeArray.length - 1];

    let fSizeInMb = qPDF.size / 1024 / 1024;
    let file_name = `${appId}_${'OTHERS'}_${moment().format('DDMMYYYY-hhmmss')}.${fType}`;
    if (fSizeInMb > 1) {
      setUploadError(true);
      setUploadErrorMsg(
        `${I18n.t('chats.maxFileSizeError1')} ${FILE_UPLOAD_SIZE_LIMIT} ${I18n.t('chats.maxFileSizeError2')}`
      );
      setFileUploadStatus('idle');
    } else {
      getPresingedURL({
        fileName: file_name,
        methodType: 'PUT',
        successCb: (presignedURL) => {
          uploadToS3({
            preSignedS3Url: presignedURL,
            fName: file_name,
            fileObj: qPDF,
            isDownload: true,
            successCbS3: () => {
              setFileUploadStatus('success');
              setUploadingFileName(file_name);
            },
            failureCbS3: () => {
              setUploadError(true);
              setUploadErrorMsg(I18n.t('chats.fileUploadIssue'));
              setFileUploadStatus('idle');
            },
          });
        },
        failureCb: () => {
          setUploadError(true);
          setUploadErrorMsg(I18n.t('chats.fileUploadIssue'));
          setFileUploadStatus('idle');
        },
      });
    }
  };

  const getSignedUrl = (fName) => {
    return new Promise((resolve) => {
      getS3DownloadUrl({
        fileName: fName,
        drsId: appId,
        successCb: (presignedURL, fileName) => {
          resolve(presignedURL[fileName]);
        },
        failureCb: () => resolve(false),
      });
    });
  };

  const updateDownloadedFiles = (params) => {
    const { fileName, signedUrl = null, downloadStatus } = params;
    let existingDownloadedFiles = downloadedFiles;
    let fileObj = find(existingDownloadedFiles, { fileName });
    if (fileObj) {
      if (signedUrl) {
        fileObj.signedUrl = signedUrl;
      }
      if (fileName) {
        fileObj.fileName = fileName;
      }
      if (downloadStatus) {
        fileObj.downloadStatus = downloadStatus;
      }
    } else {
      existingDownloadedFiles.push({ fileName, signedUrl, downloadStatus });
    }
    setDownloadedFiles(existingDownloadedFiles);
    fetchAllChats();
  };

  const downloadFile = async (fileUrl, fileName) => {
    updateDownloadedFiles({ fileName, signedUrl: fileUrl, downloadStatus: 'downloading' });
    const url = await fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    updateDownloadedFiles({ fileName, signedUrl: fileUrl, downloadStatus: 'downloaded' });
  };

  const downloadCloudFile = async (fileName) => {
    updateDownloadedFiles({ fileName, downloadStatus: 'downloading' });
    const preSignedUrl = await getSignedUrl(fileName).then((res) => {
      updateDownloadedFiles({ fileName, signedUrl: res, downloadStatus: 'downloading' });
      return res;
    });
    downloadFile(preSignedUrl, fileName);
  };

  const fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpg' && fType !== 'jpeg') {
      fType = 'document';
    }
    if (fType === 'jpg') {
      fType = 'jpeg';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  const renderMessageAttachment = (message) => {
    const { fileNames = [] } = message;
    if (isEmpty(fileNames)) {
      return null;
    }
    return fileNames.map((fileName, index) => {
      const fileState = find(downloadedFiles, ['fileName', fileName]);
      const signedUrl = get(fileState, 'signedUrl');
      const isDownloading = isEqual(get(fileState, 'downloadStatus'), 'downloading');
      return (
        <Box className={classes.listItemAttachment} key={index}>
          {isDownloading && <CircularProgress size={20} className={classes.fabProgress} />}
          {signedUrl ? (
            <IconButton
              onClick={() => downloadFile(signedUrl, fileName)}
              className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
            >
              {fileIcon(fileName)}
            </IconButton>
          ) : (
            <CloudDownloadOutlinedIcon
              fontSize="large"
              onClick={() => downloadCloudFile(fileName)}
              title={fileName}
              className={classes.listItemAttachmentIcon}
            />
          )}
          <ListItemText primary={fileName} disableTypography className={classes.fileName} />
        </Box>
      );
    });
  };

  const getSenderInfo = (type, section = 'label') => {
    const senderTypes = [
      { type: 'TIER1_ADMIN', label: I18n.t('chats.fromEnphase'), isAdmin: true },
      { type: 'TIER2_ADMIN', label: I18n.t('chats.fromEnphase'), isAdmin: true },
      { type: 'REVIEWER', label: I18n.t('chats.fromEnphase'), isAdmin: true },
      { type: 'SERVICE_ADMIN', label: I18n.t('chats.fromEnphase'), isAdmin: true },
      { type: 'INSTALLER', label: I18n.t('chats.fromInstaller'), isAdmin: false },
      { type: 'default', label: I18n.t('chats.fromUnknown'), isAdmin: false },
    ];
    const item = find(senderTypes, { type }) || find(senderTypes, { type: 'default' });
    return item[section];
  };

  const isSnackbarEnabled = () => {
    return chatInfoEnabled && sendMessageCount === 1;
  };

  const renderMessageContent = () => {
    const snackbarEnabled = isSnackbarEnabled();
    return (
      <DialogContent
        className={clsx(classes.dialogContent, snackbarEnabled ? classes.snackbarPadding : {})}
        id="messageUnorderedList"
      >
        <Snackbar
          open={snackbarEnabled}
          TransitionComponent={Fade}
          message={I18n.t('chats.notifyViaEmail')}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          action={
            <IconButton aria-label="close" color="inherit" onClick={() => setChatInfoEnabled(false)}>
              <CloseIcon />
            </IconButton>
          }
          onClose={() => setChatInfoEnabled(false)}
          autoHideDuration={120000}
          className={classes.snackbar}
        />
        <Box className={classes.chatNotes}>{I18n.t('chats.query')}</Box>
        <List className={classes.chatList}>
          {messages.map((message, index) => {
            const lines = message.bodyContent.split('\n');
            const messageByCurrentUser =
              (isInstaller && message.sender.user_type === 'INSTALLER') ||
              (!isInstaller && message.sender.user_type !== 'INSTALLER');
            const listItemStyle = messageByCurrentUser
              ? clsx(classes.listItem, classes.listItemRight)
              : clsx(classes.listItem, classes.listItemLeft);
            return (
              <ListItem button className={listItemStyle} key={index} disableGutters id={message.id}>
                <Box
                  className={
                    messageByCurrentUser
                      ? clsx(classes.listItemHeaderRow, classes.senderListItemHeaderRow)
                      : classes.listItemHeaderRow
                  }
                >
                  {!messageByCurrentUser && (
                    <ListItemText
                      secondary={getSenderInfo(message.sender.user_type)}
                      disableTypography
                      className={
                        getSenderInfo(message.sender.user_type, 'isAdmin')
                          ? clsx(classes.litstItemHeader, classes.listItemAdminHeader)
                          : classes.litstItemHeader
                      }
                    />
                  )}

                  <ListItemText
                    secondary={`${I18n.t('chats.submitted')} ${moment(message.messageDate).format('DD MMM h:mm A')}`}
                    disableTypography
                    className={clsx(classes.litstItemHeader, classes.dateText)}
                  />
                </Box>
                {!isEmpty(message.fileNames) && renderMessageAttachment(message)}
                <Box className={classes.listItemAttachment}>
                  <ListItemText
                    multiline="true"
                    primary={lines.map((line) => (
                      <div key={index}>{line}</div>
                    ))}
                    disableTypography
                    className={classes.contentText}
                  />
                </Box>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    );
  };

  const renderFooterContent = () => {
    return (
      <DialogActions className={classes.dialogActions}>
        <Box className={classes.messageTextFieldRow}>
          <TextField
            size="small"
            variant="outlined"
            id="standard-start-adornment"
            className={fileUploadStatus === 'success' ? classes.messageAttachmentSection : classes.messageSection}
            onChange={(event) => handleMessageBody(event)}
            placeholder={I18n.t('chats.typeMessage')}
            value={messageBody}
            autoFocus={!(isFromAdmin || isFromApplicationStatus)}
            multiline
            rowsMax="5"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx(fileUploadStatus === 'success' && classes.inputAdornment)}
                >
                  {uploadError && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {uploadErrorMsg}
                    </Typography>
                  )}
                  {fileUploadStatus === 'in_progress' && <CircularProgress size={20} className={classes.fabProgress} />}
                  {fileUploadStatus === 'success' ? (
                    <Chip
                      label={uploadingFileName}
                      onDelete={() => resetFileUploadStatus()}
                      variant="outlined"
                      size="small"
                      className={classes.selectedAttachment}
                    />
                  ) : (
                    <IconButton
                      variant="outlined"
                      component="label"
                      style={{ fontSize: '10px', textTransform: 'none', width: '26%' }}
                      size="small"
                      disabled={fileUploadStatus === 'in_progress'}
                      onClick={() => resetFileUploadStatus()}
                    >
                      <AttachmentOutlinedIcon />
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        id="fileUpload"
                        onChange={(e) => handleFileUpload(e)}
                        value=""
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {fileUploadStatus !== 'in_progress' &&
            ((messageBody && messageBody.trim().length !== 0) || fileUploadStatus === 'success') && (
              <Box className={classes.iconWrapper}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleMessageSend()}
                  aria-label="close"
                  size="small"
                  style={{ marginLeft: 4 }}
                >
                  <SendIcon color="primary" />
                </IconButton>
              </Box>
            )}
        </Box>
      </DialogActions>
    );
  };

  if (isFromAdmin || isFromApplicationStatus) {
    return (
      <Box className={classes.chatBoxWrapper}>
        <DialogTitle className={classes.chatBoxTitleWrapper} disableTypography>
          <div className={classes.displayFlex}>
            <ChatSvg />
            <Typography className={classes.chatBoxTitle}>
              {isInstaller ? I18n.t('chats.communicateWithEnphase') : I18n.t('chats.communicateWithInstaller')}
            </Typography>
          </div>
        </DialogTitle>
        {renderMessageContent()}
        {renderFooterContent()}
      </Box>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={() => {}}
      TransitionComponent={Transition}
      scroll={'paper'}
      className={classes.mainDialog}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <div className={classes.displayFlex}>
          <ChatSvg />
          <Typography className={classes.leftPadding}>
            {' '}
            {isInstaller ? I18n.t('chats.communicateWithEnphase') : I18n.t('chats.communicateWithInstaller')}
          </Typography>
        </div>
        <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close" size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {renderMessageContent()}
      {renderFooterContent()}
    </Dialog>
  );
}
