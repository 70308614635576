var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Badge, Box, IconButton, TextField, Tooltip, Typography, withStyles, CircularProgress, Grid, Button, ClickAwayListener, useMediaQuery, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import InfoIcon from '@material-ui/icons/Info';
import FileUploadDialog from '../FileUploadDialog';
import Icons from '../../../containers/Chats/images';
import { get } from 'lodash';
import HelpIcon from '../../../containers/images/Help';
import { DOCUMENT_FIELD_REQUIRED, LINK_ERROR } from '../../../containers/Installer/constants';
import { otherDocumentList, loadTemplateImage } from '../../../utils/constant';
import { Label } from '../../Common/Label';
import I18n from '../../../config/locales';
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        documentWrapper: (_a = {
                padding: theme.spacing(2),
                width: '48%'
            },
            _a[theme.breakpoints.down('md')] = {
                width: '60%',
            },
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
            },
            _a),
        download: {
            width: '100%',
            maxWidth: theme.spacing(65),
            '& label': {
                fontSize: 12,
            },
        },
        addNew: (_b = {
                fontSize: 14,
                color: '#008EEF',
                display: 'inline-block',
                marginTop: theme.spacing(1.25),
                marginBottom: theme.spacing(1),
                cursor: 'pointer'
            },
            _b[theme.breakpoints.down('xs')] = {
                display: 'block',
            },
            _b),
        displayFlex: {
            display: 'flex',
            alignItems: 'center',
        },
        fixedHeight: {
            height: theme.spacing(6),
        },
        star: {
            marginBottom: theme.spacing(2.5),
        },
        close: {
            opacity: 0.8,
            cursor: 'pointer',
            fontSize: theme.spacing(2),
            position: 'absolute',
            top: 0,
            right: 0,
        },
        fileList: {
            marginBottom: theme.spacing(2.5),
        },
        disableForm: {
            pointerEvents: 'none',
            opacity: 0.6,
        },
        iconCoverChat: {
            height: theme.spacing(5),
            width: theme.spacing(5),
            padding: 0,
        },
        listItemAttachmentIcon: {
            pointerEvents: 'auto',
        },
        fileAttachmentWrapper: {
            display: 'inline-block',
            textAlign: 'center',
        },
        fileAttachmentIconWrapper: {
            border: '1px solid #f1f1f1',
            width: theme.spacing(18.75),
            height: theme.spacing(12.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        text: {
            fontSize: theme.spacing(2),
            color: '#000000',
            opacity: '0.7',
            fontWeight: 'bold',
        },
        sampleDownload: (_c = {
                color: '#008EEF',
                display: 'inline-block',
                marginTop: theme.spacing(1.25),
                marginBottom: theme.spacing(1),
                cursor: 'pointer',
                fontSize: '14px',
                textDecoration: 'underline',
                paddingLeft: theme.spacing(10)
            },
            _c[theme.breakpoints.down('xs')] = {
                paddingLeft: theme.spacing(0),
            },
            _c),
        button: (_d = {
                color: '#888888',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 0px 6px #00000029',
                textTransform: 'none',
                borderRadius: theme.spacing(0.5),
                marginLeft: theme.spacing(1),
                height: theme.spacing(4)
            },
            _d[theme.breakpoints.down('sm')] = {
                marginLeft: theme.spacing(0),
            },
            _d),
        buttonText: {
            marginRight: theme.spacing(1),
            fontSize: 11,
        },
        tooltipWrapper: (_e = {
                display: 'flex'
            },
            _e[theme.breakpoints.down('sm')] = {
                display: 'inline-block',
            },
            _e[theme.breakpoints.down('xs')] = {
                // height: theme.spacing(38),
                overflowY: 'scroll',
            },
            _e),
        tooltipContent: {
            padding: theme.spacing(1, 2),
        },
        tooltipHeading: {
            color: '#707070',
            fontSize: 14,
            fontWeight: 'bold',
        },
        tooltipList: {
            fontSize: 12,
            color: '#797979',
            padding: theme.spacing(0.5, 0),
        },
        boxTooltipWrapper: {
            overflow: 'scroll',
        },
        imageElement: (_f = {
                minHeight: '300px',
                minWidth: '300px',
                maxWidth: '800px'
            },
            _f[theme.breakpoints.down('xs')] = {
                width: '600px',
                height: '400px',
            },
            _f),
        disableUpload: {
            opacity: 0.7,
            pointerEvents: 'none',
        },
        shareLink: {
            fontSize: '0.85rem',
        },
        requiredBadge: {
            color: '#f44336',
        },
        labelColor: {
            color: 'rgba(0, 0, 0, 0.54)',
            fontWeight: 400,
            fontSize: theme.spacing(1.75),
            transform: 'translate(0, -4px) scale(1)',
            textTransform: 'initial',
        },
    });
});
var windowHeight = window.innerHeight >= 800 ? window.innerHeight - 400 : window.innerHeight * 0.5;
var windowWidth = window.innerWidth >= 1920 ? 1520 : window.innerWidth - 300;
var LightTooltip = withStyles(function (theme) { return ({
    tooltip: {
        backgroundColor: '#FFFFFF !important',
        color: '#0F0F0F',
        borderRadius: 0,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        padding: theme.spacing(0.5),
        top: theme.spacing(-1),
        minWidth: 100,
        maxWidth: windowWidth,
        maxHeight: windowHeight,
        pointerEvents: 'auto',
        margin: theme.spacing(1),
        // '&::-webkit-scrollbar': {
        //   width: '6px',
        // },
        // '&::-webkit-scrollbar-track': {
        //   backgroundColor: '#fafafa',
        // },
        // '&::-webkit-scrollbar-thumb': {
        //   borderRadius: '3px',
        //   backgroundColor: '#21212169',
        // },
        overflow: 'auto',
    },
    arrow: {
        color: '#FFFFFF !important',
        fontSize: 14,
    },
}); })(Tooltip);
var DocumentUpload = function (props) {
    var classes = useStyles();
    var text = props.text, type = props.type, required = props.required, fileArray = props.fileArray, onDelete = props.onDelete, disableForm = props.disableForm, infoIcon = props.infoIcon, handleDocUrlChange = props.handleDocUrlChange, docUrl = props.docUrl, docUrlKey = props.docUrlKey, projectDetailsErrors = props.projectDetailsErrors, errorKey = props.errorKey, docType = props.docType, fileLinkError = props.fileLinkError;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var paymentCompleted = get(props.application, 'current_status.state_id', 0) || 0 >= 1;
    var _b = useState(false), tooltipOpen = _b[0], setTooltipOpen = _b[1];
    var handlePopupClose = function () {
        setOpen(false);
    };
    var handleFileUpload = function (value) {
        props.onChange(value, props.docType);
    };
    var handleAddDoc = function () {
        setOpen(true);
    };
    var iconArray = ['pdf', 'png', 'jpeg'];
    var fileIcon = function (fName) {
        var fTypeArray = fName.split('.');
        var fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
        if (!iconArray.includes(fType)) {
            fType = 'document';
        }
        var Icon = Icons[fType];
        return _jsx(Icon, {});
    };
    var handleDownload = function (index) {
        props.getS3DownloadURL({
            drsId: props.application.drs_id,
            fileName: fileArray[index].file_name,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            successCb: function (preSignedUrls, name) { return __awaiter(void 0, void 0, void 0, function () {
                var urls, url, a;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            urls = Object.keys(preSignedUrls).map(function (key) { return preSignedUrls[key]; });
                            return [4 /*yield*/, fetch(urls[0])
                                    .then(function (response) { return response.blob(); })
                                    .then(function (blob) { return URL.createObjectURL(blob); })];
                        case 1:
                            url = _a.sent();
                            a = document.createElement('a');
                            a.href = url;
                            a.download = name;
                            a.click();
                            return [2 /*return*/];
                    }
                });
            }); },
        });
    };
    var PhotoChecklistTooltip = function () {
        var srcImage = '';
        var sourceImageLocation = loadTemplateImage[props.countryCode]
            ? loadTemplateImage[props.countryCode]
            : 'BatterySizingTool.png';
        if (props.docType === 'sld_documents') {
            var SLDChangesCountryList = ['DE', 'AT', 'BE', 'NL', 'FR', 'CH'];
            srcImage = SLDChangesCountryList.includes(props.countryCode)
                ? require('../../../containers/images/LineDiagram_de_at_be.png')
                : require('../../../containers/images/LineDiagram.png');
        }
        else if (props.docType === 'site_map_photos') {
            srcImage = require('../../../containers/images/SitePlan.png');
        }
        else if (props.docType === 'load_calculation_documents') {
            srcImage = require("../../../containers/images/".concat(sourceImageLocation));
        }
        return _jsx(Box, { children: srcImage && _jsx("img", { src: srcImage, className: classes.imageElement }) });
    };
    var InfoTooltip = function () {
        return (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _jsx(ClickAwayListener, { onClickAway: function (event) { return setTooltipOpen(false); }, children: _jsx(LightTooltip, { open: tooltipOpen, title: _jsx(PhotoChecklistTooltip, {}), placement: "bottom-start", arrow: true, children: _jsx(IconButton, { onClick: function () { return setTooltipOpen(!tooltipOpen); }, children: _jsx(InfoIcon, {}) }) }) }));
    };
    var DocumentChecklistTooltip = function () {
        return (_jsx(Box, { className: classes.tooltipWrapper, children: _jsx(Box, { className: classes.tooltipContent, children: otherDocumentList.map(function (list, index) { return (_jsx(Typography, { className: classes.tooltipList, children: I18n.t(list) }, index)); }) }) }));
    };
    var DocumentTooltip = function () {
        return (_jsx(LightTooltip, { open: tooltipOpen, title: _jsx(DocumentChecklistTooltip, {}), placement: "right-start", arrow: true, children: _jsxs(Button, { className: classes.button, onClick: function () { return setTooltipOpen(true); }, onMouseEnter: function () { return setTooltipOpen(true); }, onMouseLeave: function () { return setTooltipOpen(false); }, children: [_jsx(Typography, { className: classes.buttonText, children: I18n.t('fileUploadDialog.documentUpload') }), _jsx(HelpIcon, {})] }) }));
    };
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    return (_jsxs(Box, { className: classes.documentWrapper, children: [docType === 'other_documents' ? (_jsxs(Box, { className: clsx(classes.displayFlex, text && classes.fixedHeight), children: [_jsx(Typography, { display: "inline", className: classes.text, children: text }), _jsx(DocumentTooltip, {})] })) : (_jsxs(Box, { className: clsx(classes.displayFlex, text && classes.fixedHeight), children: [_jsx(Typography, { display: "inline", className: classes.text, children: text }), required && _jsx(Badge, { className: classes.requiredBadge, children: " * " }), infoIcon && docType !== 'load_calculation_documents' && _jsx(InfoTooltip, {})] })), !disableForm && (_jsx(Typography, { className: classes.addNew, onClick: handleAddDoc, children: docType === 'load_calculation_documents'
                    ? "+ " + I18n.t('docUpload.addFile')
                    : "+ " + (type === 'Image' ? I18n.t('docUpload.addImage') : I18n.t('docUpload.addDocument')) })), docType === 'load_calculation_documents' &&
                (props.downloadingDoc ? (_jsx(CircularProgress, { color: "primary" })) : !disableForm ? (_jsxs(Fragment, { children: [_jsx(Typography, { className: classes.sampleDownload, onClick: function () {
                                if (props.downloadLoadCalculationFile) {
                                    props.downloadLoadCalculationFile(props.countryCode);
                                }
                            }, children: I18n.t('docUpload.downloadTemplate') }), _jsx(InfoTooltip, {})] })) : (_jsx(Fragment, {}))), _jsx(FileUploadDialog, { type: type, docType: props.docType, fileArray: props.fileArray, open: open, onPopupClose: handlePopupClose, onFileUpload: handleFileUpload, drsId: get(props.application, 'drs_id', '') }), _jsx(Grid, { container: true, spacing: 1, className: classes.fileList, children: fileArray === null || fileArray === void 0 ? void 0 : fileArray.map(function (file, i) {
                    var _a;
                    return (_jsx(Grid, { item: true, children: !file.loading && (_jsxs(Box, { className: classes.fileAttachmentWrapper, children: [_jsxs(Box, { className: classes.fileAttachmentIconWrapper, children: [_jsx(IconButton, { className: clsx(classes.listItemAttachmentIcon, classes.iconCoverChat), onClick: function () { return handleDownload(i); }, children: fileIcon(file.original_file_name) }), _jsx("span", { children: paymentCompleted ? (_jsx(SystemUpdateAltIcon, { onClick: function () { return handleDownload(i); }, className: classes.close })) : (_jsx(CloseIcon, { onClick: function () { return onDelete(i, file.tag); }, className: classes.close })) })] }), _jsx(Typography, { variant: "caption", display: "block", children: (_a = file === null || file === void 0 ? void 0 : file.original_file_name) === null || _a === void 0 ? void 0 : _a.substr(0, 15) })] })) }, "'file'-".concat(i)));
                }) }), _jsx(Typography, { display: "block", className: classes.shareLink, children: I18n.t('docUpload.shareDocLink') }), _jsx(TextField, { className: classes.download, disabled: disableForm, value: docUrl, onChange: function (e) {
                    handleDocUrlChange(e.target.value, docUrlKey);
                }, label: _jsx(Label, { text: isMobile ? I18n.t('docUpload.linkFromGoogleDrive') : I18n.t('docUpload.giveLink'), isRequired: false, className: classes.labelColor }), error: errorKey ? projectDetailsErrors[errorKey] : false, helperText: errorKey && projectDetailsErrors[errorKey]
                    ? fileLinkError
                        ? I18n.t(LINK_ERROR)
                        : I18n.t(DOCUMENT_FIELD_REQUIRED)
                    : '', id: errorKey ? errorKey : "".concat(text).concat(docType) })] }));
};
export default DocumentUpload;
