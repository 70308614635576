/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Box,
  Link,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    '& .MuiTypography-h6': {
      fontSize: '1.25rem',
      color: '#335977',
      fontWeight: 'bold',
    },
  },
  applyForCC: {
    marginTop: theme.spacing(1.5),
  },
  button: {
    fontSize: theme.spacing(1.7),
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4.75),
    padding: theme.spacing(0, 3),
    border: '1px solid',
    borderColor: '#F37321',
    backgroundColor: '#F37321',
    color: '#FFFFFF',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#d15d13',
    },
    overflow: 'scroll',
    textOverflow: 'ellipsis',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  boxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  linkColour: {
    color: '#3B86FF',
    cursor: 'pointer',
  },
}));

const ServicesDialog = (props) => {
  const { open, onClose, redirectToServiceDashboard, createNewApplication, type, allowedCourseIds = [] } = props;
  const classes = useStyles();
  return (
    <Fragment>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className={classes.titleRoot}>
          {`Would you like to initiate ${type} Application for this site ?`}
          {!isEmpty(allowedCourseIds) && (
            <FormControlLabel
              control={
                <Checkbox checked={props.applyForCC} onChange={props.setApplyForCC} name="ApplyForCC" color="primary" />
              }
              label="Create this application for my Enphase Storage certification course"
              className={classes.applyForCC}
            />
          )}
        </DialogTitle>
        <DialogActions className={classes.actions}>
          <Link className={classes.linkColour} onClick={redirectToServiceDashboard}>
            {' '}
            View {type} Dashboard{' '}
          </Link>
          <Box className={classes.boxWrapper}>
            <Button onClick={onClose}> No </Button>
            <Button className={classes.button} onClick={createNewApplication}>
              {' '}
              Yes{' '}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ServicesDialog;
