import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  Divider,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import I18n from '../../../../../config/locales';

const useStyles = makeStyles((theme) => ({
  quoteDialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogTitle: {
    fontSize: 25,
    color: '#4A4A4A',
    fontWeight: 700,
  },
  knowMoreDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontFamily: 'Roboto',
  },
  button: {
    borderRadius: 0,
    color: '#FFFFFF',
    textTransform: 'none',
    fontWeight: 'bold',
    width: theme.spacing(10),
    margin: theme.spacing(2, 2),
  },
}));
const MeasurementPendingDialog = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth={'sm'} fullWidth id="knowMoreVideoDialog">
      <DialogTitle disableTypography className={classes.quoteDialogTitle}>
        <Typography className={classes.dialogTitle}>{I18n.t('serviceDialog.measurementInProgress')}</Typography>
        <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.knowMoreDialogContent}>{I18n.t('serviceDialog.projectDesign')}</DialogContent>
      <Divider />
      <DialogActions>
        <Button
          className={clsx(classes.button)}
          color="primary"
          variant="contained"
          onClick={() => onClose()}
          disableElevation
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeasurementPendingDialog;
