import { combineReducers } from 'redux';
import appReducer from './containers/Layouts/reducer';
import installerReducer from './containers/Installer/reducer';
import chatReducer from './containers/Chats/reducer';
import listingReducer from './containers/DRSListing/reducer';
import adminReducer from './containers/Admin/reducer';
import defaultReducer from './components/Temp/containers/Layouts/reducer';
import leadReducer from './components/Temp/containers/LeadManagement/reducer';
export default combineReducers({
    appReducer: appReducer,
    installerReducer: installerReducer,
    chatReducer: chatReducer,
    listingReducer: listingReducer,
    adminReducer: adminReducer,
    defaultReducer: defaultReducer,
    leadReducer: leadReducer,
});
